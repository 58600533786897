<template>
  <div id="video">
    <div style="padding: 56.25% 0 0 0; position: relative">
      <youtube
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        player-width="100%"
        player-height="100%"
        v-if="youtubeVideoId.length"
        :video-id="youtubeVideoId"
        @ended="onEnded"
        @ready="onYoutubePlayerReady"
      ></youtube>
      <iframe
        v-else
        :src="`${url}${autoplay ? '?autoplay=1' : ''}`"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        :allow="`${autoplay ? 'autoplay; ' : ''} fullscreen`"
        allowfullscreen
        ref="vimeoPlayer"
        id="vimeoPlayer"
      />
    </div>
    <img
      v-if="closeButton"
      class="close"
      src="../../assets/icons/IconeX.svg"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import Player from '@vimeo/player';

export default {
  name: 'VimeoVideo',
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    closeButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
      required: false,
    },
    disableSound: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      duration: null,
      youtubeVideoId: '',
    };
  },
  computed: {
    url() {
      if (!this.videoUrl) return '';

      const url = new URL(this.videoUrl);
      if (this.disableSound) {
        url.searchParams.append('muted', '1');
      }
      return url.toString();
    },
  },
  methods: {
    onPlay() {
      this.$emit('play');
    },
    onPause() {
      this.$emit('stop');
    },
    onEnded() {
      this.$emit('stop');
      this.$emit('end');
    },
    async getDuration(player) {
      let duration = 0;
      try {
        duration = await player?.getDuration();
        this.$emit('getDuration', duration);
      } catch (e) {
        this.$emit('getDuration', duration);
      }
    },
    async onYoutubePlayerReady(event) {
      await this.getDuration(event.target);
    },
  },
  async mounted() {
    if (this.videoUrl && this.videoUrl.includes('vimeo.com')) {
      const player = new Player(this.$refs.vimeoPlayer);
      player.on('play', this.onPlay);
      player.on('pause', this.onPause);
      player.on('ended', this.onEnded);
      await this.getDuration(player);
    } else {
      this.youtubeVideoId = this.$youtube.getIdFromURL(this.videoUrl);
    }
  },
};
</script>

<style lang="scss" scoped>
.close {
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 3rem;
  top: 1rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
</style>
