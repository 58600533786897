import { Conference } from '@/models/conference/conference.model';
import HttpService from '@/services/http/http.service';
import {
  DiscussionGroup,
  ExtendedScheduleItem,
  HttpMethod,
  ScheduleForm,
  ScheduleItem,
} from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import conferenceService from '@/services/conference/conference.service';
import discussionGroupService from '@/services/discussion-group/discussion-group.service';
import { Schedulable } from '@/models/schedule/Schedulable.model';
import { UserEventAppointment } from '@/models/users-appointments/user-event-appointment';
import { getAppointments } from '@/services/users-appointments/users-appointments.service';

enum SchedulableType {
  CONFERENCE,
  DISCUSSION_GROUP,
}

class AgendaService {
  public async getAgenda(eventId: string): Promise<ExtendedScheduleItem[]> {
    const conferences: Promise<Conference>[] = [];
    const discussionGroups: Promise<DiscussionGroup>[] = [];
    let appointmentsPromise: Promise<UserEventAppointment[]>;

    const scheduleItems: ScheduleItem[] = await HttpService.perform<ScheduleItem[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.USER_SCHEDULE]),
    );

    if (scheduleItems) {
      scheduleItems
        .filter((item: ScheduleItem) => !!item.conferenceId)
        .forEach((item) => {
          conferences.push(conferenceService.getConferenceById(item.eventId, item.conferenceId));
        });

      scheduleItems
        .filter((item: ScheduleItem) => !!item.discussionGroupId)
        .forEach((item) => {
          discussionGroups.push(
            discussionGroupService.getById(item.eventId, item.discussionGroupId),
          );
        });

      const scheduleItemsAppointments = scheduleItems.filter(
        (item: ScheduleItem) => !!item.userEventAppointmentId,
      );

      if (scheduleItemsAppointments.length > 0) {
        appointmentsPromise = getAppointments({ eventId: scheduleItemsAppointments[0].eventId });
      }

      const confs = await Promise.all(conferences);
      const mappedConfs = confs.map((conf: Conference) => {
        const sched = scheduleItems.find((sch: ScheduleItem) => sch.conferenceId === conf.id);
        return {
          ...sched,
          conference: conf,
        } as ExtendedScheduleItem;
      });

      const dgs = await Promise.all(discussionGroups);
      const mappedDgs = dgs.map((dg: DiscussionGroup) => {
        const sched = scheduleItems.find((sch: ScheduleItem) => sch.discussionGroupId === dg.id);
        return {
          ...sched,
          discussionGroup: dg,
        } as ExtendedScheduleItem;
      });

      const appointments = (await appointmentsPromise) || [];
      const mappedAppointments = appointments.map((it) => {
        const sched = scheduleItems.find(
          (sch: ScheduleItem) => sch.userEventAppointmentId === it.id,
        );
        return {
          ...sched,
          userEventAppointment: it,
        } as ExtendedScheduleItem;
      });

      return [...mappedConfs, ...mappedDgs, ...mappedAppointments];
    }

    return [];
  }

  public async addConferenceToAgenda(conference: Conference): Promise<ExtendedScheduleItem> {
    return AgendaService.addToAgenda(conference, SchedulableType.CONFERENCE);
  }

  public async addDiscussionGroupToAgenda(
    discussionGroup: DiscussionGroup,
  ): Promise<ExtendedScheduleItem> {
    return AgendaService.addToAgenda(discussionGroup, SchedulableType.DISCUSSION_GROUP);
  }

  private static async addToAgenda(
    schedulableItem: Schedulable,
    type: SchedulableType,
  ): Promise<ExtendedScheduleItem> {
    const scheduleForm: ScheduleForm = {
      conferenceId: type === SchedulableType.CONFERENCE ? schedulableItem.id : undefined,
      discussionGroupId: type === SchedulableType.DISCUSSION_GROUP ? schedulableItem.id : undefined,
    };

    const item = await HttpService.perform<ScheduleItem>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        schedulableItem.eventId,
        HttpHelper.USER_SCHEDULE,
      ]),
      scheduleForm,
    );

    return {
      ...item,
      conference: type === SchedulableType.CONFERENCE ? (schedulableItem as Conference) : undefined,
      discussionGroup:
        type === SchedulableType.DISCUSSION_GROUP
          ? (schedulableItem as DiscussionGroup)
          : undefined,
    };
  }

  public async removeFromAgenda(scheduledItem: ScheduleItem) {
    return HttpService.perform<Conference>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        scheduledItem.eventId,
        HttpHelper.USER_SCHEDULE,
        scheduledItem.id,
      ]),
    );
  }
}

export interface SchedulableItem {
  conference?: Conference;
  discussionGroup?: DiscussionGroup;
}

export default new AgendaService();
