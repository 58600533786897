import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import {
  LoginForm,
  RegisterForm,
  SessionUser,
  MessageType,
  LoadMessagesSuccess,
  NewMessage,
} from '@/models';
import AuthService from '@/services/auth/auth.service';
import UserService from '@/services/user/user.service';
import i18n from '@/i18n';
import {
  DISCONNECT,
  DISCONNECT_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CONNECT_TO_CHAT,
  LOGIN_WITH_SESSION_USER,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  SESSION_VALIDITY,
  SESSION_VALIDITY_ERROR,
  SESSION_VALIDITY_SUCCESS,
  DISCONNECT_FROM_CHAT,
  CONNECT_TO_STAT,
  DISCONNECT_FROM_STAT,
  CONNECT_TO_NOTIFICATION,
} from '@/stores/shared/actions/auth/auth.actions';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import {
  APP_CHATS_MODULE,
  CHATS_LOADMESSAGES_SUCCESS,
  CHATS_NEW_MESSAGE,
  CLEAR_CHATS,
  GET_CHATS,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  CLEAR_LAYOUT_EVENT_ID,
  SHARED_LAYOUT_MODULE,
} from '@/stores/shared/actions/layout/layout.actions';
import {
  connectToChatNamespace,
  disconnectFromChatNamespace,
  getChatSocket,
  setChatSocket,
} from '@/socket/chat-namespace';
import { USER_MODULE_USER } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  connectToStatNamespace,
  disconnectFromStatNamespace,
  getStatSocket,
  setStatSocket,
} from '@/socket/stat-namespace';
import {
  connectToNotificationNamespace,
  getNotificationSocket,
  setNotificationSocket,
} from '@/socket/notification-namespace';
import {
  DISPLAY_NOTIFICATION,
  APP_NOTIFICATION_MODULE,
} from '@/stores/umanize-app/actions/notification/app-notification.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { notifySound } from '@/helpers/notification/notification';

export interface AuthState {
  status: {
    error: string;
    isSending: boolean;
    isCheckingSession: boolean;
    isLoggedIn: boolean;
    isResetSent: boolean;
    isUpdateSent: boolean;
  };
}

const state: AuthState = {
  status: {
    error: '',
    isSending: false,
    isCheckingSession: false,
    isLoggedIn: false,
    isResetSent: false,
    isUpdateSent: false,
  },
};

const actions = {
  async [REGISTER](
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    registerForm: RegisterForm,
  ) {
    commit(REGISTER);
    try {
      const loginData = await UserService.register(registerForm);

      commit(REGISTER_SUCCESS);
      return loginData;
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.register.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(REGISTER_ERROR, error);
    }
    return null;
  },
  async [LOGIN]({ commit, dispatch }: ActionContext<AuthState, RootState>, loginForm: LoginForm) {
    commit(LOGIN);
    try {
      const userData = await AuthService.login(loginForm);

      await dispatch(CONNECT_TO_CHAT, userData.id);
      await dispatch(CONNECT_TO_STAT);
      await dispatch(CONNECT_TO_NOTIFICATION);
      await dispatch(USER_MODULE_USER, userData, { root: true });
      await dispatch(`${APP_CHATS_MODULE}/${GET_CHATS}`, null, { root: true });

      commit(LOGIN_SUCCESS);
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.login.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(LOGIN_ERROR, error);
    }
  },
  [CONNECT_TO_STAT]() {
    if (getStatSocket()) {
      return;
    }
    setStatSocket(connectToStatNamespace());
  },
  [CONNECT_TO_NOTIFICATION]({ dispatch }: ActionContext<AuthState, RootState>) {
    if (getNotificationSocket()) {
      return;
    }
    setNotificationSocket(connectToNotificationNamespace());
    getNotificationSocket().on('send-notification-success', async (payload: Notification) => {
      notifySound.play();

      await dispatch(`${APP_NOTIFICATION_MODULE}/${DISPLAY_NOTIFICATION}`, payload, {
        root: true,
      });
    });
  },
  [CONNECT_TO_CHAT]({ dispatch }: ActionContext<AuthState, RootState>, userId) {
    if (getChatSocket()) {
      return;
    }
    setChatSocket(connectToChatNamespace());
    getChatSocket().on('load-messages-success', async (payload: LoadMessagesSuccess) => {
      await dispatch(`${APP_CHATS_MODULE}/${CHATS_LOADMESSAGES_SUCCESS}`, payload, {
        root: true,
      });
    });
    getChatSocket().on('new-message', async (payload: NewMessage) => {
      const hasSent = payload.userId === userId;

      if (!hasSent) {
        notifySound.play();
      }

      await dispatch(`${APP_CHATS_MODULE}/${CHATS_NEW_MESSAGE}`, payload, {
        root: true,
      });
    });
    getChatSocket().on('new-chat', async () => {
      await dispatch(`${APP_CHATS_MODULE}/${GET_CHATS}`, null, {
        root: true,
      });
    });
  },

  async [SESSION_VALIDITY]({ commit, dispatch }: ActionContext<AuthState, RootState>) {
    commit(SESSION_VALIDITY);

    let err;
    try {
      const isSessionAlive = await AuthService.isSessionAlive();

      if (isSessionAlive) {
        const sessionUser: SessionUser = AuthService.getUserFromToken();

        commit(SESSION_VALIDITY_SUCCESS);
        const userData = await UserService.getUserById(sessionUser.id);
        commit(LOGIN_SUCCESS);
        dispatch(CONNECT_TO_CHAT, userData.id);
        dispatch(CONNECT_TO_STAT);
        dispatch(CONNECT_TO_NOTIFICATION);
        await dispatch(USER_MODULE_USER, userData, { root: true });
        return userData;
      }
    } catch (error) {
      err = error;
    }

    // There might be a real error (for example: invalid unparseable JWT), or
    // the session might be invalid. In both cases, we make sure to update
    // the state to an error.
    commit(SESSION_VALIDITY_ERROR, err || 'invalid session');
    return null;
  },
  async [LOGIN_WITH_SESSION_USER](
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    sessionUser: SessionUser,
  ) {
    commit(LOGIN_WITH_SESSION_USER);
    try {
      const userData = await UserService.getUserById(sessionUser.id);
      commit(LOGIN_SUCCESS);
      await dispatch(CONNECT_TO_CHAT, userData.id);
      await dispatch(CONNECT_TO_STAT);
      await dispatch(CONNECT_TO_NOTIFICATION);
      await dispatch(USER_MODULE_USER, userData, { root: true });
      await dispatch(`${APP_CHATS_MODULE}/${GET_CHATS}`, null, { root: true });
      return userData;
    } catch (err) {
      const error = i18n.t(`auth.login.errors.${err.status}`);
      commit(LOGIN_ERROR, error);
      throw err;
    }
  },
  async [DISCONNECT]({ commit, dispatch }: ActionContext<AuthState, RootState>) {
    await AuthService.disconnect();

    commit(DISCONNECT_SUCCESS);

    await dispatch(DISCONNECT_FROM_CHAT);
    await dispatch(DISCONNECT_FROM_STAT);
    await dispatch(`${APP_CHATS_MODULE}/${CLEAR_CHATS}`, null, { root: true });
    await dispatch(USER_MODULE_USER, null, { root: true });
    await dispatch(`${SHARED_LAYOUT_MODULE}/${CLEAR_LAYOUT_EVENT_ID}`, null, { root: true });
  },
  async [DISCONNECT_FROM_CHAT]() {
    disconnectFromChatNamespace();
  },
  [DISCONNECT_FROM_STAT]() {
    disconnectFromStatNamespace();
  },
};

const mutations = {
  [REGISTER](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [REGISTER_SUCCESS](state) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [REGISTER_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
    };
  },
  [LOGIN](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [LOGIN_SUCCESS](state) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoggedIn: true,
      error: null,
    };
  },
  [LOGIN_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoggedIn: false,
      error,
    };
  },
  [SESSION_VALIDITY](state) {
    state.status = {
      ...state.status,
      isCheckingSession: true,
      error: null,
    };
  },
  [SESSION_VALIDITY_SUCCESS](state) {
    state.status = {
      ...state.status,
      isCheckingSession: false,
      error: null,
    };
  },
  [SESSION_VALIDITY_ERROR](state, error) {
    state.status = {
      ...state.status,
      isCheckingSession: false,
      error,
    };
  },
  [LOGIN_WITH_SESSION_USER](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [DISCONNECT_SUCCESS](state) {
    state.status = {
      ...state.status,
      isLoggedIn: false,
    };
  },
};

const getters = {
  isSending: (state) => (state.status && state.status.isSending) || false,
  isCheckingSession: (state) => (state.status && state.status.isCheckingSession) || false,
  error: (state) => (state.status && state.status.error) || '',
  isLoggedIn: (state) => (state.status && state.status.isLoggedIn) || false,
};

export const AuthModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
