import { StoreOptions } from 'vuex';

import { RootState } from '@/stores/store.model';
import { UmanizeAdminModule } from '@/stores/umanize-admin/umanize-admin.module';
import { UmanizeAppModule } from '@/stores/umanize-app/umanize-app.module';
import { SharedModule } from '@/stores/shared/shared.module';

const initialRootState: RootState = {
  loaded: false,
};

const storeOptions: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',
  devtools: process.env.NODE_ENV !== 'production',
  state: initialRootState,
  modules: {
    UmanizeAppModule,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    UmanizeAdminModule,
    SharedModule,
  },
};

export default storeOptions;
