<template>
  <div class="chat-form chat-form--bottom">
    <v-form class="form">
      <div class="form__data">
        <div class="form-data__picture" v-if="!hideAvatar">
          <user-avatar :avatar="user.avatar" :size="40" />
        </div>

        <div
          role="textbox"
          class="form-data__input"
          ref="currentMessage"
          v-html="currentMessage"
          @input="onMessageChange($event.target.innerHTML)"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="sendMessage"
          contenteditable
          :data-placeholder="$t('chat.message')"
          data-test-id="textarea"
        />
      </div>

      <v-btn
        class="form__controls mr-0"
        text
        color="primaryFont"
        @click.native="toggleEmoji"
        data-test-id="emoji-btn"
      >
        <v-icon>mdi-emoticon-happy</v-icon>
      </v-btn>

      <v-btn
        class="form__controls"
        text
        color="primaryFont"
        @click.native="sendMessage"
        data-test-id="send-btn"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-form>

    <v-emoji-picker v-if="emojisVisible" @select="selectEmoji" />

    <div v-if="tagsVisible" class="tags">
      <v-btn
        v-for="user in otherUsers(user.id)"
        :key="user.id"
        @click="selectTag(user)"
        depressed
        tile
        block
      >
        {{ user.firstName }} {{ user.lastName }}
      </v-btn>
    </div>

    <div class="chat" v-chat-scroll="{ smooth: true, notSmoothOnInit: true, always: false }">
      <div
        v-for="message in chat.messages"
        :key="`chat-message-${message.id}`"
        class="chat__message"
        :class="{ 'chat__message--active-user': isActiveUser(message.attributes.id) }"
      >
        <template v-if="!isActiveUser(message.attributes.id)">
          <div class="message__picture">
            <user-avatar
              :avatar="message.attributes && message.attributes.avatar"
              :thinBorder="true"
            />
          </div>

          <div class="message__content">
            <div class="content__header">
              <span class="header__name">
                {{ message.attributes.firstName }} {{ message.attributes.lastName }}
              </span>

              <span class="content__header--muted">{{ getTimestamp(message.dateCreated) }}</span>
            </div>

            <message-body :message="message" />
          </div>
        </template>

        <div class="active-user" v-else>
          <div class="active-user__content">
            <div class="active-user__header">
              <span class="active-user__name">
                {{ message.attributes.firstName }} {{ message.attributes.lastName }}
              </span>

              <span class="active-user__header--muted">
                {{ getTimestamp(message.dateCreated) }}
              </span>
            </div>

            <message-body :is-active-user="true" :message="message" />
          </div>

          <div class="active-user__picture">
            <user-avatar
              :avatar="message.attributes && message.attributes.avatar"
              :thinBorder="true"
            />
          </div>
        </div>
      </div>

      <v-divider />
    </div>
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';

import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import {
  APP_CHATS_MODULE,
  GET_CHATS_MESSAGES,
  SEND_CHATS_MESSAGE,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import MessageBody from '@/components/message-body/MessageBody.vue';
import DateHelper from '@/helpers/date/date.helper';

export default {
  name: 'GlobalChat',
  components: {
    UserAvatar,
    VEmojiPicker,
    MessageBody,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentMessage: '',
    message: '',
    messageBefore: '',
    emojisVisible: false,
    tagsVisible: false,
  }),
  computed: {
    isActiveUser() {
      return (userId) => this.user.id === userId;
    },
    otherUsers() {
      return (userId) => this.chat.users.filter((u) => u.id !== userId);
    },
  },
  methods: {
    ...mapActions(APP_CHATS_MODULE, [GET_CHATS_MESSAGES, SEND_CHATS_MESSAGE]),
    getTimestamp(datetime) {
      return DateHelper.getTimestamp(DateTime.fromISO(datetime), this.$i18n.locale);
    },
    sendMessage() {
      const message = this.message.trim();

      if (message !== '') {
        this[SEND_CHATS_MESSAGE]({
          id: this.chat.id,
          message,
        });

        this.$refs.currentMessage.innerHTML = '';
        this.message = '';
        this.messageBefore = '';
      }
    },
    selectEmoji(emoji) {
      this.message = `${this.message}${emoji.data}`;
      this.currentMessage = this.message;
    },
    toggleEmoji() {
      this.emojisVisible = !this.emojisVisible;
    },
    selectTag(user) {
      this.tagsVisible = false;
      this.message = `${this.messageBefore}<b><a href="/public-profile/${user.id}">${user.firstName} ${user.lastName}</a></b>&nbsp;`;
      this.currentMessage = this.message;
    },
    onMessageChange(message) {
      this.messageBefore = message;
      this.message = message;
      this.tagsVisible = false;

      if (
        (message.charAt(message.length - 2) === '' || message.charAt(message.length - 2) === ' ') &&
        message.charAt(message.length - 1) === '@'
      ) {
        this.tagsVisible = true;
      }
    },
  },
  mounted() {
    this[GET_CHATS_MESSAGES](this.chat.id);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.chat-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &--bottom {
    flex-direction: column-reverse;
  }
}

#chat {
  display: flex;
  flex-direction: column;
}

.chat {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 0;
  height: 100%;
  font-size: $small-font-size;

  & > :first-child {
    margin-top: auto;
  }

  &__message {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 0.5rem 0.75rem;
    min-height: fit-content;

    &--active-user {
      justify-content: flex-end;
    }
  }

  &__clickable {
    cursor: pointer;
  }
}

.form {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--v-gray-base);

  &__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    padding: 0.75rem;
  }

  &__controls.v-btn {
    min-width: 35px;
    margin-right: 0.75rem;
  }
}

.form-data {
  &__picture {
    width: auto;
    margin-right: 5px;
  }

  &__input {
    flex: 3;
    font-size: 0.75rem;
    padding: 5px;
    margin: 0;
    max-width: 240px;
    border-bottom: 1px solid var(--v-primaryFont-base);
    max-height: 100px;
    overflow-y: scroll;

    &:focus {
      outline: none !important;
    }
  }
}

.message {
  &__picture {
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    flex: 4;
  }
}

.picture {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.content {
  &__header {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    font-weight: $bold;

    &--muted {
      margin-left: 8px;
      font-weight: $regular;
      font-size: $x-small-font-size;
      color: var(--v-secondaryFont-base);
    }
  }
}

.header {
  &__icon {
    color: var(--v-primary-base);
    padding-right: 0.5rem;
  }
}

.active-user {
  display: flex;
  justify-content: flex-end;

  &__picture {
    margin-left: 0.5rem;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    font-weight: $bold;

    &--muted {
      margin-left: 8px;
      font-weight: $regular;
      font-size: $x-small-font-size;
      color: var(--v-gray-base);
      text-align: right;
    }
  }

  &__name {
    text-align: right;
    flex: 1 1 0;
  }
}

.emoji-picker {
  position: absolute;
  bottom: 50px;
  width: 100% !important;
  z-index: 2;
}

.tags {
  display: flex;
  flex-direction: column;
}

[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: var(--v-darkGray-base);
}

@include breakpoint(medium) {
  #chat {
    font-size: $small-font-size;
  }
}
</style>
