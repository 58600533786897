import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';

import exhibitorVisitorService from '@/services/exhibitor-visitor/exhibitor-visitor.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';

import {
  SEARCH_EXHIBITOR_VISITORS,
  SEARCH_EXHIBITOR_VISITORS_ERROR,
  SEARCH_EXHIBITOR_VISITORS_SUCCESS,
} from '@/stores/umanize-app/actions/exhibitor-visitor/app-exhibitor-visitor.actions';
import { ExhibitorVisitor } from '@/models/exhibitor-visitor/exhibitor-visitor.model';
import {
  LEAVE_EXHIBITOR,
  VISIT_EXHIBITOR,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';

export interface ExhibitorVisitorsStatus {
  areLoading: boolean;
  error: ErrorMessage;
}

export interface ExhibitorVisitorsState {
  visitors: ExhibitorVisitor[];
  status: ExhibitorVisitorsStatus;
}

export interface AppExhibitorVisitorState {
  exhibitorVisitors: ExhibitorVisitorsState;
}

const exhibitorVisitorsState: ExhibitorVisitorsState = {
  visitors: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const state: AppExhibitorVisitorState = {
  exhibitorVisitors: exhibitorVisitorsState,
};

const actions = {
  async [SEARCH_EXHIBITOR_VISITORS](
    { commit }: ActionContext<AppExhibitorVisitorState, RootState>,
    payload: {
      eventId: string;
      exhibitorId: string;
    },
  ) {
    commit(SEARCH_EXHIBITOR_VISITORS);

    try {
      const visitors = await exhibitorVisitorService.getExhibitorVisitors(payload);
      commit(SEARCH_EXHIBITOR_VISITORS_SUCCESS, visitors);
    } catch (error) {
      commit(SEARCH_EXHIBITOR_VISITORS_ERROR, error);
    }
  },
  async [VISIT_EXHIBITOR](
    { commit }: ActionContext<AppExhibitorVisitorState, RootState>,
    exhibitorVisitor: ExhibitorVisitor,
  ) {
    commit(VISIT_EXHIBITOR, exhibitorVisitor);
  },
  async [LEAVE_EXHIBITOR](
    { commit }: ActionContext<AppExhibitorVisitorState, RootState>,
    { userId }: { userId: string },
  ) {
    commit(LEAVE_EXHIBITOR, userId);
  },
};

const mutations = {
  [SEARCH_EXHIBITOR_VISITORS](state: AppExhibitorVisitorState) {
    state.exhibitorVisitors = {
      ...state.exhibitorVisitors,
      status: {
        ...state.exhibitorVisitors.status,
        areLoading: true,
        error: null,
      },
    };
  },
  [SEARCH_EXHIBITOR_VISITORS_SUCCESS](
    state: AppExhibitorVisitorState,
    visitors: ExhibitorVisitor[],
  ) {
    state.exhibitorVisitors = {
      visitors: [...visitors],
      status: {
        ...state.exhibitorVisitors.status,
        areLoading: false,
        error: null,
      },
    };
  },
  [SEARCH_EXHIBITOR_VISITORS_ERROR](state: AppExhibitorVisitorState, error: ErrorMessage) {
    state.exhibitorVisitors = {
      ...state.exhibitorVisitors,
      status: {
        ...state.exhibitorVisitors.status,
        areLoading: false,
        error,
      },
    };
  },
  [VISIT_EXHIBITOR](state: AppExhibitorVisitorState, exhibitorVisitor: ExhibitorVisitor) {
    state.exhibitorVisitors.visitors = [...state.exhibitorVisitors.visitors, exhibitorVisitor];
  },
  [LEAVE_EXHIBITOR](state: AppExhibitorVisitorState, userId: string) {
    state.exhibitorVisitors.visitors = [
      ...state.exhibitorVisitors.visitors.filter(
        (exhibitorVisitor) => exhibitorVisitor.userId !== userId,
      ),
    ];
  },
};

const exhibitorVisitorsStateGetter = (state: AppExhibitorVisitorState): ExhibitorVisitorsState =>
  state.exhibitorVisitors;

const exhibitorVisitorsStatus = (
  _,
  { exhibitorVisitorsState }: { exhibitorVisitorsState: ExhibitorVisitorsState },
): ExhibitorVisitorsStatus => exhibitorVisitorsState?.status || null;

const exhibitorVisitorsAreLoading = (
  _,
  { exhibitorVisitorsStatus }: { exhibitorVisitorsStatus: ExhibitorVisitorsStatus },
): boolean => exhibitorVisitorsStatus?.areLoading || false;

const exhibitorVisitorsError = (
  _,
  { exhibitorVisitorsStatus }: { exhibitorVisitorsStatus: ExhibitorVisitorsStatus },
): ErrorMessage => exhibitorVisitorsStatus?.error || null;

const exhibitorVisitors = (
  _,
  { exhibitorVisitorsState }: { exhibitorVisitorsState: ExhibitorVisitorsState },
): ExhibitorVisitor[] => exhibitorVisitorsState?.visitors || [];

const getters = {
  exhibitorVisitorsState: exhibitorVisitorsStateGetter,
  exhibitorVisitors,
  exhibitorVisitorsStatus,
  exhibitorVisitorsAreLoading,
  exhibitorVisitorsError,
};

export const ExhibitorVisitorModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
