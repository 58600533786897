import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import {
  DISPLAY_NOTIFICATION,
  CLOSE_NOTIFICATION,
} from '@/stores/umanize-app/actions/notification/app-notification.actions';
import { Notification } from '@/models';

export interface NotificationState {
  notification: Notification;
  hasNotification: boolean;
}

const state: NotificationState = {
  notification: null,
  hasNotification: false,
};

const actions = {
  async [DISPLAY_NOTIFICATION](
    { commit }: ActionContext<NotificationState, RootState>,
    notification: Notification,
  ) {
    commit(DISPLAY_NOTIFICATION, notification);
  },
  async [CLOSE_NOTIFICATION]({ commit }: ActionContext<NotificationState, RootState>) {
    commit(CLOSE_NOTIFICATION);
  },
};

const mutations = {
  [DISPLAY_NOTIFICATION](state, notification) {
    state.notification = {
      ...notification,
    };
    state.hasNotification = true;
  },
  [CLOSE_NOTIFICATION](state) {
    state.hasNotification = false;
  },
};

const getters = {
  notification: (state) => state.notification,
  hasNotification: (state) => state.hasNotification,
};

export const NotificationModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
