import { HttpMethod, PublicProfile } from '@/models';
import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

export interface NewChatForm {
  userIds: string[];
}

class PublicProfileService {
  public async getPublicProfileByUserId(userId: string): Promise<PublicProfile> {
    return httpService.perform<PublicProfile>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [userId, 'profile']),
    );
  }

  public async searchUserProfiles(searchText: string, eventId = ''): Promise<PublicProfile> {
    const evenIdParam = eventId ? `&eventId=${eventId}` : '';
    return httpService.perform<PublicProfile>(
      HttpMethod.get,
      `${httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [
        httpHelper.PROFILES_ENDPOINT,
      ])}?searchText=${encodeURIComponent(searchText)}${evenIdParam}`,
    );
  }
}

export default new PublicProfileService();
