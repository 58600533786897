import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { CONTENT_MODULE } from '@/stores/agnostic/actions/content/agnostic-content.actions';

export const ADMIN_CONTENT_MODULE = `${UMANIZE_ADMIN_MODULE}/${CONTENT_MODULE}`;

export const CLEAR_CONTENT_CATEGORY = 'CLEAR_CONTENT_CATEGORY';

export const SAVE_CONTENT_CATEGORY = 'SAVE_CONTENT_CATEGORY';

export const ADD_CONTENT_CATEGORY = 'ADD_CONTENT_CATEGORY';
export const ADD_CONTENT_CATEGORY_SUCCESS = 'ADD_CONTENT_CATEGORY_SUCCESS';
export const ADD_CONTENT_CATEGORY_ERROR = 'ADD_CONTENT_CATEGORY_ERROR';

export const UPDATE_CONTENT_CATEGORY = 'UPDATE_CONTENT_CATEGORY';
export const UPDATE_CONTENT_CATEGORY_SUCCESS = 'UPDATE_CONTENT_CATEGORY_SUCCESS';
export const UPDATE_CONTENT_CATEGORY_ERROR = 'UPDATE_CONTENT_CATEGORY_ERROR';

export const DELETE_CONTENT_CATEGORY = 'DELETE_CONTENT_CATEGORY';
export const DELETE_CONTENT_CATEGORY_SUCCESS = 'DELETE_CONTENT_CATEGORY_SUCCESS';
export const DELETE_CONTENT_CATEGORY_ERROR = 'DELETE_CONTENT_CATEGORY_ERROR';

export const CLEAR_CONTENT_ITEM = 'CLEAR_CONTENT_ITEM';

export const SAVE_CONTENT_ITEM = 'SAVE_CONTENT_ITEM';

export const ADD_CONTENT_ITEM = 'ADD_CONTENT_ITEM';
export const ADD_CONTENT_ITEM_SUCCESS = 'ADD_CONTENT_ITEM_SUCCESS';
export const ADD_CONTENT_ITEM_ERROR = 'ADD_CONTENT_ITEM_ERROR';

export const UPDATE_CONTENT_ITEM = 'UPDATE_CONTENT_ITEM';
export const UPDATE_CONTENT_ITEM_SUCCESS = 'UPDATE_CONTENT_ITEM_SUCCESS';
export const UPDATE_CONTENT_ITEM_ERROR = 'UPDATE_CONTENT_ITEM_ERROR';

export const DELETE_CONTENT_ITEM = 'DELETE_CONTENT_ITEM';
export const DELETE_CONTENT_ITEM_SUCCESS = 'DELETE_CONTENT_ITEM_SUCCESS';
export const DELETE_CONTENT_ITEM_ERROR = 'DELETE_CONTENT_ITEM_ERROR';

export const UPLOAD_CONTENT_ITEM_VIDEO = 'UPLOAD_CONTENT_ITEM_VIDEO';
export const UPLOAD_CONTENT_ITEM_VIDEO_SUCCESS = 'UPLOAD_CONTENT_ITEM_VIDEO_SUCCESS';
export const UPLOAD_CONTENT_ITEM_VIDEO_ERROR = 'UPLOAD_CONTENT_ITEM_VIDEO_ERROR';
export const DELETE_CONTENT_ITEM_VIDEO = 'DELETE_CONTENT_ITEM_VIDEO';
