import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const MENU_MODULE = 'MenuModule';

export const APP_MENU_MODULE = `${UMANIZE_APP_MODULE}/${MENU_MODULE}`;

export const TOGGLE_NAV = 'TOGGLE_NAV';

export const SET_SELECTED_CONFERENCE_ROOM = 'SELECTED_CONFERENCE_ROOM';

export const CLEAR_ACTIONS_SHOWN = 'CLEAR_ACTIONS_SHOWN';

export const SET_SEARCH = 'SET_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_DISPLAY_MENU = 'SET_DISPLAY_MENU';
