import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { MATCHING_MODULE } from '@/stores/agnostic/actions/matching/matching.actions';

export const ADMIN_MATCHING_MODULE = `${UMANIZE_ADMIN_MODULE}/${MATCHING_MODULE}`;

export const SAVE_MATCHING_QUESTION = 'SAVE_MATCHING_QUESTION';

export const CREATE_MATCHING_QUESTION = 'CREATE_MATCHING_QUESTION';
export const CREATE_MATCHING_QUESTION_SUCCESS = 'CREATE_MATCHING_QUESTION_SUCCESS';
export const CREATE_MATCHING_QUESTION_ERROR = 'CREATE_MATCHING_QUESTION_ERROR';

export const UPDATE_MATCHING_QUESTION = 'UPDATE_MATCHING_QUESTION';
export const UPDATE_MATCHING_QUESTION_SUCCESS = 'UPDATE_MATCHING_QUESTION_SUCCESS';
export const UPDATE_MATCHING_QUESTION_ERROR = 'UPDATE_MATCHING_QUESTION_ERROR';

export const DELETE_MATCHING_QUESTION = 'DELETE_MATCHING_QUESTION';
export const DELETE_MATCHING_QUESTION_SUCCESS = 'DELETE_MATCHING_QUESTION_SUCCESS';
export const DELETE_MATCHING_QUESTION_ERROR = 'DELETE_MATCHING_QUESTION_ERROR';

export const SAVE_MATCHING_ANSWER = 'SAVE_MATCHING_ANSWER';

export const CREATE_MATCHING_ANSWER = 'CREATE_MATCHING_ANSWER';
export const CREATE_MATCHING_ANSWER_SUCCESS = 'CREATE_MATCHING_ANSWER_SUCCESS';
export const CREATE_MATCHING_ANSWER_ERROR = 'CREATE_MATCHING_ANSWER_ERROR';

export const UPDATE_MATCHING_ANSWER = 'UPDATE_MATCHING_ANSWER';
export const UPDATE_MATCHING_ANSWER_SUCCESS = 'UPDATE_MATCHING_ANSWER_SUCCESS';
export const UPDATE_MATCHING_ANSWER_ERROR = 'UPDATE_MATCHING_ANSWER_ERROR';

export const DELETE_MATCHING_ANSWER = 'DELETE_MATCHING_ANSWER';
export const DELETE_MATCHING_ANSWER_SUCCESS = 'DELETE_MATCHING_ANSWER_SUCCESS';
export const DELETE_MATCHING_ANSWER_ERROR = 'DELETE_MATCHING_ANSWER_ERROR';

export const CLEAR_MATCHING_QUESTION = 'CLEAR_MATCHING_QUESTION';
