import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';

import { GeneralAssembly } from '@/models';
import GeneralAssemblyService from '@/services/general-assembly/general-assembly.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_ALL_ASSEMBLIES,
  GET_ALL_ASSEMBLIES_ERROR,
  GET_ALL_ASSEMBLIES_SUCCESS,
  GET_ASSEMBLY_BY_ID,
  GET_ASSEMBLY_BY_ID_ERROR,
  GET_ASSEMBLY_BY_ID_SUCCESS,
} from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';

export interface AssembliesStatus {
  error: ErrorMessage;
  areLoading: boolean;
  isSaving: boolean;
}

export interface AssembliesState {
  generalAssemblies: GeneralAssembly[];
  status: AssembliesStatus;
}

export interface CurrentAssemblyStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface CurrentAssemblyState {
  generalAssembly: GeneralAssembly;
  status: CurrentAssemblyStatus;
}

const actions = {
  async getAssemblies({ commit }: ActionContext<AssembliesState, RootState>, eventId: string) {
    commit(GET_ALL_ASSEMBLIES);

    try {
      const generalAssemblies = await GeneralAssemblyService.getAll(eventId);
      commit(GET_ALL_ASSEMBLIES_SUCCESS, generalAssemblies);
    } catch (error) {
      commit(GET_ALL_ASSEMBLIES_ERROR, error);
    }
  },
  async getAssembly(
    { commit }: ActionContext<CurrentAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
    },
  ) {
    commit(GET_ASSEMBLY_BY_ID);

    try {
      const generalAssembly = await GeneralAssemblyService.getById(
        payload.eventId,
        payload.assemblyId,
      );
      commit(GET_ASSEMBLY_BY_ID_SUCCESS, generalAssembly);
    } catch (error) {
      commit(GET_ASSEMBLY_BY_ID_ERROR, error);
    }
  },
};

export const AgnosticGeneralAssemblyModule = {
  actions,
};
