import { ActionContext } from 'vuex';
import i18n from '@/i18n';
import { RootState } from '@/stores/store.model';
import { Relation } from '@/models/relation/relation.model';
import {
  ADD_TO_CONTACTS,
  ADD_TO_CONTACTS_ERROR,
  ADD_TO_CONTACTS_SUCCESS,
  GET_RELATIONS,
  GET_RELATIONS_ERROR,
  GET_RELATIONS_SUCCESS,
  REMOVE_FROM_CONTACTS,
  REMOVE_FROM_CONTACTS_ERROR,
  REMOVE_FROM_CONTACTS_SUCCESS,
  SEARCH_CONTACT,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import RelationsService from '@/services/relations/relations.service';

export interface RelationsState {
  relations: Relation[];
  searchTerm: string;
  status: {
    error: string;
    isLoading: boolean;
    isUpdating: boolean;
    isLoaded: boolean;
  };
}

const state: RelationsState = {
  relations: [],
  searchTerm: '',
  status: {
    error: '',
    isLoading: true,
    isUpdating: false,
    isLoaded: false,
  },
};

const actions = {
  async [GET_RELATIONS]({ commit, getters }: ActionContext<RelationsState, RootState>) {
    if (getters.isLoaded) {
      return;
    }

    commit(GET_RELATIONS);

    try {
      const contacts = await RelationsService.getRelations();
      commit(GET_RELATIONS_SUCCESS, contacts);
    } catch (err) {
      const error = i18n.t(`relations.errors.${err.status}`);
      commit(GET_RELATIONS_ERROR, error);
    }
  },
  async [SEARCH_CONTACT]({ commit }: ActionContext<RelationsState, RootState>, searchTerm: string) {
    commit(SEARCH_CONTACT, searchTerm);
  },
  async [ADD_TO_CONTACTS](
    { commit }: ActionContext<RelationsState, RootState>,
    relation: Relation,
  ) {
    commit(ADD_TO_CONTACTS);

    try {
      await RelationsService.addToContacts(relation);
      commit(ADD_TO_CONTACTS_SUCCESS, relation);
    } catch (err) {
      const error = i18n.t(`relations.errors.${err.status}`);
      commit(ADD_TO_CONTACTS_ERROR, error);
    }
  },
  async [REMOVE_FROM_CONTACTS](
    { commit }: ActionContext<RelationsState, RootState>,
    relation: Relation,
  ) {
    commit(REMOVE_FROM_CONTACTS);

    try {
      await RelationsService.removeFromContacts(relation);
      commit(REMOVE_FROM_CONTACTS_SUCCESS, relation);
    } catch (err) {
      const error = i18n.t(`relations.errors.${err.status}`);
      commit(REMOVE_FROM_CONTACTS_ERROR, error);
    }
  },
};

const mutations = {
  [GET_RELATIONS](state: RelationsState) {
    state.status = {
      ...state.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_RELATIONS_SUCCESS](state: RelationsState, relations: Relation[]) {
    state.status = {
      ...state.status,
      isLoading: false,
      isLoaded: true,
      error: null,
    };
    state.relations = relations;
  },
  [GET_RELATIONS_ERROR](state: RelationsState, error) {
    state.status = {
      ...state.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
    state.relations = [];
  },
  [SEARCH_CONTACT](state: RelationsState, searchTerm: string) {
    state.searchTerm = searchTerm;
  },
  [ADD_TO_CONTACTS](state: RelationsState) {
    state.status = {
      ...state.status,
      isUpdating: true,
      error: null,
    };
  },
  [ADD_TO_CONTACTS_SUCCESS](state: RelationsState, relation: Relation) {
    const { relations } = state;
    relations.push(relation);

    state.relations = relations;
    state.status = {
      ...state.status,
      isUpdating: false,
      isLoaded: true,
      error: null,
    };
  },
  [ADD_TO_CONTACTS_ERROR](state: RelationsState, error) {
    state.status = {
      ...state.status,
      isUpdating: false,
      isLoaded: false,
      error,
    };
  },
  [REMOVE_FROM_CONTACTS](state: RelationsState) {
    state.status = {
      ...state.status,
      isUpdating: true,
      error: null,
    };
  },
  [REMOVE_FROM_CONTACTS_SUCCESS](state: RelationsState, relation: Relation) {
    state.status = {
      ...state.status,
      isUpdating: false,
      isLoaded: true,
      error: null,
    };
    state.relations = state.relations.filter((r: Relation) => r.id !== relation.id);
  },
  [REMOVE_FROM_CONTACTS_ERROR](state: RelationsState, error) {
    state.status = {
      ...state.status,
      isUpdating: false,
      isLoaded: false,
      error,
    };
  },
};

const selectors = {
  relations: (state: RelationsState) => state.relations,
  searchTerm: (state: RelationsState) => state.searchTerm,
  filteredContacts: (state: RelationsState) =>
    state.relations.filter(
      (r: Relation) =>
        r.firstName.toUpperCase().includes(state.searchTerm.toUpperCase()) ||
        r.lastName.toUpperCase().includes(state.searchTerm.toUpperCase()),
    ),
  isLoading: (state: RelationsState) => (state.status && state.status.isLoading) || false,
  isUpdating: (state: RelationsState) => (state.status && state.status.isUpdating) || false,
  isLoaded: (state: RelationsState) => (state.status && state.status.isLoaded) || false,
  error: (state: RelationsState) => (state.status && state.status.error) || '',

  isConnected: (_, { relations }) => (id: string) => !!relations.find((r: Relation) => r.id === id),
};

export const RelationsModule = {
  namespaced: true,
  actions,
  getters: selectors,
  mutations,
  state,
};
