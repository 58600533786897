import { ActionContext } from 'vuex';

import { DateTime } from 'luxon';
import { RootState } from '@/stores/store.model';
import {
  ConferenceRoom,
  Conference,
  SurveyQuestion,
  SurveyAnswer,
  SocketChatMessage,
  ChatMessage,
} from '@/models';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import DateUtil from '@/helpers/date/date.helper';
import {
  CONFERENCE_NEW_SURVEY_EVENT,
  connectToConferenceNamespace,
  disconnectFromConferenceNamespace,
} from '@/socket/conference-namespace';
import {
  CONFERENCE_NEW_SURVEY,
  SURVEY_ANSWER_QUESTION,
  SURVEY_MODULE,
} from '@/stores/umanize-app/actions/survey/survey.actions';
import {
  CONFERENCE_ADD_ANSWER_SURVEY_EVENT,
  connectToConferenceSpeakerNamespace,
  disconnectFromConferenceSpeakerNamespace,
} from '@/socket/conference-speaker-namespace';
import {
  AgnosticConferenceModule,
  ConferenceRoomsState,
  ConferenceRoomsStatus,
  ConferenceRoomState,
  ConferenceRoomStatus,
  ConferencesState,
  ConferencesStatus,
  CurrentConferenceState,
  CurrentConferenceStatus,
} from '@/stores/agnostic/modules/conference/agnostic-conference.module';
import {
  CONFERENCE_CHAT_LOAD_MESSAGES,
  CONFERENCE_CHAT_LOAD_MESSAGES_ERROR,
  CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS,
  CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS,
  CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS,
  CONFERENCE_SEND_CHATS_MESSAGE,
  CONNECT_TO_CONFERENCE_SOCKET,
  CONNECT_TO_CONFERENCE_SPEAKER_SOCKET,
  DISCONNECT_FROM_CONFERENCE_SOCKET,
  DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET,
  SELECT_CONFERENCE_ROOM,
  MODERATE_CONFERENCE_CHAT_MESSAGE,
  CLEAR_CONFERENCE_CHAT_MESSAGES,
  SET_NEXT_CONFERENCE,
} from '@/stores/umanize-app/actions/conference/app-conference.actions';
import {
  CLEAR_CONFERENCE_ROOM,
  GET_CONFERENCE,
  GET_CONFERENCE_ERROR,
  GET_CONFERENCE_ROOM,
  GET_CONFERENCE_ROOM_ERROR,
  GET_CONFERENCE_ROOM_SUCCESS,
  GET_CONFERENCE_ROOMS,
  GET_CONFERENCE_ROOMS_ERROR,
  GET_CONFERENCE_ROOMS_SUCCESS,
  GET_CONFERENCE_SUCCESS,
  GET_CONFERENCES_FOR_EVENT,
  GET_CONFERENCES_FOR_EVENT_ERROR,
  GET_CONFERENCES_FOR_EVENT_SUCCESS,
  GET_CONFERENCES_FOR_ROOM,
  GET_CONFERENCES_FOR_ROOM_ERROR,
  GET_CONFERENCES_FOR_ROOM_SUCCESS,
  GO_TO_NEXT_CONFERENCE_IN_ROOM,
  GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS,
  GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { ChatsState } from '@/stores/umanize-app/modules/chats/chats.module';
import { addAppConferenceSocketListeners } from '@/stores/umanize-app/modules/conference/app-conference.helper';
import DataUtil from '@/helpers/data/data.helper';

export interface ConferenceMessageStatus {
  areLoading: boolean;
  isModerating: boolean;
  error: ErrorMessage;
}

export interface ConferenceMessagesState {
  messages: Map<string, SocketChatMessage>;
  pagination: {
    limit: number;
    lastFetchedDate: string;
  };
  status: ConferenceMessageStatus;
}

export interface NextConferenceState {
  eventId: string;
  nextConferenceId: string;
}
export interface AppConferenceState {
  currentConference: CurrentConferenceState;
  conferences: ConferencesState;
  conferenceRooms: ConferenceRoomsState;
  currentConferenceRoom: ConferenceRoomState;
  conferenceMessages: ConferenceMessagesState;
  nextConference: NextConferenceState;
}

const currentConference: CurrentConferenceState = {
  conference: null,
  status: {
    error: null,
    isLoading: true,
    isOver: false,
  },
};

const conferenceMessages: ConferenceMessagesState = {
  messages: new Map(),
  pagination: {
    limit: 10,
    lastFetchedDate: DateTime.local().toISO(),
  },
  status: {
    isModerating: false,
    areLoading: true,
    error: null,
  },
};

const conferences: ConferencesState = {
  conferences: [],
  status: {
    error: null,
    isLoading: true,
  },
};

const conferenceRooms: ConferenceRoomsState = {
  selectedConferenceRoom: null,
  conferenceRooms: [],
  status: {
    error: null,
    isLoading: true,
  },
};

const currentConferenceRoom: ConferenceRoomState = {
  conferenceRoom: null,
  status: {
    isLoading: true,
    error: null,
  },
};

const nextConference: NextConferenceState = {
  eventId: null,
  nextConferenceId: null,
};

const state: AppConferenceState = {
  currentConference,
  conferences,
  conferenceRooms,
  currentConferenceRoom,
  conferenceMessages,
  nextConference,
};

let conferenceSocket;

const actions = {
  [GET_CONFERENCES_FOR_EVENT]: AgnosticConferenceModule.actions.getConferencesForEvent,
  [GET_CONFERENCE_ROOMS]: AgnosticConferenceModule.actions.getConferenceRooms,
  [GET_CONFERENCE]: AgnosticConferenceModule.actions.getConference,
  [GET_CONFERENCE_ROOM]: AgnosticConferenceModule.actions.getConferenceRoom,
  [GET_CONFERENCES_FOR_ROOM]: AgnosticConferenceModule.actions.getConferencesForRoom,
  [CLEAR_CONFERENCE_ROOM]: AgnosticConferenceModule.actions.clearConferenceRoom,
  [GO_TO_NEXT_CONFERENCE_IN_ROOM]: AgnosticConferenceModule.actions.goToNextConferenceInRoom,
  [CONNECT_TO_CONFERENCE_SOCKET](
    context: ActionContext<AppConferenceState, RootState>,
    { conferenceId, userId }: { conferenceId: string; userId: string },
  ) {
    conferenceSocket = connectToConferenceNamespace(conferenceId);

    conferenceSocket.on(CONFERENCE_NEW_SURVEY_EVENT, async (surveys: SurveyQuestion[]) => {
      await context.dispatch(`${SURVEY_MODULE}/${CONFERENCE_NEW_SURVEY}`, surveys, {
        root: true,
      });
    });

    addAppConferenceSocketListeners(conferenceSocket, context, userId);
  },
  [CONNECT_TO_CONFERENCE_SPEAKER_SOCKET](
    context: ActionContext<AppConferenceState, RootState>,
    { conferenceId, userId }: { conferenceId: string; userId: string },
  ) {
    conferenceSocket = connectToConferenceSpeakerNamespace(conferenceId);

    conferenceSocket.on(
      CONFERENCE_ADD_ANSWER_SURVEY_EVENT,
      async (payload: { conferenceId: string; answer: SurveyAnswer }) => {
        await context.dispatch(`${SURVEY_MODULE}/${SURVEY_ANSWER_QUESTION}`, payload, {
          root: true,
        });
      },
    );

    conferenceSocket.on(CONFERENCE_NEW_SURVEY_EVENT, async (surveys: SurveyQuestion[]) => {
      await context.dispatch(`${SURVEY_MODULE}/${CONFERENCE_NEW_SURVEY}`, surveys, {
        root: true,
      });
    });

    addAppConferenceSocketListeners(conferenceSocket, context, userId);
  },
  [DISCONNECT_FROM_CONFERENCE_SOCKET]({ commit }: ActionContext<AppConferenceState, RootState>) {
    commit(CLEAR_CONFERENCE_CHAT_MESSAGES);
    disconnectFromConferenceNamespace(conferenceSocket);
  },
  [DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET]() {
    disconnectFromConferenceSpeakerNamespace(conferenceSocket);
  },
  [CONFERENCE_CHAT_LOAD_MESSAGES](
    { commit }: ActionContext<ChatsState, RootState>,
    payload: { eventId: string; conferenceId: string },
  ) {
    conferenceSocket.emit(
      'conference-chat-load-messages',
      payload.eventId,
      payload.conferenceId,
      state.conferenceMessages.pagination.lastFetchedDate,
      state.conferenceMessages.pagination.limit,
    );
    commit(CONFERENCE_CHAT_LOAD_MESSAGES);
  },
  async [SELECT_CONFERENCE_ROOM](
    { commit }: ActionContext<ConferenceRoomsState, RootState>,
    conferenceRoom: ConferenceRoom,
  ) {
    commit(SELECT_CONFERENCE_ROOM, conferenceRoom);
  },
  async [CONFERENCE_SEND_CHATS_MESSAGE](
    _: ActionContext<ChatsState, RootState>,
    payload: {
      eventId: string;
      conferenceId: string;
      message: string;
    },
  ) {
    conferenceSocket.emit(
      'conference-create-message',
      payload.eventId,
      payload.conferenceId,
      payload.message,
    );
  },
  [MODERATE_CONFERENCE_CHAT_MESSAGE](
    { commit }: ActionContext<ChatsState, RootState>,
    payload: {
      eventId: string;
      conferenceId: string;
      message: ChatMessage;
    },
  ) {
    conferenceSocket.emit(
      'conference-moderate-message',
      payload.eventId,
      payload.conferenceId,
      payload.message.id,
      payload.message.isModerated,
    );
    commit(MODERATE_CONFERENCE_CHAT_MESSAGE);
  },
};

const mutations = {
  [GET_CONFERENCE](state: AppConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_SUCCESS](state: AppConferenceState, conference) {
    state.currentConference = {
      ...state.currentConference,
      status: {
        ...state.currentConference.status,
        isLoading: false,
        error: null,
      },
      conference: {
        ...conference,
      },
    };
  },
  [GET_CONFERENCE_ERROR](state: AppConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: false,
      error,
    };
  },
  [GET_CONFERENCE_ROOM](state: AppConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_ROOM_SUCCESS](state: AppConferenceState, conferenceRoom) {
    state.currentConferenceRoom = {
      ...state.currentConferenceRoom,
      status: {
        ...state.currentConferenceRoom.status,
        isLoading: false,
        error: null,
      },
      conferenceRoom: {
        ...conferenceRoom,
      },
    };
  },
  [GET_CONFERENCE_ROOM_ERROR](state: AppConferenceState, error) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: false,
      error,
    };
  },
  [GET_CONFERENCES_FOR_EVENT](state: AppConferenceState) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCES_FOR_EVENT_SUCCESS](state: AppConferenceState, conferences: Conference[]) {
    state.conferences = {
      status: {
        ...state.conferences.status,
        isLoading: false,
        error: null,
      },
      conferences: [...conferences],
    };
  },
  [GET_CONFERENCES_FOR_EVENT_ERROR](state: AppConferenceState, error: ErrorMessage) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: false,
      error,
    };
  },
  [GET_CONFERENCE_ROOMS](state: AppConferenceState) {
    state.conferenceRooms.status = {
      ...state.conferenceRooms.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_ROOMS_SUCCESS](state: AppConferenceState, conferenceRooms: ConferenceRoom[]) {
    state.conferenceRooms = {
      ...state.conferenceRooms,
      status: {
        ...state.conferenceRooms.status,
        isLoading: false,
        error: null,
      },
      conferenceRooms: [...conferenceRooms],
    };
  },
  [GET_CONFERENCE_ROOMS_ERROR](state: AppConferenceState, error: ErrorMessage) {
    state.conferenceRooms.status = {
      ...state.conferenceRooms.status,
      isLoading: false,
      error,
    };
  },
  [GET_CONFERENCES_FOR_ROOM](state: AppConferenceState) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCES_FOR_ROOM_SUCCESS](state: AppConferenceState, conferences: Conference[]) {
    state.conferences = {
      status: {
        ...state.conferences.status,
        isLoading: false,
        error: null,
      },
      conferences: state.conferences.conferences
        .map((conf) => {
          const confWithRoomDetails = conferences.find((c) => c.id === conf.id);
          if (confWithRoomDetails) {
            return {
              ...conf,
              ...confWithRoomDetails,
            };
          }
          return conf;
        })
        .concat(
          conferences.filter(
            (c) => !state.conferences.conferences.some((existingConf) => existingConf.id === c.id),
          ),
        ),
    };
  },
  [GET_CONFERENCES_FOR_ROOM_ERROR](state: AppConferenceState, error: ErrorMessage) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: false,
      error,
    };
  },
  [SELECT_CONFERENCE_ROOM](state: AppConferenceState, conferenceRoom: ConferenceRoom) {
    state.currentConferenceRoom = {
      ...state.currentConferenceRoom,
      conferenceRoom: {
        ...conferenceRoom,
      },
    };
  },
  [CLEAR_CONFERENCE_ROOM](state: AppConferenceState) {
    state.currentConferenceRoom = {
      ...state.currentConferenceRoom,
      conferenceRoom: null,
    };
  },
  [GO_TO_NEXT_CONFERENCE_IN_ROOM](state: AppConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: true,
      error: null,
      isOver: false,
    };
  },
  [GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS](state: AppConferenceState) {
    state.currentConference = {
      ...state.currentConference,
      status: {
        ...state.currentConference.status,
        isLoading: false,
        error: null,
        isOver: true,
      },
    };
  },
  [GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR](state: AppConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: false,
      isOver: false,
      error,
    };
  },
  [CONFERENCE_CHAT_LOAD_MESSAGES](state: AppConferenceState) {
    state.conferenceMessages.status = {
      ...state.conferenceMessages.status,
      areLoading: true,
    };
  },
  [CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS](state: AppConferenceState, messages) {
    state.conferenceMessages.status = {
      ...state.conferenceMessages.status,
      areLoading: false,
    };

    state.conferenceMessages.pagination = {
      ...state.conferenceMessages.pagination,
      lastFetchedDate:
        (messages.length && messages[messages.length - 1].created) ||
        state.conferenceMessages.pagination.lastFetchedDate,
    };

    messages.forEach((message) => state.conferenceMessages.messages.set(message.id, message));
    state.conferenceMessages.messages = new Map(state.conferenceMessages.messages);
  },
  [CONFERENCE_CHAT_LOAD_MESSAGES_ERROR](state: AppConferenceState, error) {
    state.conferenceMessages.status = {
      ...state.conferenceMessages.status,
      error,
    };
  },
  [CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS](state: AppConferenceState, message) {
    state.conferenceMessages.messages.set(message.id, message);
    state.conferenceMessages.messages = new Map(state.conferenceMessages.messages);
  },
  [MODERATE_CONFERENCE_CHAT_MESSAGE](state: AppConferenceState) {
    state.conferenceMessages.status = {
      ...state.conferenceMessages.status,
      isModerating: true,
    };
  },
  [CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS](state: AppConferenceState, message) {
    state.conferenceMessages.messages.set(message.id, message);
    state.conferenceMessages.messages = new Map(state.conferenceMessages.messages);
  },
  [CLEAR_CONFERENCE_CHAT_MESSAGES](state: AppConferenceState) {
    state.conferenceMessages = {
      ...state.conferenceMessages,
      messages: new Map<string, SocketChatMessage>(),
    };
  },
  [SET_NEXT_CONFERENCE](state: AppConferenceState, nextConference) {
    state.nextConference = {
      ...state.nextConference,
      ...nextConference,
    };
  },
};

const getters = {
  currentState: (state: AppConferenceState): CurrentConferenceState => state.currentConference,
  conference: (_, { currentState }: { currentState: CurrentConferenceState }): Conference =>
    currentState?.conference || null,
  currentConferenceStatus: (
    _,
    { currentState }: { currentState: CurrentConferenceState },
  ): CurrentConferenceStatus => currentState?.status || null,
  conferenceIsLoading: (
    _,
    { currentConferenceStatus }: { currentConferenceStatus: CurrentConferenceStatus },
  ): boolean => currentConferenceStatus?.isLoading || false,
  conferenceError: (
    _,
    { currentConferenceStatus }: { currentConferenceStatus: CurrentConferenceStatus },
  ): ErrorMessage => currentConferenceStatus?.error || null,

  conferencesState: (state: AppConferenceState): ConferencesState => state.conferences,
  conferences: (_, { conferencesState }: { conferencesState: ConferencesState }): Conference[] =>
    conferencesState?.conferences || [],
  nextConferences: (_, { conferences }: { conferences: Conference[] }) =>
    conferences.filter((conference) => DateUtil.isNowOrAfter(conference.endTime)),
  conferencesStatus: (
    _,
    { conferencesState }: { conferencesState: ConferencesState },
  ): ConferencesStatus => conferencesState?.status || null,
  conferencesAreLoading: (
    _,
    { conferencesStatus }: { conferencesStatus: ConferencesStatus },
  ): boolean => conferencesStatus?.isLoading || false,
  conferencesError: (
    _,
    { conferencesStatus }: { conferencesStatus: ConferencesStatus },
  ): ErrorMessage => conferencesStatus?.error || null,

  conferenceRoomsState: (state: AppConferenceState): ConferenceRoomsState => state.conferenceRooms,
  selectedConferenceRoom: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoom => conferenceRoomsState?.selectedConferenceRoom || null,
  conferenceRooms: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoom[] => conferenceRoomsState?.conferenceRooms || [],
  conferenceRoomsStatus: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoomsStatus => conferenceRoomsState?.status,
  conferenceRoomsAreLoading: (
    _,
    { conferenceRoomsStatus }: { conferenceRoomsStatus: ConferenceRoomsStatus },
  ): boolean => conferenceRoomsStatus?.isLoading || false,
  conferenceRoomsError: (
    _,
    { conferenceRoomsStatus }: { conferenceRoomsStatus: ConferenceRoomsStatus },
  ): ErrorMessage => conferenceRoomsStatus?.error || null,

  conferenceRoomState: (state: AppConferenceState): ConferenceRoomState =>
    state.currentConferenceRoom,
  conferenceRoom: (
    _,
    { conferenceRoomState }: { conferenceRoomState: ConferenceRoomState },
  ): ConferenceRoom => conferenceRoomState?.conferenceRoom || null,
  conferenceRoomStatus: (
    _,
    { currentState }: { currentState: CurrentConferenceState },
  ): CurrentConferenceStatus => currentState?.status || null,
  conferenceRoomIsLoading: (
    _,
    { conferenceRoomStatus }: { conferenceRoomStatus: ConferenceRoomStatus },
  ): boolean => conferenceRoomStatus?.isLoading || false,
  conferenceRoomError: (
    _,
    { conferenceRoomStatus }: { conferenceRoomStatus: ConferenceRoomStatus },
  ): ErrorMessage => conferenceRoomStatus?.error || null,

  conferenceMessagesState: (state: AppConferenceState): ConferenceMessagesState =>
    state.conferenceMessages,
  messages: (
    _,
    { conferenceMessagesState }: { conferenceMessagesState: ConferenceMessagesState },
  ): SocketChatMessage[] =>
    DataUtil.sortByStringDesc(
      Array.from(conferenceMessagesState?.messages.values() || []),
      'created',
    ),
  moderatedMessages: (_, { messages }: { messages: SocketChatMessage[] }): SocketChatMessage[] =>
    messages?.filter((item) => !item.isModerated) || [],
  conferenceMessagesStatus: (
    _,
    { conferenceMessagesState }: { conferenceMessagesState: ConferenceMessagesState },
  ): ConferenceMessageStatus => conferenceMessagesState?.status || null,
  conferenceMessagesAreLoading: (
    _,
    { conferenceMessagesStatus }: { conferenceMessagesStatus: ConferenceMessageStatus },
  ): boolean => conferenceMessagesStatus?.areLoading || false,
  conferenceMessagesError: (
    _,
    { conferenceMessagesStatus }: { conferenceMessagesStatus: ConferenceMessageStatus },
  ): ErrorMessage => conferenceMessagesStatus?.error || null,

  nextConference: (state: AppConferenceState): NextConferenceState => state.nextConference || null,
};

export const AppConferenceModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
