import { UserEventAppointment } from '@/models/users-appointments/user-event-appointment';
import HttpService from '@/services/http/http.service';
import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import { NewUserEventAppointment } from '@/models/users-appointments/new-user-event-appointment';
import { UserHasEventAppointment } from '@/models/users-appointments/user-has-event-appointment';

type GetAppointmentsParameters = {
  eventId: string;
};
export const getAppointments = ({
  eventId,
}: GetAppointmentsParameters): Promise<UserEventAppointment[]> =>
  HttpService.perform<UserEventAppointment[]>(
    HttpMethod.get,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.APPOINTMENT_ENDPOINT,
    ]),
  );

type CreateAppointmentParameters = {
  eventId: string;
  payload: NewUserEventAppointment;
};
export const createAppointment = ({
  eventId,
  payload,
}: CreateAppointmentParameters): Promise<UserEventAppointment> =>
  HttpService.perform<UserEventAppointment>(
    HttpMethod.post,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.APPOINTMENT_ENDPOINT,
    ]),
    payload,
  );

type DeleteAppointmentParameters = {
  eventId: string;
  appointmentId: string;
};
export const deleteAppointment = ({
  eventId,
  appointmentId,
}: DeleteAppointmentParameters): Promise<void> =>
  HttpService.perform<void>(
    HttpMethod.delete,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.APPOINTMENT_ENDPOINT,
      appointmentId,
    ]),
  );

type CheckIfAnyUserHasAppointmentsSlotsParameters = {
  eventId: string;
};
export const checkIfAnyUserHasAppointmentsSlots = ({
  eventId,
}: CheckIfAnyUserHasAppointmentsSlotsParameters): Promise<UserHasEventAppointment[]> =>
  HttpService.perform<UserHasEventAppointment[]>(
    HttpMethod.get,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.APPOINTMENT_ENDPOINT,
      HttpHelper.USER_ENDPOINT,
    ]),
  );
