/* eslint-disable */
import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { AdminTrainingLessonModel } from '@/models/training/admin/section/lesson/admin-training-lesson.model';
import { Stream } from '@/models/stream/stream.model';

class TrainingLessonService {
  public async getAll(
    eventId: string,
    trainingId: string,
    sectionId: string,
  ): Promise<AdminTrainingLessonModel[]> {
    return HttpService.perform<AdminTrainingLessonModel[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        sectionId,
        HttpHelper.TRAINING_LESSON,
      ]),
    );
  }

  public async get(
    eventId: string,
    trainingId: string,
    trainingSectionId,
    lessonId: string,
  ): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<AdminTrainingLessonModel>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
        HttpHelper.TRAINING_LESSON,
        lessonId,
      ]),
    );
  }

  public async complete({
    eventId,
    trainingId,
    sectionId,
    lessonId,
    score,
    nbAttempts,
    isDone,
  }: {
    eventId: string;
    trainingId: string;
    sectionId: string;
    lessonId: string;
    score?: number;
    nbAttempts?: number;
    isDone?: boolean;
  }): Promise<any> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        sectionId,
        HttpHelper.TRAINING_LESSON,
        lessonId,
        HttpHelper.TRAINING_COMPLETE_LESSON,
      ]),
      { score, nbAttempts, isDone },
    );
  }

  public async create({
    eventId,
    sectionId,
    trainingId,
    lesson,
  }: {
    eventId: string;
    lesson: AdminTrainingLessonModel;
    sectionId: string;
    trainingId: string;
  }): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        sectionId,
        HttpHelper.TRAINING_LESSON,
      ]),
      lesson,
    );
  }

  public async update({
    eventId,
    sectionId,
    trainingId,
    lesson,
  }: {
    eventId: string;
    lesson: AdminTrainingLessonModel;
    sectionId: string;
    trainingId: string;
  }): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<any>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        sectionId,
        HttpHelper.TRAINING_LESSON,
        lesson.id,
      ]),
      lesson,
    );
  }

  public async delete(section: AdminTrainingLessonModel): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<AdminTrainingLessonModel>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        section.eventId,
        HttpHelper.TRAINING,
        section.id,
      ]),
    );
  }

  public async deleteLesson(
    eventId: string,
    sectionId: string,
    trainingId: string,
    lesson: AdminTrainingLessonModel,
  ): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<any>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        sectionId,
        HttpHelper.TRAINING_LESSON,
        lesson.id,
      ]),
    );
  }

  public async publish(
    eventId: string,
    trainingId: string,
    trainingSectionId: string,
    published: Date,
  ): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<AdminTrainingLessonModel>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
        HttpHelper.TRAINING_SECTIONS_PUBLISH,
      ]),
      { published },
    );
  }

  public async unpublish(
    eventId: string,
    trainingId: string,
    trainingSectionId: string,
  ): Promise<AdminTrainingLessonModel> {
    return HttpService.perform<AdminTrainingLessonModel>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
        HttpHelper.TRAINING_SECTIONS_PUBLISH,
      ]),
    );
  }

  public async generateCertificate(
    eventId: string,
    idTraining: string,
    userId: string,
  ): Promise<Stream> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        idTraining,
        HttpHelper.GENERATE_CERTIFICATE,
        userId,
      ]),
    );
  }
}

export default new TrainingLessonService();
