import {
  EventUserOrPublicProfile,
  HttpMethod,
  MatchingAnswer,
  MatchingQuestion,
  MatchingResult,
} from '@/models';
import QuestionnaireUtils from '@/helpers/questionnaire/questionnaire.helper';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import eventServiceInstance, { EventService } from '../event/event.service';

export interface AnswerQuestionRequest {
  eventId: string;
  questionId: string;
  answerIds: string[];
}

export class MatchingService {
  private eventService: EventService;

  matchingKey = 'sm';

  constructor(eventService: EventService) {
    this.eventService = eventService;
  }

  public async getQuestions(eventId: string): Promise<MatchingQuestion[]> {
    const questions: MatchingQuestion[] = await HttpService.perform<MatchingQuestion[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
      ]),
    );

    return QuestionnaireUtils.mapQuestionStoredAnswers({
      eventId,
      questions,
      key: this.matchingKey,
    }) as MatchingQuestion[];
  }

  public async getQuestion(payload: {
    eventId: string;
    questionId: string;
  }): Promise<MatchingQuestion> {
    return HttpService.perform<MatchingQuestion>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        payload.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        payload.questionId,
      ]),
    );
  }

  public async deleteQuestion(eventId: string, questionId: string): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        questionId,
      ]),
    );
  }

  public async answerQuestion({
    eventId,
    questionId,
    answerIds,
  }: AnswerQuestionRequest): Promise<void> {
    const result = HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        questionId,
        HttpHelper.USER_ANSWERS,
      ]),
      {
        answerIds,
      },
    );

    QuestionnaireUtils.storeAnswers({
      eventId,
      questionId,
      answerIds,
      key: this.matchingKey,
    });

    return result;
  }

  public async getResults(eventId: string): Promise<MatchingResult[]> {
    const eventUsersPromise = this.eventService.getUserForEvent(eventId, true);
    const matchingResultsPromise = HttpService.perform<MatchingResult[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.RESULTS,
      ]),
    );
    const [eventUsers, matchingResults] = await Promise.all([
      eventUsersPromise,
      matchingResultsPromise,
    ]);

    return matchingResults.map((mr: MatchingResult) => ({
      ...mr,
      ticket: eventUsers.find((u: EventUserOrPublicProfile) => u.id === mr.id)?.ticket,
    }));
  }

  public async removeAnswers(eventId: string): Promise<void> {
    // There is no way to remove answers from the backend, so we just
    // remove them from the frontend.
    QuestionnaireUtils.removeAnswers({ key: this.matchingKey, eventId });
  }

  public async createQuestion(questionForm: MatchingQuestion): Promise<MatchingQuestion> {
    return HttpService.perform<MatchingQuestion>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        questionForm.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
      ]),
      questionForm,
    );
  }

  public async updateQuestion(questionForm: MatchingQuestion): Promise<MatchingQuestion> {
    return HttpService.perform<MatchingQuestion>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        questionForm.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        questionForm.id,
      ]),
      questionForm,
    );
  }

  public async createQuestionAnswer(answer: MatchingAnswer): Promise<MatchingAnswer> {
    return HttpService.perform<MatchingAnswer>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        answer.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        answer.questionId,
        HttpHelper.ANSWERS,
      ]),
      answer,
    );
  }

  public async updateQuestionAnswer(answer: MatchingAnswer): Promise<MatchingAnswer> {
    return HttpService.perform<MatchingAnswer>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        answer.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        answer.questionId,
        HttpHelper.ANSWERS,
        answer.id,
      ]),
      answer,
    );
  }

  public async deleteQuestionAnswer(answer: MatchingAnswer): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        answer.eventId,
        HttpHelper.SMART_MATCHING,
        HttpHelper.QUESTIONS,
        answer.questionId,
        HttpHelper.ANSWERS,
        answer.id,
      ]),
    );
  }
}

export default new MatchingService(eventServiceInstance);
