import { ErrorMessage } from '@/models/error-message/error-message.model';
import { Stream } from '@/models/stream/stream.model';
import {
  AgnosticStreamModule,
  CurrentStreamState,
  StreamsState,
  StreamsStatus,
  StreamState,
  StreamStatus,
} from '@/stores/agnostic/modules/stream/agnostic-stream.module';
import {
  GET_ALL_STREAMS,
  GET_ALL_STREAMS_ERROR,
  GET_ALL_STREAMS_SUCCESS,
  GET_STREAM,
  GET_STREAM_ERROR,
  GET_STREAM_SUCCESS,
} from '@/stores/agnostic/actions/stream/agnostic-stream.actions';
import {
  streamsState,
  streamState,
} from '@/stores/umanize-admin/modules/stream/admin-stream.module';

export const state: StreamState = {
  stream: streamState,
  streams: streamsState,
};

const actions = {
  [GET_ALL_STREAMS]: AgnosticStreamModule.actions.getStreams,
  [GET_STREAM]: AgnosticStreamModule.actions.getStream,
};

const mutations = {
  [GET_ALL_STREAMS](state: StreamState) {
    state.streams.status = {
      ...state.streams.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_ALL_STREAMS_SUCCESS](state: StreamState, streams: Stream[]) {
    state.streams = {
      ...state.streams,
      status: {
        ...state.streams.status,
        areLoading: false,
        error: null,
      },
      streams,
    };
  },
  [GET_ALL_STREAMS_ERROR](state: StreamState, error: ErrorMessage) {
    state.streams.status = {
      ...state.streams.status,
      areLoading: false,
      error,
    };
  },
  [GET_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_STREAM_SUCCESS](state: StreamState, stream: Stream) {
    state.stream = {
      ...state.stream,
      status: {
        ...state.stream.status,
        isLoading: false,
        error: null,
      },
      stream,
    };
  },
  [GET_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isLoading: false,
      error,
    };
  },
};

const getters = {
  streamState: (state: StreamState): CurrentStreamState => state.stream,
  stream: (_, { streamState }: { streamState: CurrentStreamState }): Stream =>
    streamState?.stream || null,
  streamStatus: (_, { streamState }: { streamState: CurrentStreamState }): StreamStatus =>
    streamState?.status || null,
  streamIsLoading: (_, { streamStatus }: { streamStatus: StreamStatus }): boolean =>
    streamStatus?.isLoading || false,
  streamIsSaving: (_, { streamStatus }: { streamStatus: StreamStatus }): boolean =>
    streamStatus?.isSaving || false,
  streamError: (_, { streamStatus }: { streamStatus: StreamStatus }): ErrorMessage =>
    streamStatus?.error || null,

  streamsState: (state: StreamState): StreamsState => state.streams,
  streams: (_, { streamsState }: { streamsState: StreamsState }): Stream[] =>
    streamsState?.streams || [],
  streamsStatus: (_, { streamsState }: { streamsState: StreamsState }): StreamsStatus =>
    streamsState?.status || null,
  streamsAreLoading: (_, { streamsStatus }: { streamsStatus: StreamsStatus }): boolean =>
    streamsStatus?.areLoading || false,
  streamsError: (_, { streamsStatus }: { streamsStatus: StreamsStatus }): ErrorMessage =>
    streamsStatus?.error || null,
};

export const AppStreamModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
