import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const getAssemblyAdminNamespace = (assemblyId: string) => `/admin-assembly-${assemblyId}`;

export const connectToAssemblyAdminNamespace = (assemblyId: string): Socket => {
  const namespace = getAssemblyAdminNamespace(assemblyId);
  return connectToNamespace(namespace);
};

export const disconnectFromAssemblyAdminNamespace = (assemblySocket: Socket) =>
  assemblySocket.disconnect();
