<template>
  <v-snackbar v-if="message" v-model="isOpened" timeout="-1" :color="message.type">
    {{ message.text }}
    <v-btn text @click.native="close">{{ $t('globals.close') }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: ['message', 'isSnackbarOpened'],
  data: () => ({
    isOpened: false,
  }),
  methods: {
    close() {
      this.$emit('close');
    },
  },
  watch: {
    isSnackbarOpened(value) {
      this.isOpened = value;
    },
  },
  mounted() {
    this.isOpened = this.isSnackbarOpened;
  },
};
</script>
