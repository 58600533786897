import { ContentCategory, ContentItem } from '@/models';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_CATEGORIES_ERROR,
  GET_CONTENT_CATEGORIES_SUCCESS,
  GET_CONTENT_CATEGORY,
  GET_CONTENT_CATEGORY_ERROR,
  GET_CONTENT_CATEGORY_SUCCESS,
  GET_CONTENT_ITEM,
  GET_CONTENT_ITEM_ERROR,
  GET_CONTENT_ITEM_SUCCESS,
  GET_CONTENT_ITEMS,
  GET_CONTENT_ITEMS_ERROR,
  GET_CONTENT_ITEMS_SUCCESS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';
import {
  AgnosticContentModule,
  CategoriesState,
  CategoriesStatus,
  CategoryState,
  CategoryStatus,
  ItemsState,
  ItemsStatus,
  ItemState,
  ItemStatus,
} from '@/stores/agnostic/modules/content/agnostic-content.module';

export interface AppContentState {
  categories: CategoriesState;
  category: CategoryState;
  items: ItemsState;
  item: ItemState;
}

const categories: CategoriesState = {
  categories: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const category: CategoryState = {
  category: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const items: ItemsState = {
  items: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const item: ItemState = {
  item: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const state: AppContentState = {
  categories,
  category,
  items,
  item,
};

const actions = {
  [GET_CONTENT_CATEGORIES]: AgnosticContentModule.actions.getContentCategories,
  [GET_CONTENT_CATEGORY]: AgnosticContentModule.actions.getContentCategory,
  [GET_CONTENT_ITEMS]: AgnosticContentModule.actions.getContentItems,
  [GET_CONTENT_ITEM]: AgnosticContentModule.actions.getContentItem,
};

const mutations = {
  [GET_CONTENT_CATEGORIES](state) {
    state.categories.status = {
      ...state.categories.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_CONTENT_CATEGORIES_SUCCESS](state, categories) {
    state.categories = {
      status: {
        ...state.categories.status,
        areLoading: false,
        error: null,
      },
      categories: [...categories],
    };
  },
  [GET_CONTENT_CATEGORIES_ERROR](state, error) {
    state.categories.status = {
      ...state.categories.status,
      areLoading: false,
      error,
    };
  },
  [GET_CONTENT_CATEGORY](state) {
    state.category.status = {
      ...state.category.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_CONTENT_CATEGORY_SUCCESS](state, contentCategory: ContentCategory) {
    state.category = {
      status: {
        ...state.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      category: {
        ...contentCategory,
      },
    };
  },
  [GET_CONTENT_CATEGORY_ERROR](state, error) {
    state.category.status = {
      ...state.category.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
  [GET_CONTENT_ITEMS](state) {
    state.items.status = {
      ...state.items.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_CONTENT_ITEMS_SUCCESS](state, contentItems) {
    state.items = {
      status: {
        ...state.items.status,
        areLoading: false,
        error: null,
      },
      items: [...contentItems],
    };
  },
  [GET_CONTENT_ITEMS_ERROR](state, error) {
    state.items.status = {
      ...state.items.status,
      areLoading: false,
      error,
    };
  },
  [GET_CONTENT_ITEM](state) {
    state.item.status = {
      ...state.item.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_CONTENT_ITEM_SUCCESS](state, contentItem: ContentItem) {
    state.item = {
      status: {
        ...state.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      item: {
        ...contentItem,
      },
    };
  },
  [GET_CONTENT_ITEM_ERROR](state, error) {
    state.item.status = {
      ...state.item.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
};

const getters = {
  categoriesState: (state: AppContentState): CategoriesState => state.categories,
  contentCategories: (
    _,
    { categoriesState }: { categoriesState: CategoriesState },
  ): ContentCategory[] => categoriesState?.categories || [],
  categoriesStatus: (
    _,
    { categoriesState }: { categoriesState: CategoriesState },
  ): CategoriesStatus => categoriesState?.status || null,
  categoriesAreLoading: (
    _,
    { categoriesStatus }: { categoriesStatus: CategoriesStatus },
  ): boolean => categoriesStatus?.areLoading || false,
  categoriesError: (
    _,
    { categoriesStatus }: { categoriesStatus: CategoriesStatus },
  ): ErrorMessage => categoriesStatus?.error || null,

  categoryState: (state: AppContentState): CategoryState => state.category,
  contentCategory: (_, { categoryState }: { categoryState: CategoryState }): ContentCategory =>
    categoryState?.category || null,
  categoryStatus: (_, { categoryState }: { categoryState: CategoryState }): CategoryStatus =>
    categoryState?.status || null,
  categoryIsLoading: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): boolean =>
    categoryStatus?.isLoading || false,
  categoryIsSaving: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): boolean =>
    categoryStatus?.isSaving || false,
  categoryError: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): ErrorMessage =>
    categoryStatus?.error || null,

  itemsState: (state: AppContentState): ItemsState => state.items,
  contentItems: (_, { itemsState }: { itemsState: ItemsState }): ContentItem[] =>
    itemsState?.items || [],
  itemsStatus: (_, { itemsState }: { itemsState: ItemsState }): ItemsStatus =>
    itemsState?.status || null,
  itemsAreLoading: (_, { itemsStatus }: { itemsStatus: ItemsStatus }): boolean =>
    itemsStatus?.areLoading || false,
  itemsError: (_, { itemsStatus }: { itemsStatus: ItemsStatus }): ErrorMessage =>
    itemsStatus?.error || null,

  itemState: (state: AppContentState): ItemState => state.item,
  contentItem: (_, { itemState }: { itemState: ItemState }): ContentItem => itemState?.item || null,
  itemStatus: (_, { itemState }: { itemState: ItemState }): ItemStatus => itemState?.status || null,
  itemIsLoading: (_, { itemStatus }: { itemStatus: ItemStatus }): boolean =>
    itemStatus?.isLoading || false,
  itemIsSaving: (_, { itemStatus }: { itemStatus: ItemStatus }): boolean =>
    itemStatus?.isSaving || false,
  itemError: (_, { itemStatus }: { itemStatus: ItemStatus }): ErrorMessage =>
    itemStatus?.error || null,
};

export const AppContentModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
