import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const TAG_MODULE_NAME = `TagModule`;
export const ADMIN_TAG_MODULE = `${UMANIZE_ADMIN_MODULE}/${TAG_MODULE_NAME}`;

export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';

export const ADD_TAG = 'ADD_TAG';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR';
