import { ActionContext } from 'vuex';
import { User, UserRole, UserTicketProfile } from '@/models';
import { RootState } from '@/stores/store.model';
import userService from '@/services/user/user.service';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  UPDATE_AVATAR,
  UPDATE_AVATAR_ERROR,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_TICKET_PROFILE_ERROR,
  UPDATE_TICKET_PROFILE_SUCCESS,
  UPDATE_TICKET_PROFILE,
  GET_TICKET_PROFILE,
  GET_TICKET_PROFILE_ERROR,
  GET_TICKET_PROFILE_SUCCESS,
} from '@/stores/agnostic/actions/user/agnostic-user.actions';
import { AgnosticUserModule } from '@/stores/agnostic/modules/user/agnostic-user.module';
import { USER } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_ERROR,
  GET_PERMISSIONS_SUCCESS,
} from '@/stores/umanize-admin/actions/user/admin-user.actions';

export interface LoggedInUserStatus {
  error: ErrorMessage;
  isSaving: boolean;
}

export interface LoggedInProfileStatus {
  isUpdated: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: ErrorMessage;
}

export interface LoggedInProfileState {
  profile: UserTicketProfile;
  status: LoggedInProfileStatus;
}

export interface LoggedInUserState {
  user: User;
  status: LoggedInUserStatus;
}

export interface LoggedInUserRolesStatus {
  areLoading: boolean;
  areLoaded: boolean;
  areSaving: boolean;
  error: ErrorMessage;
}

export interface LoggedInUserRolesState {
  roles: UserRole[];
  status: LoggedInUserRolesStatus;
}

export interface AppUsersState {
  loggedInUser: LoggedInUserState;
  loggedInUserRoles: LoggedInUserRolesState;
  loggedInProfile: LoggedInProfileState;
}

const loggedInProfile: LoggedInProfileState = {
  profile: null,
  status: {
    error: null,
    isLoading: true,
    isLoaded: false,
    isUpdated: false,
  },
};

const loggedInUser: LoggedInUserState = {
  user: null,
  status: {
    error: null,
    isSaving: false,
  },
};

const loggedInUserRoles: LoggedInUserRolesState = {
  roles: null,
  status: {
    areLoaded: false,
    areLoading: false,
    areSaving: false,
    error: null,
  },
};

export const state: AppUsersState = {
  loggedInUser,
  loggedInUserRoles,
  loggedInProfile,
};

const actions = {
  [UPDATE_USER]: AgnosticUserModule.actions.updateUser,
  [UPDATE_AVATAR]: AgnosticUserModule.actions.updateAvatar,
  [UPDATE_TICKET_PROFILE]: AgnosticUserModule.actions.updateTicketProfile,
  [GET_TICKET_PROFILE]: AgnosticUserModule.actions.getProfileTicket,
  [UPDATE_USER_PASSWORD]: AgnosticUserModule.actions.updateUserPassword,
  async [USER]({ commit }: ActionContext<AppUsersState, RootState>, user: User) {
    commit(USER, user);
    return user;
  },
  async [GET_PERMISSIONS]({ commit, state }: ActionContext<AppUsersState, RootState>, user) {
    const isLoggedInUser = user.id === state.loggedInUser.user.id;
    commit(GET_PERMISSIONS);
    try {
      const roles = await userService.getPermissions(user, isLoggedInUser);

      commit(GET_PERMISSIONS_SUCCESS, roles);
    } catch (error) {
      commit(GET_PERMISSIONS_ERROR, error);
    }
  },
};

const mutations = {
  [USER](state: AppUsersState, user: User) {
    state.loggedInUser.user = {
      ...user,
    };
  },
  [UPDATE_AVATAR](state: AppUsersState) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: true,
    };
  },
  [UPDATE_AVATAR_SUCCESS](state: AppUsersState, payload: { avatar: string }) {
    state.loggedInUser = {
      ...state.loggedInUser,
      user: {
        ...state.loggedInUser.user,
        avatar: payload.avatar,
      },
    };
  },
  [UPDATE_AVATAR_ERROR](state: AppUsersState, error: ErrorMessage) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_USER](state: AppUsersState) {
    state.loggedInUser = {
      ...state.loggedInUser,
      status: {
        ...state.loggedInUser.status,
        isSaving: true,
        error: null,
      },
    };
  },
  [UPDATE_USER_SUCCESS](state, user) {
    state.loggedInUser = {
      user,
      status: {
        ...state.loggedInUser.status,
        isSaving: false,
      },
    };
  },
  [UPDATE_USER_ERROR](state, error) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_USER_PASSWORD](state) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_USER_PASSWORD_SUCCESS](state) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_USER_PASSWORD_ERROR](state, error) {
    state.loggedInUser.status = {
      ...state.loggedInUser.status,
      isSaving: false,
      error,
    };
  },
  [GET_PERMISSIONS](state) {
    state.loggedInUserRoles.status = {
      ...state.loggedInUserRoles.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_PERMISSIONS_SUCCESS](state, roles) {
    state.loggedInUserRoles = {
      ...state.loggedInUserRoles,
      roles: PermissionsUtil.flattenPermissionsInUserRoles(roles),
      status: {
        ...state.loggedInUserRoles.status,
        areLoading: false,
        areLoaded: true,
      },
    };
  },
  [GET_PERMISSIONS_ERROR](state: AppUsersState, error: ErrorMessage) {
    state.loggedInUserRoles = {
      ...state.loggedInUserRoles,
      roles: null,
      status: {
        ...state.loggedInUserRoles.status,
        areLoading: false,
        areLoaded: false,
        error,
      },
    };
  },
  [UPDATE_TICKET_PROFILE](state) {
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error: null,
      isLoading: false,
      isUpdated: false,
    };
  },
  [UPDATE_TICKET_PROFILE_SUCCESS](state, profile) {
    state.loggedInProfile.profile = profile;
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error: null,
      isLoaded: true,
      isLoading: false,
      isUpdated: true,
    };
    state.loggedInUser.user = {
      ...state.loggedInUser.user,
      firstName: profile.firstName,
      lastName: profile.lastName,
    };
  },
  [UPDATE_TICKET_PROFILE_ERROR](state, error) {
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error,
      isLoading: false,
      isLoaded: false,
      isUpdated: false,
    };
  },
  [GET_TICKET_PROFILE](state) {
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error: null,
      isLoading: true,
      isUpdated: false,
    };
    state.loggedInProfile.profile = null;
  },
  [GET_TICKET_PROFILE_SUCCESS](state, profile) {
    state.loggedInProfile.profile = profile;
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error: null,
      isLoading: false,
      isLoaded: true,
      isUpdated: false,
    };
  },
  [GET_TICKET_PROFILE_ERROR](state, error) {
    state.loggedInProfile.status = {
      ...state.loggedInProfile.status,
      error,
      isLoading: false,
      isLoaded: false,
      isUpdated: false,
    };
  },
};

const getters = {
  currentLoggedInUser: (state: AppUsersState): LoggedInUserState => state.loggedInUser,
  loggedInUser: (_, { currentLoggedInUser }: { currentLoggedInUser: LoggedInUserState }): User =>
    currentLoggedInUser?.user || null,
  loggedInUserStatus: (
    _,
    { currentLoggedInUser }: { currentLoggedInUser: LoggedInUserState },
  ): LoggedInUserStatus => currentLoggedInUser?.status || null,
  error: (_, { loggedInUserStatus }: { loggedInUserStatus: LoggedInUserStatus }): ErrorMessage =>
    loggedInUserStatus.error || null,

  loggedInUserRolesState: (state: AppUsersState): LoggedInUserRolesState => state.loggedInUserRoles,
  loggedInUserRoles: (
    _,
    { loggedInUserRolesState }: { loggedInUserRolesState: LoggedInUserRolesState },
  ): UserRole[] => loggedInUserRolesState?.roles || [],
  loggedInUserRolesStatus: (
    _,
    {
      loggedInUserRolesState,
    }: {
      loggedInUserRolesState: LoggedInUserRolesState;
    },
  ): LoggedInUserRolesStatus => loggedInUserRolesState?.status || null,
  loggedInUserRolesAreSaving: (
    _,
    { loggedInUserRolesStatus }: { loggedInUserRolesStatus: LoggedInUserRolesStatus },
  ): boolean => loggedInUserRolesStatus?.areSaving || false,
  loggedInUserRolesAreLoading: (
    _,
    { loggedInUserRolesStatus }: { loggedInUserRolesStatus: LoggedInUserRolesStatus },
  ): boolean => loggedInUserRolesStatus?.areLoading || false,
  loggedInUserRolesAreLoaded: (
    _,
    { loggedInUserRolesStatus }: { loggedInUserRolesStatus: LoggedInUserRolesStatus },
  ): boolean => loggedInUserRolesStatus?.areLoaded || false,
  loggedInUserRolesError: (
    _,
    { loggedInUserRolesStatus }: { loggedInUserRolesStatus: LoggedInUserRolesStatus },
  ): ErrorMessage => loggedInUserRolesStatus?.error || null,
  currentLoggedInProfile: (state: AppUsersState): LoggedInProfileState => state.loggedInProfile,
  profile: (
    _,
    { currentLoggedInProfile }: { currentLoggedInProfile: LoggedInProfileState },
  ): UserTicketProfile => currentLoggedInProfile?.profile || null,
  profileError: (
    _,
    { currentLoggedInProfile }: { currentLoggedInProfile: LoggedInProfileState },
  ): ErrorMessage => currentLoggedInProfile?.status.error || null,
  profileIsLoading: (
    _,
    { currentLoggedInProfile }: { currentLoggedInProfile: LoggedInProfileState },
  ): boolean => currentLoggedInProfile?.status.isLoading || false,
  profileIsLoaded: (
    _,
    { currentLoggedInProfile }: { currentLoggedInProfile: LoggedInProfileState },
  ): boolean => currentLoggedInProfile?.status.isLoaded || false,
  profileIsUpdated: (
    _,
    { currentLoggedInProfile }: { currentLoggedInProfile: LoggedInProfileState },
  ): boolean => currentLoggedInProfile?.status.isUpdated || false,
};

export const AppUserModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
