import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const AVAILABILITY_MODULE_NAME = 'AvailabilityModule';
export const AVAILABILITY_MODULE = `${UMANIZE_APP_MODULE}/${AVAILABILITY_MODULE_NAME}`;

export const LOAD_USER_AVAILABILITY = 'LOAD_USER_AVAILABILITY';
export const SET_SLOT_DURATION = 'SLOT_DURATION_CHANGED';
export const ADD_SLOT = 'ADD_SLOT';
export const REMOVE_SLOT = 'REMOVE_SLOT';
export const SET_START_SLOT = 'SET_START_SLOT';
export const SET_END_SLOT = 'SET_END_SLOT';
export const SAVE = 'SAVE';
export const SET_LOADING = 'SET_LOADING';
