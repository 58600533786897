import {
  SET_CUSTOM_USER_FIELDS,
  SET_QUIZ_SCORE,
  SET_TRAINING_DATA,
} from '@/stores/umanize-app/actions/training/training.actions';
import trainingService from '@/services/training/training.service';
import {
  SET_TRAINING_USER_CUSTOM_FIELDS,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { CustomField } from '@/models/training/admin/training-custom-field';

const sectionListState = {
  data: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const state = {
  sectionList: sectionListState,
};

const actions = {
  [SET_TRAINING_DATA]({ commit }, questions) {
    commit(SET_TRAINING_DATA, questions);
  },
  [SET_QUIZ_SCORE]({ commit }, data) {
    commit(SET_QUIZ_SCORE, data);
  },

  async [SET_CUSTOM_USER_FIELDS](
    { dispatch },
    {
      eventId,
      trainingId,
      data,
    }: {
      eventId: string;
      trainingId: string;
      data: CustomField[];
    },
  ) {
    await trainingService.updateUserCustomFields(eventId, trainingId, data);
    const customfields = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      customfields.push({ value, customField: key });
    }
    dispatch(`${TRAINING_ADMIN_MODULE}/${SET_TRAINING_USER_CUSTOM_FIELDS}`, customfields, {
      root: true,
    });
  },
};

const mutations = {
  [SET_TRAINING_DATA](state, val) {
    state.sectionList = val.tranningSectionList;
  },
  [SET_QUIZ_SCORE](state, data) {
    const preState = [...state.sectionList];
    preState?.forEach((ele) => {
      ele.bodyItem?.forEach((item) => {
        if (item.idLesson === data.item.id) {
          // eslint-disable-next-line no-param-reassign
          ele.title = '1231232';
          // eslint-disable-next-line no-param-reassign
          item.value = data.score;
        }
      });
    });
    state.sectionList = preState;
  },
};

const getters = {
  trainingState: (state) => state.sectionList,
};

export const AppTrainingModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
