import { TrainingCertification } from '@/models/training/admin/traning-certification.model';

export class AdminTraining {
  id: string;

  eventId: string;

  name: string;

  description: string;

  coverImage: string;

  trainerIds: [];

  trainers: [];

  certification = new TrainingCertification();

  ticketIds: [];

  deletedTicketIds: [];

  constructor() {
    this.certification = new TrainingCertification();
    this.deletedTicketIds = [];
  }
}
