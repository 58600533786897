import { UserAdminLevel } from '@/models/user/user-admin-level.model';

export class User {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  newsletter: boolean;

  isAdmin: boolean;

  adminLevel?: UserAdminLevel;

  avatar?: string;

  about?: string;

  status?: string;
}
