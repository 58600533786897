import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const TRAINING_MODULE_NAME = 'TrainingModule';
export const TRAINING_MODULE = `${UMANIZE_APP_MODULE}/${TRAINING_MODULE_NAME}`;

export const GET_TRAINING_DATA = 'GET_TRAINING_DATA';
export const SET_TRAINING_DATA = 'SET_TRAINING_DATA';
export const SET_QUIZ_SCORE = 'SET_QUIZ_SCORE';

export const SET_CUSTOM_USER_FIELDS = 'SET_CUSTOM_USER_FIELDS';
