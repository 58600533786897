import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { MessageType, Role } from '@/models';
import { ErrorMessage } from '@/models/error-message/error-message.model';

import {
  CREATE_ROLE,
  CREATE_ROLE_ERROR,
  CREATE_ROLE_SUCCESS,
  GET_ROLE,
  GET_ROLE_ERROR,
  GET_ROLE_SUCCESS,
  GET_ROLES,
  GET_ROLES_ERROR,
  GET_ROLES_SUCCESS,
  SAVE_ROLE,
  UPDATE_ROLE,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_SUCCESS,
  CLEAR_ROLE,
} from '@/stores/umanize-admin/actions/roles/roles.actions';

import rolesService from '@/services/role/roles.service';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';

export interface RolesStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface RolesState {
  roles: Role[];
  status: RolesStatus;
}

export interface CurrentRoleStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CurrentRoleState {
  role: Role;
  status: CurrentRoleStatus;
}

export interface RoleState {
  role: CurrentRoleState;
  roles: RolesState;
}

const roleState: CurrentRoleState = {
  role: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const rolesState: RolesState = {
  roles: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: RoleState = {
  role: roleState,
  roles: rolesState,
};

const actions = {
  async [GET_ROLES]({ commit }: ActionContext<RoleState, RootState>) {
    commit(GET_ROLES);

    try {
      const roles = await rolesService.getRoles();
      commit(GET_ROLES_SUCCESS, roles);
    } catch (error) {
      commit(GET_ROLES_ERROR, error);
    }
  },

  async [GET_ROLE]({ commit }: ActionContext<RoleState, RootState>, roleId: string) {
    commit(GET_ROLE);

    try {
      const roles = await rolesService.getRole(roleId);
      commit(GET_ROLE_SUCCESS, roles);
    } catch (error) {
      commit(GET_ROLE_ERROR, error);
    }
  },

  async [SAVE_ROLE]({ dispatch }: ActionContext<RoleState, RootState>, role: Role) {
    const dispatchedAction = role.id ? UPDATE_ROLE : CREATE_ROLE;
    await dispatch(dispatchedAction, role);
  },

  async [CREATE_ROLE]({ commit, dispatch }: ActionContext<RoleState, RootState>, role: Role) {
    commit(CREATE_ROLE);

    try {
      await rolesService.createRole(role);
      commit(CREATE_ROLE_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.roles.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(CREATE_ROLE_ERROR, error);
    }
  },

  async [UPDATE_ROLE]({ commit, dispatch }: ActionContext<RoleState, RootState>, role: Role) {
    commit(UPDATE_ROLE);

    try {
      await rolesService.updateRole(role);
      commit(UPDATE_ROLE_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.roles.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(UPDATE_ROLE_ERROR, error);
    }
  },

  [CLEAR_ROLE]({ commit }: ActionContext<RoleState, RootState>) {
    commit(CLEAR_ROLE);
  },
};

const mutations = {
  [GET_ROLES](state: RoleState) {
    state.roles.status = {
      ...state.roles.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_ROLES_SUCCESS](state: RoleState, roles: Role[]) {
    state.roles = {
      ...state.roles,
      status: {
        ...state.roles.status,
        areLoading: false,
        error: null,
      },
      roles: [...roles],
    };
  },
  [GET_ROLES_ERROR](state: RoleState, error: ErrorMessage) {
    state.roles = {
      ...state.roles,
      status: {
        ...state.roles.status,
        areLoading: false,
        error,
      },
    };
  },
  [GET_ROLE](state: RoleState) {
    state.role.status = {
      ...state.role.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_ROLE_SUCCESS](state: RoleState, role: Role) {
    state.role = {
      ...state.role,
      status: {
        ...state.role.status,
        isLoading: false,
        error: null,
      },
      role: {
        ...role,
      },
    };
  },
  [GET_ROLE_ERROR](state: RoleState, error: ErrorMessage) {
    state.role = {
      ...state.role,
      status: {
        ...state.role.status,
        isLoading: false,
        error,
      },
    };
  },
  [CREATE_ROLE](state: RoleState) {
    state.role.status = {
      ...state.role.status,
      isSaving: true,
      error: null,
    };
  },
  [CREATE_ROLE_SUCCESS](state: RoleState) {
    state.role.status = {
      ...state.role.status,
      isSaving: false,
      error: null,
    };
  },
  [CREATE_ROLE_ERROR](state: RoleState, error: ErrorMessage) {
    state.role.status = {
      ...state.role.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_ROLE](state: RoleState) {
    state.role.status = {
      ...state.role.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_ROLE_SUCCESS](state: RoleState) {
    state.role.status = {
      ...state.role.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_ROLE_ERROR](state: RoleState, error: ErrorMessage) {
    state.role.status = {
      ...state.role.status,
      isSaving: false,
      error,
    };
  },
  [CLEAR_ROLE](state: RoleState) {
    state.role = {
      ...state.role,
      role: null,
    };
  },
};

const getters = {
  roleState: (state: RoleState): CurrentRoleState => state.role,
  role: (_, { roleState }: { roleState: CurrentRoleState }): Role => roleState?.role || null,
  roleStatus: (_, { roleState }: { roleState: CurrentRoleState }): CurrentRoleStatus =>
    roleState?.status,
  roleIsLoading: (_, { roleStatus }: { roleStatus: CurrentRoleStatus }): boolean =>
    roleStatus?.isLoading || false,
  roleIsSaving: (_, { roleStatus }: { roleStatus: CurrentRoleStatus }): boolean =>
    roleStatus?.isSaving || false,
  roleError: (_, { roleStatus }: { roleStatus: CurrentRoleStatus }): ErrorMessage =>
    roleStatus?.error || null,

  rolesState: (state: RoleState): RolesState => state.roles,
  roles: (_, { rolesState }: { rolesState: RolesState }): Role[] => rolesState?.roles || [],
  rolesStatus: (_, { rolesState }: { rolesState: RolesState }): RolesStatus => rolesState?.status,
  rolesAreLoading: (_, { rolesStatus }: { rolesStatus: RolesStatus }): boolean =>
    rolesStatus?.areLoading || false,
  rolesError: (_, { rolesStatus }: { rolesStatus: RolesStatus }): ErrorMessage =>
    rolesStatus?.error || null,
};

export const RolesModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
