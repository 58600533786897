export const CONFERENCE_MODULE = 'ConferenceModule';

export const GET_CONFERENCES_FOR_EVENT = 'GET_CONFERENCES_FOR_EVENT';
export const GET_CONFERENCES_FOR_EVENT_SUCCESS = 'GET_CONFERENCES_FOR_EVENT_SUCCESS';
export const GET_CONFERENCES_FOR_EVENT_ERROR = 'GET_CONFERENCES_FOR_EVENT_ERROR';

export const GET_CONFERENCE_ROOMS = 'GET_CONFERENCE_ROOMS';
export const GET_CONFERENCE_ROOMS_SUCCESS = 'GET_CONFERENCE_ROOMS_SUCCESS';
export const GET_CONFERENCE_ROOMS_ERROR = 'GET_CONFERENCE_ROOMS_ERROR';

export const GET_CONFERENCE = 'GET_CONFERENCE';
export const GET_CONFERENCE_SUCCESS = 'GET_CONFERENCE_SUCCESS';
export const GET_CONFERENCE_ERROR = 'GET_CONFERENCE_ERROR';

export const GET_CONFERENCE_ROOM = 'GET_CONFERENCE_ROOM';
export const GET_CONFERENCE_ROOM_SUCCESS = 'GET_CONFERENCE_ROOM_SUCCESS';
export const GET_CONFERENCE_ROOM_ERROR = 'GET_CONFERENCE_ROOM_ERROR';

export const GET_CONFERENCES_FOR_ROOM = 'GET_CONFERENCES_FOR_ROOM';
export const GET_CONFERENCES_FOR_ROOM_SUCCESS = 'GET_CONFERENCES_FOR_ROOM_SUCCESS';
export const GET_CONFERENCES_FOR_ROOM_ERROR = 'GET_CONFERENCES_FOR_ROOM_ERROR';

export const GO_TO_NEXT_CONFERENCE_IN_ROOM = 'GO_TO_NEXT_CONFERENCE_IN_ROOM';
export const GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS = 'GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS';
export const GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR = 'GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR';

export const CLEAR_CONFERENCE_ROOM = 'CLEAR_CONFERENCE_ROOM';
