<template>
  <div class="custom-buttons">
    <div class="button__wrapper" v-if="eventHasExposition && canAccessShowRoom">
      <v-btn
        block
        x-large
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        :to="visitShowroom"
        data-test-id="showroom"
      >
        {{ event3dBtnText }}
      </v-btn>
    </div>

    <div class="button__wrapper" v-if="eventHasExhibitor">
      <v-btn
        block
        x-large
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        @click="toggleNav('exhibitors')"
        data-test-id="exhibitors"
      >
        {{ event2dBtnText }}
      </v-btn>
    </div>

    <div class="button__wrapper" v-if="eventHasConference">
      <v-btn
        block
        x-large
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        @click="toggleNav('conferenceRooms')"
        data-test-id="conference-rooms"
      >
        {{ conferenceBtnText }}
      </v-btn>
    </div>

    <div class="button__wrapper" v-if="eventHasGeneralAssembly">
      <v-btn
        x-large
        block
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        @click="toggleNav('assemblies')"
        data-test-id="assemblies"
      >
        {{ generalAssembliesBtnText }}
      </v-btn>
    </div>

    <div class="button__wrapper" v-if="eventHasDiscussionGroups">
      <v-btn
        block
        x-large
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        @click="toggleNav('discussionGroups')"
        data-test-id="discussion-groups"
      >
        {{ discussionGroupsBtnText }}
      </v-btn>
    </div>

    <div class="button__wrapper" v-if="eventHasContentLibrary">
      <v-btn
        block
        x-large
        class="custom-buttons__btn button"
        :color="accentColor"
        :style="btnTextColor"
        :to="contentRoute"
        data-test-id="content-libraries"
      >
        {{ contentLibraryBtnText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { isMobile } from 'mobile-device-detect';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_ASSEMBLY_MODULE } from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import { APP_DISCUSSION_GROUP_MODULE } from '@/stores/umanize-app/actions/discussion-group/discussion-group.actions';
import { APP_CONFERENCE_MODULE } from '@/stores/umanize-app/actions/conference/app-conference.actions';
import {
  APP_MENU_MODULE,
  TOGGLE_NAV,
  SET_DISPLAY_MENU,
} from '@/stores/umanize-app/actions/menu/menu.actions';

export default {
  name: 'CustomButtons',
  components: {},
  data: () => ({}),
  methods: {
    ...mapActions(APP_MENU_MODULE, [SET_DISPLAY_MENU, TOGGLE_NAV]),
    getRoute(suffix) {
      return `/events/${this.event?.id}/${suffix}`;
    },
    toggleNav(type) {
      this[SET_DISPLAY_MENU](true);
      this[TOGGLE_NAV]({ doc: document, type });
    },
  },
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors']),
    ...mapGetters(APP_ASSEMBLY_MODULE, ['accessibleGeneralAssemblies']),
    ...mapGetters(APP_DISCUSSION_GROUP_MODULE, ['upComingDiscussionGroups']),
    ...mapGetters(APP_CONFERENCE_MODULE, ['nextConferences']),
    ...mapGetters(APP_MENU_MODULE, ['selectedConferenceRoom']),
    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    btnTextColor() {
      return `color: ${this.customUi?.btnTextColor || '#FFFFFF'}`;
    },
    event3dBtnText() {
      return this.customUi?.event3dBtnText || this.$t('lobby.visit');
    },
    event2dBtnText() {
      return (
        this.customUi?.event2dBtnText || this.$tc('lobby.exhibitors.title', this.exhibitors.length)
      );
    },
    displayedConferences() {
      if (this.selectedConferenceRoom) {
        return this.nextConferences.filter(
          (conference) => conference.roomId === this.selectedConferenceRoom.id,
        );
      }

      return this.nextConferences;
    },
    conferenceBtnText() {
      return (
        this.customUi?.conferenceBtnText ||
        this.$tc('lobby.conferences.title', this.displayedConferences.length)
      );
    },
    generalAssembliesBtnText() {
      return this.customUi?.generalAssembliesBtnText || this.$t('lobby.generalAssemblies.title');
    },
    discussionGroupsBtnText() {
      return (
        this.customUi?.discussionGroupsBtnText ||
        this.$tc('lobby.discussionGroups.title', this.upComingDiscussionGroups.length)
      );
    },
    contentLibraryBtnText() {
      return this.customUi?.contentLibraryBtnText || this.$t('lobby.content');
    },
    eventHasPromotionalVideo() {
      return this.event?.promotionalVideo;
    },
    eventHasGeneralAssembly() {
      return this.event?.options.generalAssembly && !!this.accessibleGeneralAssemblies.length;
    },
    eventHasDiscussionGroups() {
      return this.event?.options.discussionGroups;
    },
    eventHasExposition() {
      return this.event?.options.exposition;
    },
    eventHasContentLibrary() {
      return (
        this.event?.options.contentLibrary &&
        !this.event?.ticket?.options?.disableFunctionalities?.includes('contentLibraries')
      );
    },
    eventHasConference() {
      return (
        this.event?.options?.conferences &&
        !this.event?.ticket?.options?.disableFunctionalities?.includes('conferences')
      );
    },
    eventHasExhibitor() {
      return !!this.exhibitors.length;
    },
    canAccessShowRoom() {
      return !isMobile && (this?.event?.ticket?.options?.features?.showroom || this.userIsAdmin);
    },
    userIsAdmin() {
      return this.loggedInUser.isAdmin;
    },
    visitShowroom() {
      return this.getRoute('showroom');
    },
    contentRoute() {
      return this.getRoute('content-library');
    },
  },
};
</script>
