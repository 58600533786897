import { ActionContext } from 'vuex';
import { DateTime } from 'luxon';
import { RootState } from '@/stores/store.model';

import {
  ChatMessage,
  DiscussionGroup,
  LoadConferenceMessagesSuccess,
  NewMessage,
  SocketChatMessage,
} from '@/models';

import { ErrorMessage } from '@/models/error-message/error-message.model';
import DateUtil from '@/helpers/date/date.helper';
import {
  connectToDiscussionGroupNamespace,
  disconnectFromDiscussionGroupNamespace,
  DISCUSSION_GROUP_CHAT_NEW_MESSAGE,
  DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE,
  DISCUSSION_GROUP_LOAD_MESSAGES,
} from '@/socket/discussion-group-namespace';
import DataUtil from '@/helpers/data/data.helper';
import {
  AgnosticDiscussionGroupModule,
  CurrentDiscussionGroupState,
  CurrentDiscussionGroupStatus,
  DiscussionGroupsState,
  DiscussionGroupsStatus,
} from '@/stores/agnostic/modules/discussion-group/agnostic-discussion-group.module';
import {
  GET_DISCUSSION_GROUP,
  GET_DISCUSSION_GROUP_ERROR,
  GET_DISCUSSION_GROUP_SUCCESS,
  GET_DISCUSSION_GROUPS,
  GET_DISCUSSION_GROUPS_ERROR,
  GET_DISCUSSION_GROUPS_SUCCESS,
} from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import {
  CONNECT_TO_DISCUSSION_GROUP_SOCKET,
  DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET,
  DISCUSSION_GROUP_CHAT_LOAD_MESSAGES,
  DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_ERROR,
  DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_SUCCESS,
  DISCUSSION_GROUP_CHAT_NEW_MESSAGE_SUCCESS,
  DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE_SUCCESS,
  DISCUSSION_GROUP_SEND_CHATS_MESSAGE,
} from '@/stores/umanize-app/actions/discussion-group/discussion-group.actions';
import { CLEAR_DISCUSSION_GROUP } from '@/stores/umanize-admin/actions/discussion-group/admin-discussion-group.actions';

const currentDiscussionGroup: CurrentDiscussionGroupState = {
  discussionGroup: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const discussionGroups: DiscussionGroupsState = {
  discussionGroups: [],
  status: {
    error: null,
    isLoading: true,
  },
};

export interface DiscussionGroupMessagesStatus {
  areLoading: boolean;
  isModerating: boolean;
  error: ErrorMessage;
}

export interface DiscussionGroupMessagesState {
  messages: Map<string, SocketChatMessage>;
  pagination: {
    limit: number;
    lastFetchedDate: string;
  };
  status: DiscussionGroupMessagesStatus;
}

const discussionGroupMessages: DiscussionGroupMessagesState = {
  messages: new Map(),
  status: {
    isModerating: false,
    areLoading: true,
    error: null,
  },
  pagination: {
    lastFetchedDate: DateTime.local().toISO(),
    limit: 10,
  },
};

export interface DiscussionGroupState {
  discussionGroup: CurrentDiscussionGroupState;
  discussionGroups: DiscussionGroupsState;
  discussionGroupMessages: DiscussionGroupMessagesState;
}

const state: DiscussionGroupState = {
  discussionGroupMessages,
  discussionGroup: currentDiscussionGroup,
  discussionGroups,
};

let discussionGroupSocket;

const actions = {
  [GET_DISCUSSION_GROUPS]: AgnosticDiscussionGroupModule.actions.getDiscussionGroups,
  [GET_DISCUSSION_GROUP]: AgnosticDiscussionGroupModule.actions.getDiscussionGroup,
  async [CONNECT_TO_DISCUSSION_GROUP_SOCKET](
    { commit, dispatch, getters }: ActionContext<DiscussionGroupState, RootState>,
    { discussionGroupId, userId }: { discussionGroupId: string; userId: string },
  ) {
    discussionGroupSocket = connectToDiscussionGroupNamespace(discussionGroupId);

    discussionGroupSocket.on('connect', () => {
      const { eventId, id } = getters.discussionGroup;
      dispatch(DISCUSSION_GROUP_CHAT_LOAD_MESSAGES, { eventId, discussionGroupId: id });
    });

    discussionGroupSocket.on(
      DISCUSSION_GROUP_LOAD_MESSAGES,
      async (payload: LoadConferenceMessagesSuccess) => {
        commit(DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_SUCCESS, payload.messages);
      },
    );

    discussionGroupSocket.on(DISCUSSION_GROUP_CHAT_NEW_MESSAGE, async (payload: NewMessage) => {
      commit(DISCUSSION_GROUP_CHAT_NEW_MESSAGE_SUCCESS, payload);
    });

    discussionGroupSocket.on(
      DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE,
      async (message: ChatMessage) => {
        commit(DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE_SUCCESS, message);
      },
    );
  },
  async [DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET]() {
    disconnectFromDiscussionGroupNamespace(discussionGroupSocket);
  },
  [DISCUSSION_GROUP_CHAT_LOAD_MESSAGES](
    { commit }: ActionContext<DiscussionGroupMessagesState, RootState>,
    payload: { eventId: string; discussionGroupId: string },
  ) {
    discussionGroupSocket.emit(
      'discussion-group-chat-load-messages',
      payload.eventId,
      payload.discussionGroupId,
      state.discussionGroupMessages.pagination.lastFetchedDate,
      state.discussionGroupMessages.pagination.limit,
    );
    commit(DISCUSSION_GROUP_CHAT_LOAD_MESSAGES);
  },
  async [DISCUSSION_GROUP_SEND_CHATS_MESSAGE](
    _: ActionContext<DiscussionGroupState, RootState>,
    payload: {
      eventId: string;
      discussionGroupId: string;
      message: string;
    },
  ) {
    discussionGroupSocket.emit(
      'discussion-group-chat-create-message',
      payload.eventId,
      payload.discussionGroupId,
      payload.message,
    );
  },
};

const mutations = {
  [GET_DISCUSSION_GROUPS](state: DiscussionGroupState) {
    state.discussionGroups.status = {
      ...state.discussionGroups.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_DISCUSSION_GROUPS_SUCCESS](
    state: DiscussionGroupState,
    discussionGroups: DiscussionGroup[],
  ) {
    state.discussionGroups = {
      status: {
        ...state.discussionGroups.status,
        isLoading: false,
        error: null,
      },
      discussionGroups: [...discussionGroups],
    };
  },
  [GET_DISCUSSION_GROUPS_ERROR](state: DiscussionGroupState, error: ErrorMessage) {
    state.discussionGroups.status = {
      ...state.discussionGroups.status,
      isLoading: false,
      error,
    };
  },
  [GET_DISCUSSION_GROUP](state: DiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_DISCUSSION_GROUP_SUCCESS](state: DiscussionGroupState, discussionGroup: DiscussionGroup) {
    state.discussionGroup = {
      status: {
        ...state.discussionGroup.status,
        isLoading: false,
        error: null,
      },
      discussionGroup,
    };
  },
  [GET_DISCUSSION_GROUP_ERROR](state: DiscussionGroupState, error: ErrorMessage) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_DISCUSSION_GROUP](state: DiscussionGroupState) {
    state.discussionGroup = {
      ...state.discussionGroup,
      discussionGroup: null,
    };
  },

  [DISCUSSION_GROUP_CHAT_LOAD_MESSAGES](state: DiscussionGroupState) {
    state.discussionGroupMessages.status = {
      ...state.discussionGroupMessages.status,
      areLoading: true,
    };
  },
  [DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_SUCCESS](state: DiscussionGroupState, messages) {
    state.discussionGroupMessages.status = {
      ...state.discussionGroupMessages.status,
      areLoading: false,
    };

    state.discussionGroupMessages.pagination = {
      ...state.discussionGroupMessages.pagination,
      lastFetchedDate:
        (messages.length && messages[messages.length - 1].created) ||
        state.discussionGroupMessages.pagination.lastFetchedDate,
    };

    messages.forEach((message) => state.discussionGroupMessages.messages.set(message.id, message));
    state.discussionGroupMessages.messages = new Map(state.discussionGroupMessages.messages);
  },
  [DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_ERROR](state: DiscussionGroupState, error) {
    state.discussionGroupMessages.status = {
      ...state.discussionGroupMessages.status,
      error,
    };
  },
  [DISCUSSION_GROUP_CHAT_NEW_MESSAGE_SUCCESS](state: DiscussionGroupState, message) {
    state.discussionGroupMessages.messages.set(message.id, message);
    state.discussionGroupMessages.messages = new Map(state.discussionGroupMessages.messages);
  },
  [DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE_SUCCESS](state: DiscussionGroupState, message) {
    state.discussionGroupMessages.messages.set(message.id, message);
    state.discussionGroupMessages.messages = new Map(state.discussionGroupMessages.messages);
  },
};

const getters = {
  currentState: (state: DiscussionGroupState): CurrentDiscussionGroupState => state.discussionGroup,
  discussionGroup: (
    _,
    { currentState }: { currentState: CurrentDiscussionGroupState },
  ): DiscussionGroup => currentState?.discussionGroup || null,
  currentStatus: (
    _,
    { currentState }: { currentState: CurrentDiscussionGroupState },
  ): CurrentDiscussionGroupStatus => currentState?.status || null,
  discussionGroupIsLoading: (
    _,
    { currentStatus }: { currentStatus: CurrentDiscussionGroupStatus },
  ): boolean => currentStatus?.isLoading || false,
  discussionGroupError: (
    _,
    { currentStatus }: { currentStatus: CurrentDiscussionGroupStatus },
  ): ErrorMessage => currentStatus?.error || null,

  listState: (state: DiscussionGroupState): DiscussionGroupsState => state.discussionGroups,
  discussionGroups: (_, { listState }: { listState: DiscussionGroupsState }): DiscussionGroup[] =>
    listState?.discussionGroups || [],
  upComingDiscussionGroups: (
    _,
    { discussionGroups }: { discussionGroups: DiscussionGroup[] },
  ): DiscussionGroup[] =>
    discussionGroups?.filter((discussionGroup: DiscussionGroup) =>
      DateUtil.isNowOrAfter(discussionGroup.endTime),
    ),

  listStatus: (_, { listState }: { listState: DiscussionGroupsState }): DiscussionGroupsStatus =>
    listState?.status || null,
  discussionGroupsAreLoading: (
    _,
    { listStatus }: { listStatus: DiscussionGroupsStatus },
  ): boolean => listStatus?.isLoading || false,
  discussionGroupsError: (
    _,
    { listStatus }: { listStatus: DiscussionGroupsStatus },
  ): ErrorMessage => listStatus?.error || null,

  discussionGroupMessagesState: (state: DiscussionGroupState): DiscussionGroupMessagesState =>
    state.discussionGroupMessages,
  messages: (
    _,
    {
      discussionGroupMessagesState,
    }: { discussionGroupMessagesState: DiscussionGroupMessagesState },
  ): SocketChatMessage[] =>
    DataUtil.sortByStringDesc(
      Array.from(discussionGroupMessagesState?.messages.values() || []),
      'created',
    ),
  moderatedMessages: (_, { messages }: { messages: SocketChatMessage[] }): SocketChatMessage[] =>
    messages?.filter((item) => !item.isModerated) || [],
  discussionGroupMessagesStatus: (
    _,
    {
      discussionGroupMessagesState,
    }: { discussionGroupMessagesState: DiscussionGroupMessagesState },
  ): DiscussionGroupMessagesStatus => discussionGroupMessagesState?.status || null,
  discussionGroupMessagesAreLoading: (
    _,
    {
      discussionGroupMessagesStatus,
    }: { discussionGroupMessagesStatus: DiscussionGroupMessagesStatus },
  ): boolean => discussionGroupMessagesStatus?.areLoading || true,
  discussionGroupMessagesError: (
    _,
    {
      discussionGroupMessagesStatus,
    }: { discussionGroupMessagesStatus: DiscussionGroupMessagesStatus },
  ): ErrorMessage => discussionGroupMessagesStatus?.error || null,
};

export const AppDiscussionGroupModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
