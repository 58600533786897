import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { Partner } from '@/models/event/partner.model';

class PartnerService {
  public async getPartners(payload: {
    eventId: string;
    conferenceId?: string;
    discussionGroupId?: string;
  }): Promise<Partner[]> {
    const apiRouteParams = PartnerService.buildApiRouteParams(payload);

    return HttpService.perform<Partner[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, apiRouteParams),
    );
  }

  public async getPartner(payload: {
    eventId: string;
    partnerId: string;
    conferenceId?: string;
    discussionGroupId?: string;
  }): Promise<Partner> {
    const apiRouteParams = PartnerService.buildApiRouteParams(payload);

    return HttpService.perform<Partner>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, apiRouteParams),
    );
  }

  public async create(payload: {
    partner: Partner;
    conferenceId?: string;
    discussionGroupId?: string;
  }): Promise<Partner> {
    const apiRouteParams = PartnerService.buildApiRouteParams({
      ...payload,
      eventId: payload.partner.eventId,
    });

    return HttpService.perform<Partner>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, apiRouteParams),
      payload.partner,
    );
  }

  public async update(payload: {
    partner: Partner;
    conferenceId?: string;
    discussionGroupId?: string;
  }): Promise<Partner> {
    const apiRouteParams = PartnerService.buildApiRouteParams({
      ...payload,
      eventId: payload.partner.eventId,
      partnerId: payload.partner.id,
    });

    return HttpService.perform<Partner>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, apiRouteParams),
      payload.partner,
    );
  }

  public async delete(payload: {
    partner: Partner;
    conferenceId?: string;
    discussionGroupId?: string;
  }): Promise<void> {
    // apiRouteParams is an array which contains the route elements (route and params)
    // buildApiRouteParams function will build the route elements depending on the payload.
    // eg. if the payload has the conferenceId, it will add the conference route and param.
    const apiRouteParams = PartnerService.buildApiRouteParams({
      ...payload,
      eventId: payload.partner.eventId,
      partnerId: payload.partner.id,
    });

    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, apiRouteParams),
    );
  }

  private static buildApiRouteParams(payload: {
    eventId: string;
    conferenceId?: string;
    discussionGroupId?: string;
    partnerId?: string;
  }) {
    const apiRouteParams = [payload.eventId];

    if (payload.conferenceId) {
      apiRouteParams.push(HttpHelper.CONFERENCES_ENDPOINT, payload.conferenceId);
    }

    if (payload.discussionGroupId) {
      apiRouteParams.push(HttpHelper.DISCUSSION_GROUPS, payload.discussionGroupId);
    }

    apiRouteParams.push(HttpHelper.PARTNERS_ENDPOINT);

    if (payload.partnerId) {
      apiRouteParams.push(payload.partnerId);
    }

    return apiRouteParams;
  }
}

export default new PartnerService();
