import { Document } from '@/models/document/document.model';
import { Partner } from '@/models/event/partner.model';
import { VideoType } from '@/models/video-type/video-type.model';
import { Schedulable } from '../schedule/Schedulable.model';

export class Conference implements Schedulable {
  id: string;

  eventId: string;

  roomId: string;

  name: string;

  description: string;

  hasChatRoom: boolean;

  chatRoom: string;

  startTime: string;

  endTime: string;

  partners?: ConferencePartner[];

  coverImages?: string[];

  videoType?: VideoType;

  vimeoUrl?: string;

  videoUrl?: string;

  zoomMeetingId?: string;

  zoomMeetingPassword?: string;

  documents?: Document[];

  conferenceId?: string; // To patch backend model

  public: boolean;

  chatId?: string;
}

type ConferencePartner = Partner & {
  conferenceId: string;
};
