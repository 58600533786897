import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import { DiscussionGroup } from '@/models';
import DiscussionGroupService from '@/services/discussion-group/discussion-group.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_DISCUSSION_GROUP,
  GET_DISCUSSION_GROUP_ERROR,
  GET_DISCUSSION_GROUP_SUCCESS,
  GET_DISCUSSION_GROUPS,
  GET_DISCUSSION_GROUPS_ERROR,
  GET_DISCUSSION_GROUPS_SUCCESS,
} from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';

export interface CurrentDiscussionGroupStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CurrentDiscussionGroupState {
  discussionGroup: DiscussionGroup;
  status: CurrentDiscussionGroupStatus;
}

export interface DiscussionGroupsStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface DiscussionGroupsState {
  discussionGroups: DiscussionGroup[];
  status: DiscussionGroupsStatus;
}

const actions = {
  async getDiscussionGroups(
    { commit }: ActionContext<DiscussionGroupsState, RootState>,
    eventId: string,
  ) {
    commit(GET_DISCUSSION_GROUPS);

    try {
      const contentItem = await DiscussionGroupService.getAll(eventId);

      commit(GET_DISCUSSION_GROUPS_SUCCESS, contentItem);
    } catch (error) {
      commit(GET_DISCUSSION_GROUPS_ERROR, error);
    }
  },
  async getDiscussionGroup(
    { commit }: ActionContext<CurrentDiscussionGroupState, RootState>,
    { eventId, discussionGroupId }: { eventId: string; discussionGroupId: string },
  ) {
    commit(GET_DISCUSSION_GROUP);

    try {
      const discussionGroup = await DiscussionGroupService.getById(eventId, discussionGroupId);

      commit(GET_DISCUSSION_GROUP_SUCCESS, discussionGroup);
    } catch (error) {
      commit(GET_DISCUSSION_GROUP_ERROR, error);
    }
  },
};

export const AgnosticDiscussionGroupModule = {
  actions,
};
