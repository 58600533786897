import { CustomField } from './training-custom-field';

export class TrainingCertification {
  enabled: boolean;

  apiKey: string;

  integrationType: string;

  certifierGroupId: string;

  certifierCustomAttributeCourseName: string;

  customFields: CustomField[];

  customUserFields: CustomField[];

  constructor() {
    this.customFields = [];
    this.customUserFields = [];
  }
}
