import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { ExhibitorVisitor } from '@/models/exhibitor-visitor/exhibitor-visitor.model';

class ExhibitorVisitorService {
  public async getExhibitorVisitors({
    eventId,
    exhibitorId,
  }: {
    eventId: string;
    exhibitorId: string;
  }): Promise<ExhibitorVisitor[]> {
    return HttpService.perform<ExhibitorVisitor[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.VISITORS_ENDPOINT,
      ]),
    );
  }
}

export default new ExhibitorVisitorService();
