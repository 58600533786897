import { AppEvent, HttpMethod, EventUserOrPublicProfile } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { UserTicketPreferences } from '@/models/ticketing/user-ticket-preferences';

type UpdatePreferencesParameters = {
  eventId: string;
  preferences: Partial<UserTicketPreferences>;
};

export class EventService {
  public async getAll(): Promise<AppEvent[]> {
    return HttpService.perform<AppEvent[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT),
    );
  }

  public async getMyEvents(endDate?): Promise<AppEvent[]> {
    const url = `${HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, ['me'])}${
      endDate !== undefined ? `?endDate=${endDate}` : ''
    }`;

    return HttpService.perform<AppEvent[]>(HttpMethod.get, url);
  }

  public async getById(id: string): Promise<AppEvent> {
    return HttpService.perform<AppEvent>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [id]),
    );
  }

  public async getUserForEvent(
    id: string,
    isActive?: boolean,
  ): Promise<EventUserOrPublicProfile[]> {
    const baseUrl = HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [id, 'users']);
    const url = baseUrl + (isActive !== undefined ? `?isActive=${isActive}` : '');
    return HttpService.perform<EventUserOrPublicProfile[]>(HttpMethod.get, url);
  }

  public async updateEvent(event: AppEvent): Promise<AppEvent> {
    return HttpService.perform<AppEvent>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [event.id]),
      event,
    );
  }

  public async createEvent(event: AppEvent): Promise<AppEvent> {
    return HttpService.perform<AppEvent>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT),
      event,
    );
  }

  public async updatePreferences({
    eventId,
    preferences,
  }: UpdatePreferencesParameters): Promise<UserTicketPreferences> {
    return HttpService.perform<UserTicketPreferences>(
      HttpMethod.patch,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.PREFS_ENDPOINT]),
      preferences,
    );
  }
}

export default new EventService();
