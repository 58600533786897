import { HttpMethod, UmanizeFile } from '@/models';
import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

class FileService {
  public async upload(file: File): Promise<UmanizeFile> {
    const formData: FormData = new FormData();
    formData.append('file', new Blob([file], { type: 'application/octet-stream' }), file.name);

    return httpService.perform<UmanizeFile>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.UPLOADS_ENDPOINT),
      formData,
      true,
      true,
    );
  }
}

export default new FileService();
