import { DateTime, Interval } from 'luxon';
import { UserEventAppointment } from '@/models/users-appointments/user-event-appointment';

export const getNextAppointments = (now: DateTime) => (
  appointments: UserEventAppointment[],
): UserEventAppointment[] =>
  appointments.filter((it) => {
    const appointmentInterval = Interval.fromDateTimes(
      DateTime.fromISO(it.starting),
      DateTime.fromISO(it.ending),
    );
    return appointmentInterval.contains(now) || appointmentInterval.isAfter(now);
  });

export const getPreviousAppointments = (now: DateTime) => (
  appointments: UserEventAppointment[],
): UserEventAppointment[] =>
  appointments.filter((it) => {
    const appointmentInterval = Interval.fromDateTimes(
      DateTime.fromISO(it.starting),
      DateTime.fromISO(it.ending),
    );
    return appointmentInterval.isBefore(now);
  });
