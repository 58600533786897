import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const PARTNER_MODULE_NAME = 'PartnerModule';
export const PARTNER_MODULE = `${UMANIZE_ADMIN_MODULE}/${PARTNER_MODULE_NAME}`;

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_ERROR = 'GET_PARTNERS_ERROR';

export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_ERROR = 'GET_PARTNER_ERROR';

export const CLEAR_PARTNER = 'CLEAR_PARTNER';
export const SAVE_PARTNER = 'SAVE_PARTNER';

export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';
export const ADD_PARTNER_ERROR = 'ADD_PARTNER_ERROR';

export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_ERROR = 'UPDATE_PARTNER_ERROR';

export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_ERROR = 'DELETE_PARTNER_ERROR';
