import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const PASSWORD_MODULE = 'PasswordModule';

export const APP_PASSWORD_MODULE = `${UMANIZE_APP_MODULE}/${PASSWORD_MODULE}`;

export const RESET = 'RESET';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';

export const UPDATE = 'UPDATE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_ERROR = 'VALIDATE_TOKEN_ERROR';
