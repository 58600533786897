import { ActionContext } from 'vuex';

import { AppEvent, EventUserOrPublicProfile } from '@/models';
import EventService from '@/services/event/event.service';
import { RootState } from '@/stores/store.model';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import {
  FETCH_ALL_EVENTS,
  FETCH_ALL_EVENTS_ERROR,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_EVENT_BY_ID,
  FETCH_EVENT_BY_ID_ERROR,
  FETCH_EVENT_BY_ID_SUCCESS,
  FETCH_EVENT_USERS,
  FETCH_EVENT_USERS_ERROR,
  FETCH_EVENT_USERS_SUCCESS,
  FETCH_MY_EVENTS,
  FETCH_MY_EVENTS_ERROR,
  FETCH_MY_EVENTS_SUCCESS,
} from '@/stores/agnostic/actions/event/agnostic-event.actions';

export interface CurrentEventStatus {
  error: ErrorMessage;
  isSaving: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface MyEventsStatus {
  error: ErrorMessage;
  areLoading: boolean;
  areLoaded: boolean;
}

export interface CurrentEventState {
  event: AppEvent;
  status: CurrentEventStatus;
}

export interface EventsStatus {
  error: ErrorMessage;
  areLoading: boolean;
  areLoaded: boolean;
}

export interface EventsState {
  events: AppEvent[];
  status: EventsStatus;
}

export interface MyEventsState {
  myEvents: AppEvent[];
  status: EventsStatus;
}

export interface EventUsersStatus {
  error: ErrorMessage;
  areLoading: boolean;
  areLoaded: boolean;
}

export interface EventUsersFilter {
  searchText?: string;
  ticketType?: TicketType;
}

export interface EventUsersState {
  filter: EventUsersFilter;
  users: EventUserOrPublicProfile[];
  status: EventUsersStatus;
}

const actions = {
  async fetchAllEvents({ commit }: ActionContext<EventsState, RootState>) {
    commit(FETCH_ALL_EVENTS);

    try {
      const events = await EventService.getAll();
      commit(FETCH_ALL_EVENTS_SUCCESS, events);
    } catch (error) {
      commit(FETCH_ALL_EVENTS_ERROR, error);
    }
  },
  async fetchMyEvents(
    { commit }: ActionContext<EventsState, RootState>,
    params?: { endDate: string },
  ) {
    commit(FETCH_MY_EVENTS);

    try {
      const events = await EventService.getMyEvents(params?.endDate);

      commit(FETCH_MY_EVENTS_SUCCESS, events);
    } catch (error) {
      commit(FETCH_MY_EVENTS_ERROR, error);
    }
  },
  async fetchEventsUsers(
    { commit }: ActionContext<EventUsersState, RootState>,
    { id, isActive }: { id: string; isActive?: boolean },
  ) {
    commit(FETCH_EVENT_USERS);

    try {
      const users = await EventService.getUserForEvent(id, isActive);
      commit(FETCH_EVENT_USERS_SUCCESS, users);
    } catch (error) {
      commit(FETCH_EVENT_USERS_ERROR, error);
    }
  },
  async fetchEventByIdSilent({ commit }: ActionContext<CurrentEventState, RootState>, id: string) {
    commit(FETCH_EVENT_BY_ID);
    try {
      const event = await EventService.getById(id);
      commit(FETCH_EVENT_BY_ID_SUCCESS, event);
    } catch (error) {
      commit(FETCH_EVENT_BY_ID_ERROR, error);
    }
  },
};

export const AgnosticEventModule = {
  actions,
};
