import { HttpMethod, Role } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class RolesService {
  public async getRoles(): Promise<Role[]> {
    return HttpService.perform<Role[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.ROLES_ENDPOINT),
    );
  }

  public async getRole(roleId): Promise<Role> {
    return HttpService.perform<Role>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.ROLES_ENDPOINT, [roleId]),
    );
  }

  public async createRole(role): Promise<Role> {
    return HttpService.perform<Role>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.ROLES_ENDPOINT),
      role,
    );
  }

  public async updateRole(role): Promise<Role> {
    return HttpService.perform<Role>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.ROLES_ENDPOINT, [role.id]),
      role,
    );
  }
}

export default new RolesService();
