import type { AppEvent } from '@/models';

export interface Pay {
  ticketId: string;
  price: number;
  currency: string;
  newsletter: boolean;
  receiveInfo: boolean;
}

export const paymentStatus = {
  PENDING_PAYMENT: 'pending_payment',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};
export type PaymentStatus =
  | typeof paymentStatus.PENDING_PAYMENT
  | typeof paymentStatus.SUCCEEDED
  | typeof paymentStatus.FAILED;

export interface Payment {
  id: string;
  userId: string;
  ticketId: string;
  price: number;
  currency: string;
  status: PaymentStatus;
  stripeIntentId: string;
  stripeClientSecret: string;
  taxes?: [
    {
      name: string;
      percent: number;
      amount: number;
    },
  ];
  purchaseDate: string;
  event: AppEvent;
}
