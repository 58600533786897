import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';
import { MessageType } from '@/models';
import { Stream, StreamCreateForm } from '@/models/stream/stream.model';
import {
  CREATE_STREAM,
  CREATE_STREAM_ERROR,
  CREATE_STREAM_SUCCESS,
  DELETE_STREAM,
  DELETE_STREAM_ERROR,
  DELETE_STREAM_SUCCESS,
  START_STREAM,
  START_STREAM_ERROR,
  START_STREAM_SUCCESS,
  STOP_STREAM,
  STOP_STREAM_ERROR,
  STOP_STREAM_SUCCESS,
} from '@/stores/umanize-admin/actions/stream/stream.actions';
import streamService from '@/services/stream/stream.service';
import {
  AgnosticStreamModule,
  CurrentStreamState,
  StreamsState,
  StreamsStatus,
  StreamState,
  StreamStatus,
} from '@/stores/agnostic/modules/stream/agnostic-stream.module';
import {
  GET_ALL_STREAMS,
  GET_ALL_STREAMS_ERROR,
  GET_ALL_STREAMS_SUCCESS,
  GET_STREAM,
  GET_STREAM_ERROR,
  GET_STREAM_SUCCESS,
} from '@/stores/agnostic/actions/stream/agnostic-stream.actions';

export const streamState: CurrentStreamState = {
  stream: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const streamsState: StreamsState = {
  streams: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: StreamState = {
  stream: streamState,
  streams: streamsState,
};

const actions = {
  [GET_ALL_STREAMS]: AgnosticStreamModule.actions.getStreams,
  [GET_STREAM]: AgnosticStreamModule.actions.getStream,
  async [CREATE_STREAM](
    { commit, dispatch }: ActionContext<StreamState, RootState>,
    stream: StreamCreateForm,
  ) {
    commit(CREATE_STREAM);

    try {
      await streamService.create(stream);
      commit(CREATE_STREAM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.streams.created'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(CREATE_STREAM_ERROR, error);
    }
  },
  async [START_STREAM](
    { commit, dispatch }: ActionContext<StreamState, RootState>,
    stream: Stream,
  ) {
    commit(START_STREAM);

    try {
      await streamService.start(stream);
      commit(START_STREAM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.streams.started'), type: MessageType.info },
        { root: true },
      );

      dispatch(GET_ALL_STREAMS, { eventId: stream.eventId });
    } catch (error) {
      commit(START_STREAM_ERROR, error);
    }
  },
  async [STOP_STREAM]({ commit, dispatch }: ActionContext<StreamState, RootState>, stream: Stream) {
    commit(STOP_STREAM);

    try {
      await streamService.stop(stream);
      commit(STOP_STREAM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.streams.stopped'), type: MessageType.info },
        { root: true },
      );

      dispatch(GET_ALL_STREAMS, { eventId: stream.eventId });
    } catch (error) {
      commit(STOP_STREAM_ERROR, error);
    }
  },
  async [DELETE_STREAM](
    { commit, dispatch }: ActionContext<StreamState, RootState>,
    stream: Stream,
  ) {
    commit(DELETE_STREAM);

    try {
      await streamService.delete(stream);
      commit(DELETE_STREAM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('admin.streams.delete.deleteConfirmationDialog.success'),
          type: MessageType.info,
        },
        { root: true },
      );
    } catch (error) {
      commit(DELETE_STREAM_ERROR, error);
    }
  },
};

const mutations = {
  [GET_ALL_STREAMS](state: StreamState) {
    state.streams.status = {
      ...state.streams.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_ALL_STREAMS_SUCCESS](state: StreamState, streams: Stream[]) {
    state.streams = {
      ...state.streams,
      status: {
        ...state.streams.status,
        areLoading: false,
        error: null,
      },
      streams,
    };
  },
  [GET_ALL_STREAMS_ERROR](state: StreamState, error: ErrorMessage) {
    state.streams.status = {
      ...state.streams.status,
      areLoading: false,
      error,
    };
  },
  [GET_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_STREAM_SUCCESS](state: StreamState, stream: Stream) {
    state.stream = {
      ...state.stream,
      status: {
        ...state.stream.status,
        isLoading: false,
        error: null,
      },
      stream,
    };
  },
  [GET_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isLoading: false,
      error,
    };
  },
  [CREATE_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: true,
      error: null,
    };
  },
  [CREATE_STREAM_SUCCESS](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error: null,
    };
  },
  [CREATE_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error,
    };
  },
  [START_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: true,
      error: null,
    };
  },
  [START_STREAM_SUCCESS](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error: null,
    };
  },
  [START_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error,
    };
  },
  [STOP_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: true,
      error: null,
    };
  },
  [STOP_STREAM_SUCCESS](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error: null,
    };
  },
  [STOP_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_STREAM](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_STREAM_SUCCESS](state: StreamState) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error: null,
    };
  },
  [DELETE_STREAM_ERROR](state: StreamState, error: ErrorMessage) {
    state.stream.status = {
      ...state.stream.status,
      isSaving: false,
      error,
    };
  },
};

const getters = {
  streamState: (state: StreamState): CurrentStreamState => state.stream,
  stream: (_, { streamState }: { streamState: CurrentStreamState }): Stream =>
    streamState?.stream || null,
  streamStatus: (_, { streamState }: { streamState: CurrentStreamState }): StreamStatus =>
    streamState?.status || null,
  streamIsLoading: (_, { streamStatus }: { streamStatus: StreamStatus }): boolean =>
    streamStatus?.isLoading || false,
  streamIsSaving: (_, { streamStatus }: { streamStatus: StreamStatus }): boolean =>
    streamStatus?.isSaving || false,
  streamError: (_, { streamStatus }: { streamStatus: StreamStatus }): ErrorMessage =>
    streamStatus?.error || null,

  streamsState: (state: StreamState): StreamsState => state.streams,
  streams: (_, { streamsState }: { streamsState: StreamsState }): Stream[] =>
    streamsState?.streams || [],
  streamsStatus: (_, { streamsState }: { streamsState: StreamsState }): StreamsStatus =>
    streamsState?.status || null,
  streamsAreLoading: (_, { streamsStatus }: { streamsStatus: StreamsStatus }): boolean =>
    streamsStatus?.areLoading || false,
  streamsError: (_, { streamsStatus }: { streamsStatus: StreamsStatus }): ErrorMessage =>
    streamsStatus?.error || null,
};

export const AdminStreamModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
