import { ActionContext } from 'vuex';

import { ErrorMessage } from '@/models/error-message/error-message.model';
import { UserTicket } from '@/models/ticketing/user-ticket';
import userTicketService from '@/services/user-ticket/user-ticket.service';
import { RootState } from '@/stores/store.model';
import {
  APP_EVENT_MODULE,
  REFRESH_EVENT_USER,
} from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  TOGGLE_TICKET_STATUS,
  UPDATE_USER_TICKETID,
} from '@/stores/umanize-admin/actions/user/admin-user.actions';

export const UPDATE_USER_TICKET = 'UPDATE_USER_TICKET';
export const UPDATE_USER_TICKET_ERROR = 'UPDATE_USER_TICKET_ERROR';
export const UPDATE_USER_TICKET_SUCCESS = 'UPDATE_USER_TICKET_SUCCESS';

export interface ToggleTicketRequest {
  eventId: string;
  userId: string;
  ticket: UserTicket;
}

export interface UpdateUserTickerId {
  eventId: string;
  userId: string;
  ticketId: string;
}

export interface UserTicketState {
  ticket: UserTicket;
  status: {
    isSaving: boolean;
    error: ErrorMessage;
  };
}

const state: UserTicketState = {
  ticket: null,
  status: {
    isSaving: false,
    error: null,
  },
};

const actions = {
  async [TOGGLE_TICKET_STATUS](
    { commit, dispatch }: Partial<ActionContext<UserTicketState, RootState>>,
    { eventId, userId, ticket }: ToggleTicketRequest,
  ) {
    commit(UPDATE_USER_TICKET);

    try {
      const toggledTicket = { active: !ticket.active };
      const updatedTicket = await userTicketService.update({
        userId,
        eventId,
        ticket: toggledTicket,
      });

      commit(UPDATE_USER_TICKET_SUCCESS, updatedTicket);
      dispatch(`${APP_EVENT_MODULE}/${REFRESH_EVENT_USER}`, updatedTicket, { root: true });
    } catch (error) {
      commit(UPDATE_USER_TICKET_ERROR, error);
    }
  },
  async [UPDATE_USER_TICKETID](
    { commit, dispatch }: Partial<ActionContext<UserTicketState, RootState>>,
    { eventId, userId, ticketId }: UpdateUserTickerId,
  ) {
    commit(UPDATE_USER_TICKET);

    try {
      const updatedTicket = await userTicketService.update({
        userId,
        eventId,
        ticket: { ticketId },
      });

      commit(UPDATE_USER_TICKET_SUCCESS, updatedTicket);
      dispatch(`${APP_EVENT_MODULE}/${REFRESH_EVENT_USER}`, updatedTicket, { root: true });
    } catch (error) {
      commit(UPDATE_USER_TICKET_ERROR, error);
    }
  },
};

const mutations = {
  [UPDATE_USER_TICKET](state: UserTicketState) {
    state.status.isSaving = true;
    state.status.error = null;
  },
  [UPDATE_USER_TICKET_ERROR](state: UserTicketState, error: ErrorMessage) {
    state.status.isSaving = false;
    state.status.error = error;
  },
  [UPDATE_USER_TICKET_SUCCESS](state: UserTicketState, ticket: UserTicket) {
    state.status.isSaving = false;
    state.ticket = ticket;
  },
};

const getters = {
  isSaving: (state: UserTicketState): boolean => state.status.isSaving,
};

export const UserTicketModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
