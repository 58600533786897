<template>
  <app-search-header-item
    :items="filteredConferencesBySearch"
    item="conferences"
    :eventHasItem="eventHasConference"
    :maximumDisplayedElements="maximumDisplayedElements"
  >
    <v-list-item
      v-for="(conference, index) in filteredConferencesBySearch.slice(0, maximumDisplayedElements)"
      :key="index"
    >
      <v-list-item-content>
        <v-list-item-title
          class="d-flex flex-sm-row flex-column flex-wrap justify-sm-space-between"
        >
          {{ conference.name }} - {{ getConferenceRoomName(conference.roomId) }}

          <div>
            <v-btn
              class="actions__action ms-sm-2"
              small
              depressed
              :color="accentColor"
              :style="btnStyle"
              v-if="isStarted(conference)"
              @click.native="goTo(conference.id, 'Conference', 'conferenceId')"
            >
              {{ $t('lobby.join') }}
            </v-btn>

            <v-btn
              class="actions__action ms-sm-2"
              small
              depressed
              outlined
              :color="colorBtn"
              :style="{ color: accentColor }"
              v-if="canGoToSpeaker"
              @click.native="goTo(conference.id, 'Survey', 'conferenceId')"
            >
              {{ $t('lobby.backstage') }}
            </v-btn>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </app-search-header-item>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_CONFERENCE_MODULE } from '@/stores/umanize-app/actions/conference/app-conference.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import DateUtil from '@/helpers/date/date.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { isSpeaker } from '@/models/ticketing/user-ticket';

import AppSearchHeaderItem from './AppSearchHeaderItem.vue';

export default {
  name: 'SearchConferences',
  components: {
    AppSearchHeaderItem,
  },
  data: () => ({}),
  props: {
    maximumDisplayedElements: {
      type: Number,
    },
    event: {
      type: Object,
    },
    includeSearchNoSensitive: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters(APP_CONFERENCE_MODULE, ['conferenceRooms', 'conferences']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),

    eventHasConference() {
      return (
        this.event?.options?.conferences &&
        !this.event?.ticket?.options?.disableFunctionalities?.includes('conferences')
      );
    },
    filteredConferencesBySearch() {
      return this.conferences.filter(({ name, description, roomId }) =>
        [name, description, this.getConferenceRoomName(roomId)].some((item) =>
          this.includeSearchNoSensitive(item),
        ),
      );
    },

    canGoToSpeaker() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAdmin(this.loggedInUserRoles, this.event?.id)
      );
    },

    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    colorBtn() {
      return this.customUi?.btnTextColor || '#FFFFFF';
    },
    btnStyle() {
      return `color: ${this.colorBtn}`;
    },
  },
  methods: {
    isStarted(conference) {
      return DateUtil.canAccessConference(conference, this.now, this.event) || this.isAdmin;
    },

    getConferenceRoom(roomId) {
      return this.conferenceRooms.filter((room) => room.id === roomId)[0];
    },
    getConferenceRoomName(roomId) {
      return roomId ? this.getConferenceRoom(roomId)?.name : '';
    },

    goTo(id, name, typeId) {
      this.$emit('go-to', id, name, typeId);
    },
  },
};
</script>
