import { HttpMethod } from '@/models';
import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

class CustomUiService {
  public async get(eventId: string): Promise<CustomUI> {
    return httpService.perform<CustomUI>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [eventId, httpHelper.CUSTOM_UI]),
    );
  }

  public async create(customUi: CustomUI): Promise<CustomUI> {
    return httpService.perform<CustomUI>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        customUi.eventId,
        httpHelper.CUSTOM_UI,
      ]),
      customUi,
    );
  }

  public async update(customUi: CustomUI): Promise<CustomUI> {
    return httpService.perform<CustomUI>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        customUi.eventId,
        httpHelper.CUSTOM_UI,
      ]),
      customUi,
    );
  }

  public async delete(eventId: string): Promise<CustomUI> {
    return httpService.perform<CustomUI>(
      HttpMethod.delete,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [eventId, httpHelper.CUSTOM_UI]),
    );
  }
}

export default new CustomUiService();
