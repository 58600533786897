import { ActionContext } from 'vuex';
import { User, MessageType, ResetForm, UserRole, UserTicketProfile } from '@/models';
import { RootState } from '@/stores/store.model';
import userService from '@/services/user/user.service';
import i18n from '@/i18n';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  UPDATE_AVATAR,
  UPDATE_AVATAR_ERROR,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_TICKET_PROFILE,
  UPDATE_TICKET_PROFILE_ERROR,
  UPDATE_TICKET_PROFILE_SUCCESS,
  GET_TICKET_PROFILE,
  GET_TICKET_PROFILE_ERROR,
  GET_TICKET_PROFILE_SUCCESS,
} from '@/stores/agnostic/actions/user/agnostic-user.actions';

export interface CurrentUserStatus {
  isLoading: boolean;
  isLoaded: boolean;
  isSaving: boolean;
  error: ErrorMessage;
}

export interface CurrentProfileStatus {
  isLoading: boolean;
  isLoaded: boolean;
  error: ErrorMessage;
}

export interface CurrentUserState {
  user: User;
  status: CurrentUserStatus;
}

export interface CurrentUserRolesStatus {
  areLoading: boolean;
  areLoaded: boolean;
  areSaving: boolean;
  error: ErrorMessage;
}

export interface CurrentUserRolesState {
  roles: UserRole[];
  status: CurrentUserRolesStatus;
}

const actions = {
  async updateAvatar(
    { commit, dispatch }: ActionContext<CurrentUserState, RootState>,
    payload: { user: User; file: File },
  ) {
    commit(UPDATE_AVATAR);

    try {
      const response = await userService.updateAvatar(payload.user, payload.file);

      commit(UPDATE_AVATAR_SUCCESS, { avatar: response?.avatar });
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.profile.updateSuccess'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_AVATAR_ERROR, error);
    }
  },
  async updateUserPassword(
    { commit, dispatch }: ActionContext<CurrentUserState, RootState>,
    payload: { user: User; password: ResetForm },
  ) {
    commit(UPDATE_USER_PASSWORD);

    try {
      await userService.updateUserPassword(payload.user, payload.password);

      commit(UPDATE_USER_PASSWORD_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.profile.updateSuccess'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_USER_PASSWORD_ERROR, error);
    }
  },
  async updateUser(
    { commit, dispatch }: ActionContext<CurrentUserState, RootState>,
    { user }: { user: User; ticket?: string },
  ) {
    commit(UPDATE_USER);

    try {
      const updatedUser = await userService.updateUser(user);
      commit(UPDATE_USER_SUCCESS, updatedUser);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.profile.updateSuccess'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_USER_ERROR, error);
    }
  },
  async getProfileTicket(
    { commit, dispatch }: ActionContext<CurrentUserState, RootState>,
    { user }: { user: UserTicketProfile },
  ) {
    commit(GET_TICKET_PROFILE);

    try {
      const publicProfile = await userService.getProfileTicket(user);
      commit(GET_TICKET_PROFILE_SUCCESS, publicProfile);
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(GET_TICKET_PROFILE_ERROR, error);
    }
  },
  async updateTicketProfile(
    { commit, dispatch }: ActionContext<CurrentUserState, RootState>,
    { user }: { user: UserTicketProfile },
  ) {
    commit(UPDATE_TICKET_PROFILE);

    try {
      const updatedUser = await userService.updateProfileTicket(user);

      commit(UPDATE_TICKET_PROFILE_SUCCESS, updatedUser);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.profile.updateSuccess'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_TICKET_PROFILE_ERROR, error);
    }
  },
};

export const AgnosticUserModule = {
  actions,
};
