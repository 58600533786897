import { ExhibitorSection } from './exhibitor.section.model';

export class ExhibitorTab {
  name: string;

  id: string;

  sections: ExhibitorSection[];

  constructor() {
    this.name = '';
    this.sections = [];
  }
}
