/* eslint-disable */
import { ErrorMessage } from '@/models/error-message/error-message.model';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import { RootState } from '@/stores/store.model';
import { ActionContext } from 'vuex';

import { AdminTrainingLessonModel } from '@/models/training/admin/section/lesson/admin-training-lesson.model';
import {
  ADD_TRAINING_LESSON,
  ADD_TRAINING_LESSON_ERROR,
  ADD_TRAINING_LESSON_SUCCESS,
  CLEAR_ALL_TRAINING_LESSON,
  CLEAR_TRAINING_LESSON,
  CURRENT_UPDATE_ITEM,
  DELETE_TRAINING_LESSON,
  DELETE_TRAINING_LESSON_ERROR,
  DELETE_TRAINING_LESSON_SUCCESS,
  GENERATE_USER_CERTIFICATE,
  GENERATE_USER_CERTIFICATE_ERROR,
  GENERATE_USER_CERTIFICATE_SUCCESS,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  GET_ALL_TRAININGS_LESSON_ERROR,
  GET_ALL_TRAININGS_LESSON_SUCCESS,
  GET_FULL_TRAININGS_LESSON,
  GET_TRAINING_LESSON,
  GET_TRAINING_LESSON_ERROR,
  GET_TRAINING_LESSON_SUCCESS,
  PUBLISH_TRAINING_LESSON,
  UPDATE_TRAINING_LESSON,
  UPDATE_TRAINING_LESSON_ERROR,
  UPDATE_TRAINING_LESSON_SUCCESS,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import { TrainingAdminState } from '@/stores/umanize-admin/modules/training/admin-training.module';

export interface TrainingsLessonAdminStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface AllTrainingsLessonAdminStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface AllTrainingLessonAdminState {
  status: AllTrainingsLessonAdminStatus;
  trainingsLesson: AdminTrainingLessonModel[];
}

export interface CurTrainingLessonAdminState {
  status: TrainingsLessonAdminStatus;
  trainingLesson: AdminTrainingLessonModel;
}

export interface TrainingLessonAdminState {
  trainingLesson: CurTrainingLessonAdminState;
  allTrainingsLesson: AllTrainingLessonAdminState;
  curUpdateLesson: CurTrainingLessonAdminState;

  listSection: [];
}

export const trainingLessonAdminState: CurTrainingLessonAdminState = {
  trainingLesson: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const curUpdateLesson: CurTrainingLessonAdminState = {
  trainingLesson: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const allTrainingsLessonAdminState: AllTrainingLessonAdminState = {
  trainingsLesson: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: TrainingLessonAdminState = {
  trainingLesson: trainingLessonAdminState,
  allTrainingsLesson: allTrainingsLessonAdminState,
  listSection: [],
  curUpdateLesson,
};
const actions = {
  async [GET_ALL_TRAININGS_LESSON_ADMIN](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    { eventId, trainingId, sectionId }: { eventId: string; trainingId: string; sectionId: string },
  ) {
    commit(GET_ALL_TRAININGS_LESSON_ADMIN);
    try {
      const data = await trainingLessonService.getAll(eventId, trainingId, sectionId);
      commit(GET_ALL_TRAININGS_LESSON_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_LESSON_ERROR, error);
    }
  },
  async [GET_FULL_TRAININGS_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    data: any,
  ) {
    commit(GET_ALL_TRAININGS_LESSON_ADMIN);
    try {
      const promises = data.map((ele: { eventId: string; trainingId: string; id: string }) =>
        trainingLessonService.getAll(ele.eventId, ele.trainingId, ele.id),
      );
      const sections = [...data];
      const lessons = Promise.all(promises).then((response) => response);
      sections.forEach((ele, index) => {
        // eslint-disable-next-line no-param-reassign
        ele['lessions'] = lessons[index];
      });

      commit(GET_FULL_TRAININGS_LESSON, sections);
    } catch (error) {
      commit(GET_ALL_TRAININGS_LESSON_ERROR, error);
    }
  },
  async [ADD_TRAINING_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    {
      eventId,
      sectionId,
      trainingId,
      lesson,
    }: { eventId: string; lesson: AdminTrainingLessonModel; sectionId: string; trainingId: string },
  ) {
    commit(ADD_TRAINING_LESSON);
    try {
      await trainingLessonService.create({
        eventId,
        sectionId,
        trainingId,
        lesson,
      });
      commit(ADD_TRAINING_LESSON_SUCCESS);
    } catch (e) {
      commit(ADD_TRAINING_LESSON_ERROR, e);
    }
  },
  async [UPDATE_TRAINING_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    {
      eventId,
      sectionId,
      trainingId,
      lesson,
      upDateListLessionState,
    }: {
      eventId: string;
      lesson: AdminTrainingLessonModel;
      sectionId: string;
      trainingId: string;
      upDateListLessionState: boolean;
    },
  ) {
    commit(UPDATE_TRAINING_LESSON);
    try {
      const data = await trainingLessonService.update({
        eventId,
        sectionId,
        trainingId,
        lesson,
      });

      if (upDateListLessionState) {
        commit(UPDATE_TRAINING_LESSON_SUCCESS, data);
      }
    } catch (e) {
      commit(ADD_TRAINING_LESSON_ERROR, e);
    }
  },
  [CURRENT_UPDATE_ITEM](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    data: AdminTrainingLessonModel | null,
  ) {
    commit(CURRENT_UPDATE_ITEM, data);
  },
  async [GET_TRAINING_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    {
      eventId,
      trainingId,
      sectionId,
      idLesson,
    }: { eventId: string; trainingId: string; sectionId: string; idLesson: string },
  ) {
    commit(GET_TRAINING_LESSON);
    try {
      const data = await trainingLessonService.get(eventId, trainingId, sectionId, idLesson);
      commit(GET_TRAINING_LESSON_SUCCESS, data);
    } catch (e) {
      commit(GET_TRAINING_LESSON_ERROR, e);
    }
  },
  async [PUBLISH_TRAINING_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    {
      eventId,
      trainingId,
      sectionId,
      published,
    }: { eventId: string; trainingId: string; sectionId: string; published: Date },
  ) {
    commit(PUBLISH_TRAINING_LESSON);
    try {
      const data = await trainingLessonService.publish(eventId, trainingId, sectionId, published);
    } catch (e) {
      console.error(e);
    }
  },
  async [DELETE_TRAINING_LESSON](
    { commit }: ActionContext<TrainingLessonAdminState, RootState>,
    {
      eventId,
      sectionId,
      trainingId,
      lesson,
    }: { eventId: string; sectionId: string; trainingId: string; lesson: AdminTrainingLessonModel },
  ) {
    commit(DELETE_TRAINING_LESSON);
    try {
      await trainingLessonService.deleteLesson(eventId, sectionId, trainingId, lesson);
      commit(DELETE_TRAINING_LESSON_SUCCESS);
    } catch (e) {
      commit(DELETE_TRAINING_LESSON_ERROR, e);
    }
  },
  [CLEAR_TRAINING_LESSON]({ commit }: ActionContext<TrainingLessonAdminState, RootState>) {
    commit(CLEAR_TRAINING_LESSON);
  },
  [CLEAR_ALL_TRAINING_LESSON]({ commit }: ActionContext<TrainingLessonAdminState, RootState>) {
    commit(CLEAR_ALL_TRAINING_LESSON);
  },
  async [GENERATE_USER_CERTIFICATE](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, trainingId, userId }: { eventId: string; trainingId: string; userId: string },
  ) {
    commit(GENERATE_USER_CERTIFICATE);
    try {
      await trainingLessonService.generateCertificate(eventId, trainingId, userId);
      commit(GENERATE_USER_CERTIFICATE_SUCCESS);
    } catch (e) {
      commit(GENERATE_USER_CERTIFICATE_ERROR, e);
    }
  },
};

const mutations = {
  [GET_ALL_TRAININGS_LESSON_ADMIN](state: TrainingLessonAdminState) {
    state.allTrainingsLesson = {
      ...state.allTrainingsLesson,
      status: {
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_FULL_TRAININGS_LESSON](state: TrainingLessonAdminState) {
    state.allTrainingsLesson = {
      ...state.allTrainingsLesson,
      status: {
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_ALL_TRAININGS_LESSON_SUCCESS](
    state: TrainingLessonAdminState,
    trainingLessonList: AdminTrainingLessonModel[],
  ) {
    state.allTrainingsLesson.status.areLoading = false;
    state.allTrainingsLesson.status.error = null;
    state.allTrainingsLesson = {
      ...state.allTrainingsLesson,
      trainingsLesson: trainingLessonList,
    };
  },
  [GET_ALL_TRAININGS_LESSON_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.allTrainingsLesson = {
      ...state.allTrainingsLesson,
      status: {
        areLoading: false,
        error,
      },
    };
  },
  [ADD_TRAINING_LESSON](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_TRAINING_LESSON_SUCCESS](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error: null,
    };
  },
  [ADD_TRAINING_LESSON_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_TRAINING_LESSON](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_TRAINING_LESSON_SUCCESS](
    state: TrainingLessonAdminState,
    data: AdminTrainingLessonModel,
  ) {
    if (state.allTrainingsLesson.trainingsLesson?.length) {
      const listLesson = state.allTrainingsLesson.trainingsLesson.map((ele) => {
        if (ele.id === data.id) {
          return data;
        }
        return ele;
      });
      if (listLesson.length) {
        state.allTrainingsLesson = {
          ...state.allTrainingsLesson,
          trainingsLesson: listLesson,
        };
      }
    }

    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error: null,
    };
  },
  [UPDATE_TRAINING_LESSON_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error,
    };
  },
  [GET_TRAINING_LESSON](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_TRAINING_LESSON_SUCCESS](
    state: TrainingLessonAdminState,
    trainingLesson: AdminTrainingLessonModel,
  ) {
    state.trainingLesson = {
      ...state.trainingLesson,
      trainingLesson,
      status: {
        isLoading: false,
        isSaving: false,
        error: null,
      },
    };
  },
  [GET_TRAINING_LESSON_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error,
    };
  },
  [CURRENT_UPDATE_ITEM](state: TrainingLessonAdminState, data: AdminTrainingLessonModel | null) {
    state.curUpdateLesson = {
      ...state.curUpdateLesson,
      trainingLesson: data,
    };
  },
  [DELETE_TRAINING_LESSON](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_TRAINING_LESSON_SUCCESS](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error: null,
    };
  },
  [DELETE_TRAINING_LESSON_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_ALL_TRAINING_LESSON](state: TrainingLessonAdminState) {
    state.allTrainingsLesson = {
      ...state.allTrainingsLesson,
      trainingsLesson: [],
    };
  },
  [GENERATE_USER_CERTIFICATE](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: true,
      error: null,
    };
  },
  [GENERATE_USER_CERTIFICATE_SUCCESS](state: TrainingLessonAdminState) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error: null,
    };
  },
  [GENERATE_USER_CERTIFICATE_ERROR](state: TrainingLessonAdminState, error: ErrorMessage) {
    state.trainingLesson.status = {
      ...state.trainingLesson.status,
      isLoading: false,
      error,
    };
  },
};

const getters = {
  allTrainingLessonState: (state: TrainingLessonAdminState) => state.allTrainingsLesson,
  trainingLessonStateAreLoading: (state: TrainingLessonAdminState) =>
    state.allTrainingsLesson.status.areLoading,
  allTrainingLessonData: (state: TrainingLessonAdminState) =>
    state.allTrainingsLesson.trainingsLesson,
  trainingLessonSate: (state: TrainingLessonAdminState) => state.trainingLesson,
  trainingLessonData: (state: TrainingLessonAdminState) => state.trainingLesson.trainingLesson,
  curUpdateLessonData: (state: TrainingLessonAdminState) => state.curUpdateLesson.trainingLesson,
};

export const AdminTrainingLessonModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
