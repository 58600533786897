import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';
import { CONFERENCE_MODULE } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';

export const APP_CONFERENCE_MODULE = `${UMANIZE_APP_MODULE}/${CONFERENCE_MODULE}`;

export const SELECT_CONFERENCE_ROOM = 'SELECT_CONFERENCE_ROOM';

export const CONNECT_TO_CONFERENCE_SOCKET = 'CONNECT_TO_CONFERENCE_SOCKET';
export const DISCONNECT_FROM_CONFERENCE_SOCKET = 'DISCONNECT_FROM_CONFERENCE_SOCKET';

export const CONNECT_TO_CONFERENCE_SPEAKER_SOCKET = 'CONNECT_TO_CONFERENCE_SPEAKER_SOCKET';
export const DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET =
  'DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET';

export const CONFERENCE_CHAT_LOAD_MESSAGES = 'CONFERENCE_CHAT_LOAD_MESSAGES';
export const CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS = 'CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS';
export const CONFERENCE_CHAT_LOAD_MESSAGES_ERROR = 'CONFERENCE_CHAT_LOAD_MESSAGES_ERROR';

export const CONFERENCE_SEND_CHATS_MESSAGE = 'CONFERENCE_SEND_CHATS_MESSAGE';

export const CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS = 'CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS';

export const MODERATE_CONFERENCE_CHAT_MESSAGE = 'MODERATE_CONFERENCE_CHAT_MESSAGE';

export const CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS = 'CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS';

export const CLEAR_CONFERENCE_CHAT_MESSAGES = 'CLEAR_CONFERENCE_CHAT_MESSAGES';

export const SET_NEXT_CONFERENCE = 'SET_NEXT_CONFERENCE';
