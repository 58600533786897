import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import { ContentCategory, ContentItem } from '@/models';
import ContentLibraryService from '@/services/content-library/content-library.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_CATEGORIES_ERROR,
  GET_CONTENT_CATEGORIES_SUCCESS,
  GET_CONTENT_CATEGORY,
  GET_CONTENT_CATEGORY_ERROR,
  GET_CONTENT_CATEGORY_SUCCESS,
  GET_CONTENT_ITEM,
  GET_CONTENT_ITEM_ERROR,
  GET_CONTENT_ITEM_SUCCESS,
  GET_CONTENT_ITEMS,
  GET_CONTENT_ITEMS_ERROR,
  GET_CONTENT_ITEMS_SUCCESS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';

export interface CategoriesStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface CategoriesState {
  categories: ContentCategory[];
  status: CategoriesStatus;
}

export interface CategoryStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CategoryState {
  category: ContentCategory;
  status: CategoryStatus;
}

export interface ItemsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface ItemsState {
  items: ContentItem[];
  status: ItemsStatus;
}

export interface ItemStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface ItemState {
  item: ContentItem;
  status: ItemStatus;
}

const actions = {
  async getContentCategories(
    { commit }: ActionContext<CategoriesState, RootState>,
    eventId: string,
  ) {
    commit(GET_CONTENT_CATEGORIES);

    try {
      const contentCategories = await ContentLibraryService.getContentCategories(eventId);

      commit(GET_CONTENT_CATEGORIES_SUCCESS, contentCategories);
    } catch (error) {
      commit(GET_CONTENT_CATEGORIES_ERROR, error);
    }
  },
  async getContentCategory(
    { commit }: ActionContext<CategoryState, RootState>,
    payload: { eventId: string; categoryId: string },
  ) {
    commit(GET_CONTENT_CATEGORY);

    try {
      const contentItems = await ContentLibraryService.getContentCategoryById(
        payload.eventId,
        payload.categoryId,
      );

      commit(GET_CONTENT_CATEGORY_SUCCESS, contentItems);
    } catch (error) {
      commit(GET_CONTENT_CATEGORY_ERROR, error);
    }
  },
  async getContentItems(
    { commit }: ActionContext<ItemsState, RootState>,
    payload: { eventId: string; categoryId: string },
  ) {
    commit(GET_CONTENT_ITEMS);

    try {
      const contentItems = await ContentLibraryService.getContentItems(
        payload.eventId,
        payload.categoryId,
      );

      commit(GET_CONTENT_ITEMS_SUCCESS, contentItems);
    } catch (error) {
      commit(GET_CONTENT_ITEMS_ERROR, error);
    }
  },
  async getContentItem(
    { commit }: ActionContext<ItemState, RootState>,
    payload: {
      eventId: string;
      categoryId: string;
      contentItemId: string;
    },
  ) {
    commit(GET_CONTENT_ITEM);

    try {
      const contentItem = await ContentLibraryService.getContentItem(
        payload.eventId,
        payload.categoryId,
        payload.contentItemId,
      );

      commit(GET_CONTENT_ITEM_SUCCESS, contentItem);
    } catch (error) {
      commit(GET_CONTENT_ITEM_ERROR, error);
    }
  },
};

export const AgnosticContentModule = {
  actions,
};
