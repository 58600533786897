export type StartVideoDetail = {
  videoUrl: string;
};
export const START_VIDEO = 'startVideo';
window[START_VIDEO] = (videoUrl: string) => {
  const event = new CustomEvent<StartVideoDetail>(START_VIDEO, {
    detail: {
      videoUrl,
    },
  });

  window.dispatchEvent(event);
};

export type StartExhibitorChatDetail = {
  exhibitorId: string;
};
export const START_EXHIBITOR_CHAT = 'startExhibitorChat';
window[START_EXHIBITOR_CHAT] = (exhibitorId: string) => {
  const event = new CustomEvent<StartExhibitorChatDetail>(START_EXHIBITOR_CHAT, {
    detail: {
      exhibitorId,
    },
  });

  window.dispatchEvent(event);
};

export type StartExhibitorRepVisioDetails = {
  exhibitorId: string;
  userId: string;
};
export const START_EXHIBITOR_REP_VISIO = 'startExhibitorRepVisio';
window[START_EXHIBITOR_REP_VISIO] = (exhibitorId: string, userId: string) => {
  const event = new CustomEvent<StartExhibitorRepVisioDetails>(START_EXHIBITOR_REP_VISIO, {
    detail: {
      exhibitorId,
      userId,
    },
  });

  window.dispatchEvent(event);
};

export type StartUserChatDetail = {
  userId: string;
};
export const START_USER_CHAT = 'startUserChat';
window[START_USER_CHAT] = (userId: string) => {
  const event = new CustomEvent<StartUserChatDetail>(START_USER_CHAT, {
    detail: {
      userId,
    },
  });

  window.dispatchEvent(event);
};

export type CreateMeetingForUserDetail = {
  userId: string;
};
export const CREATE_MEETING_FOR_USER = 'createMeetingForUser';
window[CREATE_MEETING_FOR_USER] = (userId: string) => {
  const event = new CustomEvent<CreateMeetingForUserDetail>(CREATE_MEETING_FOR_USER, {
    detail: {
      userId,
    },
  });
  window.dispatchEvent(event);
};

export type SaveKioskScreenshotDetail = {
  picture;
};
export const SAVE_KIOSK_SCREENSHOT = 'saveKioskScreenshot';
window[SAVE_KIOSK_SCREENSHOT] = (picture: Blob) => {
  const event = new CustomEvent<SaveKioskScreenshotDetail>(SAVE_KIOSK_SCREENSHOT, {
    detail: {
      picture,
    },
  });
  window.dispatchEvent(event);
};

export type SaveKioskThumbnailDetail = {
  picture;
};
export const SAVE_KIOSK_THUMBNAIL = 'saveKioskThumbnail';
window[SAVE_KIOSK_THUMBNAIL] = (picture: Blob) => {
  const event = new CustomEvent<SaveKioskThumbnailDetail>(SAVE_KIOSK_THUMBNAIL, {
    detail: {
      picture,
    },
  });
  window.dispatchEvent(event);
};

export type VisitKioskDetail = {
  exhibitorId;
};
export const VISIT_KIOSK = 'enterKiosk';
window[VISIT_KIOSK] = (exhibitorId: string) => {
  const event = new CustomEvent<VisitKioskDetail>(VISIT_KIOSK, {
    detail: {
      exhibitorId,
    },
  });
  window.dispatchEvent(event);
};

export type ExitKioskDetail = {
  exhibitorId;
};
export const EXIT_KIOSK = 'exitKiosk';
window[EXIT_KIOSK] = (exhibitorId: string) => {
  const event = new CustomEvent<ExitKioskDetail>(EXIT_KIOSK, {
    detail: {
      exhibitorId,
    },
  });
  window.dispatchEvent(event);
};
