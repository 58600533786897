import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { Tag } from '@/models';
import { ErrorMessage } from '@/models/error-message/error-message.model';

import tagService from '@/services/tag/tag.service';

import {
  ADD_TAG,
  ADD_TAG_ERROR,
  ADD_TAG_SUCCESS,
  GET_TAGS,
  GET_TAGS_ERROR,
  GET_TAGS_SUCCESS,
} from '@/stores/umanize-admin/actions/tag/admin-tag.actions';
import DataUtil from '@/helpers/data/data.helper';

export interface TagsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface TagsState {
  tags: Tag[];
  status: TagsStatus;
}

export interface TagState {
  tags: TagsState;
}

const tagsState: TagsState = {
  tags: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: TagState = {
  tags: tagsState,
};

const actions = {
  async [GET_TAGS]({ commit }: ActionContext<TagsState, RootState>, payload: { eventId: string }) {
    commit(GET_TAGS);

    try {
      const tags = await tagService.getTags(payload.eventId);
      commit(GET_TAGS_SUCCESS, tags);
    } catch (error) {
      commit(GET_TAGS_ERROR, error);
    }
  },
  async [ADD_TAG](
    { commit }: ActionContext<TagState, RootState>,
    payload: { eventId: string; tag: Tag },
  ) {
    commit(ADD_TAG);

    try {
      await tagService.createTag(payload.eventId, payload.tag);
      commit(ADD_TAG_SUCCESS);
    } catch (error) {
      commit(ADD_TAG_ERROR, error);
    }
  },
};

const mutations = {
  [GET_TAGS](state: TagState) {
    state.tags = {
      ...state.tags,
      status: {
        ...state.tags.status,
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_TAGS_SUCCESS](state: TagState, tags: Tag[]) {
    state.tags = {
      ...state.tags,
      status: {
        ...state.tags.status,
        areLoading: false,
        error: null,
      },
      tags: [...tags],
    };
  },
  [GET_TAGS_ERROR](state: TagState, error: ErrorMessage) {
    state.tags.status = {
      ...state.tags.status,
      areLoading: false,
      error,
    };
  },
  [ADD_TAG](state: TagState) {
    state.tags = {
      ...state.tags,
      status: {
        ...state.tags.status,
        error: null,
      },
    };
  },
  [ADD_TAG_SUCCESS](state: TagState) {
    state.tags = {
      ...state.tags,
      status: {
        ...state.tags.status,
        error: null,
      },
    };
  },
  [ADD_TAG_ERROR](state: TagState, error: ErrorMessage) {
    state.tags.status = {
      ...state.tags.status,
      error,
    };
  },
};

const getters = {
  tagsState: (state: TagState): TagsState => state.tags,
  tags: (_, { tagsState }: { tagsState: TagsState }): Tag[] =>
    (tagsState?.tags && DataUtil.sortByStringAsc(tagsState?.tags, 'name')) || [],
  tagsByType: (_, { tags }: { tags: Tag[] }) => (type: string): Tag[] =>
    tags.filter((tag: Tag) => tag.type === type) || [],
  tagsStatus: (_, { tagsState }: { tagsState: TagsState }): TagsStatus => tagsState?.status,
  tagsAreLoading: (_, { tagsStatus }: { tagsStatus: TagsStatus }): boolean =>
    tagsStatus?.areLoading || false,
  tagsError: (_, { tagsStatus }: { tagsStatus: TagsStatus }): ErrorMessage =>
    tagsStatus?.error || null,
};
export const TagModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
