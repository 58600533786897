import { ActionContext } from 'vuex';
import { DateTime } from 'luxon';
import { RootState } from '@/stores/store.model';
import {
  CHECK_USERS_HAVE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_SLOT,
  SAVE_APPOINTMENT,
  SET_LOADING,
  SET_SAVING,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { getAvailableAppointmentSlots } from '@/services/user-availability/user-availability.service';
import { UserEventAppointmentSlot } from '@/models/user-availability/user-event-appointment-slot';
import DateUtil from '@/helpers/date/date.helper';
import {
  checkIfAnyUserHasAppointmentsSlots,
  createAppointment,
  deleteAppointment,
  getAppointments,
} from '@/services/users-appointments/users-appointments.service';
import { UserEventAppointment } from '@/models/users-appointments/user-event-appointment';
import { UserHasEventAppointment } from '@/models/users-appointments/user-has-event-appointment';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';
import { MessageType } from '@/models';
import {
  getNextAppointments,
  getPreviousAppointments,
} from '@/stores/umanize-app/modules/appointment/appointment.helper';

type UserEventAppointmentSlotViewModel = UserEventAppointmentSlot & {
  isoDate: string;
};
type SaveAppointmentParameters = {
  eventId: string;
  slotUserId: string;
  userEventAvailabilityId: string;
  starting: string;
  description: string;
};
export type AppointmentState = {
  appointments: UserEventAppointment[];
  usersHaveAppointment: UserHasEventAppointment[];
  availableAppointmentSlots: UserEventAppointmentSlotViewModel[];
  status: {
    loading: boolean;
    saving: boolean;
  };
};
export const state: AppointmentState = {
  appointments: [],
  usersHaveAppointment: [],
  availableAppointmentSlots: [],
  status: {
    loading: false,
    saving: false,
  },
};

const actions = {
  async [GET_APPOINTMENTS_SLOT](
    { commit, dispatch }: ActionContext<AppointmentState, RootState>,
    { eventId, userId },
  ) {
    try {
      commit(SET_LOADING, true);

      const availableAppointmentSlots = await getAvailableAppointmentSlots({
        eventId,
        userId,
      });

      const viewModel: UserEventAppointmentSlotViewModel[] = availableAppointmentSlots.map(
        (it) => ({
          ...it,
          isoDate: DateTime.fromISO(it.starting).toISODate(),
        }),
      );

      commit(GET_APPOINTMENTS_SLOT, viewModel);
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    } finally {
      commit(SET_LOADING, false);
    }
  },
  async [GET_APPOINTMENTS](
    { commit, dispatch }: ActionContext<AppointmentState, RootState>,
    { eventId },
  ) {
    try {
      commit(SET_LOADING, true);
      const appointments = await getAppointments({ eventId });
      commit(GET_APPOINTMENTS, appointments);
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    } finally {
      commit(SET_LOADING, false);
    }
  },
  async [SAVE_APPOINTMENT](
    { commit, dispatch }: ActionContext<AppointmentState, RootState>,
    {
      eventId,
      description,
      slotUserId,
      starting,
      userEventAvailabilityId,
    }: SaveAppointmentParameters,
  ) {
    try {
      commit(SET_SAVING, true);
      await createAppointment({
        eventId,
        payload: {
          description,
          slotUserId,
          starting,
          userEventAvailabilityId,
        },
      });
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    } finally {
      commit(SET_SAVING, false);
    }
  },
  async [DELETE_APPOINTMENT](
    { commit, dispatch }: ActionContext<AppointmentState, RootState>,
    { eventId, appointmentId },
  ) {
    try {
      commit(SET_SAVING, true);
      await deleteAppointment({ eventId, appointmentId });
      commit(DELETE_APPOINTMENT, appointmentId);
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    } finally {
      commit(SET_SAVING, false);
    }
  },
  async [CHECK_USERS_HAVE_APPOINTMENT](
    { commit }: ActionContext<AppointmentState, RootState>,
    { eventId },
  ) {
    try {
      const usersHaveAppointment = await checkIfAnyUserHasAppointmentsSlots({ eventId });
      commit(CHECK_USERS_HAVE_APPOINTMENT, usersHaveAppointment || []);
    } catch (e) {
      console.error(e);
    }
  },
};

type Getters = {
  isSaving: boolean;
  isLoading: boolean;
  getAppointments: UserEventAppointment[];
  getNextAppointments: UserEventAppointment[];
  getPreviousAppointments: UserEventAppointment[];
  getAppointmentById: (id: string) => UserEventAppointment;
  hasAppointments: boolean;
  getAvailableAppointmentSlots: UserEventAppointmentSlotViewModel[];
  hasAvailableAppointmentSlots: boolean;
  getAvailableDays: string[];
  getAvailableAppointmentSlotsForDay: (day: string) => UserEventAppointmentSlotViewModel[];
  getFirstAvailableDay: string;
  getUsersHaveAppointment: UserHasEventAppointment[];
  getUserHasAppointment: (userId: string) => boolean;
};
const getters = {
  isSaving: (state: AppointmentState): boolean => state.status.saving,
  isLoading: (state: AppointmentState): boolean => state.status.loading,
  getAppointments: (state: AppointmentState): UserEventAppointment[] => state.appointments || [],
  getNextAppointments: (state: AppointmentState, { getAppointments }: Partial<Getters>) =>
    getNextAppointments(DateTime.now())(getAppointments),
  getPreviousAppointments: (state: AppointmentState, { getAppointments }: Partial<Getters>) =>
    getPreviousAppointments(DateTime.now())(getAppointments),
  getAppointmentById: (state: AppointmentState, { getAppointments }: Partial<Getters>) => (
    id: string,
  ): UserEventAppointment => getAppointments.find((it) => it.id === id),
  appointmentExists: (state: AppointmentState, { getAppointmentById }: Partial<Getters>) => (
    id: string,
  ): boolean => !!getAppointmentById(id),
  hasAppointments: (state: AppointmentState, { getAppointments }: Partial<Getters>): boolean =>
    getAppointments.length > 0,
  getAvailableAppointmentSlots: (state: AppointmentState): UserEventAppointmentSlotViewModel[] =>
    state.availableAppointmentSlots || [],
  hasAvailableAppointmentSlots: (
    state: AppointmentState,
    { getAvailableAppointmentSlots }: Partial<Getters>,
  ): boolean => getAvailableAppointmentSlots.length > 0,
  getAvailableDays: (
    state: AppointmentState,
    { getAvailableAppointmentSlots }: Partial<Getters>,
  ): string[] => {
    const allDays = getAvailableAppointmentSlots.map((it) => it.isoDate);
    return [...new Set(allDays)];
  },
  getAvailableAppointmentSlotsForDay: (
    state: AppointmentState,
    { getAvailableAppointmentSlots }: Partial<Getters>,
  ) => (day: string): UserEventAppointmentSlotViewModel[] =>
    getAvailableAppointmentSlots.filter((it) => it.isoDate === day),
  getFirstAvailableDay: (
    state: AppointmentState,
    { getAvailableAppointmentSlots }: Partial<Getters>,
  ) => {
    if (getAvailableAppointmentSlots.length <= 0) {
      return undefined;
    }

    const timestamps = getAvailableAppointmentSlots
      .map((it) => it.isoDate)
      .map((it) => DateTime.fromFormat(it, DateUtil.ISO_DATE_FORMAT))
      .map((it) => it.toMillis());
    const firstTimestamp = Math.min(...timestamps);
    return DateTime.fromMillis(firstTimestamp).toFormat(DateUtil.ISO_DATE_FORMAT);
  },
  getUsersHaveAppointment: (state: AppointmentState) => state.usersHaveAppointment || [],
  getUserHasAppointment: (
    state: AppointmentState,
    { getUsersHaveAppointment }: Partial<Getters>,
  ) => (userId: string) =>
    getUsersHaveAppointment.some((it) => it.userId === userId && it.hasAvailableSlots),
};

const mutations = {
  [GET_APPOINTMENTS_SLOT](
    state: AppointmentState,
    availableAppointmentSlots: UserEventAppointmentSlotViewModel[],
  ) {
    state.availableAppointmentSlots = [...availableAppointmentSlots];
  },
  [SET_LOADING](state: AppointmentState, loading: boolean) {
    state.status.loading = loading;
  },
  [SET_SAVING](state: AppointmentState, saving: boolean) {
    state.status.saving = saving;
  },
  [GET_APPOINTMENTS](state: AppointmentState, appointments: UserEventAppointment[]) {
    state.appointments = [...appointments];
  },
  [DELETE_APPOINTMENT](state: AppointmentState, appointmentId: string) {
    state.appointments = state.appointments.filter((it) => it.id !== appointmentId);
  },
  [CHECK_USERS_HAVE_APPOINTMENT](
    state: AppointmentState,
    usersHaveAppointment: UserHasEventAppointment[],
  ) {
    state.usersHaveAppointment = [...usersHaveAppointment];
  },
};

export const AppointmentModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
