import { Manager, Socket } from 'socket.io-client';
import HttpHelper from '@/helpers/http/http.helper';

export const connectToNamespace = (namespace: string, query?: { [p: string]: string }) => {
  const manager = new Manager(process.env.VUE_APP_SOCKETV2_PATH, {
    multiplex: false,
    autoConnect: false,
    transports: ['websocket'],
    query,
  });

  return manager
    .socket(namespace, {
      auth: { token: localStorage.getItem(HttpHelper.JWT_TOKEN) },
    })
    .connect();
};

export const sockets: Socket[] = [];
