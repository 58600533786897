import { ErrorMessage } from '@/models/error-message/error-message.model';
import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import { UserNote } from '@/models/training/admin/training-note.model';
import {
  ADD_TRAINING_NOTE,
  ADD_TRAINING_NOTE_ERROR,
  ADD_TRAINING_NOTE_SUCCESS,
  CLEAR_ALL_TRAINING_NOTE,
  CLEAR_TRAINING_NOTE,
  GET_ALL_TRAINING_NOTES,
  GET_ALL_TRAINING_NOTES_ERROR,
  GET_ALL_TRAINING_NOTES_SUCCESS,
  GET_TRAINING_NOTE,
  GET_TRAINING_NOTE_ERROR,
  GET_TRAINING_NOTE_SUCCESS,
  UPDATE_TRAINING_NOTE,
  UPDATE_TRAINING_NOTE_SUCCESS,
  UPDATE_TRAINING_NOTE_ERROR,
  DELETE_TRAINING_NOTE,
  DELETE_TRAINING_NOTE_SUCCESS,
  DELETE_TRAINING_NOTE_ERROR,
  UPDATE_LIST_TRAINING_NOTE,
  UPDATE_LIST_TRAINING_NOTE_SUCCESS,
  UPDATE_LIST_TRAINING_NOTE_ERROR,
} from '@/stores/umanize-admin/actions/training-note/trainings-note.actions';
import trainingNoteService from '@/services/training-note/training-note.service';

export interface TrainingsNoteStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface AllTrainingNoteStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface AllTrainingNoteState {
  status: AllTrainingNoteStatus;
  note: UserNote[];
}

export interface CurTrainingNoteState {
  status: TrainingsNoteStatus;
  note: UserNote;
}

export interface TrainingNoteState {
  note: CurTrainingNoteState;
  allNote: AllTrainingNoteState;
}

export const trainingNoteState: CurTrainingNoteState = {
  note: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const allTrainingsNoteState: AllTrainingNoteState = {
  note: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: TrainingNoteState = {
  note: trainingNoteState,
  allNote: allTrainingsNoteState,
};
const actions = {
  async [GET_ALL_TRAINING_NOTES](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId }: { eventId: string; trainingId: string },
  ) {
    commit(GET_ALL_TRAINING_NOTES);
    try {
      const data = await trainingNoteService.getAll(eventId, trainingId);
      commit(GET_ALL_TRAINING_NOTES_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAINING_NOTES_ERROR, error);
    }
  },
  async [ADD_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId, note }: { eventId: string; trainingId: string; note: UserNote },
  ) {
    commit(ADD_TRAINING_NOTE);
    try {
      await trainingNoteService.create(eventId, trainingId, note);
      commit(ADD_TRAINING_NOTE_SUCCESS);
    } catch (e) {
      commit(ADD_TRAINING_NOTE_ERROR, e);
    }
  },
  async [UPDATE_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId, note }: { eventId: string; trainingId: string; note: UserNote },
  ) {
    commit(UPDATE_TRAINING_NOTE);
    try {
      await trainingNoteService.update(eventId, trainingId, note);
      commit(UPDATE_TRAINING_NOTE_SUCCESS);
    } catch (e) {
      commit(UPDATE_TRAINING_NOTE_ERROR, e);
    }
  },
  async [GET_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId, noteId }: { eventId: string; trainingId: string; noteId: string },
  ) {
    commit(GET_TRAINING_NOTE);
    try {
      const data = await trainingNoteService.get(eventId, trainingId, noteId);
      commit(GET_TRAINING_NOTE_SUCCESS, data);
    } catch (e) {
      commit(GET_TRAINING_NOTE_ERROR, e);
    }
  },
  async [GET_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId, noteId }: { eventId: string; trainingId: string; noteId: string },
  ) {
    commit(GET_TRAINING_NOTE);
    try {
      const data = await trainingNoteService.get(eventId, trainingId, noteId);
      commit(GET_TRAINING_NOTE_SUCCESS, data);
    } catch (e) {
      commit(GET_TRAINING_NOTE_ERROR, e);
    }
  },
  async [UPDATE_LIST_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    {
      eventId,
      trainingId,
      listUpdateNote,
    }: { eventId: string; trainingId: string; listUpdateNote: UserNote[] },
  ) {
    commit(UPDATE_LIST_TRAINING_NOTE);
    try {
      const promises = listUpdateNote.map((ele) =>
        trainingNoteService.update(eventId, trainingId, ele),
      );
      await Promise.all(promises).then((response) => response);
      commit(UPDATE_LIST_TRAINING_NOTE_SUCCESS);
    } catch (e) {
      commit(UPDATE_LIST_TRAINING_NOTE_ERROR, e);
    }
  },
  async [DELETE_TRAINING_NOTE](
    { commit }: ActionContext<TrainingNoteState, RootState>,
    { eventId, trainingId, note }: { eventId: string; trainingId: string; note: UserNote },
  ) {
    commit(DELETE_TRAINING_NOTE);
    try {
      await trainingNoteService.delete(eventId, trainingId, note);
      commit(DELETE_TRAINING_NOTE_SUCCESS);
    } catch (e) {
      commit(DELETE_TRAINING_NOTE_ERROR, e);
    }
  },
  [CLEAR_TRAINING_NOTE]({ commit }: ActionContext<TrainingNoteState, RootState>) {
    commit(CLEAR_TRAINING_NOTE);
  },
  [CLEAR_ALL_TRAINING_NOTE]({ commit }: ActionContext<TrainingNoteState, RootState>) {
    commit(CLEAR_ALL_TRAINING_NOTE);
  },
};

const mutations = {
  [GET_ALL_TRAINING_NOTES](state: TrainingNoteState) {
    state.allNote = {
      ...state.allNote,
      status: {
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_ALL_TRAINING_NOTES_SUCCESS](state: TrainingNoteState, allNote: UserNote[]) {
    state.allNote.status.areLoading = false;
    state.allNote.status.error = null;
    state.allNote = {
      ...state.allNote,
      note: allNote,
    };
  },
  // [GET_ALL_TRAININGS_ADMIN_PROGRESS](
  //   state: TrainingAdminState,
  //   trainingList: TrainingProgressModel[],
  // ) {
  //   state.trainingProgress.status.areLoading = false;
  //   state.trainingProgress.status.error = null;
  //   state.trainingProgress = {
  //     ...state.allTrainings,
  //     trainingProgress: trainingList,
  //   };
  // },
  [GET_ALL_TRAINING_NOTES_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.allNote = {
      ...state.allNote,
      status: {
        areLoading: false,
        error,
      },
    };
  },
  // [GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR](state: TrainingNoteState, error: ErrorMessage) {
  //   state.trainingProgress = {
  //     ...state.trainingProgress,
  //     status: {
  //       areLoading: false,
  //       error,
  //     },
  //   };
  // },
  // [SET_TRAINING_TRAINER_LIST_ID](state: TrainingNoteState, data: []) {
  //   state.training.training = {
  //     ...state.training.training,
  //     trainerIds: data,
  //   };
  // },
  [ADD_TRAINING_NOTE](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_TRAINING_NOTE_SUCCESS](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error: null,
    };
  },
  [ADD_TRAINING_NOTE_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_TRAINING_NOTE](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_TRAINING_NOTE_SUCCESS](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error: null,
    };
  },
  [UPDATE_TRAINING_NOTE_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error,
    };
  },
  [GET_TRAINING_NOTE](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_TRAINING_NOTE_SUCCESS](state: TrainingNoteState, note: UserNote) {
    state.note = {
      ...state.note,
      note,
      status: {
        isLoading: false,
        isSaving: false,
        error: null,
      },
    };
  },
  [GET_TRAINING_NOTE_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_LIST_TRAINING_NOTE](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_LIST_TRAINING_NOTE_SUCCESS](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error: null,
    };
  },
  [UPDATE_LIST_TRAINING_NOTE_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error,
    };
  },
  [DELETE_TRAINING_NOTE](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_TRAINING_NOTE_SUCCESS](state: TrainingNoteState) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error: null,
    };
  },
  [DELETE_TRAINING_NOTE_ERROR](state: TrainingNoteState, error: ErrorMessage) {
    state.note.status = {
      ...state.note.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_TRAINING_NOTE](state: TrainingNoteState) {
    state.note = {
      ...state.note,
      note: null,
    };
  },
  [CLEAR_ALL_TRAINING_NOTE](state: TrainingNoteState) {
    state.allNote = {
      ...state.allNote,
      note: [],
    };
  },
};

const getters = {
  allNoteState: (state: TrainingNoteState) => state.allNote,
};

export const TrainingNoteModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
