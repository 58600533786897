import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';
import { MATCHING_MODULE } from '@/stores/agnostic/actions/matching/matching.actions';

export const APP_MATCHING_MODULE = `${UMANIZE_APP_MODULE}/${MATCHING_MODULE}`;

export const ANSWER_MATCHING_QUESTION = 'ANSWER_MATCHING_QUESTION';
export const ANSWER_MATCHING_QUESTION_SUCCESS = 'ANSWER_MATCHING_QUESTION_SUCCESS';
export const ANSWER_MATCHING_QUESTION_ERROR = 'ANSWER_MATCHING_QUESTION_ERROR';

export const FILTER_MATCHING_RESULTS = 'FILTER_MATCHING_RESULTS';

export const GET_MATCHING_RESULTS = 'GET_MATCHING_RESULTS';
export const GET_MATCHING_RESULTS_SUCCESS = 'GET_MATCHING_RESULTS_SUCCESS';
export const GET_MATCHING_RESULTS_ERROR = 'GET_MATCHING_RESULTS_ERROR';

export const REMOVE_MATCHING_RESULTS = 'REMOVE_MATCHING_RESULTS';
export const REMOVE_MATCHING_RESULTS_SUCCESS = 'REMOVE_MATCHING_RESULTS_SUCCESS';
