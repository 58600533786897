import { Socket } from 'socket.io-client';
import { connectToNamespace, sockets } from '@/socket/socket-v3';

export const EXHIBITOR_USER_STATUS_UPDATED = 'exhibitor-user-status-updated';
export const EXHIBITOR_VISITED = 'exhibitor-visited';
export const EXHIBITOR_LEFT = 'exhibitor-left';

export const EXHIBITOR_USER_NAMESPACE = `/exhibitor-user`;

export const connectToExhibitorUserNamespace = (
  eventId: string,
  exhibitorId: string,
  userId: string,
): Socket => {
  const namespace = EXHIBITOR_USER_NAMESPACE;
  sockets[namespace] = connectToNamespace(namespace, { eventId, exhibitorId, userId });
  return sockets[namespace];
};

export const disconnectFromExhibitorUserNamespace = (exhibitorSocket: Socket) =>
  exhibitorSocket.disconnect();
