/* eslint-disable */

import { ErrorMessage } from '@/models/error-message/error-message.model';
import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import trainingSectionService from '@/services/training-section/training-section.service';

import {
  ADD_TRAINING_SECTION,
  ADD_TRAINING_SECTION_ERROR,
  ADD_TRAINING_SECTION_SUCCESS,
  CLEAR_ALL_TRAINING_SECTION,
  CLEAR_TRAINING_SECTION,
  COMPLETE_LESSION_TRAINING_SECTION,
  DELETE_TRAINING_SECTION,
  DELETE_TRAINING_SECTION_ERROR,
  DELETE_TRAINING_SECTION_SUCCESS,
  GET_ALL_TRAININGS_SECTION_ADMIN,
  GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
  GET_ALL_TRAININGS_SECTION_ERROR,
  GET_ALL_TRAININGS_SECTION_SUCCESS,
  GET_TRAINING_SECTION,
  GET_TRAINING_SECTION_ERROR,
  GET_TRAINING_SECTION_SUCCESS,
  PUBLISH_TRAINING_SECTION,
  UNPUBLISH_TRAINING_SECTION,
  UPDATE_TRAINING_SECTION,
  UPDATE_TRAINING_SECTION_ERROR,
  UPDATE_TRAINING_SECTION_SUCCESS,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import { AdminTrainingSectionModel } from '@/models/training/admin/section/admin-training-section.model';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import trainingService from '@/services/training/training.service';

export interface TrainingsSectionAdminStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface AllTrainingsSectionAdminStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface AllTrainingSectionAdminState {
  status: AllTrainingsSectionAdminStatus;
  trainingsSection: AdminTrainingSectionModel[];
}

export interface CurTrainingSectionAdminState {
  status: TrainingsSectionAdminStatus;
  trainingSection: AdminTrainingSectionModel;
}

export interface TrainingSectionAdminState {
  trainingSection: CurTrainingSectionAdminState;
  allTrainingsSection: AllTrainingSectionAdminState;
}

export const trainingSectionAdminState: CurTrainingSectionAdminState = {
  trainingSection: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const allTrainingsSectionAdminState: AllTrainingSectionAdminState = {
  trainingsSection: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const state: TrainingSectionAdminState = {
  trainingSection: trainingSectionAdminState,
  allTrainingsSection: allTrainingsSectionAdminState,
};
const actions = {
  async [GET_ALL_TRAININGS_SECTION_ADMIN](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    { eventId, trainingId }: { eventId: string; trainingId: string },
  ) {
    commit(GET_ALL_TRAININGS_SECTION_ADMIN);
    try {
      const data = await trainingSectionService.getAll(eventId, trainingId);

      commit(GET_ALL_TRAININGS_SECTION_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_SECTION_ERROR, error);
    }
  },
  async [GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON]({
    commit,
    state,
  }: ActionContext<TrainingSectionAdminState, RootState>) {
    commit(GET_ALL_TRAININGS_SECTION_ADMIN);
    try {
      const data = state.allTrainingsSection.trainingsSection.filter(
        (ele) => ele.published && new Date(ele.published).getTime() <= new Date().getTime(),
      );
      const promises = data.map((ele: { eventId: string; trainingId: string; id: string }) =>
        trainingLessonService.getAll(ele.eventId, ele.trainingId, ele.id),
      );
      const lessons = await Promise.all(promises).then((response) => response);
      data.forEach((ele, index) => {
        // eslint-disable-next-line no-param-reassign
        ele['lessons'] = lessons[index];
      });

      if (data?.length) {
        const detail = data[0];
        const { eventId, trainingId } = detail;
        const listProgressLesson = await trainingService.getUserProcess(eventId, trainingId);
        data.forEach((eleData) => {
          let numFinishLesson = 0;
          eleData.lessons.forEach((eleItem) => {
            const lesson = listProgressLesson.lessons.find((les) => les.lessonId === eleItem.id);
            if (lesson) {
              eleItem['score'] = lesson['score'];
              eleItem['isDone'] = lesson['isDone'];
              eleItem['nbAttempts'] = lesson['nbAttempts'];
              if (lesson['isDone']) numFinishLesson++;
            }
          });
          if (numFinishLesson > 0 && numFinishLesson === eleData.lessons.length) {
            eleData['isDone'] = true;
          }
        });
      }

      commit(GET_ALL_TRAININGS_SECTION_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_SECTION_ERROR, error);
      console.error(error);
    }
  },
  async [COMPLETE_LESSION_TRAINING_SECTION](
    { commit, state }: ActionContext<TrainingSectionAdminState, RootState>,
    {
      eventId,
      trainingId,
      sectionId,
      lessonId,
      score,
      nbAttempts,
      isDone,
    }: {
      eventId: string;
      trainingId: string;
      sectionId: string;
      lessonId: string;
      score?: number;
      nbAttempts?: number;
      isDone?: boolean;
    },
  ) {
    commit(GET_ALL_TRAININGS_SECTION_ADMIN);
    try {
      const data = state.allTrainingsSection.trainingsSection.filter(
        (ele) => ele.published && new Date(ele.published).getTime() <= new Date().getTime(),
      );
      await trainingLessonService.complete({
        eventId,
        trainingId,
        sectionId,
        lessonId,
        score,
        nbAttempts,
        isDone,
      });
      data.forEach((ele) => {
        if (ele.id === sectionId) {
          let numFinishLesson = 0;
          ele.lessons = ele?.lessons?.map((eleLesson) => {
            if (eleLesson.id === lessonId) {
              return {
                ...eleLesson,
                isDone,
                score,
                nbAttempts,
              };
            }
            return eleLesson;
          });
          ele?.lessons?.forEach((eleLesson) => {
            if (eleLesson.isDone) {
              numFinishLesson++;
            }
          });
          if (numFinishLesson === ele?.lessons?.length) {
            // eslint-disable-next-line no-param-reassign
            ele['isDone'] = true;
          }
        }
      });
      commit(GET_ALL_TRAININGS_SECTION_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_SECTION_ERROR, error);
    }
  },
  async [ADD_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    {
      eventId,
      section,
      trainingId,
    }: { eventId: string; section: AdminTrainingSectionModel; trainingId: string },
  ) {
    commit(ADD_TRAINING_SECTION);
    try {
      await trainingSectionService.create(eventId, section, trainingId);
      commit(ADD_TRAINING_SECTION_SUCCESS);
    } catch (e) {
      commit(ADD_TRAINING_SECTION_ERROR, e);
    }
  },
  async [UPDATE_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    {
      eventId,
      section,
      trainingId,
    }: { eventId: string; section: AdminTrainingSectionModel; trainingId: string },
  ) {
    commit(UPDATE_TRAINING_SECTION);
    try {
      await trainingSectionService.update(eventId, section, trainingId);
      commit(ADD_TRAINING_SECTION_SUCCESS);
    } catch (e) {
      commit(ADD_TRAINING_SECTION_ERROR, e);
    }
  },
  async [GET_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    { eventId, trainingId, sectionId }: { eventId: string; trainingId: string; sectionId: string },
  ) {
    commit(GET_TRAINING_SECTION);
    try {
      const data = await trainingSectionService.get(eventId, trainingId, sectionId);
      commit(GET_TRAINING_SECTION_SUCCESS, data);
    } catch (e) {
      commit(GET_TRAINING_SECTION_ERROR, e);
    }
  },
  async [PUBLISH_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    {
      eventId,
      trainingId,
      sectionId,
      published,
    }: { eventId: string; trainingId: string; sectionId: string; published: Date },
  ) {
    try {
      const data = await trainingSectionService.publish(eventId, trainingId, sectionId, published);
    } catch (e) {
      console.error(e);
    }
  },
  async [UNPUBLISH_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    { eventId, trainingId, sectionId }: { eventId: string; trainingId: string; sectionId: string },
  ) {
    try {
      const data = await trainingSectionService.unpublish(eventId, trainingId, sectionId);
    } catch (e) {
      console.error(e);
    }
  },
  async [DELETE_TRAINING_SECTION](
    { commit }: ActionContext<TrainingSectionAdminState, RootState>,
    {
      eventId,
      trainingId,
      section,
    }: { eventId: string; trainingId: string; section: AdminTrainingSectionModel },
  ) {
    commit(DELETE_TRAINING_SECTION);
    try {
      await trainingSectionService.delete(eventId, trainingId, section);
      commit(DELETE_TRAINING_SECTION_SUCCESS, section);
    } catch (e) {
      commit(DELETE_TRAINING_SECTION_ERROR, e);
    }
  },
  [CLEAR_TRAINING_SECTION]({ commit }: ActionContext<TrainingSectionAdminState, RootState>) {
    commit(CLEAR_TRAINING_SECTION);
  },
  [CLEAR_ALL_TRAINING_SECTION]({ commit }: ActionContext<TrainingSectionAdminState, RootState>) {
    commit(CLEAR_ALL_TRAINING_SECTION);
  },
};

const mutations = {
  [GET_ALL_TRAININGS_SECTION_ADMIN](state: TrainingSectionAdminState) {
    state.allTrainingsSection = {
      ...state.allTrainingsSection,
      status: {
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_ALL_TRAININGS_SECTION_SUCCESS](
    state: TrainingSectionAdminState,
    trainingSectionList: AdminTrainingSectionModel[],
  ) {
    state.allTrainingsSection.status.areLoading = false;
    state.allTrainingsSection.status.error = null;
    state.allTrainingsSection = {
      ...state.allTrainingsSection,
      trainingsSection: trainingSectionList,
    };
  },
  [GET_ALL_TRAININGS_SECTION_ERROR](state: TrainingSectionAdminState, error: ErrorMessage) {
    state.allTrainingsSection = {
      ...state.allTrainingsSection,
      status: {
        areLoading: false,
        error,
      },
    };
  },
  [ADD_TRAINING_SECTION](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_TRAINING_SECTION_SUCCESS](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error: null,
    };
  },
  [ADD_TRAINING_SECTION_ERROR](state: TrainingSectionAdminState, error: ErrorMessage) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_TRAINING_SECTION](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_TRAINING_SECTION_SUCCESS](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error: null,
    };
  },
  [UPDATE_TRAINING_SECTION_ERROR](state: TrainingSectionAdminState, error: ErrorMessage) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error,
    };
  },
  [GET_TRAINING_SECTION](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_TRAINING_SECTION_SUCCESS](
    state: TrainingSectionAdminState,
    trainingSection: AdminTrainingSectionModel,
  ) {
    state.trainingSection = {
      ...state.trainingSection,
      trainingSection,
      status: {
        isLoading: false,
        isSaving: false,
        error: null,
      },
    };
  },
  [GET_TRAINING_SECTION_ERROR](state: TrainingSectionAdminState, error: ErrorMessage) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error,
    };
  },
  [DELETE_TRAINING_SECTION](state: TrainingSectionAdminState) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_TRAINING_SECTION_SUCCESS](
    state: TrainingSectionAdminState,
    section: AdminTrainingSectionModel,
  ) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error: null,
    };
    const newListSection = state.allTrainingsSection.trainingsSection.filter(
      (e) => e.id !== section.id,
    );
    state.allTrainingsSection.trainingsSection = newListSection;
  },
  [DELETE_TRAINING_SECTION_ERROR](state: TrainingSectionAdminState, error: ErrorMessage) {
    state.trainingSection.status = {
      ...state.trainingSection.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_ALL_TRAINING_SECTION](state: TrainingSectionAdminState) {
    state.allTrainingsSection = {
      ...state.allTrainingsSection,
      trainingsSection: [],
    };
  },
};

const getters = {
  allTrainingSectionState: (state: TrainingSectionAdminState) => state.allTrainingsSection,
  trainingSectionStateAreLoading: (state: TrainingSectionAdminState) =>
    state.allTrainingsSection.status.areLoading,
  allTrainingSectionData: (state: TrainingSectionAdminState) =>
    state.allTrainingsSection.trainingsSection,
  allPublicTrainingSectionData: (state: TrainingSectionAdminState) =>
    state.allTrainingsSection.trainingsSection.filter(
      (ele) => ele.published && new Date(ele.published).getTime() <= new Date().getTime(),
    ),
  trainingSectionSate: (state: TrainingSectionAdminState) => state.trainingSection,
  trainingSectionData: (state: TrainingSectionAdminState) => state.trainingSection.trainingSection,
  currentTrainingProgress: (state: TrainingSectionAdminState) => {
    let totalLesson = 0;
    let finishedLesson = 0;
    state.allTrainingsSection.trainingsSection
      .filter((ele) => ele.published)
      ?.forEach((section) => {
        section.lessons?.forEach((lesson) => {
          totalLesson++;
          if (lesson.isDone) {
            finishedLesson++;
          }
        });
      });
    return totalLesson === 0 ? '--' : Math.round((finishedLesson * 100) / totalLesson);
  },
};

export const AdminTrainingSectionModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
