export class ConferenceRoom {
  id: string;

  eventId: string;

  name: string;

  backgroundImage: string;

  coverImage: string;
}
