import HttpService from '@/services/http/http.service';
import HttpHelper from '@/helpers/http/http.helper';
import { Relation } from '@/models/relation/relation.model';
import { HttpMethod } from '@/models';

class RelationsService {
  public async getRelations(): Promise<Relation[]> {
    return HttpService.perform<Relation[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.CONTACTS_ENDPOINT),
    );
  }

  public async addToContacts(relation: Relation): Promise<Relation> {
    return HttpService.perform<Relation>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.CONTACTS_ENDPOINT, [relation.id]),
    );
  }

  public async removeFromContacts(relation: Relation): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.CONTACTS_ENDPOINT, [relation.id]),
    );
  }
}

export default new RelationsService();
