import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import StreamService from '@/services/stream/stream.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { Stream } from '@/models/stream/stream.model';
import {
  GET_ALL_STREAMS,
  GET_ALL_STREAMS_ERROR,
  GET_ALL_STREAMS_SUCCESS,
  GET_STREAM,
  GET_STREAM_ERROR,
  GET_STREAM_SUCCESS,
} from '@/stores/agnostic/actions/stream/agnostic-stream.actions';

export interface StreamStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CurrentStreamState {
  stream: Stream;
  status: StreamStatus;
}

export interface StreamState {
  stream: CurrentStreamState;
  streams: StreamsState;
}

export interface StreamsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface StreamsState {
  streams: Stream[];
  status: StreamsStatus;
}

const actions = {
  async getStreams(
    { commit }: ActionContext<StreamsState, RootState>,
    { eventId }: { eventId: string },
  ) {
    commit(GET_ALL_STREAMS);

    try {
      const streams = await StreamService.getAll(eventId);

      commit(GET_ALL_STREAMS_SUCCESS, streams);
    } catch (error) {
      commit(GET_ALL_STREAMS_ERROR, error);
    }
  },
  async getStream(
    { commit }: ActionContext<StreamsState, RootState>,
    { eventId, streamId }: { eventId: string; streamId: string },
  ) {
    commit(GET_STREAM);

    try {
      const stream = await StreamService.get(eventId, streamId);

      commit(GET_STREAM_SUCCESS, stream);
    } catch (error) {
      commit(GET_STREAM_ERROR, error);
    }
  },
};

export const AgnosticStreamModule = {
  actions,
};
