import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { ResetForm, UpdateForm, MessageType } from '@/models';
import AuthService from '@/services/auth/auth.service';
import {
  RESET,
  RESET_ERROR,
  RESET_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
} from '@/stores/umanize-app/actions/password/password.actions';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';

export interface PasswordState {
  status: {
    error: string;
    isSending: boolean;
    isResetSent: boolean;
    isUpdateSent: boolean;
    isValidated: boolean;
  };
}

const state: PasswordState = {
  status: {
    error: '',
    isSending: false,
    isResetSent: false,
    isUpdateSent: false,
    isValidated: false,
  },
};

const actions = {
  async [RESET](
    { commit, dispatch }: ActionContext<PasswordState, RootState>,
    resetForm: ResetForm,
  ) {
    commit(RESET);
    try {
      await AuthService.resetPassword(resetForm);
      commit(RESET_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.reset.emailText'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      const error = i18n.t(`auth.reset.errors.${err.status}`);
      commit(RESET_ERROR, error);
    }
  },
  async [UPDATE](
    { commit, dispatch }: ActionContext<PasswordState, RootState>,
    payload: {
      passwordForm: UpdateForm;
      token: string;
    },
  ) {
    commit(UPDATE);
    try {
      await AuthService.updatePassword(payload.passwordForm, payload.token);
      commit(UPDATE_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('auth.update.updateSuccess'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );

      return true;
    } catch (err) {
      const error = i18n.t(`auth.update.errors.${err.status}`);
      commit(UPDATE_ERROR, error);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: error,
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
      return false;
    }
  },
  async [VALIDATE_TOKEN](
    { commit, dispatch }: ActionContext<PasswordState, RootState>,
    token: string,
  ) {
    commit(VALIDATE_TOKEN);
    try {
      await AuthService.validatePasswordToken(token);
      commit(VALIDATE_TOKEN_SUCCESS);

      return true;
    } catch (err) {
      const error = i18n.t('auth.update.invalidToken');
      commit(VALIDATE_TOKEN_ERROR, error);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: error,
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      throw err;
    }
  },
};

const mutations = {
  [RESET](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
      isResetSent: false,
    };
  },
  [RESET_SUCCESS](state) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
      isResetSent: true,
    };
  },
  [RESET_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
      isResetSent: false,
    };
  },
  [UPDATE](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
      isUpdateSent: false,
    };
  },
  [UPDATE_SUCCESS](state) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
      isUpdateSent: true,
    };
  },
  [UPDATE_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
      isUpdateSent: false,
    };
  },
  [VALIDATE_TOKEN](state) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
      isValidated: false,
    };
  },
  [VALIDATE_TOKEN_SUCCESS](state) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
      isValidated: true,
    };
  },
  [VALIDATE_TOKEN_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
      isValidated: false,
    };
  },
};

const getters = {
  isSending: (state) => (state.status && state.status.isSending) || false,
  error: (state) => (state.status && state.status.error) || '',
  isResetSent: (state) => (state.status && state.status.isResetSent) || false,
  isUpdateSent: (state) => (state.status && state.status.isUpdateSent) || false,
  isValidated: (state) => (state.status && state.status.isValidated) || false,
};

export const PasswordModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
