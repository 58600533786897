import Nested from '@/components/nested/Nested.vue';
import UmanizeEventAdmin from '@/components/umanize-event-admin/UmanizeEventAdmin.vue';

export function buildPublicEventRoutes(fetchEvent, adminGuard) {
  return [
    {
      path: '/events/:eventId',
      component: Nested,
      beforeEnter: fetchEvent,
      redirect: { name: 'Lobby', replace: true, append: false },
      children: [
        {
          path: 'trainings',
          // redirect: { name: 'Trainings', replace: true, append: false },
          component: Nested,
          children: [
            {
              path: 'detail/:trainingId/:sectionId?/:lessonId?',
              name: 'TrainingsDetail',
              component: () =>
                import(
                  /* webpackChunkName: "trainings-detail" */ '@/views/training/TrainingDetail.vue'
                ),
              meta: {
                title: 'menu.trainings',
                noFooter: true,
                requiresAuth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: '',
              name: 'Trainings',
              component: () =>
                import(/* webpackChunkName: "trainings-list" */ '@/views/TrainingsList.vue'),
              meta: {
                title: 'menu.trainings',
                noFooter: true,
                requiresAuth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'buy',
          name: 'Buy',
          component: () =>
            import(/* webpackChunkName: "ticket" */ '@/views/ticketing/BuyTicket.vue'),
          meta: {
            title: 'ticketing.selectTicket',
            hideMenu: true,
            fullWidth: true,
            beforePayment: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'summary',
          name: 'Summary',
          component: () =>
            import(/* webpackChunkName: "ticket" */ '@/views/ticketing/BuyTicketSummary.vue'),
          meta: {
            title: 'ticketing.command.summary',
            hideMenu: true,
            fullWidth: true,
            beforePayment: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'pay',
          name: 'Payment',
          component: () =>
            import(/* webpackChunkName: "ticket" */ '@/views/ticketing/ProcessPayment.vue'),
          meta: {
            title: 'ticketing.payment.title',
            requiresAuth: true,
            hideMenu: true,
            beforePayment: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'confirmation',
          name: 'Confirmation',
          component: () =>
            import(/* webpackChunkName: "ticket" */ '@/views/ticketing/Confirmation.vue'),
          meta: {
            title: 'ticketing.thankYou.title',
            requiresAuth: true,
            hideMenu: true,
            beforePayment: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'agenda',
          name: 'Agenda',
          component: () => import(/* webpackChunkName: "agenda" */ '@/views/Agenda.vue'),
          meta: {
            title: 'menu.agenda',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'full',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'exhibitors',
          component: Nested,
          children: [
            {
              path: '/',
              name: 'Exhibitors',
              component: () =>
                import(/* webpackChunkName: "exhibitors" */ '@/views/Exhibitors.vue'),
              meta: {
                title: 'menu.exhibitors',
                requiresAuth: true,
                fullWidth: true,
                adverts: 'full',
                requiredCompleteProfile: true,
              },
            },
            {
              path: ':exhibitorId',
              name: 'Exhibitor',
              component: () =>
                import(
                  /* webpackChunkName: "exhibitors" */ '@/views/exhibitors/exhibitor/Exhibitor.vue'
                ),
              meta: {
                title: 'menu.exhibitors',
                requiresAuth: true,
                fullWidth: true,
                adverts: 'full',
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'profile',
          name: 'TicketProfile',
          component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
          meta: {
            title: 'menu.profile',
            hideMenu: false,
            fullWidth: true,
            beforePayment: true,
          },
        },
        {
          path: 'smart-matching',
          component: Nested,
          redirect: { name: 'SmartMatching', replace: true, append: false },
          children: [
            {
              path: '/',
              name: 'SmartMatching',
              component: () =>
                import(
                  /* webpackChunkName: "smart-matching" */ '@/views/matching/SmartMatching.vue'
                ),
              meta: {
                title: 'menu.smartMatching',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'results',
              name: 'SmartMatchingResults',
              component: () =>
                import(
                  /* webpackChunkName: "smart-matching" */ '@/views/matching/SmartMatchingResults.vue'
                ),
              meta: {
                title: 'menu.smartMatching',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'confirmation',
              name: 'SmartMatchingConfirmation',
              component: () =>
                import(
                  /* webpackChunkName: "smart-matching" */ '@/views/matching/Confirmation.vue'
                ),
              meta: {
                title: 'menu.smartMatching',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'recommended-activities',
          component: Nested,
          redirect: { name: 'Activities', replace: true, append: false },
          children: [
            {
              path: '/',
              name: 'Activities',
              component: () =>
                import(
                  /* webpackChunkName: "activities" */ '@/views/activities/activities/Activities.vue'
                ),
              meta: {
                title: 'menu.activities',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'results',
              name: 'ActivitiesResults',
              component: () =>
                import(
                  /* webpackChunkName: "activities" */ '@/views/activities/activities-results/ActivitiesResults.vue'
                ),
              meta: {
                title: 'menu.activities',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'confirmation',
              name: 'ActivitiesConfirmation',
              component: () =>
                import(
                  /* webpackChunkName: "activities" */ '@/views/activities/activities-confirmation/ActivitiesConfirmation.vue'
                ),
              meta: {
                title: 'menu.activities',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'admin',
          component: UmanizeEventAdmin,
          beforeEnter: adminGuard,
          redirect: { name: 'EventDetailsAdmin', replace: true, append: false },
          meta: {
            requiresAuth: true,
            fullWidth: true,
            isAdmin: true,
          },
          children: [
            {
              path: 'details',
              component: Nested,
              redirect: { name: 'EventAdmin', replace: true, append: false },
              children: [
                {
                  path: '',
                  name: 'EventDetailsAdmin',
                  component: () =>
                    import(/* webpackChunkName: "admin" */ '@/views/admin/events/EventAdmin.vue'),
                  meta: {
                    title: 'menu.adminHome',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.events.canView'],
                  },
                },
                {
                  path: 'custom-ui',
                  name: 'CustomUIAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/events/custom-ui/CustomUIAdmin.vue'
                    ),
                  meta: {
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.customUi.canView'],
                  },
                },
              ],
            },
            {
              path: 'users',
              name: 'UsersAdmin',
              component: () =>
                import(/* webpackChunkName: "admin" */ '@/views/admin/users/UsersAdmin.vue'),
              meta: {
                title: 'menu.admin',
                requiresAuth: true,
                fullWidth: true,
                requiredPermissions: ['permission.users.canView'],
              },
            },
            {
              path: 'training',
              component: Nested,
              meta: {
                requiresAuth: true,
                fullWidth: true,
                isAdmin: true,
              },
              children: [
                {
                  path: '/',
                  name: 'TrainingListAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "TrainingListAdmin" */ '@/views/admin/trainings/training-list/TrainingListAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.adminHome',
                    requiresAuth: true,
                    fullWidth: true,
                  },
                },
                {
                  path: ':idTraining/formation-seo',
                  name: 'TrainingFormationSEO',
                  component: () =>
                    import(
                      /* webpackChunkName: "TrainingFormationSEO" */ '@/views/admin/trainings/training-list/training-formation-seo/TrainingFormationSeo.vue'
                    ),
                  meta: {
                    title: 'menu.adminHome',
                    requiresAuth: true,
                    fullWidth: true,
                  },
                },
                {
                  path: ':idTraining/formation-seo/list-participants',
                  name: 'TrainingFormationSEOListParticipants',
                  component: () =>
                    import(
                      /* webpackChunkName: "TrainingFormationSEOListParticipants" */ '@/views/admin/trainings/training-list/training-formation-seo/list-participants/ListParticipants.vue'
                    ),
                  meta: {
                    title: 'menu.adminHome',
                    requiresAuth: true,
                    fullWidth: true,
                  },
                },
                {
                  path: ':idTraining/formation-seo/:idSection',
                  name: 'TrainingFormationSEOListSection',
                  component: () =>
                    import(
                      /* webpackChunkName: "TrainingFormationSEOListSection" */ '@/views/admin/trainings/training-list/training-formation-seo/list-section/List-section.vue'
                    ),
                  meta: {
                    title: 'menu.adminHome',
                    requiresAuth: true,
                    fullWidth: true,
                  },
                },
              ],
            },
            {
              path: 'conferences',
              component: Nested,
              redirect: { name: 'ConferencesAdmin', replace: true, append: false },
              children: [
                {
                  path: ':conferenceId/edit/partners/:partnerId/edit',
                  name: 'EditConferencePartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "EditConferencePartnerAdmin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: [
                      'permission.conferences.canView',
                      'permission.partners.canView',
                    ],
                    partnerType: 'conference',
                    modalType: 'edit',
                  },
                },
                {
                  path: ':conferenceId/edit/partners/add',
                  name: 'AddConferencePartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "AddConferencePartnerAdmin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: [
                      'permission.conferences.canView',
                      'permission.partners.canCreate',
                    ],
                    partnerType: 'conference',
                    modalType: 'create',
                  },
                },
                {
                  path: ':conferenceId/edit',
                  name: 'EditConferenceAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "EditConferenceAdmin" */ '@/views/admin/conferences/edit/EditConferenceAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canView'],
                  },
                },
                {
                  path: 'add',
                  name: 'AddConferenceAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "AddConferenceAdmin" */ '@/views/admin/conferences/edit/EditConferenceAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canCreate'],
                  },
                },
                {
                  path: '',
                  name: 'ConferencesAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/conferences/ConferencesAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canView'],
                  },
                },
              ],
            },
            {
              path: 'tickets',
              component: Nested,
              redirect: { name: 'TicketsAdmin', replace: true, append: false },
              children: [
                {
                  path: ':ticketId/edit',
                  name: 'EditTicketAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/tickets/edit/EditTicketAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.tickets.canView'],
                    modalType: 'edit',
                  },
                },
                {
                  path: 'add',
                  name: 'AddTicketAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/tickets/edit/EditTicketAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.tickets.canCreate'],
                    modalType: 'create',
                  },
                },
                {
                  path: '',
                  name: 'TicketsAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/tickets/TicketsAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.tickets.canView'],
                  },
                },
              ],
            },
            {
              path: 'conference-rooms',
              component: Nested,
              redirect: { name: 'ConferenceRoomsAdmin', replace: true, append: false },
              children: [
                {
                  path: ':conferenceRoomId/edit',
                  name: 'EditRoomAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/conference-rooms/edit/EditRoomAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canView'],
                  },
                },
                {
                  path: 'add',
                  name: 'AddRoomAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/conference-rooms/edit/EditRoomAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canCreate'],
                  },
                },
                {
                  path: '',
                  name: 'ConferenceRoomsAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/conference-rooms/ConfRoomsAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.conferences.canView'],
                  },
                },
              ],
            },
            {
              path: 'content-libraries',
              component: Nested,
              redirect: { name: 'ContentLibrariesAdmin', replace: true, append: false },
              children: [
                {
                  path: ':contentLibraryId/edit/content-items/:contentItemId/edit',
                  name: 'EditContentItemAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/content-libraries/edit/EditContentItemAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.contentLibraries.canView'],
                  },
                },
                {
                  path: ':contentLibraryId/edit/content-items/add',
                  name: 'AddContentItemAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/content-libraries/edit/EditContentItemAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.contentLibraries.canCreate'],
                  },
                },
                {
                  path: ':contentLibraryId/edit',
                  name: 'EditContentLibraryAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/content-libraries/edit/EditContentLibraryAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.contentLibraries.canView'],
                  },
                },
                {
                  path: 'add',
                  name: 'AddContentLibraryAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/content-libraries/edit/EditContentLibraryAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.contentLibraries.canCreate'],
                  },
                },
                {
                  path: '',
                  name: 'ContentLibrariesAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/content-libraries/ContentLibrariesAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.contentLibraries.canView'],
                  },
                },
              ],
            },
            {
              path: 'discussion-groups',
              component: Nested,
              redirect: { name: 'DiscussionGroupsAdmin', replace: true, append: false },
              children: [
                {
                  path: ':discussionGroupId/edit/partners/:partnerId/edit',
                  name: 'EditGroupPartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: [
                      'permission.discussionGroups.canView',
                      'permission.partners.canUpdate',
                    ],
                    partnerType: 'conference',
                  },
                },
                {
                  path: ':discussionGroupId/edit/partners/add',
                  name: 'AddGroupPartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: [
                      'permission.discussionGroups.canView',
                      'permission.partners.canCreate',
                    ],
                    partnerType: 'conference',
                    modalType: 'create',
                  },
                },
                {
                  path: ':discussionGroupId/edit',
                  name: 'EditDiscussionGroupAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/discussion-groups/edit/EditDiscussionGroupAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.discussionGroups.canView'],
                  },
                },
                {
                  path: 'add',
                  name: 'AddDiscussionGroupAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/discussion-groups/edit/EditDiscussionGroupAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.discussionGroups.canCreate'],
                  },
                },
                {
                  path: '',
                  name: 'DiscussionGroupsAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/discussion-groups/DiscussionGroupsAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.discussionGroups.canView'],
                  },
                },
              ],
            },
            {
              path: 'exhibitors',
              component: Nested,
              redirect: { name: 'ExhibitorsAdmin', replace: true, append: false },
              children: [
                {
                  path: ':exhibitorId/edit',
                  name: 'EditExhibitorAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/exhibitors/edit/EditExhibitorAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.exhibitors.canView'],
                  },
                },
                {
                  path: ':exhibitorId/configure',
                  name: 'ConfigureExhibitorAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/components/kiosk-configuration/KioskStandConfiguration.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.exhibitors.canUpdate'],
                  },
                },
                {
                  path: 'add',
                  name: 'AddExhibitorAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/exhibitors/edit/EditExhibitorAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.exhibitors.canCreate'],
                  },
                },
                {
                  path: '',
                  name: 'ExhibitorsAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/exhibitors/ExhibitorsAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.exhibitors.canView'],
                  },
                },
              ],
            },
            {
              path: 'partners',
              component: Nested,
              redirect: { name: 'PartnersAdmin', replace: true, append: false },
              children: [
                {
                  path: ':partnerId/edit',
                  name: 'EditPartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.partners.canView'],
                    partnerType: 'event',
                    modalType: 'edit',
                  },
                },
                {
                  path: 'add',
                  name: 'AddPartnerAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/partners/edit/EditPartnerAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.partners.canCreate'],
                    partnerType: 'event',
                    modalType: 'create',
                  },
                },
                {
                  path: '',
                  name: 'PartnersAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/partners/PartnersAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.partners.canView'],
                  },
                },
              ],
            },
            {
              path: 'matching',
              component: Nested,
              redirect: { name: 'MatchingAdmin', replace: true, append: false },
              children: [
                {
                  path: ':questionId/edit',
                  name: 'EditMatchingAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/matching/edit/EditMatchingAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.smartMatchings.canView'],
                    partnerType: 'event',
                    modalType: 'edit',
                  },
                },
                {
                  path: 'add',
                  name: 'AddMatchingAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/matching/edit/EditMatchingAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.smartMatchings.canCreate'],
                    partnerType: 'event',
                    modalType: 'create',
                  },
                },
                {
                  path: '/',
                  name: 'MatchingAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/matching/MatchingAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.smartMatchings.canView'],
                  },
                },
              ],
            },
            {
              path: 'general-assemblies',
              component: Nested,
              children: [
                {
                  path: ':assemblyId/edit',
                  name: 'EditGeneralAssemblyAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/general-assemblies/EditGeneralAssembliesAdmin.vue'
                    ),
                  meta: {
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.generalAssemblies.canView'],
                    modalType: 'edit',
                  },
                },
                {
                  path: 'add',
                  name: 'AddGeneralAssemblyAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/general-assemblies/EditGeneralAssembliesAdmin.vue'
                    ),
                  meta: {
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.generalAssemblies.canCreate'],
                    modalType: 'create',
                  },
                },
                {
                  path: '',
                  name: 'GeneralAssembliesAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/general-assemblies/GeneralAssembliesAdmin.vue'
                    ),
                  meta: {
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.generalAssemblies.canView'],
                  },
                },
              ],
            },
            {
              path: 'notifications',
              component: Nested,
              children: [
                {
                  path: '',
                  name: 'NotificationsAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/notifications/NotificationsAdmin.vue'
                    ),
                  meta: {
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.notifications.canView'],
                  },
                },
              ],
            },
            {
              path: 'tags',
              name: 'TagAdmin',
              component: () =>
                import(/* webpackChunkName: "admin" */ '@/views/admin/tag/TagAdmin.vue'),
              meta: {
                requiresAuth: true,
                fullWidth: true,
                requiredPermissions: ['permission.events.canView'],
              },
            },
            {
              path: 'streams',
              component: Nested,
              redirect: { name: 'StreamAdmin', replace: true, append: false },
              children: [
                {
                  path: ':streamId/edit',
                  name: 'EditStreamAdmin',
                  component: () =>
                    import(
                      /* webpackChunkName: "admin" */ '@/views/admin/streams/edit/EditStreamAdmin.vue'
                    ),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.streaming.canView'],
                  },
                },
                {
                  path: '',
                  name: 'StreamAdmin',
                  component: () =>
                    import(/* webpackChunkName: "admin" */ '@/views/admin/streams/StreamAdmin.vue'),
                  meta: {
                    title: 'menu.admin',
                    requiresAuth: true,
                    fullWidth: true,
                    requiredPermissions: ['permission.streaming.canView'],
                  },
                },
              ],
            },
            {
              path: 'group-chat',
              name: 'GroupChatAdmin',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/admin/group-chat/GroupChatAdmin.vue'
                ),
              meta: {
                requiresAuth: true,
                fullWidth: true,
                requiredPermissions: ['permission.events.canView'],
              },
            },
          ],
        },
        {
          path: 'meetings',
          component: Nested,
          children: [
            {
              path: '/',
              name: 'Meetings',
              component: () =>
                import(/* webpackChunkName: "meetings" */ '@/views/meeting/Meetings.vue'),
              meta: {
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'configure',
              name: 'MeetingConfiguration',
              component: () =>
                import(/* webpackChunkName: "meetings" */ '@/views/meeting/Configuration.vue'),
              meta: {
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              path: ':meetingId',
              name: 'MeetingDetail',
              component: () =>
                import(/* webpackChunkName: "meetings" */ '@/views/meeting/MeetingDetail.vue'),
              meta: {
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'user/:userId',
          component: Nested,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              name: 'EventPublicProfile',
              meta: {
                requiredCompleteProfile: true,
              },
              component: () =>
                import(/* webpackChunkName: "public-profile" */ '@/views/PublicProfile.vue'),
            },
            {
              path: 'booking',
              name: 'Booking',
              component: () =>
                import(/* webpackChunkName: "meetings" */ '@/views/meeting/Booking.vue'),
              meta: {
                requiresAuth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
      ],
    },
  ];
}

export function buildPrivateEventViews(
  eventGuard,
  exhibitorGuard,
  showroomGuard,
  contentLibraryGuard,
  speakerGuard,
) {
  return [
    {
      path: '/events/:eventId',
      component: Nested,
      beforeEnter: eventGuard,
      children: [
        {
          path: 'lobby',
          name: 'Lobby',
          component: () => import(/* webpackChunkName: "lobby" */ '@/views/Lobby.vue'),
          meta: {
            title: 'lobby.title',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'full',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'conference-room/:conferenceId',
          name: 'ConferenceRoom',
          redirect: {
            name: 'Conference',
          },
        },
        {
          path: 'conference/:conferenceId',
          component: Nested,
          children: [
            {
              path: '',
              name: 'Conference',
              component: () =>
                import(
                  /* webpackChunkName: "conference" */ '@/views/conference/ConferenceRoom.vue'
                ),
              meta: {
                title: 'conference.title',
                requiresAuth: true,
                fullWidth: true,
                adverts: 'partial',
                requiredCompleteProfile: true,
              },
            },
            {
              path: 'speaker',
              name: 'Survey',
              component: () =>
                import(
                  /* webpackChunkName: "conference" */ '@/views/conference/ConferenceSpeaker.vue'
                ),
              beforeEnter: speakerGuard,
              meta: {
                title: 'survey.title',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'discussion-groups/:discussionGroupId',
          name: 'DiscussionGroup',
          component: () =>
            import(
              /* webpackChunkName: "discussion_group" */ '@/views/discussion-group/DiscussionGroup.vue'
            ),
          meta: {
            title: 'discussion-group.title',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'partial',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'content-library/:categoryId/content/:contentItemId',
          name: 'Content',
          component: () =>
            import(/* webpackChunkName: "content-library" */ '@/views/content/ContentItem.vue'),
          meta: {
            title: 'contentLibraries.title',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'full',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'content-library/:categoryId',
          name: 'ContentCategory',
          component: () =>
            import(/* webpackChunkName: "content-library" */ '@/views/content/ContentCategory.vue'),
          meta: {
            title: 'contentLibraries.title',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'full',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'content-library',
          name: 'ContentLibrary',
          component: () =>
            import(/* webpackChunkName: "content-library" */ '@/views/content/ContentLibrary.vue'),
          beforeEnter: contentLibraryGuard,
          meta: {
            title: 'contentLibraries.title',
            requiresAuth: true,
            fullWidth: true,
            adverts: 'full',
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'general-assembly/:assemblyId/admin',
          name: 'GeneralAssemblyAdmin',
          component: () =>
            import(
              /* webpackChunkName: "general-assembly" */ '@/views/general-assembly/GeneralAssemblyAdmin.vue'
            ),
          meta: {
            title: 'general-assembly.title',
            requiredPermissions: ['permission.generalAssemblies.canUpdate'],
            requiresAuth: true,
            fullWidth: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'general-assembly/:assemblyId',
          name: 'GeneralAssembly',
          component: () =>
            import(
              /* webpackChunkName: "general-assembly" */ '@/views/general-assembly/GeneralAssembly.vue'
            ),
          meta: {
            title: 'general-assembly.title',
            requiresAuth: true,
            fullWidth: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'my-kiosk',
          component: Nested,
          beforeEnter: exhibitorGuard,
          children: [
            {
              name: 'MyKiosk',
              component: () => import('@/views/my-kiosk/MyKiosk.vue'),
              path: '/',
              meta: {
                title: 'menu.myKiosk',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              name: 'MyKioskConfigure',
              component: () => import('@/views/my-kiosk/configure/MyKioskConfigure.vue'),
              path: 'configure',
              meta: {
                title: 'menu.myKiosk',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
            {
              name: 'MyKioskConfigureStand',
              component: () =>
                import('@/components/kiosk-configuration/KioskStandConfiguration.vue'),
              path: 'configure-stand',
              meta: {
                title: 'menu.myKiosk',
                requiresAuth: true,
                fullWidth: true,
                requiredCompleteProfile: true,
              },
            },
          ],
        },
        {
          path: 'showroom',
          name: 'ShowRoom',
          beforeEnter: showroomGuard,
          component: () => import(/* webpackChunkName: "showroom" */ '@/views/ShowRoom.vue'),
          meta: {
            title: 'showroom.title',
            requiresAuth: true,
            fullWidth: true,
            noFooter: true,
            requiredCompleteProfile: true,
          },
        },
        {
          path: 'participants',
          name: 'Participants',
          component: () =>
            import(/* webpackChunkName: "participants" */ '@/views/participants/Participants.vue'),
          meta: {
            title: 'menu.participants',
            requiresAuth: true,
            fullWidth: true,
            requiredCompleteProfile: true,
          },
        },
      ],
    },
  ];
}
