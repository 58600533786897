import { AuthModule } from '@/stores/shared/modules/auth/auth.module';
import { ConfigModule } from '@/stores/shared/modules/config/config.module';
import { MessageModule } from '@/stores/shared/modules/message/message.module';
import { LayoutModule } from '@/stores/shared/modules/layout/layout.module';
import { FileUploadModule } from '@/stores/shared/modules/file/file-upload.module';

export const SharedModule = {
  namespaced: true,
  modules: {
    AuthModule,
    ConfigModule,
    FileUploadModule,
    LayoutModule,
    MessageModule,
  },
};
