<template>
  <div>
    <p v-html="formatted"></p>

    <span v-if="text.length > textLength" class="d-flex justify-center">
      <v-btn
        text
        color="primary"
        data-test-id="more"
        @click.native="readMore(true)"
        v-show="!isReadMore"
        >{{ more }}
      </v-btn>

      <v-btn
        text
        color="primary"
        data-test-id="less"
        @click.native="readMore(false)"
        v-show="isReadMore"
        >{{ less }}</v-btn
      >
    </span>
  </div>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    text: {
      type: String,
      required: true,
    },
    textLength: {
      type: Number,
      default: 250,
    },
    more: {
      type: String,
      required: true,
    },
    less: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isReadMore: false,
  }),
  computed: {
    formatted() {
      let formatted = this.text;

      if (!this.isReadMore && this.text.length > this.textLength) {
        formatted = `${formatted.substring(0, this.textLength)}...`;
      }

      return formatted;
    },
  },
  methods: {
    readMore(readMore) {
      this.isReadMore = readMore;
    },
  },
};
</script>
