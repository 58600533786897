import { HttpMethod, ContentCategory, ContentItem } from '@/models';
import { UmanizeVideo } from '@/models/umanize-video/umanize-video';
import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

class ContentLibraryService {
  public async getContentCategories(eventId: string): Promise<ContentCategory[]> {
    return httpService.perform<ContentCategory[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [eventId, 'content-libraries']),
    );
  }

  public async getContentCategoryById(
    eventId: string,
    contentCategoryId: string,
  ): Promise<ContentCategory> {
    return httpService.perform<ContentCategory>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        contentCategoryId,
      ]),
    );
  }

  public async getContentItems(eventId: string, categoryId: string): Promise<ContentItem[]> {
    return httpService.perform<ContentItem[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        categoryId,
        'contents',
      ]),
    );
  }

  public async getContentItem(
    eventId: string,
    categoryId: string,
    contentItemId: string,
  ): Promise<ContentItem> {
    return httpService.perform<ContentItem>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        categoryId,
        'contents',
        contentItemId,
      ]),
    );
  }

  public async updateContentLibrary(contentLibrary: ContentCategory): Promise<ContentCategory> {
    return httpService.perform<ContentCategory>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        contentLibrary.eventId,
        'content-libraries',
        contentLibrary.id,
      ]),
      contentLibrary,
    );
  }

  public async createContentLibrary(contentLibrary): Promise<ContentCategory> {
    return httpService.perform<ContentCategory>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        contentLibrary.eventId,
        'content-libraries',
      ]),
      contentLibrary,
    );
  }

  public async deleteContentLibrary(eventId: string, categoryId: string): Promise<ContentCategory> {
    return httpService.perform<ContentCategory>(
      HttpMethod.delete,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        categoryId,
      ]),
    );
  }

  public async updateContentItem(contentItem): Promise<ContentItem> {
    return httpService.perform<ContentItem>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        contentItem.eventId,
        'content-libraries',
        contentItem.libraryId,
        'contents',
        contentItem.id,
      ]),
      contentItem,
    );
  }

  public async createContentItem(contentItem): Promise<ContentItem> {
    return httpService.perform<ContentItem>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        contentItem.eventId,
        'content-libraries',
        contentItem.libraryId,
        'contents',
      ]),
      contentItem,
    );
  }

  public async deleteContentItem(
    eventId: string,
    categoryId: string,
    itemId: string,
  ): Promise<ContentItem> {
    return httpService.perform<ContentItem>(
      HttpMethod.delete,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        categoryId,
        'contents',
        itemId,
      ]),
    );
  }

  public async upload(eventId: string, categoryId: string, file: File): Promise<UmanizeVideo> {
    const formData: FormData = new FormData();
    formData.append('file', new Blob([file], { type: 'application/octet-stream' }));

    return httpService.perform<UmanizeVideo>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        'content-libraries',
        categoryId,
        'contents',
        httpHelper.VIDEOS_ENDPOINT,
      ]),
      formData,
      true,
      true,
    );
  }
}

export default new ContentLibraryService();
