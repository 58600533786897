import { Route } from 'vue-router';

export default abstract class LayoutUtil {
  public static getThemeColor(index) {
    return LayoutUtil.oneInTwo(index) ? 'dark' : 'light';
  }

  public static oneInTwo(index) {
    return index % 2 !== 0;
  }

  public static scrollToTop() {
    window.scrollTo(0, 0);
  }

  public static isAdminRoute(to: Route) {
    return (
      to.path.includes('admin') && !to.path.match(/^\/events\/.*\/general-assembly\/.*\/admin$/)
    );
  }
}
