import { DateTime } from 'luxon';
import { ExtendedScheduleItem } from '@/models';

const getEndTime = (item: ExtendedScheduleItem): DateTime => {
  let endTime;
  if (item.conference) {
    endTime = DateTime.fromISO(item.conference.endTime);
  } else if (item.discussionGroup) {
    endTime = DateTime.fromISO(item.discussionGroup.endTime);
  } else {
    endTime = DateTime.fromISO(item.userEventAppointment.ending);
  }

  return endTime;
};

const getStartTime = (item: ExtendedScheduleItem): DateTime => {
  let startTime;
  if (item.conference) {
    startTime = DateTime.fromISO(item.conference.startTime);
  } else if (item.discussionGroup) {
    startTime = DateTime.fromISO(item.discussionGroup.startTime);
  } else {
    startTime = DateTime.fromISO(item.userEventAppointment.starting);
  }

  return startTime;
};

const getName = (item: ExtendedScheduleItem): string => {
  let name;
  if (item.conference) {
    name = item.conference.name;
  } else if (item.discussionGroup) {
    name = item.discussionGroup.name;
  } else {
    name = null;
  }

  return name;
};

const isUserEventAppointment = (item: ExtendedScheduleItem): boolean => !!item.userEventAppointment;

export const sortedAgenda = (now: DateTime) => (
  agenda: ExtendedScheduleItem[],
): ExtendedScheduleItem[] =>
  agenda
    .filter((it) => {
      const endTime = getEndTime(it);
      return endTime.valueOf() >= now.valueOf();
    })
    .sort((x, y) => {
      const xStartTime = getStartTime(x);
      const yStartTime = getStartTime(y);

      if (xStartTime.equals(yStartTime)) {
        if (isUserEventAppointment(x) || isUserEventAppointment(y)) {
          return -1;
        }

        const xName = getName(x);
        const yName = getName(y);

        return xName.localeCompare(yName);
      }

      return xStartTime.valueOf() - yStartTime.valueOf();
    });
