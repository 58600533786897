import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import ticketService from '@/services/ticket/ticket.service';
import { Payment } from '@/models/ticketing/payment.model';
import {
  GET_PAYMENTS,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_ERROR,
} from '@/stores/umanize-app/actions/payments/payments.actions';

export interface PaymentsState {
  payments: Payment[];
  status: {
    error: string;
    isSending: boolean;
    isLoaded: boolean;
  };
}

const state: PaymentsState = {
  payments: null,
  status: {
    error: '',
    isSending: false,
    isLoaded: false,
  },
};

const actions = {
  async [GET_PAYMENTS]({ commit }: ActionContext<PaymentsState, RootState>) {
    commit(GET_PAYMENTS);

    try {
      const payments = await ticketService.getPayments();

      commit(GET_PAYMENTS_SUCCESS, payments);
    } catch (error) {
      commit(GET_PAYMENTS_ERROR, error);
    }
  },
};

const mutations = {
  [GET_PAYMENTS](state) {
    state.status = {
      ...state.status,
      isSending: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_PAYMENTS_SUCCESS](state, payments) {
    state.payments = payments;
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },
  [GET_PAYMENTS_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
};

const getters = {
  payments: (state) => state.payments || null,
  isSending: (state) => (state.status && state.status.isSending) || false,
  isLoaded: (state) => (state.status && state.status.isLoaded) || false,
  error: (state) => (state.status && state.status.error) || '',
};

export const PaymentsModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
