import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { CONFERENCE_MODULE } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';

export const ADMIN_CONFERENCE_MODULE = `${UMANIZE_ADMIN_MODULE}/${CONFERENCE_MODULE}`;

export const SAVE_CONFERENCE = 'SAVE_CONFERENCE';

export const UPDATE_CONFERENCE = 'UPDATE_CONFERENCE';
export const UPDATE_CONFERENCE_SUCCESS = 'UPDATE_CONFERENCE_SUCCESS';
export const UPDATE_CONFERENCE_ERROR = 'UPDATE_CONFERENCE_ERROR';

export const ADD_CONFERENCE = 'ADD_CONFERENCE';
export const ADD_CONFERENCE_SUCCESS = 'ADD_CONFERENCE_SUCCESS';
export const ADD_CONFERENCE_ERROR = 'ADD_CONFERENCE_ERROR';

export const DELETE_CONFERENCE = 'DELETE_CONFERENCE';
export const DELETE_CONFERENCE_SUCCESS = 'DELETE_CONFERENCE_SUCCESS';
export const DELETE_CONFERENCE_ERROR = 'DELETE_CONFERENCE_ERROR';

export const CLEAR_CONFERENCE = 'CLEAR_CONFERENCE';

export const SAVE_CONFERENCE_ROOM = 'SAVE_CONFERENCE_ROOM';

export const UPDATE_CONFERENCE_ROOM = 'UPDATE_CONFERENCE_ROOM';
export const UPDATE_CONFERENCE_ROOM_SUCCESS = 'UPDATE_CONFERENCE_ROOM_SUCCESS';
export const UPDATE_CONFERENCE_ROOM_ERROR = 'UPDATE_CONFERENCE_ROOM_ERROR';

export const ADD_CONFERENCE_ROOM = 'ADD_CONFERENCE_ROOM';
export const ADD_CONFERENCE_ROOM_SUCCESS = 'ADD_CONFERENCE_ROOM_SUCCESS';
export const ADD_CONFERENCE_ROOM_ERROR = 'ADD_CONFERENCE_ROOM_ERROR';

export const DELETE_CONFERENCE_ROOM = 'DELETE_CONFERENCE_ROOM';
export const DELETE_CONFERENCE_ROOM_SUCCESS = 'DELETE_CONFERENCE_ROOM_SUCCESS';
export const DELETE_CONFERENCE_ROOM_ERROR = 'DELETE_CONFERENCE_ROOM_ERROR';
