import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';
import { EVENT_MODULE } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export const APP_EVENT_MODULE = `${UMANIZE_APP_MODULE}/${EVENT_MODULE}`;

export const REFRESH_EVENT_USER = 'REFRESH_EVENT_USER';

export const UPDATE_EVENT_FILTER = 'UPDATE_EVENT_FILTER';

export const UPDATE_WANTS_APPOINTMENT = 'UPDATE_WANTS_APPOINTMENT';

export const FETCH_EVENT_USERS = 'FETCH_EVENT_USERS';

export const UPDATE_UNITY_ID = 'UPDATE_UNITY_ID';

export const REFRESH_EVENT_COMPLETED = 'REFRESH_EVENT_COMPLETED';
