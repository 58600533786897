import { HttpMethod, DiscussionGroup } from '@/models';
import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

class DiscussionGroupService {
  public async getAll(eventId: string): Promise<DiscussionGroup[]> {
    return httpService.perform<DiscussionGroup[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [eventId, httpHelper.DISCUSSION_GROUPS]),
    );
  }

  public async getById(eventId: string, discussionGroupId: string): Promise<DiscussionGroup> {
    return httpService.perform<DiscussionGroup>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        httpHelper.DISCUSSION_GROUPS,
        discussionGroupId,
      ]),
    );
  }

  public async create({
    eventId,
    discussionGroup,
  }: {
    eventId: string;
    discussionGroup: DiscussionGroup;
  }): Promise<DiscussionGroup> {
    return httpService.perform<DiscussionGroup>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [eventId, httpHelper.DISCUSSION_GROUPS]),
      discussionGroup,
    );
  }

  public async update({
    eventId,
    discussionGroup,
  }: {
    eventId: string;
    discussionGroup: DiscussionGroup;
  }): Promise<DiscussionGroup> {
    return httpService.perform<DiscussionGroup>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        httpHelper.DISCUSSION_GROUPS,
        discussionGroup.id,
      ]),
      discussionGroup,
    );
  }

  public async delete(eventId: string, discussionGroupId: string): Promise<DiscussionGroup> {
    return httpService.perform<DiscussionGroup>(
      HttpMethod.delete,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        eventId,
        httpHelper.DISCUSSION_GROUPS,
        discussionGroupId,
      ]),
    );
  }
}

export default new DiscussionGroupService();
