import i18n from '@/i18n';

export class StripeOptions {
  get apiKey(): string {
    return process.env.VUE_APP_STRIPE_API_KEY;
  }

  get locale(): string {
    return i18n.locale.substr(0, 2);
  }

  get style() {
    return {
      base: {
        color: 'rgb(35, 35, 35, 0.8)',
        iconColor: 'rgb(35, 35, 35, 0.8)',
        ':-webkit-autofill': {
          color: 'rgb(35, 35, 35, 0.8)',
        },
        '::placeholder': {
          color: 'rgb(35, 35, 35, 0.8)',
        },
      },
    };
  }
}

export default new StripeOptions();
