import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  CLEAR_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import FileService from '@/services/file/file.service';
import { UmanizeFile } from '@/models';

export interface FileUploadState {
  file: UmanizeFile;
  status: {
    error: string;
    isSending: boolean;
    isUploaded: boolean;
  };
}

const state: FileUploadState = {
  file: null,
  status: {
    error: '',
    isSending: false,
    isUploaded: false,
  },
};

const actions = {
  async [UPLOAD_FILE]({ commit }: ActionContext<FileUploadState, RootState>, file: File) {
    commit(UPLOAD_FILE);

    try {
      const uploadedFile = await FileService.upload(file);

      commit(UPLOAD_FILE_SUCCESS, uploadedFile);
    } catch (error) {
      commit(UPLOAD_FILE_ERROR, error);
    }
  },
  async [CLEAR_FILE]({ commit }: ActionContext<FileUploadState, RootState>) {
    commit(CLEAR_FILE);
  },
};

const mutations = {
  [UPLOAD_FILE](state) {
    state.status = {
      ...state.status,
      isSending: true,
      isUploaded: false,
      error: null,
    };
  },
  [UPLOAD_FILE_SUCCESS](state, file) {
    state.file = {
      ...file,
    };
    state.status = {
      ...state.status,
      isSending: false,
      isUploaded: true,
      error: null,
    };
  },
  [UPLOAD_FILE_ERROR](state, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isUploaded: false,
      error,
    };
  },
  [CLEAR_FILE](state) {
    state.file = null;
  },
};

const getters = {
  file: (state) => state.file || null,
  isSending: (state) => (state.status && state.status.isSending) || false,
  isUploaded: (state) => (state.status && state.status.isUploaded) || false,
  error: (state) => (state.status && state.status.error) || '',
};

export const FileUploadModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
