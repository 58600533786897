import { EXHIBITOR_MODULE } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const APP_EXHIBITOR_MODULE = `${UMANIZE_APP_MODULE}/${EXHIBITOR_MODULE}`;

export const GET_SELF_EXHIBITOR_USER = 'GET_SELF_EXHIBITOR_USER';
export const GET_SELF_EXHIBITOR_USER_SUCCESS = 'GET_SELF_EXHIBITOR_USER_SUCCESS';
export const GET_SELF_EXHIBITOR_USER_ERROR = 'GET_SELF_EXHIBITOR_USER_ERROR';

export const CONNECT_TO_EXHIBITOR_USER_SOCKET = 'CONNECT_TO_EXHIBITOR_USER_SOCKET';
export const DISCONNECT_FROM_EXHIBITOR_USER_SOCKET = 'DISCONNECT_FROM_EXHIBITOR_USER_SOCKET';

export const CONNECT_TO_EXHIBITOR_VISITOR_SOCKET = 'CONNECT_TO_EXHIBITOR_VISITOR_SOCKET';
export const DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET = 'DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET';

export const EXHIBITOR_USER_UPDATE_STATUS = 'EXHIBITOR_UPDATE_STATUS';
export const EXHIBITOR_USER_UPDATE_STATUS_SUCCESS = 'EXHIBITOR_UPDATE_STATUS_SUCCESS';
export const EXHIBITOR_USER_UPDATE_STATUS_ERROR = 'EXHIBITOR_UPDATE_STATUS_ERROR';

export const EXHIBITOR_PUNCH_IN = 'EXHIBITOR_PUNCH_IN';
export const EXHIBITOR_PUNCH_OUT = 'EXHIBITOR_PUNCH_OUT';

export const GET_EXHIBITOR = 'GET_EXHIBITOR';

export const VISIT_EXHIBITOR = 'VISIT_EXHIBITOR';
export const VISIT_EXHIBITOR_SUCCESS = 'VISIT_EXHIBITOR_SUCCESS';
export const VISIT_EXHIBITOR_ERROR = 'VISIT_EXHIBITOR_ERROR';

export const LEAVE_EXHIBITOR = 'LEAVE_EXHIBITOR';
export const LEAVE_EXHIBITOR_SUCCESS = 'LEAVE_EXHIBITOR_SUCCESS';
export const LEAVE_EXHIBITOR_ERROR = 'LEAVE_EXHIBITOR_ERROR';
