import Vue from 'vue';
import { DateTime } from 'luxon';

const formats = {
  long: DateTime.DATE_FULL,
  medium: DateTime.DATE_MED,
  short: DateTime.DATE_SHORT,
  dateTimeShort: DateTime.DATETIME_SHORT,
};

const dateFilter = (value, format) => {
  if (!value) {
    return '';
  }

  const date = DateTime.fromISO(value, { zone: 'utc' });

  return date.toLocaleString(formats[format] || DateTime.DATE_FULL);
};

Vue.filter('date', dateFilter);

export default dateFilter;
