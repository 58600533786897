import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const DISCUSSION_GROUP_LOAD_MESSAGES = 'discussion-group-chat-load-messages-success';
export const DISCUSSION_GROUP_CHAT_NEW_MESSAGE = 'discussion-group-new-chat-message';
export const DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE = 'discussion-group-chat-updated-message';

export const DISCUSSION_GROUP_NAMESPACE = '/discussion-group';

export const connectToDiscussionGroupNamespace = (discussionGroupId: string): Socket =>
  connectToNamespace(DISCUSSION_GROUP_NAMESPACE, { discussionGroupId });

export const disconnectFromDiscussionGroupNamespace = (discussionGroupSocket: Socket) =>
  discussionGroupSocket.disconnect();
