import { Exhibitor, HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { UmanizeVideo } from '@/models/umanize-video/umanize-video';
import { ExhibitorScreenShotType } from '@/models/exhibitor/ExhibitorScreenShotType';

class ExhibitorService {
  public async getExhibitor({
    eventId,
    exhibitorId,
  }: {
    eventId: string;
    exhibitorId: string;
  }): Promise<Exhibitor> {
    return HttpService.perform<Exhibitor>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
      ]),
    );
  }

  public async getExhibitors({ eventId }: { eventId: string }): Promise<Exhibitor[]> {
    return HttpService.perform<Exhibitor[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
      ]),
    );
  }

  public async create({
    eventId,
    exhibitor,
  }: {
    eventId: string;
    exhibitor: Exhibitor;
  }): Promise<Exhibitor> {
    return HttpService.perform<Exhibitor>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
      ]),
      exhibitor,
    );
  }

  public async update({
    eventId,
    exhibitor,
  }: {
    eventId: string;
    exhibitor: Exhibitor;
  }): Promise<Exhibitor> {
    return HttpService.perform<Exhibitor>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitor.id,
      ]),
      exhibitor,
    );
  }

  public async link({
    eventId,
    exhibitorId,
    userId,
  }: {
    eventId: string;
    exhibitorId: string;
    userId: string;
  }): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.USER_ENDPOINT,
        userId,
      ]),
    );
  }

  public async unLink({
    eventId,
    exhibitorId,
    userId,
  }: {
    eventId: string;
    exhibitorId: string;
    userId: string;
  }): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.USER_ENDPOINT,
        userId,
      ]),
    );
  }

  public async getSelfExhibitorUser({ eventId }: { eventId: string }): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        'me',
      ]),
    );
  }

  public async updateStatus({
    eventId,
    exhibitorId,
    userId,
    status,
  }: {
    eventId: string;
    exhibitorId: string;
    userId: string;
    status: string;
  }): Promise<Exhibitor> {
    return HttpService.perform<Exhibitor>(
      HttpMethod.patch,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.USER_ENDPOINT,
        userId,
        HttpHelper.STATUS_ENDPOINT,
      ]),
      { status },
    );
  }

  public async upload(eventId: string, exhibitorId: string, file: File): Promise<UmanizeVideo> {
    const formData: FormData = new FormData();
    formData.append('file', new Blob([file], { type: 'application/octet-stream' }));

    return HttpService.perform<UmanizeVideo>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.VIDEOS_ENDPOINT,
      ]),
      formData,
      true,
      true,
    );
  }

  public async uploadScreenShot(
    eventId: string,
    exhibitorId: string,
    picture: Blob,
    pictureType: ExhibitorScreenShotType,
  ): Promise<string> {
    const formData: FormData = new FormData();
    formData.append('file', picture);
    formData.append('pictureType', pictureType);

    return HttpService.perform<string>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
        HttpHelper.IMAGES_ENDPOINT,
      ]),
      formData,
      true,
      true,
    );
  }

  public async delete(eventId: string, exhibitorId: string): Promise<Exhibitor> {
    return HttpService.perform<Exhibitor>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.EXHIBITOR_ENDPOINT,
        exhibitorId,
      ]),
    );
  }
}

export default new ExhibitorService();
