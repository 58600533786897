import { UserRole } from '@/models';

export default class PermissionsUtil {
  public static flattenPermissionsInUserRoles(userRoles: UserRole[]): UserRole[] {
    return userRoles.map((userRole) => {
      const flattenedPermissions = Object.keys(userRole.permissions)
        .map((category) => {
          const categoryPermissions = Object.entries(userRole.permissions[category]).filter(
            ([, value]: [string, unknown]) => !!value,
          );

          return categoryPermissions.map((item) => `permission.${category}.${item[0]}`);
        })
        .flat();

      return {
        ...userRole,
        flattenedPermissions,
      } as UserRole;
    });
  }

  public static isAuthorized(
    requiredPermissions: string[],
    userRoles: UserRole[],
    eventId?: string,
  ): boolean {
    let eventAuthorizedUserRoles: UserRole[] = [...(userRoles || [])];

    if (eventId) {
      eventAuthorizedUserRoles = this.getAuthorizedRolesForEvent(eventId, userRoles);
    }

    return (
      eventAuthorizedUserRoles.filter((eventAuthorizedUserRole: UserRole) =>
        requiredPermissions.every((item) =>
          eventAuthorizedUserRole.flattenedPermissions.includes(item),
        ),
      ).length > 0
    );
  }

  public static getAuthorizedRolesForEvent(eventId: string, userRoles: UserRole[]): UserRole[] {
    return userRoles.filter((userRole) => userRole.eventId === eventId || !userRole.eventId);
  }

  public static isAdmin(userRoles: UserRole[] | undefined, eventId?: string | undefined): boolean {
    return eventId
      ? !!userRoles.find(
          (userRole: UserRole) =>
            userRole.permissions.admin.canView &&
            (userRole.eventId === eventId || !userRole.eventId),
        )
      : !!userRoles.find((userRole) => userRole.permissions.admin.canView);
  }
}
