import store from '@/stores';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  SET_LAYOUT_EVENT_ID,
  SHARED_LAYOUT_MODULE,
} from '@/stores/shared/actions/layout/layout.actions';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import LayoutUtil from '@/helpers/layout/layout.helper';
import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';

export default class MenuUtil {
  public static async getMenu(to) {
    const isLoggedIn = store.getters[`${SHARED_AUTH_MODULE}/isLoggedIn`];

    // If the user is not logged in or the menu should be hidden on a particular view,
    // returns an empty array
    if (!isLoggedIn || to.meta?.hideMenu) {
      return [];
    }

    // Sets the layout eventId which is used to build menu items
    if (to.params.eventId && !to.meta?.beforePayment) {
      await store.dispatch(`${SHARED_LAYOUT_MODULE}/${SET_LAYOUT_EVENT_ID}`, to.params.eventId);
    }

    // Unsets the layout eventId when going back to the dashboard
    if (to.name === 'Dashboard') {
      await store.dispatch(`${SHARED_LAYOUT_MODULE}/${SET_LAYOUT_EVENT_ID}`, '');
    }

    return !LayoutUtil.isAdminRoute(to) ? MenuUtil.getEventMenu(to) : MenuUtil.getAdminMenu();
  }

  private static async getEventMenu(to) {
    // gets eventId param from route, if not set then checks if layout module has an event Id
    // this is useful for routes that don't have an eventId but we still want the last event's
    // menu (eg. profile route)
    const eventId = to.params.eventId || store.getters[`${SHARED_LAYOUT_MODULE}/layoutEventId`];
    const event = store.getters[`${APP_EVENT_MODULE}/event`];

    if (eventId) {
      return [
        {
          name: 'lobby',
          route: `/events/${eventId}/lobby`,
          displayed: to.name !== 'Dashboard',
        },
        {
          name: 'smartMatching',
          route: `/events/${eventId}/smart-matching`,
          displayed: event?.options?.smartMatching,
        },
        {
          name: 'activities',
          route: `/events/${eventId}/recommended-activities`,
          displayed: event?.options?.recommendedActivities,
        },
        {
          name: 'participants',
          route: `/events/${eventId}/participants`,
          displayed: !!event?.options?.participantList,
        },
        {
          name: 'appointments',
          route: `/events/${eventId}/meetings`,
          displayed: event?.options?.appointments,
        },
      ];
    }

    return [];
  }

  private static getAdminMenu() {
    const loggedInUserRoles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];

    return [
      {
        name: 'adminHome',
        route: '/admin/events',
        displayed: true,
      },
      {
        name: 'roleAdmin',
        route: '/admin/roles',
        displayed: PermissionsUtil.isAuthorized(['permission.roles.canView'], loggedInUserRoles),
      },
    ];
  }
}
