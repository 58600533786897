import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { DISCUSSION_GROUP_MODULE } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';

export const ADMIN_DISCUSSION_GROUP_MODULE = `${UMANIZE_ADMIN_MODULE}/${DISCUSSION_GROUP_MODULE}`;

export const CLEAR_DISCUSSION_GROUP = 'CLEAR_DISCUSSION_GROUP';
export const SAVE_DISCUSSION_GROUP = 'SAVE_DISCUSSION_GROUP';

export const ADD_DISCUSSION_GROUP = 'ADD_DISCUSSION_GROUP';
export const ADD_DISCUSSION_GROUP_SUCCESS = 'ADD_DISCUSSION_GROUP_SUCCESS';
export const ADD_DISCUSSION_GROUP_ERROR = 'ADD_DISCUSSION_GROUP_ERROR';

export const UPDATE_DISCUSSION_GROUP = 'UPDATE_DISCUSSION_GROUP';
export const UPDATE_DISCUSSION_GROUP_SUCCESS = 'UPDATE_DISCUSSION_GROUP_SUCCESS';
export const UPDATE_DISCUSSION_GROUP_ERROR = 'UPDATE_DISCUSSION_GROUP_ERROR';

export const DELETE_DISCUSSION_GROUP = 'DELETE_DISCUSSION_GROUP';
export const DELETE_DISCUSSION_GROUP_SUCCESS = 'DELETE_DISCUSSION_GROUP_SUCCESS';
export const DELETE_DISCUSSION_GROUP_ERROR = 'DELETE_DISCUSSION_GROUP_ERROR';
