<template>
  <app-search-header-item
    :items="filteredDiscussionGroupsBySearch"
    item="discussionGroups"
    :eventHasItem="eventHasDiscussionGroups"
    :maximumDisplayedElements="maximumDisplayedElements"
  >
    <v-list-item
      v-for="(upComingDiscussionGroup, index) in filteredDiscussionGroupsBySearch.slice(
        0,
        maximumDisplayedElements,
      )"
      :key="index"
    >
      <v-list-item-content>
        <v-list-item-title
          class="d-flex flex-sm-row flex-column flex-wrap justify-sm-space-between"
        >
          {{ upComingDiscussionGroup.name }}

          <v-btn
            class="actions__action ms-sm-2"
            small
            depressed
            :color="accentColor"
            :style="btnStyle"
            @click="goTo(upComingDiscussionGroup.id, 'DiscussionGroup', 'discussionGroupId')"
            v-if="canAccess(upComingDiscussionGroup)"
          >
            {{ $t('lobby.join') }}
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </app-search-header-item>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_DISCUSSION_GROUP_MODULE } from '@/stores/umanize-app/actions/discussion-group/discussion-group.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

import DateUtil from '@/helpers/date/date.helper';

import AppSearchHeaderItem from './AppSearchHeaderItem.vue';

export default {
  name: 'SearchDiscussionGroups',
  components: {
    AppSearchHeaderItem,
  },
  data: () => ({}),
  props: {
    event: {
      type: Object,
    },
    maximumDisplayedElements: {
      type: Number,
    },
    includeSearchNoSensitive: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters(APP_DISCUSSION_GROUP_MODULE, ['upComingDiscussionGroups']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),

    eventHasDiscussionGroups() {
      return this.event?.options.discussionGroups;
    },
    filteredDiscussionGroupsBySearch() {
      return this.upComingDiscussionGroups.filter(({ name, description }) =>
        [name, description].some(this.includeSearchNoSensitive),
      );
    },

    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    colorBtn() {
      return this.customUi?.btnTextColor || '#FFFFFF';
    },
    btnStyle() {
      return `color: ${this.colorBtn}`;
    },
  },
  methods: {
    canAccess(discussionGroup) {
      return DateUtil.canAccessDiscussionGroup(discussionGroup, this.isAdmin, this.now);
    },
    goTo(id, name, typeId) {
      this.$emit('go-to', id, name, typeId);
    },
  },
};
</script>
