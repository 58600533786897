import { Socket } from 'socket.io-client';
import { ActionContext } from 'vuex';
import {
  CONFERENCE_CHAT_NEW_MESSAGE,
  CONFERENCE_CHAT_UPDATED_MESSAGE,
  CONFERENCE_CLOSE_SURVEY_EVENT,
  CONFERENCE_LOAD_MESSAGES,
  CONFERENCE_NEXT,
} from '@/socket/conference-namespace';
import { ChatMessage, LoadConferenceMessagesSuccess, NewMessage, SurveyQuestion } from '@/models';
import {
  CONFERENCE_CLOSE_SURVEY,
  SURVEY_MODULE,
} from '@/stores/umanize-app/actions/survey/survey.actions';
import {
  CONFERENCE_CHAT_LOAD_MESSAGES,
  CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS,
  CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS,
  CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS,
  SET_NEXT_CONFERENCE,
} from '@/stores/umanize-app/actions/conference/app-conference.actions';
import { RootState } from '@/stores/store.model';

export const addAppConferenceSocketListeners = (
  conferenceSocket: Socket,
  context: ActionContext<unknown, RootState>,
  userId: string,
) => {
  conferenceSocket.on('connect', async () => {
    const { eventId, id } = context.getters.conference;

    await context.dispatch(CONFERENCE_CHAT_LOAD_MESSAGES, { eventId, conferenceId: id });
  });

  conferenceSocket.on(CONFERENCE_CLOSE_SURVEY_EVENT, async (surveys: SurveyQuestion[]) => {
    await context.dispatch(`${SURVEY_MODULE}/${CONFERENCE_CLOSE_SURVEY}`, surveys, {
      root: true,
    });
  });

  conferenceSocket.on(CONFERENCE_LOAD_MESSAGES, async (payload: LoadConferenceMessagesSuccess) => {
    context.commit(CONFERENCE_CHAT_LOAD_MESSAGES_SUCCESS, payload.messages);
  });

  conferenceSocket.on(CONFERENCE_CHAT_NEW_MESSAGE, async (payload: NewMessage) => {
    context.commit(CONFERENCE_CHAT_NEW_MESSAGE_SUCCESS, payload);
  });

  conferenceSocket.on(CONFERENCE_CHAT_UPDATED_MESSAGE, async (message: ChatMessage) => {
    context.commit(CONFERENCE_CHAT_UPDATED_MESSAGE_SUCCESS, message);
  });

  conferenceSocket.on(
    CONFERENCE_NEXT,
    async (nextConference: { eventId: string; nextConferenceId: string }) => {
      context.commit(SET_NEXT_CONFERENCE, nextConference);
    },
  );
};
