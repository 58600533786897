import { TicketFeatures } from '@/models/ticketing/ticket-features';
import { TicketGAOptions } from '@/models/ticketing/ticket-general-assembly-options';
import { TicketType } from './ticket-type.model';

export class Ticket {
  id: string;

  eventId: string;

  name: string;

  description: string;

  type: TicketType;

  price: number;

  currency: string;

  restricted: boolean;

  options: {
    disableFunctionalities: string[];
    generalAssemblies: TicketGAOptions[];
    features: TicketFeatures;
    conferences: string[];
    discussionGroups: string[];
  };

  roles: string[];

  gst?: number;

  qst?: number;

  total?: number;

  ticketUserProfile?: {
    displayAvatar: boolean;
    id?: string;
    ticketId?: string;
    profileItems: [];
  };

  constructor() {
    this.restricted = false;
    this.options = {
      features: {
        conferences: true,
        generalAssemblies: true,
        discussionGroups: true,
        showroom: true,
      },
      conferences: [],
      disableFunctionalities: [],
      generalAssemblies: [],
      discussionGroups: [],
    };
    this.roles = [];
    this.currency = 'CAD';
    this.ticketUserProfile = {
      profileItems: [],
      displayAvatar: false,
    };
  }
}
