import { ConferenceRoom, HttpMethod } from '@/models';
import { Conference } from '@/models/conference/conference.model';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class ConferenceService {
  public async getConferenceRooms(eventId: string): Promise<ConferenceRoom[]> {
    return HttpService.perform<ConferenceRoom[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCE_ROOMS_ENDPOINT,
      ]),
    );
  }

  public async getConferenceRoomById(
    eventId: string,
    conferenceRoomId: string,
  ): Promise<ConferenceRoom> {
    return HttpService.perform<ConferenceRoom>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCE_ROOMS_ENDPOINT,
        conferenceRoomId,
      ]),
    );
  }

  public async deleteConferenceRoom(
    eventId: string,
    conferenceRoomId: string,
  ): Promise<ConferenceRoom> {
    return HttpService.perform<ConferenceRoom>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCE_ROOMS_ENDPOINT,
        conferenceRoomId,
      ]),
    );
  }

  public async getConferencesForEvent(id: string): Promise<Conference[]> {
    return HttpService.perform<Conference[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [id, HttpHelper.CONFERENCES_ENDPOINT]),
    );
  }

  public async getConferenceById(eventId: string, conferenceId: string): Promise<Conference> {
    return HttpService.perform<Conference>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
      ]),
    );
  }

  public async updateConference(conference: Conference): Promise<Conference> {
    const newConference = ConferenceService.buildNewConference(conference);

    return HttpService.perform<Conference>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        conference.eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conference.id,
      ]),
      newConference,
    );
  }

  public async addConference(conference: Conference): Promise<Conference> {
    const newConference = ConferenceService.buildNewConference(conference);

    return HttpService.perform<Conference>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        conference.eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
      ]),
      newConference,
    );
  }

  public async deleteConference(conference: Conference): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        conference.eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conference.id,
      ]),
    );
  }

  public async updateConferenceRoom(conferenceRoom: ConferenceRoom): Promise<ConferenceRoom> {
    return HttpService.perform<ConferenceRoom>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        conferenceRoom.eventId,
        HttpHelper.CONFERENCE_ROOMS_ENDPOINT,
        conferenceRoom.id,
      ]),
      conferenceRoom,
    );
  }

  public async addConferenceRoom(conferenceRoom: ConferenceRoom): Promise<ConferenceRoom> {
    return HttpService.perform<ConferenceRoom>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        conferenceRoom.eventId,
        HttpHelper.CONFERENCE_ROOMS_ENDPOINT,
      ]),
      conferenceRoom,
    );
  }

  public async getConferencesForRoom(eventId: string, roomId: string): Promise<Conference[]> {
    return HttpService.perform<Conference[]>(
      HttpMethod.get,
      `${HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
      ])}?conferenceRoom=${roomId}`,
    );
  }

  public async goToNextConferenceInRoom(eventId: string, conferenceId: string): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        'next',
      ]),
    );
  }

  private static buildNewConference(conference: Conference) {
    return {
      roomId: conference.roomId,
      name: conference.name,
      description: conference.description,
      hasChatRoom: conference.hasChatRoom,
      chatRoom: conference.chatRoom,
      startTime: conference.startTime,
      endTime: conference.endTime,
      coverImages: conference.coverImages,
      documents: conference.documents,
      videoType: conference.videoType,
      vimeoUrl: conference.vimeoUrl,
      zoomMeetingId: conference.zoomMeetingId,
      zoomMeetingPassword: conference.zoomMeetingPassword,
      public: conference.public,
      chatId: conference.chatId,
    };
  }
}

export default new ConferenceService();
