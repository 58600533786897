<template>
  <div class="exhibitor pa-2">
    <v-avatar class="exhibitor__content-logo">
      <img v-if="exhibitor.kioskOptions.logo" :src="exhibitor.kioskOptions.logo" alt="logo" />
    </v-avatar>

    <div class="exhibitor__content">
      <div class="content__title">{{ exhibitor.name }}</div>

      <div class="content__tags">
        <v-chip
          x-small
          v-for="item in exhibitorTags"
          :key="`exhibitor-item-${exhibitor.name}-${item}`"
          @click="$emit('search', item, 'exhibitor')"
        >
          {{ item }}
        </v-chip>
      </div>

      <v-btn
        small
        depressed
        class="content__action"
        :color="accentColor"
        :style="btnStyle"
        @click="$emit('select-exhibitor', exhibitor.id)"
      >
        {{ $t('exhibitor.visit') }}
      </v-btn>
    </div>

    <div class="exhibitor__picture">
      <img
        v-if="exhibitor.kioskOptions.logo"
        class="picture"
        :src="exhibitor.kioskOptions.thumbnail || exhibitor.kioskOptions.logo"
        :alt="exhibitor.name"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExhibitorItem',
  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    btnStyle: {
      type: String,
      required: false,
    },
  },
  computed: {
    exhibitorTags() {
      const tags = this.exhibitor?.kioskOptions?.tags ?? [];
      return tags.length === 0 ? [] : this.exhibitor?.kioskOptions?.tags?.split(',');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.exhibitor {
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: stretch;
  height: 100%;
  grid-template-columns: 1fr 1fr 3fr;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-logo {
    img {
      max-width: 100% !important;
      max-height: 100% !important;
    }

    margin-bottom: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 50%;
    height: auto !important;
    width: auto !important;
  }

  &__picture {
    margin-left: 25px;
  }
}

@include breakpoint(large) {
  .exhibitor {
    &__picture {
      max-width: 35%;
    }
  }
}

.content {
  &__title {
    color: var(--v-primaryBackground-base);
    font-weight: $bold;
    font-size: $large-font-size;
    margin-bottom: 15px;
    text-align: center;
  }

  &__tags {
    margin-bottom: 15px;
    font-size: $default-font-size;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__action {
    width: 90px;
    border-radius: 5px;
  }
}

.picture {
  width: 100%;
}
</style>
