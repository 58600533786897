<template>
  <v-footer dark class="footer">
    <a class="footer__logo" href="https://www.umanize.com/" target="_blank">
      <p class="ma-0 logo__text">Propulsé par</p>
      <LogoUmanize :width="135" color="gray" />
    </a>

    <p class="footer__copyright" data-test-id="copyright">{{ $t('footer.copyright') }}</p>
    <div />
  </v-footer>
</template>

<script>
import LogoUmanize from '@/components/logo-umanize/LogoUmanize.vue';

export default {
  components: { LogoUmanize },
  name: 'LayoutFooter',
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.footer {
  padding: 20px 35px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: auto;
  width: 100vw;

  border-top: 1px solid var(--v-primaryFont-base);

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
  }

  &__copyright {
    color: var(--v-gray-base);
  }
}

.logo {
  &__text {
    color: var(--v-gray-base);
    margin-bottom: 5px;
  }
}

@include breakpoint(small) {
  .footer {
    height: 8rem;
    z-index: 1;

    & > *:not(:last-child) {
      margin-bottom: 0;
    }

    &__logo {
      align-items: center;
    }

    &__copyright {
      margin-bottom: 0 !important;
    }
  }
}
</style>
