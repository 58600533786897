import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const EXHIBITOR_VISITOR_MODULE = 'ExhibitorVisitorModule';
export const APP_EXHIBITOR_VISITOR_MODULE = `${UMANIZE_APP_MODULE}/${EXHIBITOR_VISITOR_MODULE}`;

export const SEARCH_EXHIBITOR_VISITORS = 'SEARCH_EXHIBITOR_VISITORS';
export const SEARCH_EXHIBITOR_VISITORS_SUCCESS = 'SEARCH_EXHIBITOR_VISITORS_SUCCESS';
export const SEARCH_EXHIBITOR_VISITORS_ERROR = 'SEARCH_EXHIBITOR_VISITORS_ERROR';

export const UPDATE_EXHIBITOR_VISITORS = 'UPDATE_EXHIBITOR_VISITORS';
