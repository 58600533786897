import { SHARED_MODULE } from '@/stores/shared/shared.module.config';

export const CONFIG_MODULE = 'ConfigModule';
export const SHARED_CONFIG_MODULE = `${SHARED_MODULE}/${CONFIG_MODULE}`;

export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_ERROR = 'GET_CONFIG_ERROR';

export const CLEAR_CONFIG = 'CLEAR_CONFIG';
