import { AppEventOptions } from '@/models/event/event-options.model';
import { UserTicket } from '@/models/ticketing/user-ticket';
import { Partner } from './partner.model';

export class AppEvent {
  public id: string;

  public name: string;

  public startDate: string;

  public endDate: string;

  public options: AppEventOptions = new AppEventOptions();

  public public = false;

  public description: string = null;

  public partners: Partner[] = []; // not in form

  public coverImages: string[] = []; // TODO

  public externalLinks: { name: string; url: string }[] = []; // TODO

  public lobbyBackground: string = null;

  public logoMobile: string = null;

  public logoWeb: string = null;

  public promotionalVideoTitle: string = null;

  public promotionalVideo: string = null;

  public mainPartnerLogo: string = null;

  public exhibitorsStatus: string;

  public tags: string;

  // FrontEnd mapping: not in form
  public hasConferences?: boolean;

  public ticket?: UserTicket;
}
