import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const PUBLIC_PROFILE_MODULE_NAME = 'PublicProfilesModule';
export const PUBLIC_PROFILE_MODULE = `${UMANIZE_APP_MODULE}/${PUBLIC_PROFILE_MODULE_NAME}`;

export const GET_PUBLIC_PROFILE_BY_ID = 'GET_PUBLIC_PROFILE_BY_ID';
export const GET_PUBLIC_PROFILE_BY_ID_SUCCESS = 'GET_PUBLIC_PROFILE_BY_ID_SUCCESS';
export const GET_PUBLIC_PROFILE_BY_ID_ERROR = 'GET_PUBLIC_PROFILE_BY_ID_ERROR';

export const SEARCH_USER_PROFILES = 'SEARCH_USER_PROFILES';
export const SEARCH_USER_PROFILES_SUCCESS = 'SEARCH_USER_PROFILES_SUCCESS';
export const SEARCH_USER_PROFILES_ERROR = 'SEARCH_USER_PROFILES_ERROR';
