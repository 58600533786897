import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { GeneralAssembly, GeneralAssemblyRequest, MessageType } from '@/models';
import GeneralAssemblyService from '@/services/general-assembly/general-assembly.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import i18n from '@/i18n';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import {
  AgnosticGeneralAssemblyModule,
  AssembliesState,
  AssembliesStatus,
  CurrentAssemblyState,
  CurrentAssemblyStatus,
} from '@/stores/agnostic/modules/general-assembly/agnostic-general-assembly.module';
import {
  GET_ALL_ASSEMBLIES,
  GET_ALL_ASSEMBLIES_ERROR,
  GET_ALL_ASSEMBLIES_SUCCESS,
  GET_ASSEMBLY_BY_ID,
  GET_ASSEMBLY_BY_ID_ERROR,
  GET_ASSEMBLY_BY_ID_SUCCESS,
} from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import {
  SAVE_GENERAL_ASSEMBLY,
  SAVE_UPDATE_GENERAL_ASSEMBLY,
  SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR,
  SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS,
  UPDATE_GENERAL_ASSEMBLY,
} from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';
import {
  DELETE_GENERAL_ASSEMBLY,
  DELETE_GENERAL_ASSEMBLY_ERROR,
  DELETE_GENERAL_ASSEMBLY_SUCCESS,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';

export interface AdminGeneralAssemblyState {
  generalAssemblies: AssembliesState;
  generalAssembly: CurrentAssemblyState;
}

const assembliesState: AssembliesState = {
  generalAssemblies: [],
  status: {
    error: null,
    isSaving: false,
    areLoading: true,
  },
};

const assemblyState: CurrentAssemblyState = {
  generalAssembly: null,
  status: {
    error: null,
    isLoading: true,
  },
};

const state: AdminGeneralAssemblyState = {
  generalAssembly: assemblyState,
  generalAssemblies: assembliesState,
};

const actions = {
  [GET_ASSEMBLY_BY_ID]: AgnosticGeneralAssemblyModule.actions.getAssembly,
  [GET_ALL_ASSEMBLIES]: AgnosticGeneralAssemblyModule.actions.getAssemblies,
  async [SAVE_GENERAL_ASSEMBLY](
    { commit, dispatch }: ActionContext<AdminGeneralAssemblyState, RootState>,
    {
      eventId,
      assembly,
    }: {
      eventId: string;
      assembly: GeneralAssemblyRequest;
    },
  ) {
    commit(SAVE_UPDATE_GENERAL_ASSEMBLY);
    try {
      const createdAssembly = await GeneralAssemblyService.create(eventId, assembly);
      commit(SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS, {
        generalAssembly: createdAssembly,
        type: 'add',
      });

      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('admin.generalAssemblies.form.created'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
      return createdAssembly;
    } catch (error) {
      commit(SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR, error);
      return null;
    }
  },
  async [DELETE_GENERAL_ASSEMBLY](
    { commit, dispatch }: ActionContext<AdminGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
    },
  ) {
    commit(DELETE_GENERAL_ASSEMBLY);

    try {
      await GeneralAssemblyService.delete(payload?.eventId, payload?.assemblyId);
      commit(DELETE_GENERAL_ASSEMBLY_SUCCESS, payload?.assemblyId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('conferenceRoom.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conferenceRoom.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_GENERAL_ASSEMBLY_ERROR, error);
    }
  },
  async [UPDATE_GENERAL_ASSEMBLY](
    { commit, dispatch }: ActionContext<AdminGeneralAssemblyState, RootState>,
    assembly: GeneralAssembly,
  ) {
    commit(SAVE_UPDATE_GENERAL_ASSEMBLY);
    try {
      const updatedAssembly = await GeneralAssemblyService.update(
        assembly.eventId,
        assembly.id,
        assembly,
      );
      commit(SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS, {
        generalAssembly: updatedAssembly,
        type: 'edit',
      });

      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('admin.generalAssemblies.form.updated'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      commit(SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR, error);
    }
  },
};

const mutations = {
  [GET_ALL_ASSEMBLIES](state) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_ALL_ASSEMBLIES_SUCCESS](state, generalAssemblies) {
    state.generalAssemblies = {
      status: {
        ...state.status,
        areLoading: false,
        error: null,
      },
      generalAssemblies: [...generalAssemblies],
    };
  },
  [GET_ALL_ASSEMBLIES_ERROR](state, error) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      areLoading: false,
      error,
    };
  },
  [GET_ASSEMBLY_BY_ID](state) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_ASSEMBLY_BY_ID_SUCCESS](state, generalAssembly) {
    state.generalAssembly = {
      status: {
        ...state.generalAssembly.status,
        isLoading: false,
        error: null,
      },
      generalAssembly: {
        ...generalAssembly,
      },
    };
  },
  [GET_ASSEMBLY_BY_ID_ERROR](state, error) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: false,
      error,
    };
  },
  [DELETE_GENERAL_ASSEMBLY](state: AdminGeneralAssemblyState) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_GENERAL_ASSEMBLY_SUCCESS](state: AdminGeneralAssemblyState, assemblyId: string) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: false,
      error: null,
    };
    state.generalAssemblies.generalAssemblies = state.generalAssemblies.generalAssemblies.filter(
      (generalAssembly) => generalAssembly.id !== assemblyId,
    );
  },
  [DELETE_GENERAL_ASSEMBLY_ERROR](state: AdminGeneralAssemblyState, error: ErrorMessage) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: false,
      error,
    };
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY](state: AdminGeneralAssemblyState) {
    state.generalAssembly = {
      status: {
        ...state.generalAssembly.status,
        isLoading: true,
        error: null,
      },
      generalAssembly: null,
    };
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS](
    state: AdminGeneralAssemblyState,
    { generalAssembly, type },
  ) {
    state.generalAssembly = {
      status: {
        ...state.generalAssembly.status,
        isLoading: false,
        error: null,
      },
      generalAssembly,
    };
    if (type === 'edit') {
      state.generalAssemblies.generalAssemblies = state.generalAssemblies.generalAssemblies.map(
        (ga) => (ga.id === generalAssembly.id ? generalAssembly : ga),
      );
    }
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR](state: AdminGeneralAssemblyState, error) {
    state.generalAssembly = {
      status: {
        ...state.generalAssembly.status,
        isLoading: false,
        error,
      },
      generalAssembly: null,
    };
  },
};

const getters = {
  assembliesState: (state: AdminGeneralAssemblyState): AssembliesState => state.generalAssemblies,
  generalAssemblies: (
    _,
    { assembliesState }: { assembliesState: AssembliesState },
  ): GeneralAssembly[] => assembliesState?.generalAssemblies || [],

  generalAssemblyIdNameMap: (
    _,
    { generalAssemblies }: { generalAssemblies: GeneralAssembly[] },
  ): Map<string, string> => {
    const map = new Map<string, string>();
    generalAssemblies.forEach((assembly) => map.set(assembly.id, assembly.name));
    return map;
  },

  accessibleGeneralAssemblies: (
    _,
    { generalAssemblies }: { generalAssemblies: GeneralAssembly[] },
  ): GeneralAssembly[] =>
    generalAssemblies.filter(
      (generalAssembly) => generalAssembly.userAccess && generalAssembly.userAccess.canAccess,
    ),
  assembliesStatus: (
    _,
    { assembliesState }: { assembliesState: AssembliesState },
  ): AssembliesStatus => assembliesState?.status || null,
  assembliesAreLoading: (
    _,
    { assembliesStatus }: { assembliesStatus: AssembliesStatus },
  ): boolean => assembliesStatus?.areLoading || false,
  assembliesError: (
    _,
    { assembliesStatus }: { assembliesStatus: AssembliesStatus },
  ): ErrorMessage => assembliesStatus?.error || null,

  assemblyState: (state: AdminGeneralAssemblyState): CurrentAssemblyState => state.generalAssembly,
  generalAssembly: (
    _,
    { assemblyState }: { assemblyState: CurrentAssemblyState },
  ): GeneralAssembly => assemblyState?.generalAssembly || null,
  assemblyStatus: (
    _,
    { assemblyState }: { assemblyState: CurrentAssemblyState },
  ): CurrentAssemblyStatus => assemblyState?.status || null,
  assemblyIsLoading: (_, { assemblyStatus }: { assemblyStatus: CurrentAssemblyStatus }): boolean =>
    assemblyStatus?.isLoading || false,
  assemblyError: (_, { assemblyStatus }: { assemblyStatus: CurrentAssemblyStatus }): ErrorMessage =>
    assemblyStatus?.error || null,
};

export const AdminGeneralAssemblyModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
