<template>
  <div class="chat-list" v-if="openedChats && user">
    <v-sheet
      v-for="openedChat in showedChats"
      :key="`chat-${openedChat.id}`"
      class="chat-item"
      @click.native="markAsRead(openedChat)"
    >
      <div class="chat-title">
        <div class="chat-title__left">
          <v-btn
            icon
            small
            class="chat-title__close hidden-lg-and-up"
            @click.native="closeChat(openedChat)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <div v-if="isGroupChat(openedChat)" class="chat-title__picture">
            <v-icon>mdi-account-group</v-icon>
          </div>

          <div
            v-else
            class="chat-title__picture"
            v-bind:class="{ clickable: canGoToProfile(openedChat) }"
            @click="goToPublicProfile(openedChat)"
            data-test-id="chat-picture"
          >
            <user-avatar :avatar="openedChat.avatar" />
          </div>

          <div
            class="chat-title__text"
            v-bind:class="{ clickable: canGoToProfile(openedChat), unread: hasUnread(openedChat) }"
            @click="goToPublicProfile(openedChat)"
            data-test-id="chat-name"
          >
            <span v-if="isGroupChat(openedChat)">
              {{ openedChat.name }}
            </span>

            <span v-else>
              {{
                $tc('chat.name', openedChat.displayLength, {
                  count: openedChat.displayLength,
                  name: openedChat.name,
                })
              }}
            </span>
          </div>
        </div>

        <div class="chat-title__right hidden-md-and-down">
          <v-btn
            icon
            small
            color="primaryFont"
            class="chat-title__close"
            @click.native="closeChat(openedChat)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-divider />

      <global-chat :user="user" :chat="openedChat" :hideAvatar="true" />
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  APP_CHATS_MODULE,
  MARK_AS_READ,
  OPEN_CHAT,
  UPDATE_MAX_OPENED_CHATS,
} from '@/stores/umanize-app/actions/chats/chats.actions';

import GlobalChat from '@/components/global-chat/GlobalChat.vue';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'Chats',
  components: {
    GlobalChat,
    UserAvatar,
  },
  props: {
    openedChats: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_CHATS_MODULE, ['maxOpenedChats', 'chatHasUnreadMessages']),
    hasUnread() {
      return (chat) => this.chatHasUnreadMessages(chat);
    },
    showedChats() {
      return this.openedChats.slice(0, this.maxOpenedChats);
    },
    isGroupChat() {
      return (openedChat) => openedChat?.eventId;
    },
  },
  methods: {
    ...mapActions(APP_CHATS_MODULE, [UPDATE_MAX_OPENED_CHATS, OPEN_CHAT, MARK_AS_READ]),
    updateMaxOpenedChats() {
      if (this.$vuetify.breakpoint.mobile) {
        return;
      }

      const appWidth = document.getElementById('umanizeApp').clientWidth;
      // 75px = hidden chats panel width
      // 318px = 20rem = chat panel width
      // 16px = 1rem = chat space
      const componentWidth = appWidth - (75 + 318 + 16 * this.maxOpenedChats);
      this[UPDATE_MAX_OPENED_CHATS](Math.floor(componentWidth / 350));
    },
    goToPublicProfile(openedChat) {
      if (!this.canGoToProfile(openedChat)) {
        return;
      }

      const userIds = openedChat.userIds.filter((c) => c !== this.loggedInUser.id);
      const userId = userIds[0];

      if (userId) {
        this.$router.push({ name: 'PublicProfile', params: { userId } });
      }
    },
    openChat(chat) {
      this[OPEN_CHAT]({ id: chat.id, userIds: chat.userIds });
    },
    closeChat(openedChat) {
      this.$emit('close-chat', openedChat.id);
    },
    canGoToProfile(chat) {
      return chat.displayLength === 1;
    },
    getDisplayLength(chat) {
      return chat?.displayLength;
    },
    markAsRead(chat) {
      this[MARK_AS_READ](chat);
    },
  },
  mounted() {
    this.updateMaxOpenedChats();
    window.addEventListener('resize', this.updateMaxOpenedChats);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateMaxOpenedChats);
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.clickable {
  cursor: pointer;
}

.chat-list {
  width: 100%;
  height: 100%;

  box-shadow: 0px 3px 6px #00000029;
  backdrop-filter: blur(5px);
  border-radius: 0.5rem;

  & > *:not(:first-child) {
    margin-right: 15px;
  }
}

.chat-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem;

  &__picture {
    margin-right: 0.75rem;
  }

  &__left {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }

  &__right {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }

  &__text {
    &.unread {
      font-weight: $semi-bold;
    }
  }
}

.chat-picture {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.chat-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
}

.hidden-chats {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
  background: transparent;

  overflow: scroll;

  &__chat {
    margin: 0.2rem;
    cursor: pointer;
  }
}

@include breakpoint(medium) {
  .chat-list {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  .chat-item {
    width: 350px;
    border-radius: 0.5rem;
    border: 1px solid var(--v-primaryFont-base);
  }
}
</style>
