import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { MatchingQuestion } from '@/models';
import matchingService from '@/services/matching/matching.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_MATCHING_QUESTION,
  GET_MATCHING_QUESTION_ERROR,
  GET_MATCHING_QUESTION_SUCCESS,
  GET_MATCHING_QUESTIONS,
  GET_MATCHING_QUESTIONS_ERROR,
  GET_MATCHING_QUESTIONS_SUCCESS,
} from '@/stores/agnostic/actions/matching/matching.actions';

export interface MatchingQuestionsStatus {
  error: ErrorMessage;
  isAnswering: boolean;
  areLoading: boolean;
  areLoaded: boolean;
  isSaving: boolean;
}

export interface MatchingQuestionsState {
  questions: MatchingQuestion[];
  status: MatchingQuestionsStatus;
}

export interface MatchingQuestionStatus {
  isLoading: boolean;
  isSaving: boolean;
  error: ErrorMessage;
}

export interface MatchingQuestionState {
  question: MatchingQuestion;
  status: MatchingQuestionStatus;
}

const actions = {
  async getMatchingQuestions(
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    eventId,
  ) {
    commit(GET_MATCHING_QUESTIONS);

    try {
      const questions = await matchingService.getQuestions(eventId);
      commit(GET_MATCHING_QUESTIONS_SUCCESS, questions);
    } catch (error) {
      commit(GET_MATCHING_QUESTIONS_ERROR, error);
    }
  },
  async getMatchingQuestion(
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    payload: { eventId: string; questionId: string },
  ) {
    commit(GET_MATCHING_QUESTION);

    try {
      const questions = await matchingService.getQuestion(payload);
      commit(GET_MATCHING_QUESTION_SUCCESS, questions);
    } catch (error) {
      commit(GET_MATCHING_QUESTION_ERROR, error);
    }
  },
};

export const AgnosticMatchingModule = {
  actions,
};
