import Vue from 'vue';
import i18n from '@/i18n';

const currencyFilter = (value, currency) => {
  const formatter = new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency,
  });

  return formatter.format(value);
};

Vue.filter('currency', currencyFilter);

export default currencyFilter;
