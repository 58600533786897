import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import { MessageType } from '@/models';
import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import CustomUiService from '@/services/custom-ui/custom-ui.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';

import {
  AgnosticCustomUiModule,
  CustomUiState,
  CustomUiStatus,
} from '@/stores/agnostic/modules/custom-ui/agnostic-custom-ui.module';
import {
  GET_CUSTOM_UI,
  GET_CUSTOM_UI_SUCCESS,
  GET_CUSTOM_UI_ERROR,
} from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';
import {
  CLEAR_CUSTOM_UI,
  SAVE_CUSTOM_UI,
  ADD_CUSTOM_UI,
  ADD_CUSTOM_UI_ERROR,
  ADD_CUSTOM_UI_SUCCESS,
  UPDATE_CUSTOM_UI,
  UPDATE_CUSTOM_UI_ERROR,
  UPDATE_CUSTOM_UI_SUCCESS,
} from '@/stores/umanize-admin/actions/custom-ui/admin-custom-ui.actions';

const customUi: CustomUiState = {
  customUi: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

export interface AdminCustomUiState {
  customUi: CustomUiState;
}

const state: AdminCustomUiState = {
  customUi,
};

const actions = {
  [GET_CUSTOM_UI]: AgnosticCustomUiModule.actions.getCustomUi,
  async [SAVE_CUSTOM_UI](
    { dispatch }: ActionContext<AdminCustomUiState, RootState>,
    customUi: CustomUI,
  ) {
    const action = customUi.id ? UPDATE_CUSTOM_UI : ADD_CUSTOM_UI;
    await dispatch(action, customUi);
  },
  [CLEAR_CUSTOM_UI]({ commit }: ActionContext<AdminCustomUiState, RootState>) {
    commit(CLEAR_CUSTOM_UI);
  },
  async [ADD_CUSTOM_UI](
    { commit, dispatch }: ActionContext<AdminCustomUiState, RootState>,
    payload: CustomUI,
  ) {
    commit(ADD_CUSTOM_UI);

    try {
      await CustomUiService.create(payload);

      commit(ADD_CUSTOM_UI_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.custom-ui.add.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(ADD_CUSTOM_UI_ERROR, error);
    }
  },
  async [UPDATE_CUSTOM_UI](
    { commit, dispatch }: ActionContext<AdminCustomUiState, RootState>,
    payload: CustomUI,
  ) {
    commit(UPDATE_CUSTOM_UI);

    try {
      await CustomUiService.update(payload);

      commit(UPDATE_CUSTOM_UI_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.custom-ui.update.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(UPDATE_CUSTOM_UI_ERROR, error);
    }
  },
};

const mutations = {
  [GET_CUSTOM_UI](state: AdminCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CUSTOM_UI_SUCCESS](state: AdminCustomUiState, customUi: CustomUI) {
    state.customUi = {
      status: {
        ...state.customUi.status,
        isLoading: false,
        error: null,
      },
      customUi,
    };
  },
  [GET_CUSTOM_UI_ERROR](state: AdminCustomUiState, error: ErrorMessage) {
    state.customUi.status = {
      ...state.customUi.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_CUSTOM_UI](state: AdminCustomUiState) {
    state.customUi = {
      ...state.customUi,
      customUi: null,
    };
  },
  [ADD_CUSTOM_UI](state: AdminCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_CUSTOM_UI_SUCCESS](state: AdminCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_CUSTOM_UI_ERROR](state: AdminCustomUiState, error: ErrorMessage) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_CUSTOM_UI](state: AdminCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_CUSTOM_UI_SUCCESS](state: AdminCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_CUSTOM_UI_ERROR](state: AdminCustomUiState, error: ErrorMessage) {
    state.customUi.status = {
      ...state.customUi.status,
      isSaving: false,
      error,
    };
  },
};

const getters = {
  currentState: (state: AdminCustomUiState): CustomUiState => state.customUi,
  customUi: (_, { currentState }: { currentState: CustomUiState }): CustomUI =>
    currentState?.customUi || null,
  currentStatus: (_, { currentState }: { currentState: CustomUiState }): CustomUiStatus =>
    currentState?.status || null,
  customUiIsLoading: (_, { currentStatus }: { currentStatus: CustomUiStatus }): boolean =>
    currentStatus?.isLoading || false,
  customUiIsSaving: (_, { currentStatus }: { currentStatus: CustomUiStatus }): boolean =>
    currentStatus?.isSaving || false,
  customUiError: (_, { currentStatus }: { currentStatus: CustomUiStatus }): ErrorMessage =>
    currentStatus?.error || null,
};

export const AdminCustomUiModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
