import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import CustomUiService from '@/services/custom-ui/custom-ui.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  GET_CUSTOM_UI,
  GET_CUSTOM_UI_ERROR,
  GET_CUSTOM_UI_SUCCESS,
} from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';

export interface CustomUiStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CustomUiState {
  customUi: CustomUI;
  status: CustomUiStatus;
}

const actions = {
  async getCustomUi(
    { commit }: ActionContext<CustomUiState, RootState>,
    { eventId }: { eventId: string },
  ) {
    commit(GET_CUSTOM_UI);

    try {
      const customUi = await CustomUiService.get(eventId);

      commit(GET_CUSTOM_UI_SUCCESS, customUi);
    } catch (error) {
      if ((error as ErrorMessage).status === 404) {
        commit(GET_CUSTOM_UI_SUCCESS, null);
      }
      commit(GET_CUSTOM_UI_ERROR, error);
    }
  },
};

export const AgnosticCustomUiModule = {
  actions,
};
