import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const CONFERENCE_NEW_SURVEY_EVENT = 'new-survey';
export const CONFERENCE_CLOSE_SURVEY_EVENT = 'close-survey';
export const CONFERENCE_LOAD_MESSAGES = 'conference-chat-load-messages-success';
export const CONFERENCE_CHAT_NEW_MESSAGE = 'CONFERENCE_CHAT_NEW_MESSAGE';
export const CONFERENCE_CHAT_UPDATED_MESSAGE = 'CONFERENCE_CHAT_UPDATED_MESSAGE';
export const CONFERENCE_NEXT = 'CONFERENCE_NEXT';

export const CONFERENCE_NAMESPACE = `/conference`;

export const connectToConferenceNamespace = (conferenceId: string): Socket =>
  connectToNamespace(CONFERENCE_NAMESPACE, { conferenceId });

export const disconnectFromConferenceNamespace = (conferenceSocket: Socket) => {
  conferenceSocket.disconnect();
};
