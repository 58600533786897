import { ActionContext } from 'vuex';

import { AppEvent, EventUser, EventUserOrPublicProfile } from '@/models';
import EventService from '@/services/event/event.service';
import { RootState } from '@/stores/store.model';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import { UserTicketPreferences } from '@/models/ticketing/user-ticket-preferences';
import { UserTicket } from '@/models/ticketing/user-ticket';
import {
  AgnosticEventModule,
  CurrentEventState,
  CurrentEventStatus,
  EventsState,
  EventsStatus,
  EventUsersFilter,
  EventUsersState,
  EventUsersStatus,
  MyEventsState,
  MyEventsStatus,
} from '@/stores/agnostic/modules/event/agnostic-event.module';
import {
  FETCH_ALL_EVENTS,
  FETCH_ALL_EVENTS_ERROR,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_EVENT_BY_ID,
  FETCH_EVENT_BY_ID_ERROR,
  FETCH_EVENT_BY_ID_SILENT,
  FETCH_EVENT_BY_ID_SUCCESS,
  FETCH_EVENT_USERS,
  FETCH_EVENT_USERS_ERROR,
  FETCH_EVENT_USERS_SUCCESS,
  FETCH_MY_EVENTS,
  FETCH_MY_EVENTS_ERROR,
  FETCH_MY_EVENTS_SUCCESS,
} from '@/stores/agnostic/actions/event/agnostic-event.actions';
import {
  REFRESH_EVENT_USER,
  UPDATE_EVENT_FILTER,
  UPDATE_WANTS_APPOINTMENT,
  UPDATE_UNITY_ID,
  REFRESH_EVENT_COMPLETED,
} from '@/stores/umanize-app/actions/event/app-event.actions';

export interface AppEventState {
  events: EventsState;
  event: CurrentEventState;
  users: EventUsersState;
  myEvents: MyEventsState;
  unityId: string;
}

const currentEventState: CurrentEventState = {
  event: null,
  status: {
    error: null,
    isSaving: false,
    isLoading: false,
    isLoaded: false,
  },
};

const eventUsersState: EventUsersState = {
  filter: {
    searchText: '',
    ticketType: TicketType.all,
  },
  users: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

const myEventsState: MyEventsState = {
  myEvents: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

const eventsState: EventsState = {
  events: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

export const state: AppEventState = {
  events: eventsState,
  event: currentEventState,
  users: eventUsersState,
  myEvents: myEventsState,
  unityId: null,
};

const actions = {
  [FETCH_ALL_EVENTS]: AgnosticEventModule.actions.fetchAllEvents,
  [FETCH_EVENT_USERS]: AgnosticEventModule.actions.fetchEventsUsers,
  [FETCH_EVENT_BY_ID_SILENT]: AgnosticEventModule.actions.fetchEventByIdSilent,
  [FETCH_MY_EVENTS]: AgnosticEventModule.actions.fetchMyEvents,
  [REFRESH_EVENT_COMPLETED]: ({ commit }: ActionContext<AppEventState, RootState>) =>
    commit(REFRESH_EVENT_COMPLETED),
  async [REFRESH_EVENT_USER](
    { commit }: ActionContext<AppEventState, RootState>,
    ticket: UserTicket,
  ) {
    commit(REFRESH_EVENT_USER, ticket);
  },
  async [UPDATE_EVENT_FILTER](
    { commit }: ActionContext<AppEventState, RootState>,
    filter: EventUsersFilter,
  ) {
    commit(UPDATE_EVENT_FILTER, filter);
  },
  async [UPDATE_WANTS_APPOINTMENT](
    { commit }: ActionContext<AppEventState, RootState>,
    { eventId, wantsAppointments }: { eventId: string; wantsAppointments: boolean },
  ) {
    try {
      const preferences = await EventService.updatePreferences({
        eventId,
        preferences: { wantsAppointments },
      });

      commit(UPDATE_WANTS_APPOINTMENT, preferences);
    } catch (error) {
      // Silent catch this error, we do not want to block the UI if the preferences fails to update
      commit(UPDATE_WANTS_APPOINTMENT, { wantsAppointments });
    }
  },
  [UPDATE_UNITY_ID]({ commit }, id: string) {
    commit(UPDATE_UNITY_ID, id);
  },
};

const mutations = {
  [FETCH_ALL_EVENTS](state) {
    state.events.status = {
      ...state.events.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_ALL_EVENTS_SUCCESS](state, events) {
    state.events = {
      status: {
        ...state.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      events: [...events],
    };
  },
  [FETCH_ALL_EVENTS_ERROR](state, error) {
    state.events.status = {
      ...state.events.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [FETCH_EVENT_BY_ID](state) {
    state.event.status = {
      ...state.event.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [FETCH_EVENT_BY_ID_SUCCESS](state, event) {
    state.event = {
      status: {
        ...state.event.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      event: {
        ...event,
      },
    };
  },
  [FETCH_EVENT_BY_ID_ERROR](state, error) {
    state.event.status = {
      ...state.event.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
  [REFRESH_EVENT_USER](state, ticket: UserTicket) {
    state.users.users = state.users.users.map((u: EventUser) => {
      if (u.id === ticket.userId) {
        return {
          ...u,
          ticket,
        };
      }

      return u;
    });
  },
  [FETCH_EVENT_USERS](state) {
    state.users.status = {
      ...state.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_EVENT_USERS_SUCCESS](state, users) {
    state.users = {
      ...state.users,
      status: {
        ...state.users.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      users: [...users],
    };
  },
  [FETCH_EVENT_USERS_ERROR](state, error) {
    state.users.status = {
      ...state.users.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [UPDATE_EVENT_FILTER](state, filter: EventUsersFilter) {
    state.users = {
      ...state.users,
      filter: {
        ...state.users.filter,
        ...filter,
      },
    };
  },
  [FETCH_MY_EVENTS](state) {
    state.myEvents.status = {
      ...state.myEvents.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_MY_EVENTS_SUCCESS](state, events) {
    state.myEvents = {
      status: {
        ...state.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      myEvents: [...events],
    };
  },
  [FETCH_MY_EVENTS_ERROR](state, error) {
    state.myEvents.status = {
      ...state.myEvents.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [REFRESH_EVENT_COMPLETED](state) {
    state.event = {
      ...state.event,
      event: {
        ...state.event.event,
        ticket: {
          ...state.event.event.ticket,
          userProfileCompleted: true,
        },
      },
    };
  },
  [UPDATE_WANTS_APPOINTMENT](state: AppEventState, preferences: Partial<UserTicketPreferences>) {
    state.event = {
      ...state.event,
      event: {
        ...state.event.event,
        ticket: {
          ...state.event.event.ticket,
          ...preferences,
        },
      },
    };
  },
  [UPDATE_UNITY_ID](state: AppEventState, id: string) {
    state.unityId = id;
  },
};

const getters = {
  eventState: (state: AppEventState): CurrentEventState => state.event,
  event: (_, { eventState }: { eventState: CurrentEventState }): AppEvent =>
    eventState?.event || null,
  isCompleted: (_, { eventState }: { eventState: CurrentEventState }): boolean =>
    eventState?.event?.ticket.userProfileCompleted || false,
  eventStatus: (_, { eventState }: { eventState: CurrentEventState }): CurrentEventStatus =>
    eventState?.status || null,
  eventIsLoading: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): boolean =>
    eventStatus?.isLoading || false,
  eventIsLoaded: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): boolean =>
    eventStatus?.isLoaded || false,
  eventError: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): ErrorMessage =>
    eventStatus?.error || null,

  eventUsersState: (state: AppEventState): EventUsersState => state.users,
  eventUsersFilter: (
    _,
    {
      eventUsersState,
    }: {
      eventUsersState: EventUsersState;
    },
  ): EventUsersFilter => eventUsersState?.filter || { searchText: '', ticketType: TicketType.all },
  eventUserFilterTicketType: (
    _,
    {
      eventUsersFilter,
    }: {
      eventUsersFilter: EventUsersFilter;
    },
  ): TicketType => eventUsersFilter?.ticketType || TicketType.all,
  eventUsers: (
    _,
    { eventUsersState }: { eventUsersState: EventUsersState },
  ): EventUserOrPublicProfile[] => eventUsersState?.users || [],
  filteredEventUsers: (
    _,
    {
      eventUsersState,
      eventUsersFilter,
    }: {
      eventUsersState: EventUsersState;
      eventUsersFilter: EventUsersFilter;
    },
  ): EventUserOrPublicProfile[] =>
    (eventUsersState?.users || [])
      .filter(
        (r: EventUserOrPublicProfile) =>
          !eventUsersFilter?.ticketType ||
          eventUsersFilter?.ticketType === TicketType.all ||
          eventUsersFilter?.ticketType === r.ticket?.type ||
          (eventUsersFilter?.ticketType === TicketType.visitor && r.ticket?.type === undefined),
      )
      .filter((user: EventUserOrPublicProfile) =>
        (user.firstName.toLowerCase() + user.lastName.toLowerCase()).includes(
          eventUsersFilter.searchText.toLowerCase(),
        ),
      ),
  eventUsersStatus: (
    _,
    { eventUsersState }: { eventUsersState: EventUsersState },
  ): EventUsersStatus => eventUsersState?.status || null,
  usersAreLoading: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): boolean =>
    eventUsersStatus?.areLoading || false,
  usersAreLoaded: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): boolean =>
    eventUsersStatus?.areLoaded || false,
  usersError: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): ErrorMessage =>
    eventUsersStatus?.error || null,

  eventsState: (state: AppEventState): EventsState => state.events,
  events: (_, { eventsState }: { eventsState: EventsState }) =>
    eventsState?.events?.filter((event) => event.public) || [],
  eventsStatus: (_, { eventsState }: { eventsState: EventsState }): EventsStatus =>
    eventsState?.status || null,
  eventsAreLoading: (_, { eventsStatus }: { eventsStatus: EventsStatus }): boolean =>
    eventsStatus?.areLoading || false,
  eventsAreLoaded: (_, { eventsStatus }: { eventsStatus: EventsStatus }): boolean =>
    eventsStatus?.areLoaded || false,
  eventsError: (_, { eventsStatus }: { eventsStatus: EventsStatus }): ErrorMessage =>
    eventsStatus?.error || null,

  myEventsState: (state: AppEventState): MyEventsState => state.myEvents,
  myEvents: (_, { myEventsState }: { myEventsState: MyEventsState }) =>
    myEventsState?.myEvents || [],
  myEventsStatus: (_, { myEventsState }: { myEventsState: MyEventsState }): MyEventsStatus =>
    myEventsState?.status || null,
  myEventsAreLoading: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): boolean =>
    myEventsStatus?.areLoading || false,
  myEventsAreLoaded: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): boolean =>
    myEventsStatus?.areLoaded || false,
  myEventsError: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): ErrorMessage =>
    myEventsStatus?.error || null,

  filteredEvents: (
    _,
    { myEvents, events }: { myEvents: AppEvent[]; events: AppEvent[] },
  ): AppEvent[] => events.filter((event) => !myEvents.find((myEvent) => myEvent.id === event.id)),
  myOnlyEvent: (
    _,
    { filteredEvents, myEvents }: { filteredEvents: AppEvent[]; myEvents: AppEvent[] },
  ) => (myEvents.length === 1 && filteredEvents.length === 0 ? myEvents[0] : null),
  unityId: (state: AppEventState): string => state.unityId,
};

export const AppEventModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
