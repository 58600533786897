import { CustomUI } from '@/models/custom-ui/custom-ui.model';
import { ErrorMessage } from '@/models/error-message/error-message.model';

import {
  AgnosticCustomUiModule,
  CustomUiState,
  CustomUiStatus,
} from '@/stores/agnostic/modules/custom-ui/agnostic-custom-ui.module';
import {
  GET_CUSTOM_UI,
  GET_CUSTOM_UI_SUCCESS,
  GET_CUSTOM_UI_ERROR,
} from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';

const customUi: CustomUiState = {
  customUi: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

export interface AppCustomUiState {
  customUi: CustomUiState;
}

const state: AppCustomUiState = {
  customUi,
};

const actions = {
  [GET_CUSTOM_UI]: AgnosticCustomUiModule.actions.getCustomUi,
};

const mutations = {
  [GET_CUSTOM_UI](state: AppCustomUiState) {
    state.customUi.status = {
      ...state.customUi.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CUSTOM_UI_SUCCESS](state: AppCustomUiState, customUi: CustomUI) {
    state.customUi = {
      status: {
        ...state.customUi.status,
        isLoading: false,
        error: null,
      },
      customUi,
    };
  },
  [GET_CUSTOM_UI_ERROR](state: AppCustomUiState, error: ErrorMessage) {
    state.customUi.status = {
      ...state.customUi.status,
      isLoading: false,
      error,
    };
  },
};

const getters = {
  currentState: (state: AppCustomUiState): CustomUiState => state.customUi,
  customUi: (_, { currentState }: { currentState: CustomUiState }): CustomUI =>
    currentState?.customUi || null,
  currentStatus: (_, { currentState }: { currentState: CustomUiState }): CustomUiStatus =>
    currentState?.status || null,
  customUiIsLoading: (_, { currentStatus }: { currentStatus: CustomUiStatus }): boolean =>
    currentStatus?.isLoading || false,
  customUiError: (_, { currentStatus }: { currentStatus: CustomUiStatus }): ErrorMessage =>
    currentStatus?.error || null,
};

export const AppCustomUiModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
