export const USER_MODULE = 'UserModule';
export const USER_TICKET_MODULE = 'UserTicketModule';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_TICKET_PROFILE = 'UPDATE_TICKET_PROFILE';
export const UPDATE_TICKET_PROFILE_SUCCESS = 'UPDATE_TICKET_PROFILE_SUCCESS';
export const UPDATE_TICKET_PROFILE_ERROR = 'UPDATE_TICKET_PROFILE_ERROR';

export const GET_TICKET_PROFILE = 'GET_TICKET_PROFILE';
export const GET_TICKET_PROFILE_SUCCESS = 'GET_TICKET_PROFILE_SUCCESS';
export const GET_TICKET_PROFILE_ERROR = 'GET_TICKET_PROFILE_ERROR';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR';

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_ERROR = 'UPDATE_AVATAR_ERROR';
