/* eslint-disable */
import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { Stream } from '@/models/stream/stream.model';
import { AdminTraining } from '@/models/training/admin/admin-training.model';
import { TrainingProgressModel } from '@/models/training/admin/training-progress.model';
import { UmanizeVideo } from '@/models/umanize-video/umanize-video';

class TrainingService {
  public async getAll(eventId: string): Promise<AdminTraining[]> {
    return HttpService.perform<AdminTraining[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.TRAINING]),
    );
  }

  public async get(eventId: string, trainingId: string): Promise<AdminTraining> {
    return HttpService.perform<AdminTraining>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
      ]),
    );
  }

  public async getUserProcess(eventId: string, trainingId: string): Promise<TrainingProgressModel> {
    return HttpService.perform<TrainingProgressModel>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_PROGRESS,
      ]),
    );
  }

  public async create(eventId: string, adminTraining: AdminTraining): Promise<Stream> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.TRAINING]),
      adminTraining,
    );
  }

  public async update(eventId: string, adminTraining: AdminTraining): Promise<AdminTraining> {
    return HttpService.perform<any>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        adminTraining.id,
      ]),
      adminTraining,
    );
  }

  public async delete(eventId: string, trainingId: string): Promise<AdminTraining> {
    return HttpService.perform<AdminTraining>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
      ]),
    );
  }

  public async getListLinkedUserToTraining(eventId: string, idTraining: string): Promise<Stream> {
    return HttpService.perform<any>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        idTraining,
        HttpHelper.LINK_USER_TRAINING,
      ]),
    );
  }

  public async linkUserToTraining(
    eventId: string,
    idTraining: string,
    userLinkTraining: any,
  ): Promise<Stream> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        idTraining,
        HttpHelper.LINK_USER_TRAINING,
        userLinkTraining.id,
      ]),
    );
  }

  public async updateUserCustomFields(
    eventId: string,
    idTraining: string,
    customFields: any,
  ): Promise<Stream> {
    const data = { customFields: [] };
    for (const [key, value] of Object.entries(customFields)) {
      data.customFields.push({ value, customField: key });
    }
    return HttpService.perform<any>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        idTraining,
        HttpHelper.USER_ENDPOINT,
        HttpHelper.CUSTOM_FIELDS,
      ]),
      data,
    );
  }

  public async upload(eventId: string, trainingId: string, file: File): Promise<UmanizeVideo> {
    const formData: FormData = new FormData();
    formData.append('file', new Blob([file], { type: 'application/octet-stream' }));

    return HttpService.perform<UmanizeVideo>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        'videos',
      ]),
      formData,
      true,
      true,
    );
  }
}

export default new TrainingService();
