<template>
  <div id="zendesk"></div>
</template>

<script>
export default {
  name: 'Zendesk',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    lang: {},
  },
  computed: {
    currentRouteName() {
      return this.$route?.name;
    },
  },
  data() {
    return {
      iframe: null,
      isSetHeightChatSection: false,
    };
  },
  methods: {
    initImg() {
      this.iframe = document.querySelector(
        '[title="Bouton de lancement de la fenêtre de messagerie"]',
      );
      if (!this.iframe) {
        this.iframe = document.querySelector('[title="Button to launch messaging window"]');
      }
      const chatWindows = document.querySelector('[title="Messaging window"]');
      if (chatWindows?.parentNode?.offsetHeight === 0) {
        this.iframe.style.display = 'none';
      }
    },
  },
  mounted() {
    const formScript = document.createElement('script');
    formScript.src =
      'https://static.zdassets.com/ekr/snippet.js?key=545a2159-54be-40d5-98b4-44e63b50dc4d';
    formScript.id = 'ze-snippet';
    formScript.async = false;
    document.head.append(formScript);
    formScript.addEventListener('load', () => {
      setTimeout(this.initImg, 2000);
    });
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.iframe.style.display = 'block';
        setTimeout(this.initImg, 100);
      } else {
        this.iframe.style.display = 'none';
      }
    },
    lang() {
      setTimeout(this.initImg, 10);
    },
    currentRouteName() {
      setTimeout(this.initImg, 100);
    },
  },
};
</script>
