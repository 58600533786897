<template>
  <v-sheet class="full-height">
    <v-row no-gutters class="full-height">
      <v-col class="admin-menu" cols="2">
        <v-list dark dense>
          <v-subheader class="admin-menu__title">{{ $t('admin.title') }}</v-subheader>

          <v-list-item-group v-model="selectedItem" color="#FFFFFF">
            <div v-for="item in items" :key="item.route">
              <v-list-item v-if="canAccess(item)" :to="buildRoute(item.route)" :title="item.text">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col class="admin-content" cols="10">
        <router-view />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'UmanizeEventAdmin',
  data: () => ({
    selectedItem: null,
  }),
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    items() {
      return [
        {
          icon: 'mdi-calendar-edit',
          text: this.$t('admin.menu.details'),
          route: 'details',
          permissions: ['permission.events.canView'],
        },
        {
          icon: 'mdi-tag-multiple',
          text: this.$t('admin.menu.tags'),
          route: 'tags',
          permissions: ['permission.events.canView'],
        },
        {
          icon: 'mdi-account-group-outline',
          text: this.$t('admin.menu.users'),
          route: 'users',
          permissions: ['permission.users.canView'],
        },
        {
          icon: 'mdi-ticket-confirmation',
          text: this.$t('admin.menu.tickets'),
          route: 'tickets',
          permissions: ['permission.tickets.canView'],
        },
        {
          icon: 'mdi-account-voice',
          text: this.$t('admin.menu.conferences'),
          route: 'conferences',
          permissions: ['permission.conferences.canView'],
        },
        {
          icon: 'mdi-google-classroom',
          text: this.$t('admin.menu.conferenceRooms'),
          route: 'conference-rooms',
          permissions: ['permission.conferences.canView'],
        },
        {
          icon: 'mdi-filmstrip-box-multiple',
          text: this.$t('admin.menu.contentLibraries'),
          route: 'content-libraries',
          permissions: ['permission.contentLibraries.canView'],
        },
        {
          icon: 'mdi-bullhorn',
          text: this.$t('admin.menu.discussionGroups'),
          route: 'discussion-groups',
          permissions: ['permission.discussionGroups.canView'],
        },
        {
          icon: 'mdi-briefcase-account',
          text: this.$t('admin.menu.exhibitors'),
          route: 'exhibitors',
          permissions: ['permission.exhibitors.canView'],
        },
        {
          icon: 'mdi-handshake',
          text: this.$t('admin.menu.partners'),
          route: 'partners',
          permissions: ['permission.partners.canView'],
        },
        {
          icon: 'mdi-heart-multiple',
          text: this.$t('admin.menu.matching'),
          route: 'matching',
          permissions: ['permission.smartMatchings.canView'],
        },
        {
          icon: 'mdi-vote',
          text: this.$t('admin.menu.generalAssemblies'),
          route: 'general-assemblies',
          permissions: ['permission.generalAssemblies.canView'],
        },
        process.env.VUE_APP_NOTIFICATIONS_TOGGLER === 'true' && {
          icon: 'mdi-bell',
          text: this.$t('admin.menu.notifications'),
          route: 'notifications',
          permissions: ['permission.notifications.canView'],
        },
        {
          icon: 'mdi-video-outline',
          text: this.$t('admin.menu.streams'),
          route: 'streams',
          // TODO: Update permissions
          permissions: ['permission.conferences.canView'],
        },
        {
          icon: 'mdi-account-school',
          text: this.$t('admin.menu.Training'),
          route: 'training',
          permissions: ['permission.generalAssemblies.canView'],
        },
        {
          icon: 'mdi-forum',
          text: this.$t('admin.menu.groupChat'),
          route: 'group-chat',
          // TODO: Update permissions
          permissions: ['permission.conferences.canView'],
        },
      ].filter(Boolean);
    },
  },
  methods: {
    buildRoute(route) {
      const { eventId } = this.$route.params;

      return `/events/${eventId}/admin/${route}`;
    },
    canAccess(item) {
      const { eventId } = this.$route.params;

      return PermissionsUtil.isAuthorized(item.permissions, this.loggedInUserRoles, eventId);
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  height: inherit;
}

.theme--dark.v-list {
  background-color: var(--v-primaryBackground-dark);
}
.admin-menu {
  background-color: var(--v-primaryFont-base);
  &__title {
    color: #ffffff !important;
  }
}

.admin-content {
  background-color: var(--v-adminBackground-base);
}
</style>
