<template>
  <div class="notification-center" v-if="showNotificationCenter">
    <div class="notification-center__content">
      <div class="notification-center__content__animate">
        {{ content }}
      </div>
    </div>
    <div class="notification-center__button" @click="goToEvent()">
      <div class="notification-center__button__content">{{ btnContent }}</div>
    </div>
    <div class="notification-center__close" @click="onClose">
      <img src="~@assets/icons/X.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationCenter',
  props: {
    hasGlobalUnread: {
      type: Boolean,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNotificationCenter: false, // pending for API
      content: 'Welcome to the event ! Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      btnContent: 'En savior plus',
    };
  },
  methods: {
    goToEvent() {
      window.location.reload();
      return true;
    },
    onClose() {
      this.showNotificationCenter = false;
      document.getElementById('event-name').classList.remove('truncate-event-name');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
.notification-center {
  border: 1px solid gray;
  border-radius: 3px;
  display: flex;
  position: relative;
  padding: 0.5rem 1rem;
  width: 700px;
  @media screen and (max-width: 1600px) {
    width: 600px;
  }
  @media screen and (max-width: 1366px) {
    width: 480px;
  }
  @media screen and (max-width: 1200px) {
    width: 400px;
  }
  @media screen and (max-width: 1024px) {
    width: 335px;
  }

  &__content {
    width: 80%;
    overflow-x: hidden;
    height: 25px;

    &__animate {
      width: 100%;
      font-size: $default-font-size;
      white-space: nowrap;
      text-overflow: clip;
      animation-name: rightToLeft;
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    @keyframes rightToLeft {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  &__button {
    margin-left: 1rem;
    font-size: $default-font-size;
    color: white;
    background: black;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    &__content {
      width: max-content;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}
</style>
