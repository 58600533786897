import { ASSEMBLY_MODULE } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const APP_ASSEMBLY_MODULE = `${UMANIZE_APP_MODULE}/${ASSEMBLY_MODULE}`;

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';

export const QUESTION_ANSWER = 'QUESTION_ANSWER';
export const QUESTION_ANSWER_SUCCESS = 'QUESTION_ANSWER_SUCCESS';
export const QUESTION_ANSWER_ERROR = 'QUESTION_ANSWER_ERROR';

export const ADD_QUESTION = 'ADD_QUESTION';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_ERROR = 'ADD_QUESTION_ERROR';

export const CLOSE_QUESTION = 'CLOSE_QUESTION';
export const CLOSE_QUESTION_SUCCESS = 'CLOSE_QUESTION_SUCCESS';
export const CLOSE_QUESTION_ERROR = 'CLOSE_QUESTION_ERROR';

export const START_QUESTION = 'START_QUESTION';
export const START_QUESTION_SUCCESS = 'START_QUESTION_SUCCESS';
export const START_QUESTION_ERROR = 'START_QUESTION_ERROR';

export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR';

export const UPDATE_QUESTION = 'UPDATE_QUESTION';

export const UPDATE_QUESTION_CLOSE = 'UPDATE_QUESTION_CLOSE';

export const GET_QUESTIONS_RESULTS = 'GET_QUESTIONS_RESULTS';
export const GET_QUESTIONS_RESULTS_SUCCESS = 'GET_QUESTIONS_RESULTS_SUCCESS';
export const GET_QUESTIONS_RESULTS_ERROR = 'GET_QUESTIONS_RESULTS_ERROR';

export const CONNECT_TO_ASSEMBLY_SOCKET = 'CONNECT_TO_ASSEMBLY_SOCKET';
export const DISCONNECT_FROM_ASSEMBLY_SOCKET = 'DISCONNECT_FROM_ASSEMBLY_SOCKET';

export const CONNECT_TO_ASSEMBLY_ADMIN_SOCKET = 'CONNECT_TO_ASSEMBLY_ADMIN_SOCKET';
export const DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET = 'DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET';

export const ASSEMBLY_CHAT_LOAD_MESSAGES = 'ASSEMBLY_CHAT_LOAD_MESSAGES';
export const ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS = 'ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS';
export const ASSEMBLY_CHAT_LOAD_MESSAGES_ERROR = 'ASSEMBLY_CHAT_LOAD_MESSAGES_ERROR';

export const ASSEMBLY_SEND_CHATS_MESSAGE = 'ASSEMBLY_SEND_CHATS_MESSAGE';
export const ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS = 'ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS';
export const ASSEMBLY_SEND_CHATS_MESSAGE_ERROR = 'ASSEMBLY_SEND_CHATS_MESSAGE_ERROR';

export const ASSEMBLY_CHAT_NEW_MESSAGE_SUCCESS = 'ASSEMBLY_CHAT_NEW_MESSAGE_SUCCESS';

export const MODERATE_ASSEMBLY_CHAT_MESSAGE = 'MODERATE_ASSEMBLY_CHAT_MESSAGE';

export const ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS = 'ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS';

export const DELETE_GENERAL_ASSEMBLY = 'DELETE_GENERAL_ASSEMBLY';
export const DELETE_GENERAL_ASSEMBLY_SUCCESS = 'DELETE_GENERAL_ASSEMBLY_SUCCESS';
export const DELETE_GENERAL_ASSEMBLY_ERROR = 'DELETE_GENERAL_ASSEMBLY_ERROR';
