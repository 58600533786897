import { MatchingQuestion } from '@/models';
import { QuestionnaireQuestion } from '@/models/interface/questionnaire-question.interface';

interface EventAnswers {
  [key: string]: string[];
}

export default abstract class QuestionnaireUtils {
  public static hasAnsweredLastQuestion(questions: MatchingQuestion[]): boolean {
    const currentQuestionIndex: number = this.getCurrentQuestionIndex(questions);
    const isLast: boolean = currentQuestionIndex === questions.length - 1;

    return isLast && questions[currentQuestionIndex]?.currentAnswerIds?.length > 0;
  }

  public static getCurrentQuestionIndex(matchingQuestions: MatchingQuestion[]): number {
    const lastIndex = matchingQuestions.findIndex(
      (question: MatchingQuestion) => (question.currentAnswerIds || []).length <= 0,
    );

    return lastIndex >= 0 ? lastIndex : matchingQuestions.length - 1;
  }

  /**
   * Removes answers stored locally for event with id "eventId" and the given "key".
   *
   * @param object
   */
  public static removeAnswers({ key, eventId }: { eventId: string; key: string }) {
    const eventKey = QuestionnaireUtils.getEventKey(key, eventId);
    localStorage.removeItem(eventKey);
  }

  public static storeAnswers({
    eventId,
    questionId,
    answerIds,
    key,
  }: {
    eventId: string;
    questionId: string;
    answerIds: string[];
    key: string;
  }): void {
    const eventKey = QuestionnaireUtils.getEventKey(key, eventId);
    const eventAnswers: EventAnswers = JSON.parse(localStorage.getItem(eventKey)) || {};

    eventAnswers[questionId] = answerIds;
    localStorage.setItem(eventKey, JSON.stringify(eventAnswers));
  }

  public static mapQuestionStoredAnswers({
    eventId,
    questions,
    key,
  }: {
    eventId: string;
    questions: QuestionnaireQuestion[];
    key: string;
  }): QuestionnaireQuestion[] {
    const eventKey = QuestionnaireUtils.getEventKey(key, eventId);
    const eventAnswers: EventAnswers = JSON.parse(localStorage.getItem(eventKey)) || {};

    return questions.map((question: QuestionnaireQuestion) => ({
      ...question,
      currentAnswerIds: eventAnswers[question.id],
    }));
  }

  /**
   * Returns the unique key used in the local storage for storing
   * answers to questions regarding an event.
   *
   * @param key
   * @param eventId
   */
  private static getEventKey(key: string, eventId: string) {
    return `${key}-${eventId}`;
  }
}
