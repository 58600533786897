import { ActionContext } from 'vuex';
import * as Sentry from '@sentry/browser';
import { Payment, paymentStatus } from '@/models/ticketing/payment.model';

import { Ticket } from '@/models';
import TicketService from '@/services/ticket/ticket.service';
import { RootState } from '@/stores/store.model';
import i18n from '@/i18n';
import { PaymentForm } from '@/models/ticketing/payment-form.model';
import { Severity } from '@sentry/browser';
import {
  AgnosticTicketModule,
  TicketErrorType,
  TicketsState,
} from '@/stores/agnostic/modules/ticket/agnostic-ticket.module';
import {
  GET_TICKETS,
  GET_TICKETS_ERROR,
  GET_TICKETS_SUCCESS,
  SELECT_TICKET,
} from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';
import {
  PAY,
  PAY_ERROR,
  PAY_SUCCESS,
  PAYMENT_INTENT,
  PAYMENT_INTENT_ERROR,
  PAYMENT_INTENT_SUCCESS,
  SET_TICKET_NEWSLETTER,
  SET_TICKET_RECEIVE_INFO,
} from '@/stores/umanize-app/actions/ticket/app-ticket.actions';

const state: TicketsState = {
  tickets: [],
  selectedTicket: undefined,
  newsletter: false,
  receiveInfo: false,
  payment: undefined,
  status: {
    error: null,
    isSending: false,
    isLoaded: false,
  },
};

const actions = {
  [GET_TICKETS]: AgnosticTicketModule.actions.getTickets,
  [SELECT_TICKET]: AgnosticTicketModule.actions.selectTicket,
  async [SET_TICKET_NEWSLETTER](
    { commit }: ActionContext<TicketsState, RootState>,
    newsletter: boolean,
  ): Promise<void> {
    commit(SET_TICKET_NEWSLETTER, newsletter);
  },
  async [SET_TICKET_RECEIVE_INFO](
    { commit }: ActionContext<TicketsState, RootState>,
    receiveInfo: boolean,
  ): Promise<void> {
    commit(SET_TICKET_RECEIVE_INFO, receiveInfo);
  },
  async [PAYMENT_INTENT](
    { commit, getters }: ActionContext<TicketsState, RootState>,
    ticket: Ticket,
  ): Promise<Payment> {
    commit(PAYMENT_INTENT);

    try {
      const payment: Payment = await TicketService.pay(
        ticket,
        getters.newsletter,
        getters.receiveInfo,
      );
      commit(PAYMENT_INTENT_SUCCESS, payment);
      return payment;
    } catch (err) {
      const error = i18n.t(`ticketing.errors.${err.status}`);
      commit(PAYMENT_INTENT_ERROR, error);
      throw err;
    }
  },
  async [PAY](
    { commit, getters }: ActionContext<TicketsState, RootState>,
    paymentForm: PaymentForm,
  ): Promise<Payment> {
    commit(PAY);

    let errorToThrow;
    try {
      const { paymentIntent, error } = await TicketService.payWithStripe(
        paymentForm,
        getters.payment.stripeClientSecret,
      );

      if (!error) {
        // TODO: what should we do when the payment is being processes: paymentStatus.PENDING_PAYMENT
        if (paymentIntent.status === paymentStatus.SUCCEEDED) {
          commit(PAY_SUCCESS);
        }
        return getters.payment;
      }

      commit(PAY_ERROR, {
        message: error.message,
        type: TicketErrorType.STRIPE,
      });
      errorToThrow = error;
    } catch (error) {
      // Something unexpected happened (a 500 for example) or our request was completely wrong.
      // Either way, we have no readable answer, so we're going to log the native error.
      errorToThrow = error;
      commit(PAY_ERROR, {
        ...error,
        message: error.message || i18n.t('ticketing.errors.unexpected'),
        type: TicketErrorType.UNEXPECTED,
      });
    }

    // We capture the error into sentry.
    Sentry.captureException(errorToThrow, { level: Severity.Error });
    throw new Error(errorToThrow.message || i18n.t('ticketing.errors.unexpected'));
  },
};

const mutations = {
  [GET_TICKETS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_TICKETS_SUCCESS](state: TicketsState, tickets) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
    state.tickets = [...tickets];
  },
  [GET_TICKETS_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
    state.tickets = [];
    state.selectedTicket = undefined;
  },
  [SELECT_TICKET](state: TicketsState, ticket: Ticket) {
    state.selectedTicket = {
      ...ticket,
    };
  },
  [SET_TICKET_NEWSLETTER](state: TicketsState, newsletter: boolean) {
    state.newsletter = newsletter;
  },
  [SET_TICKET_RECEIVE_INFO](state: TicketsState, receiveInfo: boolean) {
    state.receiveInfo = receiveInfo;
  },
  [PAYMENT_INTENT](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [PAYMENT_INTENT_SUCCESS](state: TicketsState, payment: Payment) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
    state.newsletter = false;
    state.receiveInfo = false;
    state.payment = payment;
  },
  [PAYMENT_INTENT_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
    };
    state.newsletter = false;
    state.receiveInfo = false;
    state.payment = null;
  },
  [PAY](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [PAY_SUCCESS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
    state.payment = {
      ...state.payment,
      status: paymentStatus.SUCCEEDED,
    };
  },
  [PAY_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      error,
    };
  },
};

const selectors = {
  tickets: (state: TicketsState) => state.tickets,
  ticketName: (state: TicketsState) => (ticketId: string) =>
    state.tickets.find((ticket) => ticket.id === ticketId)?.name || null,
  selectedTicket: (state: TicketsState) => state.selectedTicket,
  newsletter: (state: TicketsState) => state.newsletter,
  receiveInfo: (state: TicketsState) => state.receiveInfo,
  payment: (state: TicketsState) => state.payment,
  isSending: (state: TicketsState) => (state.status && state.status.isSending) || false,
  isLoaded: (state: TicketsState) => (state.status && state.status.isLoaded) || false,
  error: (state: TicketsState) => (state.status && state.status.error) || '',
  errorMessage: (state: TicketsState) => state.status?.error?.message || '',
  errorType: (state: TicketsState) => state.status?.error?.type || '',
};

export const AppTicketModule = {
  namespaced: true,
  actions,
  getters: selectors,
  mutations,
  state,
};
