import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const RELATIONS_MODULE_NAME = 'RelationsModule';
export const RELATIONS_MODULE = `${UMANIZE_APP_MODULE}/${RELATIONS_MODULE_NAME}`;

export const GET_RELATIONS = 'GET_RELATIONS';
export const GET_RELATIONS_SUCCESS = 'GET_RELATIONS_SUCCESS';
export const GET_RELATIONS_ERROR = 'GET_RELATIONS_ERROR';

export const SEARCH_CONTACT = 'SEARCH_CONTACT';

export const ADD_TO_CONTACTS = 'ADD_TO_CONTACTS';
export const ADD_TO_CONTACTS_SUCCESS = 'ADD_TO_CONTACTS_SUCCESS';
export const ADD_TO_CONTACTS_ERROR = 'ADD_TO_CONTACTS_ERROR';

export const REMOVE_FROM_CONTACTS = 'REMOVE_FROM_CONTACTS';
export const REMOVE_FROM_CONTACTS_SUCCESS = 'REMOVE_FROM_CONTACTS_SUCCESS';
export const REMOVE_FROM_CONTACTS_ERROR = 'REMOVE_FROM_CONTACTS_ERROR';
