import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { TICKET_MODULE } from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';

export const ADMIN_TICKET_MODULE = `${UMANIZE_ADMIN_MODULE}/${TICKET_MODULE}`;

export const SAVE_TICKET = 'SAVE_TICKET';
export const CLEAR_SELECTED_TICKET = 'CLEAR_SELECTED_TICKET';

export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_ERROR = 'UPDATE_TICKET_ERROR';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR';

export const GET_ADMIN_TICKETS = 'GET_ADMIN_TICKETS';
export const GET_ADMIN_TICKETS_SUCCESS = 'GET_ADMIN_TICKETS_SUCCESS';
export const GET_ADMIN_TICKETS_ERROR = 'GET_ADMIN_TICKETS_ERROR';
