import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const ACTIVITY_MODULE_NAME = 'ActivityModule';
export const ACTIVITY_MODULE = `${UMANIZE_APP_MODULE}/${ACTIVITY_MODULE_NAME}`;

export const GET_ACTIVITY_QUESTIONS = 'GET_ACTIVITY_QUESTIONS';
export const GET_ACTIVITY_QUESTIONS_SUCCESS = 'GET_ACTIVITY_QUESTIONS_SUCCESS';
export const GET_ACTIVITY_QUESTIONS_ERROR = 'GET_ACTIVITY_QUESTIONS_ERROR';

export const ANSWER_ACTIVITY = 'ANSWER_ACTIVITY';
export const ANSWER_ACTIVITY_SUCCESS = 'ANSWER_ACTIVITY_SUCCESS';
export const ANSWER_ACTIVITY_ERROR = 'ANSWER_ACTIVITY_ERROR';

export const GET_ACTIVITY_RESULT = 'GET_ACTIVITY_RESULT';
export const GET_ACTIVITY_RESULT_SUCCESS = 'GET_ACTIVITY_RESULT_SUCCESS';
export const GET_ACTIVITY_RESULT_ERROR = 'GET_ACTIVITY_RESULT_ERROR';
