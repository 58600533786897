import HttpHelper from '@/helpers/http/http.helper';
import { HttpMethod, Ticket } from '@/models';
import { PaymentForm } from '@/models/ticketing/payment-form.model';
import { Pay, Payment } from '@/models/ticketing/payment.model';
import HttpService from '@/services/http/http.service';
import stripeWrapper from '@/stripe/stripe-wrapper';

import PaymentIntentResponse = stripe.PaymentIntentResponse;

class TicketService {
  public async getEventTickets({
    eventId,
    noRestriction,
  }: {
    eventId: string;
    noRestriction: boolean;
  }): Promise<Ticket[]> {
    const endpoint = noRestriction
      ? HttpHelper.TICKETS_ENDPOINT
      : `${HttpHelper.TICKETS_ENDPOINT}?restricted=false`;

    return HttpService.perform<Ticket[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, endpoint]),
    );
  }

  public async updateTicket(ticket: Ticket): Promise<Ticket> {
    return HttpService.perform<Ticket>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        ticket.eventId,
        HttpHelper.TICKETS_ENDPOINT,
        ticket.id,
      ]),
      ticket,
    );
  }

  public async createTicket(ticket: Ticket): Promise<Ticket> {
    return HttpService.perform<Ticket>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        ticket.eventId,
        HttpHelper.TICKETS_ENDPOINT,
      ]),
      ticket,
    );
  }

  public async pay(ticket: Ticket, newsletter: boolean, receiveInfo: boolean): Promise<Payment> {
    const body: Pay = {
      ticketId: ticket.id,
      price: ticket.price,
      currency: ticket.currency,
      newsletter,
      receiveInfo,
    };

    return HttpService.perform<Payment>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        ticket.eventId,
        HttpHelper.PAYMENTS_ENDPOINT,
      ]),
      body,
    );
  }

  public async payWithStripe(
    paymentForm: PaymentForm,
    secret: string,
  ): Promise<PaymentIntentResponse> {
    return stripeWrapper.stripe.confirmCardPayment(secret, {
      payment_method: {
        card: {
          token: paymentForm.card.id,
        },
        billing_details: {
          name: paymentForm.name,
        },
      },
    });
  }

  public async getPayments(): Promise<Payment[]> {
    return HttpService.perform<Payment[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.PAYMENTS_ENDPOINT),
    );
  }
}

export default new TicketService();
