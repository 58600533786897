import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import { DiscussionGroup, MessageType } from '@/models';
import DiscussionGroupService from '@/services/discussion-group/discussion-group.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import DateUtil from '@/helpers/date/date.helper';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';

import {
  AgnosticDiscussionGroupModule,
  CurrentDiscussionGroupState,
  CurrentDiscussionGroupStatus,
  DiscussionGroupsState,
  DiscussionGroupsStatus,
} from '@/stores/agnostic/modules/discussion-group/agnostic-discussion-group.module';
import {
  GET_DISCUSSION_GROUP,
  GET_DISCUSSION_GROUP_ERROR,
  GET_DISCUSSION_GROUP_SUCCESS,
  GET_DISCUSSION_GROUPS,
  GET_DISCUSSION_GROUPS_ERROR,
  GET_DISCUSSION_GROUPS_SUCCESS,
} from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import {
  ADD_DISCUSSION_GROUP,
  ADD_DISCUSSION_GROUP_ERROR,
  ADD_DISCUSSION_GROUP_SUCCESS,
  CLEAR_DISCUSSION_GROUP,
  DELETE_DISCUSSION_GROUP,
  DELETE_DISCUSSION_GROUP_ERROR,
  DELETE_DISCUSSION_GROUP_SUCCESS,
  SAVE_DISCUSSION_GROUP,
  UPDATE_DISCUSSION_GROUP,
  UPDATE_DISCUSSION_GROUP_ERROR,
  UPDATE_DISCUSSION_GROUP_SUCCESS,
} from '@/stores/umanize-admin/actions/discussion-group/admin-discussion-group.actions';

const currentDiscussionGroup: CurrentDiscussionGroupState = {
  discussionGroup: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const discussionGroups: DiscussionGroupsState = {
  discussionGroups: [],
  status: {
    error: null,
    isLoading: true,
  },
};

export interface AdminDiscussionGroupState {
  discussionGroup: CurrentDiscussionGroupState;
  discussionGroups: DiscussionGroupsState;
}

const state: AdminDiscussionGroupState = {
  discussionGroup: currentDiscussionGroup,
  discussionGroups,
};

const actions = {
  [GET_DISCUSSION_GROUPS]: AgnosticDiscussionGroupModule.actions.getDiscussionGroups,
  [GET_DISCUSSION_GROUP]: AgnosticDiscussionGroupModule.actions.getDiscussionGroup,
  async [SAVE_DISCUSSION_GROUP](
    { dispatch }: ActionContext<AdminDiscussionGroupState, RootState>,
    payload: { eventId: string; discussionGroup: Partial<DiscussionGroup> },
  ) {
    const action = payload.discussionGroup.id ? UPDATE_DISCUSSION_GROUP : ADD_DISCUSSION_GROUP;
    await dispatch(action, payload);
  },
  [CLEAR_DISCUSSION_GROUP]({ commit }: ActionContext<AdminDiscussionGroupState, RootState>) {
    commit(CLEAR_DISCUSSION_GROUP);
  },
  async [ADD_DISCUSSION_GROUP](
    { commit, dispatch }: ActionContext<AdminDiscussionGroupState, RootState>,
    payload: { eventId: string; discussionGroup: DiscussionGroup },
  ) {
    commit(ADD_DISCUSSION_GROUP);

    try {
      await DiscussionGroupService.create(payload);

      commit(ADD_DISCUSSION_GROUP_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.discussionGroups.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(ADD_DISCUSSION_GROUP_ERROR, error);
    }
  },
  async [UPDATE_DISCUSSION_GROUP](
    { commit, dispatch }: ActionContext<AdminDiscussionGroupState, RootState>,
    payload: { eventId: string; discussionGroup: DiscussionGroup },
  ) {
    commit(UPDATE_DISCUSSION_GROUP);

    try {
      await DiscussionGroupService.update(payload);

      commit(UPDATE_DISCUSSION_GROUP_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.discussionGroups.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(UPDATE_DISCUSSION_GROUP_ERROR, error);
    }
  },
  async [DELETE_DISCUSSION_GROUP](
    { commit, dispatch }: ActionContext<AdminDiscussionGroupState, RootState>,
    payload: { eventId: string; discussionGroupId: string },
  ) {
    commit(DELETE_DISCUSSION_GROUP);

    try {
      await DiscussionGroupService.delete(payload.eventId, payload.discussionGroupId);

      commit(DELETE_DISCUSSION_GROUP_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.discussionGroups.delete.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(DELETE_DISCUSSION_GROUP_ERROR, error);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.discussionGroups.delete.error'), type: MessageType.error },
        { root: true },
      );
    }
  },
};

const mutations = {
  [GET_DISCUSSION_GROUPS](state: AdminDiscussionGroupState) {
    state.discussionGroups.status = {
      ...state.discussionGroups.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_DISCUSSION_GROUPS_SUCCESS](
    state: AdminDiscussionGroupState,
    discussionGroups: DiscussionGroup[],
  ) {
    state.discussionGroups = {
      status: {
        ...state.discussionGroups.status,
        isLoading: false,
        error: null,
      },
      discussionGroups: [...discussionGroups],
    };
  },
  [GET_DISCUSSION_GROUPS_ERROR](state: AdminDiscussionGroupState, error: ErrorMessage) {
    state.discussionGroups.status = {
      ...state.discussionGroups.status,
      isLoading: false,
      error,
    };
  },
  [GET_DISCUSSION_GROUP](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_DISCUSSION_GROUP_SUCCESS](
    state: AdminDiscussionGroupState,
    discussionGroup: DiscussionGroup,
  ) {
    state.discussionGroup = {
      status: {
        ...state.discussionGroup.status,
        isLoading: false,
        error: null,
      },
      discussionGroup,
    };
  },
  [GET_DISCUSSION_GROUP_ERROR](state: AdminDiscussionGroupState, error: ErrorMessage) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_DISCUSSION_GROUP](state: AdminDiscussionGroupState) {
    state.discussionGroup = {
      ...state.discussionGroup,
      discussionGroup: null,
    };
  },
  [ADD_DISCUSSION_GROUP](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_DISCUSSION_GROUP_SUCCESS](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_DISCUSSION_GROUP_ERROR](state: AdminDiscussionGroupState, error: ErrorMessage) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_DISCUSSION_GROUP](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_DISCUSSION_GROUP_SUCCESS](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_DISCUSSION_GROUP_ERROR](state: AdminDiscussionGroupState, error: ErrorMessage) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_DISCUSSION_GROUP](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_DISCUSSION_GROUP_SUCCESS](state: AdminDiscussionGroupState) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error: null,
    };
  },
  [DELETE_DISCUSSION_GROUP_ERROR](state: AdminDiscussionGroupState, error: ErrorMessage) {
    state.discussionGroup.status = {
      ...state.discussionGroup.status,
      isSaving: false,
      error,
    };
  },
};

const getters = {
  currentState: (state: AdminDiscussionGroupState): CurrentDiscussionGroupState =>
    state.discussionGroup,
  discussionGroup: (
    _,
    { currentState }: { currentState: CurrentDiscussionGroupState },
  ): DiscussionGroup => currentState?.discussionGroup || null,
  currentStatus: (
    _,
    { currentState }: { currentState: CurrentDiscussionGroupState },
  ): CurrentDiscussionGroupStatus => currentState?.status || null,
  discussionGroupIsLoading: (
    _,
    { currentStatus }: { currentStatus: CurrentDiscussionGroupStatus },
  ): boolean => currentStatus?.isLoading || false,
  discussionGroupIsSaving: (
    _,
    { currentStatus }: { currentStatus: CurrentDiscussionGroupStatus },
  ): boolean => currentStatus?.isSaving || false,
  discussionGroupError: (
    _,
    { currentStatus }: { currentStatus: CurrentDiscussionGroupStatus },
  ): ErrorMessage => currentStatus?.error || null,

  listState: (state: AdminDiscussionGroupState): DiscussionGroupsState => state.discussionGroups,
  discussionGroups: (_, { listState }: { listState: DiscussionGroupsState }): DiscussionGroup[] =>
    listState?.discussionGroups || [],
  upComingDiscussionGroups: (
    _,
    { discussionGroups }: { discussionGroups: DiscussionGroup[] },
  ): DiscussionGroup[] =>
    discussionGroups?.filter((discussionGroup: DiscussionGroup) =>
      DateUtil.isNowOrAfter(discussionGroup.endTime),
    ),

  listStatus: (_, { listState }: { listState: DiscussionGroupsState }): DiscussionGroupsStatus =>
    listState?.status || null,
  discussionGroupsAreLoading: (
    _,
    { listStatus }: { listStatus: DiscussionGroupsStatus },
  ): boolean => listStatus?.isLoading || false,
  discussionGroupsError: (
    _,
    { listStatus }: { listStatus: DiscussionGroupsStatus },
  ): ErrorMessage => listStatus?.error || null,
};

export const AdminDiscussionGroupModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
