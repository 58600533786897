import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const AGENDA_MODULE_NAME = 'AgendaModule';
export const AGENDA_MODULE = `${UMANIZE_APP_MODULE}/${AGENDA_MODULE_NAME}`;

export const GET_AGENDA = 'GET_AGENDA';
export const GET_AGENDA_SUCCESS = 'GET_AGENDA_SUCCESS';
export const GET_AGENDA_ERROR = 'GET_AGENDA_ERROR';

export const ADD_TO_AGENDA = 'ADD_TO_AGENDA';
export const ADD_CONFERENCE_TO_AGENDA = 'ADD_CONFERENCE_TO_AGENDA';
export const ADD_DISCUSSION_GROUP_TO_AGENDA = 'ADD_DISCUSSION_GROUP_TO_AGENDA';
export const ADD_TO_AGENDA_SUCCESS = 'ADD_TO_AGENDA_SUCCESS';
export const ADD_TO_AGENDA_ERROR = 'ADD_TO_AGENDA_ERROR';

export const REMOVE_FROM_AGENDA = 'REMOVE_FROM_AGENDA';
export const REMOVE_CONFERENCE_FROM_AGENDA = 'REMOVE_CONFERENCE_FROM_AGENDA';
export const REMOVE_DISCUSSION_GROUP_FROM_AGENDA = 'REMOVE_DISCUSSION_GROUP_FROM_AGENDA';
export const REMOVE_FROM_AGENDA_SUCCESS = 'REMOVE_FROM_AGENDA_SUCCESS';
export const REMOVE_FROM_AGENDA_ERROR = 'REMOVE_FROM_AGENDA_ERROR';
export const REMOVE_APPOINTMENT_FROM_AGENDA = 'REMOVE_APPOINTMENT_FROM_AGENDA';
