import Nested from '@/components/nested/Nested.vue';

export function buildAuthenticatedRoutes(adminGuard) {
  return [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      meta: {
        title: 'menu.dashboard',
        requiresAuth: true,
        fullWidth: true,
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
      meta: {
        title: 'menu.profile',
        requiresAuth: true,
      },
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: () => import(/* webpackChunkName: "contacts" */ '@/views/Contacts.vue'),
      meta: {
        title: 'menu.contacts',
        requiresAuth: true,
      },
    },
    {
      path: '/public-profile/:userId',
      name: 'PublicProfile',
      component: () => import(/* webpackChunkName: "public-profile" */ '@/views/PublicProfile.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/transactions',
      name: 'Transactions',
      component: () => import(/* webpackChunkName: "transactions" */ '@/views/Transactions.vue'),
      meta: {
        title: 'menu.transactions',
        requiresAuth: true,
      },
    },
    {
      path: '/admin',
      component: Nested,
      beforeEnter: adminGuard,
      redirect: { name: 'EventListAdmin', replace: true, append: false },
      children: [
        {
          path: 'events',
          component: Nested,
          children: [
            {
              path: '',
              name: 'EventListAdmin',
              component: () =>
                import(/* webpackChunkName: "admin" */ '@/views/admin/EventListAdmin.vue'),
              meta: {
                title: 'menu.adminHome',
                requiresAuth: true,
                requiredPermissions: ['permission.events.canView'],
              },
            },
            {
              path: 'add-event',
              name: 'AddEventAdmin',
              component: () =>
                import(/* webpackChunkName: "admin" */ '@/views/admin/events/EventAdmin.vue'),
              meta: {
                title: 'menu.adminHome',
                requiresAuth: true,
                fullWidth: false,
                requiredPermissions: ['permission.events.canCreate'],
              },
            },
          ],
        },
        {
          path: 'roles',
          component: Nested,
          children: [
            {
              path: '',
              name: 'RolesAdmin',
              component: () =>
                import(/* webpackChunkName: "admin" */ '@/views/admin/roles/RolesAdmin.vue'),
              meta: {
                title: 'menu.roleAdmin',
                requiresAuth: true,
                requiredPermissions: ['permission.roles.canView'],
              },
            },
            {
              path: 'edit/:roleId',
              name: 'EditRoleAdmin',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/admin/roles/edit/EditRoleAdmin.vue'
                ),
              meta: {
                title: 'menu.roleAdmin',
                requiresAuth: true,
                fullWidth: false,
                requiredPermissions: ['permission.roles.canView'],
              },
            },
            {
              path: 'add',
              name: 'AddRoleAdmin',
              component: () =>
                import(
                  /* webpackChunkName: "admin" */ '@/views/admin/roles/edit/EditRoleAdmin.vue'
                ),
              meta: {
                title: 'menu.roleAdmin',
                requiresAuth: true,
                fullWidth: false,
                requiredPermissions: ['permission.roles.canCreate'],
              },
            },
          ],
        },
      ],
    },
  ];
}
