import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import { DISPLAY_MESSAGE, CLOSE_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { Message } from '@/models';

export interface MessageState {
  message: Message;
  opened: boolean;
}

const state: MessageState = {
  message: null,
  opened: false,
};

const actions = {
  async [DISPLAY_MESSAGE](
    { commit, dispatch }: ActionContext<MessageState, RootState>,
    message: Message,
  ) {
    commit(DISPLAY_MESSAGE, message);

    setTimeout(() => {
      dispatch(CLOSE_MESSAGE);
    }, 3000);
  },
  async [CLOSE_MESSAGE]({ commit }: ActionContext<MessageState, RootState>) {
    commit(CLOSE_MESSAGE);
  },
};

const mutations = {
  [DISPLAY_MESSAGE](state, message) {
    state.message = {
      ...message,
    };
    state.opened = true;
  },
  [CLOSE_MESSAGE](state) {
    state.opened = false;
  },
};

const getters = {
  message: (state) => state.message,
  opened: (state) => state.opened,
};

export const MessageModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
