import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { ConferenceRoom } from '@/models';
import {
  CLEAR_ACTIONS_SHOWN,
  CLEAR_SEARCH,
  SET_DISPLAY_MENU,
  SET_SEARCH,
  SET_SELECTED_CONFERENCE_ROOM,
  TOGGLE_NAV,
} from '../../actions/menu/menu.actions';

export interface MenuState {
  actionsShown: boolean;
  displayedItemsType: string;
  search: {
    conf: string;
    confRoom: string;
    exhibitor: string;
    assembly: string;
    discussionGroup: string;
  };
  selectedConferenceRoom: ConferenceRoom;
  displayMenu: boolean;
}

const state: MenuState = {
  actionsShown: true,
  displayedItemsType: null,
  search: {
    conf: '',
    confRoom: '',
    exhibitor: '',
    assembly: '',
    discussionGroup: '',
  },
  selectedConferenceRoom: null,
  displayMenu: false,
};

const actions = {
  [CLEAR_ACTIONS_SHOWN]({ commit }: ActionContext<MenuState, RootState>) {
    commit(CLEAR_ACTIONS_SHOWN);
  },
  [TOGGLE_NAV](
    { commit }: ActionContext<MenuState, RootState>,
    props: { doc: Document; type: string },
  ) {
    props.doc.querySelector('[data-scroll-id="nav"]')?.scrollIntoView();
    commit(TOGGLE_NAV, props.type);
  },
  [SET_SELECTED_CONFERENCE_ROOM]({ commit }: ActionContext<MenuState, RootState>, value: string) {
    commit(SET_SELECTED_CONFERENCE_ROOM, value);
  },
  [SET_SEARCH](
    { commit }: ActionContext<MenuState, RootState>,
    props: { type: string; search: string },
  ) {
    commit(SET_SEARCH, props);
  },
  [CLEAR_SEARCH]({ commit }: ActionContext<MenuState, RootState>) {
    commit(CLEAR_SEARCH);
  },
  [SET_DISPLAY_MENU]({ commit }: ActionContext<MenuState, RootState>, value) {
    commit(SET_DISPLAY_MENU, value);
  },
};

const mutations = {
  [CLEAR_ACTIONS_SHOWN](state) {
    state.actionsShown = true;
    state.displayedItemsType = null;
  },
  [CLEAR_SEARCH](state) {
    state.search = {
      conf: '',
      confRoom: '',
      exhibitor: '',
      assembly: '',
      discussionGroup: '',
    };
  },
  [SET_SEARCH](state, { type, search }: { type: string; search: string }) {
    state.search[type] = search;
  },
  [TOGGLE_NAV](state, type: string) {
    state.actionsShown = !type;
    if (type !== 'conferences') {
      state.selectedConferenceRoom = null;
    }

    state.search = {
      conf: '',
      confRoom: '',
      exhibitor: '',
      assembly: '',
      discussionGroup: '',
    };
    state.displayedItemsType = type;
  },
  [SET_SELECTED_CONFERENCE_ROOM](state, value) {
    state.selectedConferenceRoom = value;
  },
  [SET_DISPLAY_MENU](state, value) {
    state.displayMenu = value;
  },
};

const getters = {
  actionsShown: (state) => state.actionsShown,
  displayedItemsType: (state) => state.displayedItemsType,
  search: (state) => state.search,
  selectedConferenceRoom: (state) => state.selectedConferenceRoom,
  displayMenu: (state) => state.displayMenu,
};

export const MenuModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
