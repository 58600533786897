import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const CHATS_MODULE = `ChatsModule`;

export const APP_CHATS_MODULE = `${UMANIZE_APP_MODULE}/${CHATS_MODULE}`;

export const UPDATE_MAX_OPENED_CHATS = 'UPDATE_MAX_OPENED_CHATS';

export const DISPLAY_CHATS = 'DISPLAY_CHATS';
export const HIDE_CHATS = 'HIDE_CHATS';

export const UPDATE_SEARCH = 'UPDATE_SEARCH';

export const OPEN_CHAT = 'OPEN_CHAT';

export const CLOSE_CHAT = 'CLOSE_CHAT';

export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_ERROR = 'GET_CHATS_ERROR';

export const GET_EVENT_CHATS = 'GET_EVENT_CHATS';
export const GET_EVENT_CHATS_SUCCESS = 'GET_EVENT_CHATS_SUCCESS';

export const CLEAR_CHATS = 'CLEAR_CHATS';

export const CREATE_CHAT = 'CREATE_CHAT';
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
export const CREATE_CHAT_ERROR = 'CREATE_CHAT_ERROR';

export const GET_CHATS_MESSAGES = 'GET_CHATS_MESSAGES';

export const SEND_CHATS_MESSAGE = 'SEND_CHATS_MESSAGE';

export const CHATS_LOADMESSAGES_SUCCESS = 'CHATS_LOADMESSAGES_SUCCESS';
export const CHATS_NEW_MESSAGE = 'CHATS_NEW_MESSAGE';

export const MARK_AS_READ = 'MARK_AS_READ';

export const UPDATE_CHAT = 'UPDATE_CHAT';
export const UPDATE_CHAT_SUCCESS = 'UPDATE_SUCCESS_CHAT';
export const UPDATE_CHAT_ERROR = 'UPDATE_ERROR_CHAT';
