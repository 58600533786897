<template>
  <div class="question-zendesk">
    <a href="mailto:support@umanize.com"
      ><img src="~@assets/images/zendesk.png" title="Support"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'QuestionZendesk',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    lang: {},
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    chatHeightWindows() {
      return document.querySelector('[title="Messaging window"]')?.clientHeight;
    },
  },
  data() {
    return {
      iframe: null,
      isSetHeightChatSection: false,
      chatHeight: 0,
      isAdded: false,
    };
  },
  created() {
    window.addEventListener('resize', this.initImg);
    const chatWindows = document.querySelector('[title="Messaging window"]');
    if (chatWindows) {
      // if (this.isAdded) return;
      const ro = new ResizeObserver((entries) => {
        // eslint-disable-next-line no-restricted-syntax,@typescript-eslint/no-unused-vars
        for (const entry of entries) {
          if (!this.iframe) {
            this.iframe = document.querySelector('[title="Button to launch messaging window"]');
          }
          if (window.innerHeight < 671) {
            this.iframe.style.display = 'none';
            return;
          }
          if (chatWindows?.clientHeight < 100) {
            this.iframe.style.display = 'none';
          } else {
            const chatWindows = document.querySelector('[title="Messaging window"]');

            if (chatWindows?.parentNode?.offsetHeight < 500) {
              this.iframe.style.display = 'none';
            } else {
              this.iframe.style.display = 'unset';
            }
          }
        }
      });
      ro.observe(chatWindows);
      this.isAdded = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initImg);
  },
  methods: {
    toggleZendesk() {
      window.location.assign('mailto:support@umanize.com');
    },
  },
};
</script>
<style lang="scss" scoped>
.question-zendesk {
  margin-top: 5px;
  margin-right: 1rem;

  img {
    height: 28px;
    cursor: pointer;
  }
}
</style>
