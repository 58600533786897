import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
  GET_EXHIBITOR_ERROR,
  GET_EXHIBITOR_SUCCESS,
  GET_EXHIBITORS,
  GET_EXHIBITORS_ERROR,
  GET_EXHIBITORS_SUCCESS,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import exhibitorService from '@/services/exhibitor/exhibitor.service';
import { Exhibitor } from '@/models';

export interface ExhibitorsState {
  exhibitors: Exhibitor[];
  status: ExhibitorsStatus;
}

export interface ExhibitorsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface CurrentExhibitorState {
  exhibitor: Exhibitor;
  status: CurrentExhibitorStatus;
}

export interface CurrentExhibitorStatus {
  error: ErrorMessage;
  isSaving: boolean;
  isLoading: boolean;
  isSavingScreenshot: boolean;
}

const actions = {
  async getExhibitor(
    { commit }: ActionContext<CurrentExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitorId: string;
    },
  ) {
    commit(GET_EXHIBITOR);

    try {
      const exhibitor = await exhibitorService.getExhibitor(payload);
      commit(GET_EXHIBITOR_SUCCESS, exhibitor);
    } catch (error) {
      commit(GET_EXHIBITOR_ERROR, error);
    }
  },
  async getExhibitors(
    { commit }: ActionContext<ExhibitorsState, RootState>,
    payload: {
      eventId: string;
    },
  ) {
    commit(GET_EXHIBITORS);

    try {
      const exhibitors = await exhibitorService.getExhibitors(payload);
      commit(GET_EXHIBITORS_SUCCESS, exhibitors);
    } catch (error) {
      commit(GET_EXHIBITORS_ERROR, error);
    }
  },
  async clearExhibitor({ commit }: ActionContext<CurrentExhibitorState, RootState>) {
    commit(CLEAR_EXHIBITOR);
  },
};

export const AgnosticExhibitorModule = {
  namespaced: true,
  actions,
};
