export default {
  PATH: process.env.VUE_APP_API_PATH,
  ME: 'me',
  AUTH_ENDPOINT: 'users/authenticate',
  RESET_ENDPOINT: 'users/resetPassword',
  USER_ENDPOINT: 'users',
  PROFILES_ENDPOINT: 'profiles',
  EVENTS_ENDPOINT: 'events',
  UPLOADS_ENDPOINT: 'uploads',
  VIDEOS_ENDPOINT: 'videos',
  TICKETS_ENDPOINT: 'tickets',
  PROFILE_ENDPOINT: 'profile',
  PAYMENTS_ENDPOINT: 'payments',
  DISCUSSION_GROUPS: 'discussion-groups',
  CONTACTS_ENDPOINT: 'contacts',
  CONFERENCE_ROOMS_ENDPOINT: 'conference_rooms',
  GENERAL_ASSEMBLIES: 'general-assemblies',
  CONFIGS_ENDPOINT: 'configs',
  QUESTIONS: 'questions',
  RESULTS: 'results',
  QUESTIONS_RESULTS: 'questions-with-results',
  ANSWERS: 'answers',
  USER_ANSWERS: 'user-answers',
  SMART_MATCHING: 'smart-matching',
  ACTIVITY: 'recommended-activities',
  USER_SCHEDULE: 'user-schedule',
  EXHIBITOR_ENDPOINT: 'exhibitors',
  VISIT_ENDPOINT: 'visit',
  LEAVE_ENDPOINT: 'leave',
  VISITORS_ENDPOINT: 'visitors',
  IMAGES_ENDPOINT: 'images',
  CHATS_ENDPOINT: 'chats',
  SURVEY_ENDPOINT: 'surveys',
  CONFERENCES_ENDPOINT: 'conferences',
  PARTNERS_ENDPOINT: 'partners',
  SLOTS_ENDPOINT: 'slots',
  DURATION_ENDPOINT: 'duration',
  APPOINTMENT_ENDPOINT: 'appointments',
  PREFS_ENDPOINT: 'prefs',
  PERMISSIONS_ENDPOINT: 'permissions',
  ROLES_ENDPOINT: 'roles',
  STATUS_ENDPOINT: 'status',
  TAGS_ENDPOINT: 'tags',
  JWT_TOKEN: 'jwt_token',
  CUSTOM_UI: 'custom-ui',
  STREAM: 'streaming',
  TRAINING: 'trainings',
  TRAINING_NOTE: 'notes',
  TRAINING_PROGRESS: 'progress',
  TRAINING_SECTIONS: 'sections',
  TRAINING_LESSON: 'lessons',
  TRAINING_COMPLETE_LESSON: 'complete',
  TRAINING_SECTIONS_PUBLISH: 'publish',
  LINK_USER_TRAINING: 'users',
  CUSTOM_FIELDS: 'custom-fields',
  GENERATE_CERTIFICATE: 'generate-user-certificate',

  buildApiRoute(endpoint: string, segments = []) {
    return [this.PATH, endpoint, ...segments].join('/');
  },
};
