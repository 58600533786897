<template>
  <v-text-field
    :class="['search', { 'search--dark': dark }]"
    dense
    outlined
    hide-details
    prepend-inner-icon="mdi-magnify"
    single-line
    :color="color"
    :label="$t('globals.search')"
    :value="search"
    @input="onSearchChange"
    data-test-id="search"
  />
</template>

<script>
export default {
  name: 'AppSearch',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
    },
  },
  computed: {
    color() {
      return this.dark ? 'gray' : 'primary';
    },
  },
  methods: {
    onSearchChange(search) {
      this.$emit('change', search);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  border-radius: 12px;
}
</style>
