import { HttpMethod, SurveyAnswer, SurveyQuestion } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class SurveyService {
  public async getQuestions(
    eventId: string,
    conferenceId: string,
    withAnswers = true,
  ): Promise<SurveyQuestion[]> {
    const params: string[] = [
      eventId,
      HttpHelper.CONFERENCES_ENDPOINT,
      conferenceId,
      HttpHelper.SURVEY_ENDPOINT,
    ];

    if (withAnswers) {
      params.push(HttpHelper.RESULTS);
    }

    return HttpService.perform<SurveyQuestion[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, params),
    );
  }

  public async getQuestionById(
    eventId: string,
    conferenceId: string,
    questionId: string,
  ): Promise<SurveyQuestion> {
    return HttpService.perform<SurveyQuestion>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
      ]),
    );
  }

  public async answerQuestion(
    eventId: string,
    conferenceId: string,
    questionId: string,
    answer: string,
  ): Promise<SurveyAnswer> {
    return HttpService.perform<SurveyAnswer>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
        HttpHelper.ANSWERS,
      ]),
      { answer },
    );
  }

  public getAnswers(
    eventId: string,
    conferenceId: string,
    questionId: string,
  ): Promise<SurveyAnswer[]> {
    return HttpService.perform<SurveyAnswer[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
        HttpHelper.RESULTS,
      ]),
    );
  }

  public getUserAnswers(
    eventId: string,
    conferenceId: string,
    questionId: string,
  ): Promise<SurveyAnswer[]> {
    return HttpService.perform<SurveyAnswer[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
        HttpHelper.USER_ENDPOINT,
        HttpHelper.RESULTS,
      ]),
    );
  }

  public createQuestion(
    eventId: string,
    conferenceId: string,
    question: SurveyQuestion,
  ): Promise<SurveyQuestion> {
    return HttpService.perform<SurveyQuestion>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
      ]),
      question,
    );
  }

  public closeQuestion(
    eventId: string,
    conferenceId: string,
    questionId: string,
  ): Promise<SurveyQuestion> {
    return HttpService.perform<SurveyQuestion>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
        'close',
      ]),
    );
  }

  public startQuestion(
    eventId: string,
    conferenceId: string,
    questionId: string,
  ): Promise<SurveyQuestion> {
    return HttpService.perform<SurveyQuestion>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
        'start',
      ]),
    );
  }

  public deleteQuestion(eventId: string, conferenceId: string, questionId: string): Promise<void> {
    return HttpService.perform(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.CONFERENCES_ENDPOINT,
        conferenceId,
        HttpHelper.SURVEY_ENDPOINT,
        questionId,
      ]),
    );
  }
}

export default new SurveyService();
