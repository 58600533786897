import { ActionContext } from 'vuex';

import { Socket } from 'socket.io-client';
import { DateTime } from 'luxon';
import { RootState } from '@/stores/store.model';

import {
  AnswerForm,
  ChatMessage,
  GeneralAssembly,
  Question,
  QuestionForm,
  SocketChatMessage,
} from '@/models';
import GeneralAssemblyService from '@/services/general-assembly/general-assembly.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import DataUtil from '@/helpers/data/data.helper';

import {
  ASSEMBLY_ANSWER_QUESTION,
  connectToAssemblyNamespace,
  disconnectFromAssemblyNamespace,
} from '@/socket/assembly-namespace';
import {
  connectToAssemblyAdminNamespace,
  disconnectFromAssemblyAdminNamespace,
} from '@/socket/assembly-admin-namespace';
import { addSharedSocketEvents } from '@/stores/agnostic/modules/general-assembly/general-assembly.helper';
import { ChatsState } from '@/stores/umanize-app/modules/chats/chats.module';
import {
  AgnosticGeneralAssemblyModule,
  AssembliesState,
  AssembliesStatus,
  CurrentAssemblyState,
  CurrentAssemblyStatus,
} from '@/stores/agnostic/modules/general-assembly/agnostic-general-assembly.module';
import {
  ADD_QUESTION,
  ADD_QUESTION_ERROR,
  ADD_QUESTION_SUCCESS,
  ASSEMBLY_CHAT_LOAD_MESSAGES,
  ASSEMBLY_CHAT_LOAD_MESSAGES_ERROR,
  ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS,
  ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS,
  ASSEMBLY_SEND_CHATS_MESSAGE,
  ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS,
  CLOSE_QUESTION,
  CLOSE_QUESTION_ERROR,
  CLOSE_QUESTION_SUCCESS,
  CONNECT_TO_ASSEMBLY_ADMIN_SOCKET,
  CONNECT_TO_ASSEMBLY_SOCKET,
  DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET,
  DISCONNECT_FROM_ASSEMBLY_SOCKET,
  GET_QUESTION,
  GET_QUESTION_ERROR,
  GET_QUESTION_SUCCESS,
  GET_QUESTIONS,
  GET_QUESTIONS_ERROR,
  GET_QUESTIONS_RESULTS,
  GET_QUESTIONS_RESULTS_ERROR,
  GET_QUESTIONS_RESULTS_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  MODERATE_ASSEMBLY_CHAT_MESSAGE,
  QUESTION_ANSWER,
  QUESTION_ANSWER_ERROR,
  QUESTION_ANSWER_SUCCESS,
  START_QUESTION,
  START_QUESTION_ERROR,
  START_QUESTION_SUCCESS,
  UPDATE_QUESTION,
  UPDATE_QUESTION_CLOSE,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import {
  GET_ALL_ASSEMBLIES,
  GET_ALL_ASSEMBLIES_ERROR,
  GET_ALL_ASSEMBLIES_SUCCESS,
  GET_ASSEMBLY_BY_ID,
  GET_ASSEMBLY_BY_ID_ERROR,
  GET_ASSEMBLY_BY_ID_SUCCESS,
} from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import {
  SAVE_UPDATE_GENERAL_ASSEMBLY,
  SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR,
  SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS,
} from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';

export interface AssemblyMessagesStatus {
  areLoading: boolean;
  isModerating: boolean;
  error: ErrorMessage;
}

export interface AssemblyMessagesState {
  messages: Map<string, SocketChatMessage>;
  pagination: {
    limit: number;
    lastFetchedDate: string;
  };
  status: AssemblyMessagesStatus;
}

export interface QuestionsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface QuestionsState {
  questions: Question[];
  status: QuestionsStatus;
}

export interface QuestionStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface QuestionState {
  question: Question;
  status: QuestionStatus;
}

export interface QuestionResultsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface QuestionResultsState {
  questionResults: Question[];
  status: QuestionResultsStatus;
}

export interface AppGeneralAssemblyState {
  generalAssemblies: AssembliesState;
  generalAssembly: CurrentAssemblyState;
  generalAssemblyMessages: AssemblyMessagesState;
  questions: QuestionsState;
  question: QuestionState;
  questionResults: QuestionResultsState;
}

const assembliesState: AssembliesState = {
  generalAssemblies: [],
  status: {
    error: null,
    isSaving: false,
    areLoading: true,
  },
};

const assemblyState: CurrentAssemblyState = {
  generalAssembly: null,
  status: {
    error: null,
    isLoading: true,
  },
};

const assemblyMessagesState: AssemblyMessagesState = {
  messages: new Map(),
  pagination: {
    lastFetchedDate: DateTime.local().toISO(),
    limit: 10,
  },
  status: {
    error: null,
    isModerating: false,
    areLoading: true,
  },
};

const questionsState: QuestionsState = {
  questions: [],
  status: {
    areLoading: true,
    error: null,
  },
};

const questionState: QuestionState = {
  question: null,
  status: {
    isLoading: true,
    error: null,
  },
};

const questionResultsState: QuestionResultsState = {
  questionResults: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const state: AppGeneralAssemblyState = {
  generalAssembly: assemblyState,
  generalAssemblies: assembliesState,
  generalAssemblyMessages: assemblyMessagesState,
  questions: questionsState,
  question: questionState,
  questionResults: questionResultsState,
};

let assemblySocket: Socket;

const actions = {
  [GET_ASSEMBLY_BY_ID]: AgnosticGeneralAssemblyModule.actions.getAssembly,
  [GET_ALL_ASSEMBLIES]: AgnosticGeneralAssemblyModule.actions.getAssemblies,
  async [GET_QUESTIONS](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
    },
  ) {
    assemblySocket.emit('assembly-load-questions', payload.eventId, payload.assemblyId);
    commit(GET_QUESTIONS);
  },
  async [QUESTION_ANSWER](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      questionId: string;
      answerForm: AnswerForm;
    },
  ) {
    assemblySocket.emit(
      'assembly-answer-question',
      payload.eventId,
      payload.assemblyId,
      payload.questionId,
      payload.answerForm,
    );
    commit(QUESTION_ANSWER);
  },
  async [ADD_QUESTION](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      questionForm: QuestionForm;
    },
  ) {
    assemblySocket.emit(
      'assembly-add-question',
      payload.eventId,
      payload.assemblyId,
      payload.questionForm,
    );
    commit(ADD_QUESTION);
  },
  async [CLOSE_QUESTION](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      questionId: string;
    },
  ) {
    assemblySocket.emit(
      'assembly-close-question',
      payload.eventId,
      payload.assemblyId,
      payload.questionId,
    );
    commit(CLOSE_QUESTION);
  },
  async [START_QUESTION](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      questionId: string;
    },
  ) {
    assemblySocket.emit(
      'assembly-start-question',
      payload.eventId,
      payload.assemblyId,
      payload.questionId,
    );
    commit(START_QUESTION);
  },
  async [GET_QUESTION](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      questionId: string;
    },
  ) {
    assemblySocket.emit(
      'assembly-get-question',
      payload.eventId,
      payload.assemblyId,
      payload.questionId,
    );
    commit(GET_QUESTION);
  },
  [UPDATE_QUESTION]({ commit }: ActionContext<AppGeneralAssemblyState, RootState>, payload) {
    commit(UPDATE_QUESTION, payload.question);
  },
  [UPDATE_QUESTION_CLOSE]({ commit }: ActionContext<AppGeneralAssemblyState, RootState>) {
    commit(UPDATE_QUESTION_CLOSE);
  },
  async [GET_QUESTIONS_RESULTS](
    { commit }: ActionContext<AppGeneralAssemblyState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
    },
  ) {
    commit(GET_QUESTIONS_RESULTS);

    try {
      const questionsResults = await GeneralAssemblyService.getQuestionsAndResults(
        payload.eventId,
        payload.assemblyId,
      );
      const sortedQuestions = DataUtil.sortByStringDesc(questionsResults, 'created');
      commit(GET_QUESTIONS_RESULTS_SUCCESS, sortedQuestions);
    } catch (error) {
      commit(GET_QUESTIONS_RESULTS_ERROR, error);
    }
  },
  [CONNECT_TO_ASSEMBLY_SOCKET](
    context: ActionContext<AppGeneralAssemblyState, RootState>,
    { assemblyId }: { assemblyId: string },
  ) {
    assemblySocket = connectToAssemblyNamespace(assemblyId);

    addSharedSocketEvents(assemblySocket, context);

    assemblySocket.on(
      ASSEMBLY_ANSWER_QUESTION,
      async (payload: { eventId: string; assemblyId: string; questionId: string }) => {
        await context.dispatch(GET_QUESTION, payload);
      },
    );
  },
  [DISCONNECT_FROM_ASSEMBLY_SOCKET]() {
    disconnectFromAssemblyNamespace(assemblySocket);
  },
  [CONNECT_TO_ASSEMBLY_ADMIN_SOCKET](
    context: ActionContext<AppGeneralAssemblyState, RootState>,
    { assemblyId }: { assemblyId: string },
  ) {
    assemblySocket = connectToAssemblyAdminNamespace(assemblyId);

    addSharedSocketEvents(assemblySocket, context);
  },
  [DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET]() {
    disconnectFromAssemblyAdminNamespace(assemblySocket);
  },
  [ASSEMBLY_CHAT_LOAD_MESSAGES](
    { commit }: ActionContext<ChatsState, RootState>,
    payload: { eventId: string; assemblyId: string },
  ) {
    assemblySocket.emit(
      'assembly-load-chat-messages',
      payload.eventId,
      payload.assemblyId,
      state.generalAssemblyMessages.pagination.lastFetchedDate,
      state.generalAssemblyMessages.pagination.limit,
    );
    commit(ASSEMBLY_CHAT_LOAD_MESSAGES);
  },
  async [ASSEMBLY_SEND_CHATS_MESSAGE](
    _: ActionContext<ChatsState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      message: string;
    },
  ) {
    assemblySocket.emit(
      'assembly-create-message',
      payload.eventId,
      payload.assemblyId,
      payload.message,
    );
  },
  [MODERATE_ASSEMBLY_CHAT_MESSAGE](
    { commit }: ActionContext<ChatsState, RootState>,
    payload: {
      eventId: string;
      assemblyId: string;
      message: ChatMessage;
    },
  ) {
    assemblySocket.emit(
      'assembly-moderate-message',
      payload.eventId,
      payload.assemblyId,
      payload.message.id,
      payload.message.isModerated,
    );
    commit(MODERATE_ASSEMBLY_CHAT_MESSAGE);
  },
};

const mutations = {
  [GET_ALL_ASSEMBLIES](state) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_ALL_ASSEMBLIES_SUCCESS](state, generalAssemblies) {
    state.generalAssemblies = {
      status: {
        ...state.status,
        areLoading: false,
        error: null,
      },
      generalAssemblies: [...generalAssemblies],
    };
  },
  [GET_ALL_ASSEMBLIES_ERROR](state, error) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      areLoading: false,
      error,
    };
  },
  [GET_ASSEMBLY_BY_ID](state) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_ASSEMBLY_BY_ID_SUCCESS](state, generalAssembly) {
    state.generalAssembly = {
      status: {
        ...state.generalAssembly.status,
        isLoading: false,
        error: null,
      },
      generalAssembly: {
        ...generalAssembly,
      },
    };
  },
  [GET_ASSEMBLY_BY_ID_ERROR](state, error) {
    state.generalAssembly.status = {
      ...state.generalAssembly.status,
      isLoading: false,
      error,
    };
  },
  [GET_QUESTIONS](state) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_QUESTIONS_SUCCESS](state, questions) {
    state.questions = {
      status: {
        ...state.status,
        areLoading: false,
        error: null,
      },
      questions: [...(questions || [])],
    };
  },
  [GET_QUESTIONS_ERROR](state, error) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: false,
      error,
    };
  },
  [QUESTION_ANSWER](state) {
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
  },
  [QUESTION_ANSWER_SUCCESS](state) {
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
  },
  [QUESTION_ANSWER_ERROR](state, error) {
    state.questions.status = {
      ...state.questions.status,
      error,
    };
  },
  [ADD_QUESTION](state) {
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
  },
  [ADD_QUESTION_SUCCESS](state, question) {
    state.questions = {
      status: {
        ...state.questions.status,
        error: null,
      },
      questions: [...state.questions.questions, question],
    };
  },
  [ADD_QUESTION_ERROR](state, error) {
    state.questions.status = {
      ...state.questions.status,
      error,
    };
  },
  [CLOSE_QUESTION](state) {
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
  },
  [CLOSE_QUESTION_SUCCESS](state, question) {
    state.question.question = null;
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
    state.questionResults.questionResults = [...state.questionResults.questionResults, question];
  },
  [CLOSE_QUESTION_ERROR](state, error) {
    state.questions.status = {
      ...state.questions.status,
      error,
    };
  },
  [START_QUESTION](state) {
    state.questions.status = {
      ...state.questions.status,
      error: null,
    };
  },
  [START_QUESTION_SUCCESS](state, startedQuestion) {
    state.questions = {
      status: {
        ...state.questions.status,
        error: null,
      },
      questions: [
        ...state.questions.questions.filter(
          (question: Question) => question.id !== startedQuestion.id,
        ),
      ],
    };
    state.question = {
      question: startedQuestion,
    };
  },
  [START_QUESTION_ERROR](state, error) {
    state.questions.status = {
      ...state.questions.status,
      error,
    };
  },
  [GET_QUESTION](state) {
    state.question.status = {
      ...state.question.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_QUESTION_SUCCESS](state, question) {
    state.question = {
      status: {
        ...state.status,
        isLoading: false,
        error: null,
      },
      question: {
        ...question,
      },
    };
  },
  [GET_QUESTION_ERROR](state, error) {
    state.question.status = {
      ...state.question.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_QUESTION](state, question) {
    state.question.question = {
      ...question,
    };
  },
  [UPDATE_QUESTION_CLOSE](state) {
    state.question.question = {
      ...state.question.question,
      closedAt: new Date().toISOString(),
    };
  },
  [GET_QUESTIONS_RESULTS](state) {
    state.questionResults.status = {
      ...state.questionResults.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_QUESTIONS_RESULTS_SUCCESS](state, questionsResults) {
    state.questionResults = {
      status: {
        ...state.status,
        areLoading: false,
        error: null,
      },
      questionResults: [...questionsResults],
    };
  },
  [GET_QUESTIONS_RESULTS_ERROR](state, error) {
    state.questionResults.status = {
      ...state.questionResults.status,
      areLoading: false,
      error,
    };
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY](state: AppGeneralAssemblyState) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      isSaving: true,
    };
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS](state: AppGeneralAssemblyState) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      isSaving: false,
    };
  },
  [SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR](state: AppGeneralAssemblyState, error) {
    state.generalAssemblies.status = {
      ...state.generalAssemblies.status,
      isSaving: false,
      error,
    };
  },
  [ASSEMBLY_CHAT_LOAD_MESSAGES](state: AppGeneralAssemblyState, error) {
    state.generalAssemblyMessages.status = {
      ...state.generalAssemblyMessages.status,
      areLoading: true,
      error,
    };
  },
  [ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS](state: AppGeneralAssemblyState, messages) {
    state.generalAssemblyMessages.pagination = {
      ...state.generalAssemblyMessages.pagination,
      lastFetchedDate:
        (messages.length && messages[messages.length - 1].created) ||
        state.generalAssemblyMessages.pagination.lastFetchedDate,
    };

    messages.forEach((message) => state.generalAssemblyMessages.messages.set(message.id, message));
    state.generalAssemblyMessages.messages = new Map(state.generalAssemblyMessages.messages);

    state.generalAssemblyMessages.status = {
      ...state.generalAssemblyMessages.status,
      areLoading: false,
    };
  },
  [ASSEMBLY_CHAT_LOAD_MESSAGES_ERROR](state: AppGeneralAssemblyState, error) {
    state.generalAssemblyMessages.status = {
      ...state.generalAssemblyMessages.status,
      error,
    };
  },
  [ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS](state: AppGeneralAssemblyState, message) {
    state.generalAssemblyMessages.messages.set(message.id, message);
  },
  [MODERATE_ASSEMBLY_CHAT_MESSAGE](state: AppGeneralAssemblyState) {
    state.generalAssemblyMessages.status = {
      ...state.generalAssemblyMessages.status,
      isModerating: true,
    };
  },
  [ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS](state: AppGeneralAssemblyState, message) {
    state.generalAssemblyMessages.messages.set(message.id, message);
  },
};

const getters = {
  assembliesState: (state: AppGeneralAssemblyState): AssembliesState => state.generalAssemblies,
  generalAssemblies: (
    _,
    { assembliesState }: { assembliesState: AssembliesState },
  ): GeneralAssembly[] => assembliesState?.generalAssemblies || [],
  accessibleGeneralAssemblies: (
    _,
    { generalAssemblies }: { generalAssemblies: GeneralAssembly[] },
  ): GeneralAssembly[] =>
    generalAssemblies.filter(
      (generalAssembly) => generalAssembly.userAccess && generalAssembly.userAccess.canAccess,
    ),
  assembliesStatus: (
    _,
    { assembliesState }: { assembliesState: AssembliesState },
  ): AssembliesStatus => assembliesState?.status || null,
  assembliesAreLoading: (
    _,
    { assembliesStatus }: { assembliesStatus: AssembliesStatus },
  ): boolean => assembliesStatus?.areLoading || false,
  assembliesError: (
    _,
    { assembliesStatus }: { assembliesStatus: AssembliesStatus },
  ): ErrorMessage => assembliesStatus?.error || null,

  assemblyState: (state: AppGeneralAssemblyState): CurrentAssemblyState => state.generalAssembly,
  generalAssembly: (
    _,
    { assemblyState }: { assemblyState: CurrentAssemblyState },
  ): GeneralAssembly => assemblyState?.generalAssembly || null,
  assemblyStatus: (
    _,
    { assemblyState }: { assemblyState: CurrentAssemblyState },
  ): CurrentAssemblyStatus => assemblyState?.status || null,
  assemblyIsLoading: (_, { assemblyStatus }: { assemblyStatus: CurrentAssemblyStatus }): boolean =>
    assemblyStatus?.isLoading || false,
  assemblyError: (_, { assemblyStatus }: { assemblyStatus: CurrentAssemblyStatus }): ErrorMessage =>
    assemblyStatus?.error || null,

  questionsState: (state: AppGeneralAssemblyState): QuestionsState => state.questions,
  questions: (_, { questionsState }: { questionsState: QuestionsState }): Question[] =>
    questionsState?.questions || [],
  questionsStatus: (_, { questionsState }: { questionsState: QuestionsState }): QuestionsStatus =>
    questionsState?.status || null,
  questionsAreLoading: (_, { questionsStatus }: { questionsStatus: QuestionsStatus }): boolean =>
    questionsStatus?.areLoading || false,
  questionsError: (_, { questionsStatus }: { questionsStatus: QuestionsStatus }): ErrorMessage =>
    questionsStatus?.error || null,

  questionState: (state: AppGeneralAssemblyState): QuestionState => state.question,
  question: (_, { questionState }: { questionState: QuestionState }): Question =>
    questionState?.question || null,
  questionStatus: (_, { questionState }: { questionState: QuestionState }): QuestionStatus =>
    questionState?.status || null,
  questionIsLoading: (_, { questionStatus }: { questionStatus: QuestionStatus }): boolean =>
    questionStatus?.isLoading || false,
  questionError: (_, { questionStatus }: { questionStatus: QuestionStatus }): ErrorMessage =>
    questionStatus?.error || null,

  questionResultsState: (state: AppGeneralAssemblyState): QuestionResultsState =>
    state.questionResults,
  questionResults: (
    _,
    { questionResultsState }: { questionResultsState: QuestionResultsState },
  ): Question[] => questionResultsState?.questionResults || [],
  questionResultsStatus: (
    _,
    { questionResultsState }: { questionResultsState: QuestionResultsState },
  ): QuestionResultsStatus => questionResultsState?.status || null,
  questionResultsAreLoading: (
    _,
    { questionResultsStatus }: { questionResultsStatus: QuestionResultsStatus },
  ): boolean => questionResultsStatus?.areLoading || false,
  questionResultsError: (
    _,
    { questionResultsStatus }: { questionResultsStatus: QuestionResultsStatus },
  ): ErrorMessage => questionResultsStatus?.error || null,

  assemblyMessagesState: (state: AppGeneralAssemblyState): AssemblyMessagesState =>
    state.generalAssemblyMessages,
  messages: (
    _,
    { assemblyMessagesState }: { assemblyMessagesState: AssemblyMessagesState },
  ): SocketChatMessage[] =>
    DataUtil.sortByStringDesc(
      Array.from(assemblyMessagesState?.messages.values() || []),
      'created',
    ),
  moderatedMessages: (_, { messages }: { messages: SocketChatMessage[] }): SocketChatMessage[] =>
    messages?.filter((item) => !item.isModerated) || [],
  assemblyMessagesStatus: (
    _,
    { assemblyMessagesState }: { assemblyMessagesState: AssemblyMessagesState },
  ): AssemblyMessagesStatus => assemblyMessagesState?.status || null,
  assemblyMessagesAreLoading: (
    _,
    { assemblyMessagesStatus }: { assemblyMessagesStatus: AssemblyMessagesStatus },
  ): boolean => assemblyMessagesStatus?.areLoading || false,
  assemblyMessagesError: (
    _,
    { assemblyMessagesStatus }: { assemblyMessagesStatus: AssemblyMessagesStatus },
  ): ErrorMessage => assemblyMessagesStatus?.error || null,
};

export const AppGeneralAssemblyModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
