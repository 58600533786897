import { SHARED_MODULE } from '@/stores/shared/shared.module.config';

export const AUTH_MODULE = 'AuthModule';
export const SHARED_AUTH_MODULE = `${SHARED_MODULE}/${AUTH_MODULE}`;

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SESSION_VALIDITY = 'SESSION_VALIDITY';

export const SESSION_VALIDITY_SUCCESS = 'SESSION_VALIDITY_SUCCESS';
export const SESSION_VALIDITY_ERROR = 'SESSION_VALIDITY_ERROR';
export const LOGIN_WITH_SESSION_USER = 'LOGIN_WITH_SESSION_USER';

export const DISCONNECT = 'DISCONNECT';

export const DISCONNECT_SUCCESS = 'DISCONNECT_SUCCESS';

export const CONNECT_TO_CHAT = 'CONNECT_TO_CHAT';
export const DISCONNECT_FROM_CHAT = 'DISCONNECT_FROM_CHAT';

export const CONNECT_TO_STAT = 'CONNECT_TO_STAT';
export const DISCONNECT_FROM_STAT = 'DISCONNECT_FROM_STAT';

export const CONNECT_TO_NOTIFICATION = 'CONNECT_TO_NOTIFICATION';
export const DISCONNECT_FROM_NOTIFICATION = 'DISCONNECT_FROM_NOTIFICATION';

export const AUTH_MODULE_LOGIN = `${SHARED_AUTH_MODULE}/${LOGIN}`;
export const AUTH_MODULE_REGISTER = `${SHARED_AUTH_MODULE}/${REGISTER}`;
export const AUTH_MODULE_SESSION_VALIDITY = `${SHARED_AUTH_MODULE}/${SESSION_VALIDITY}`;
export const AUTH_MODULE_DISCONNECT = `${SHARED_AUTH_MODULE}/${DISCONNECT}`;
