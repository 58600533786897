import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { CUSTOM_UI_MODULE } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';

export const ADMIN_CUSTOM_UI_MODULE = `${UMANIZE_ADMIN_MODULE}/${CUSTOM_UI_MODULE}`;

export const SAVE_CUSTOM_UI = 'SAVE_CUSTOM_UI';

export const ADD_CUSTOM_UI = 'ADD_CUSTOM_UI';
export const ADD_CUSTOM_UI_SUCCESS = 'ADD_CUSTOM_UI_SUCCESS';
export const ADD_CUSTOM_UI_ERROR = 'ADD_CUSTOM_UI_ERROR';

export const UPDATE_CUSTOM_UI = 'UPDATE_CUSTOM_UI';
export const UPDATE_CUSTOM_UI_SUCCESS = 'UPDATE_CUSTOM_UI_SUCCESS';
export const UPDATE_CUSTOM_UI_ERROR = 'UPDATE_CUSTOM_UI_ERROR';

export const CLEAR_CUSTOM_UI = 'CLEAR_CUSTOM_UI';
