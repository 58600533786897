import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { Config } from '@/models/config/config.model';

class ConfigService {
  public async getConfig(payload: { name: string }): Promise<Config> {
    return HttpService.perform<Config>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.CONFIGS_ENDPOINT, [payload.name]),
    );
  }
}

export default new ConfigService();
