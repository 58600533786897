import {
  ADD_TRAINING,
  ADD_TRAINING_ERROR,
  ADD_TRAINING_SUCCESS,
  CLEAR_ALL_TRAINING,
  CLEAR_TRAINING,
  DELETE_TRAINING,
  DELETE_TRAINING_ERROR,
  DELETE_TRAINING_SUCCESS,
  GET_ALL_TRAININGS_ADMIN,
  GET_ALL_TRAININGS_ADMIN_PROGRESS,
  GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR,
  GET_ALL_TRAININGS_ERROR,
  GET_ALL_TRAININGS_SUCCESS,
  GET_TRAINING,
  GET_TRAINING_ERROR,
  GET_TRAINING_SUCCESS,
  GET_USERS_LINKED_TO_TRAINING,
  GET_USERS_LINKED_TO_TRAINING_ERROR,
  GET_USERS_LINKED_TO_TRAINING_SUCCESS,
  LINK_USER_TO_TRAINING,
  LINK_USER_TO_TRAINING_ERROR,
  LINK_USER_TO_TRAINING_SUCCESS,
  SET_TRAINING_TRAINER_LIST_ID,
  SET_TRAINING_USER_CUSTOM_FIELDS,
  UPDATE_TRAINING,
  UPDATE_TRAINING_ERROR,
  UPDATE_TRAINING_SUCCESS,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import trainingService from '@/services/training/training.service';
import { AdminTraining } from '@/models/training/admin/admin-training.model';
import { TrainingProgressModel } from '@/models/training/admin/training-progress.model';
import { UserLinkTrainingModel } from '@/models/training/admin/user-link-training.model';
import { CustomField } from '@/models/training/admin/training-custom-field';

export interface TrainingsAdminStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface AllTrainingsAdminStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface AllTrainingAdminState {
  status: AllTrainingsAdminStatus;
  trainings: AdminTraining[];
}

export interface TrainingProgressState {
  status: AllTrainingsAdminStatus;
  trainingProgress: TrainingProgressModel;
}

export interface LinkedUserTrainingState {
  status: TrainingsAdminStatus;
  linkedUserTraining: UserLinkTrainingModel[];
}

export interface CurTrainingAdminState {
  status: TrainingsAdminStatus;
  training: AdminTraining;
}

export interface TrainingAdminState {
  training: CurTrainingAdminState;
  allTrainings: AllTrainingAdminState;
  trainingProgress: TrainingProgressState;
  linkedUserTraining: LinkedUserTrainingState;
}

export const trainingAdminState: CurTrainingAdminState = {
  training: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const allTrainingsAdminState: AllTrainingAdminState = {
  trainings: [],
  status: {
    error: null,
    areLoading: true,
  },
};

export const trainingProgress: TrainingProgressState = {
  trainingProgress: null,
  status: {
    error: null,
    areLoading: true,
  },
};

export const linkedUserTraining: LinkedUserTrainingState = {
  linkedUserTraining: [],
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const state: TrainingAdminState = {
  training: trainingAdminState,
  allTrainings: allTrainingsAdminState,
  trainingProgress,
  linkedUserTraining,
};
const actions = {
  async [GET_ALL_TRAININGS_ADMIN](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId }: { eventId: string },
  ) {
    commit(GET_ALL_TRAININGS_ADMIN);
    try {
      const data = await trainingService.getAll(eventId);
      commit(GET_ALL_TRAININGS_SUCCESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_ERROR, error);
    }
  },
  async [GET_ALL_TRAININGS_ADMIN_PROGRESS](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, trainingId }: { eventId: string; trainingId: string },
  ) {
    commit(GET_ALL_TRAININGS_ADMIN_PROGRESS);
    try {
      const data = await trainingService.getUserProcess(eventId, trainingId);
      commit(GET_ALL_TRAININGS_ADMIN_PROGRESS, data);
    } catch (error) {
      commit(GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR, error);
    }
  },
  async [ADD_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, training }: { eventId: string; training: AdminTraining },
  ) {
    commit(ADD_TRAINING);
    try {
      await trainingService.create(eventId, training);
      commit(ADD_TRAINING_SUCCESS);
    } catch (e) {
      commit(ADD_TRAINING_ERROR, e);
    }
  },
  async [UPDATE_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, training }: { eventId: string; training: AdminTraining },
  ) {
    commit(UPDATE_TRAINING);
    try {
      await trainingService.update(eventId, training);
      commit(UPDATE_TRAINING_SUCCESS);
    } catch (e) {
      commit(UPDATE_TRAINING_ERROR, e);
    }
  },
  async [GET_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, trainingId }: { eventId: string; trainingId: string },
  ) {
    commit(GET_TRAINING);
    try {
      const data = await trainingService.get(eventId, trainingId);
      commit(GET_TRAINING_SUCCESS, data);
    } catch (e) {
      commit(GET_TRAINING_ERROR, e);
    }
  },
  async [DELETE_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, trainingId }: { eventId: string; trainingId: string },
  ) {
    commit(DELETE_TRAINING);
    try {
      await trainingService.delete(eventId, trainingId);
      commit(DELETE_TRAINING_SUCCESS);
    } catch (e) {
      commit(DELETE_TRAINING_ERROR, e);
    }
  },
  [SET_TRAINING_TRAINER_LIST_ID](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    listId: [],
  ) {
    commit(SET_TRAINING_TRAINER_LIST_ID, listId);
  },
  [CLEAR_TRAINING]({ commit }: ActionContext<TrainingAdminState, RootState>) {
    commit(CLEAR_TRAINING);
  },
  [CLEAR_ALL_TRAINING]({ commit }: ActionContext<TrainingAdminState, RootState>) {
    commit(CLEAR_ALL_TRAINING);
  },
  async [GET_USERS_LINKED_TO_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    { eventId, idTraining }: { eventId: string; idTraining: string },
  ) {
    commit(GET_USERS_LINKED_TO_TRAINING);
    try {
      const data = await trainingService.getListLinkedUserToTraining(eventId, idTraining);
      commit(GET_USERS_LINKED_TO_TRAINING_SUCCESS, data);
    } catch (error) {
      commit(GET_USERS_LINKED_TO_TRAINING_ERROR, error);
    }
  },
  async [LINK_USER_TO_TRAINING](
    { commit }: ActionContext<TrainingAdminState, RootState>,
    {
      eventId,
      idTraining,
      userLinkTraining,
    }: { eventId: string; idTraining: string; userLinkTraining: UserLinkTrainingModel[] },
  ) {
    commit(LINK_USER_TO_TRAINING);
    try {
      const promises = userLinkTraining.map((ele) =>
        trainingService.linkUserToTraining(eventId, idTraining, ele),
      );
      await Promise.all(promises).then((response) => response);
      commit(LINK_USER_TO_TRAINING_SUCCESS);
    } catch (e) {
      commit(LINK_USER_TO_TRAINING_ERROR, e);
    }
  },
  [SET_TRAINING_USER_CUSTOM_FIELDS]({ commit }, data) {
    commit(SET_TRAINING_USER_CUSTOM_FIELDS, data);
  },
};

const mutations = {
  [GET_ALL_TRAININGS_ADMIN](state: TrainingAdminState) {
    state.allTrainings = {
      ...state.allTrainings,
      status: {
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_ALL_TRAININGS_SUCCESS](state: TrainingAdminState, trainingList: AdminTraining[]) {
    state.allTrainings.status.areLoading = false;
    state.allTrainings.status.error = null;
    state.allTrainings = {
      ...state.allTrainings,
      trainings: trainingList,
    };
  },
  [GET_ALL_TRAININGS_ADMIN_PROGRESS](
    state: TrainingAdminState,
    trainingList: TrainingProgressModel,
  ) {
    state.trainingProgress.status.areLoading = false;
    state.trainingProgress.status.error = null;
    state.trainingProgress = {
      ...state.allTrainings,
      trainingProgress: trainingList,
    };
  },
  [GET_ALL_TRAININGS_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.allTrainings = {
      ...state.allTrainings,
      status: {
        areLoading: false,
        error,
      },
    };
  },
  [GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.trainingProgress = {
      ...state.trainingProgress,
      status: {
        areLoading: false,
        error,
      },
    };
  },
  [SET_TRAINING_TRAINER_LIST_ID](state: TrainingAdminState, data: []) {
    state.training.training = {
      ...state.training.training,
      trainerIds: data,
    };
  },
  [ADD_TRAINING](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_TRAINING_SUCCESS](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error: null,
    };
  },
  [ADD_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_TRAINING](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_TRAINING_SUCCESS](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error: null,
    };
  },
  [UPDATE_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error,
    };
  },
  [GET_TRAINING](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_TRAINING_SUCCESS](state: TrainingAdminState, training: AdminTraining) {
    state.training = {
      ...state.training,
      training,
      status: {
        isLoading: false,
        isSaving: false,
        error: null,
      },
    };
  },
  [GET_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error,
    };
  },
  [DELETE_TRAINING](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_TRAINING_SUCCESS](state: TrainingAdminState) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error: null,
    };
  },
  [DELETE_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.training.status = {
      ...state.training.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_ALL_TRAINING](state: TrainingAdminState) {
    state.allTrainings = {
      ...state.allTrainings,
      trainings: [],
    };
  },
  [GET_USERS_LINKED_TO_TRAINING](state: TrainingAdminState) {
    state.linkedUserTraining = {
      ...state.linkedUserTraining,
      status: {
        ...state.linkedUserTraining.status,
        isLoading: true,
        error: null,
      },
    };
  },
  [GET_USERS_LINKED_TO_TRAINING_SUCCESS](
    state: TrainingAdminState,
    linkedUserTraining: UserLinkTrainingModel[],
  ) {
    state.linkedUserTraining.status.isLoading = false;
    state.linkedUserTraining.status.error = null;
    state.linkedUserTraining = {
      ...state.linkedUserTraining,
      linkedUserTraining,
    };
  },
  [GET_USERS_LINKED_TO_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.linkedUserTraining = {
      ...state.linkedUserTraining,
      status: {
        ...state.linkedUserTraining.status,
        isLoading: false,
        error,
      },
    };
  },
  [LINK_USER_TO_TRAINING](state: TrainingAdminState) {
    state.linkedUserTraining.status = {
      ...state.linkedUserTraining.status,
      isSaving: true,
      error: null,
    };
  },
  [LINK_USER_TO_TRAINING_SUCCESS](state: TrainingAdminState) {
    state.linkedUserTraining.status = {
      ...state.linkedUserTraining.status,
      isLoading: false,
      error: null,
    };
  },
  [LINK_USER_TO_TRAINING_ERROR](state: TrainingAdminState, error: ErrorMessage) {
    state.linkedUserTraining.status = {
      ...state.linkedUserTraining.status,
      isLoading: false,
      error,
    };
  },
  [SET_TRAINING_USER_CUSTOM_FIELDS](state: TrainingAdminState, customFields: CustomField[]) {
    state.trainingProgress.trainingProgress.certification.customFields = customFields;
  },
};

const getters = {
  allTrainingState: (state: TrainingAdminState) => state.allTrainings || [],
  trainingStateAreLoading: (state: TrainingAdminState) =>
    state.allTrainings.status.areLoading || false,
  allTrainingData: (state: TrainingAdminState) => state.allTrainings.trainings || [],
  trainingSate: (state: TrainingAdminState) => state.training || {},
  trainingStateLoading: (state: TrainingAdminState) => state.training.status.isLoading,
  trainingData: (state: TrainingAdminState) => state.training.training || {},
  trainingProgress: (state: TrainingAdminState) => state.trainingProgress.trainingProgress || [],
  trainingProgressLoading: (state: TrainingAdminState) =>
    state.trainingProgress.status.areLoading || false,
  getUsersLinkedToTraining: (state: TrainingAdminState) =>
    state.linkedUserTraining.linkedUserTraining || [],
};

export const AdminTrainingModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
