<template>
  <div class="drawer__header custom-buttons" v-if="isLoggedIn && menu.length > 0">
    <template v-for="item in menu">
      <div v-if="item.displayed" :key="item.name" class="header__item button__wrapper">
        <v-btn
          class="custom-buttons__btn"
          block
          :x-large="isCustomUi"
          :tile="!isCustomUi"
          :depressed="!isCustomUi"
          :color="accentColor"
          :style="btnTextColor"
          :to="item.route"
          exact
        >
          {{ $t(getTranslationString(item)) }}
        </v-btn>
      </div>
    </template>

    <div
      v-if="!trainingStateAreLoading && allTrainingData.length"
      key="training-menu"
      class="button__wrapper"
    >
      <v-btn
        class="custom-buttons__btn"
        block
        :x-large="isCustomUi"
        :tile="!isCustomUi"
        :depressed="!isCustomUi"
        :color="accentColor"
        :style="btnTextColor"
        :to="getRoute('trainings')"
        exact
      >
        {{ $t('admin.menu.Training') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { TRAINING_ADMIN_MODULE } from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

export default {
  name: 'MenuButtons',
  components: {},
  props: {
    isLoggedIn: {
      type: Boolean,
    },
    menu: {
      type: Array,
    },
    isCustomUi: {
      type: Boolean,
      default: false,
    },
    colorText: {
      type: String,
    },
    btnColor: {
      type: String,
    },
  },
  methods: {
    getTranslationString(menuItem) {
      return `menu.${menuItem.name}`;
    },
    getRoute(suffix) {
      return `/events/${this.$route.params.eventId}/${suffix}`;
    },
  },
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingStateAreLoading', 'allTrainingData']),
    accentColor() {
      const defaultValue = this.btnColor ?? '#00B5A8';
      return this.isCustomUi ? this.customUi?.btnColor || defaultValue : defaultValue;
    },
    btnTextColor() {
      const defaultTextColor = this.colorText ?? '#FFFFFF';
      const customUiColor = this.customUi?.btnTextColor || defaultTextColor;
      return `color: ${this.isCustomUi ? customUiColor : customUiColor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.custom-buttons__btn {
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
