export default abstract class WindowUtil {
  public static giveFocusBackToShowroomIfExists(id) {
    const canvas = document.getElementById(id);

    if (canvas) {
      canvas.setAttribute('tabindex', '1');
      canvas.focus();
    }
  }
}
