import {
  RegisterForm,
  LoginForm,
  User,
  HttpMethod,
  ResetForm,
  UploadUserFile,
  UserRole,
  UserTicketProfile,
} from '@/models';

import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';

class UserService {
  public async register(registerForm: RegisterForm): Promise<LoginForm> {
    await httpService.perform<User>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT),
      {
        ...registerForm,
      },
      true,
    );

    return UserService.extractLoginFormDataFromRegisterFormData(registerForm);
  }

  public async getUserById(id: string): Promise<User> {
    return httpService.perform<User>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [id]),
    );
  }

  public async getAll(): Promise<User[]> {
    return httpService.perform<User[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT),
    );
  }

  public async updateUser(user: User): Promise<User> {
    return httpService.perform<User>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [user.id]),
      user,
    );
  }

  public async getProfileTicket(userTicketProfile: UserTicketProfile): Promise<UserTicketProfile> {
    return httpService.perform<UserTicketProfile>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [
        userTicketProfile.userId,
        httpHelper.TICKETS_ENDPOINT,
        userTicketProfile.ticketId,
        httpHelper.PROFILE_ENDPOINT,
      ]),
    );
  }

  public async updateProfileTicket(userTicketProfile: UserTicketProfile): Promise<User> {
    return httpService.perform<User>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [
        userTicketProfile.userId,
        httpHelper.PROFILE_ENDPOINT,
        userTicketProfile.id,
      ]),
      userTicketProfile,
    );
  }

  public async updateAvatar(user: User, file: File): Promise<User> {
    const formData: FormData = new FormData();
    formData.append('image', new Blob([file]));

    return httpService.perform<User>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [user.id, 'avatar']),
      formData,
      true,
      true,
    );
  }

  public async createUser(user: User): Promise<User> {
    return httpService.perform<User>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT),
      user,
    );
  }

  public async updateUserPassword(user: User, password: ResetForm): Promise<void> {
    return httpService.perform<void>(
      HttpMethod.put,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [user.id, 'password']),
      password,
    );
  }

  public async uploadCsvUsers(uploadUserfile: UploadUserFile): Promise<void> {
    const formData: FormData = new FormData();
    formData.append('file', new Blob([uploadUserfile.file], { type: 'application/octet-stream' }));

    return httpService.perform<void>(
      HttpMethod.post,
      httpHelper.buildApiRoute(httpHelper.EVENTS_ENDPOINT, [
        uploadUserfile.eventId,
        httpHelper.TICKETS_ENDPOINT,
        uploadUserfile.ticketId,
        'import_users',
      ]),
      formData,
      true,
      true,
    );
  }

  public async getPermissions(user: User, isLoggedInUser: boolean): Promise<UserRole[]> {
    if (isLoggedInUser) {
      return UserService.getLoggedInUserPermissions();
    }

    return httpService.perform<UserRole[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [
        user.id,
        httpHelper.PERMISSIONS_ENDPOINT,
      ]),
    );
  }

  private static async getLoggedInUserPermissions(): Promise<UserRole[]> {
    return httpService.perform<UserRole[]>(
      HttpMethod.get,
      httpHelper.buildApiRoute(httpHelper.ME, [httpHelper.PERMISSIONS_ENDPOINT]),
    );
  }

  private static extractLoginFormDataFromRegisterFormData(registerForm: RegisterForm): LoginForm {
    return {
      email: registerForm.email,
      password: registerForm.password,
    };
  }
}

export default new UserService();
