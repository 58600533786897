import { ActionContext } from 'vuex';

import { ErrorMessage } from '@/models/error-message/error-message.model';
import { Config } from '@/models/config/config.model';

import { RootState } from '@/stores/store.model';
import {
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_ERROR,
  CLEAR_CONFIG,
} from '@/stores/shared/actions/config/config.actions';
import configService from '@/services/config/config.service';

export interface ConfigState {
  config: Config;
  status: ConfigStatus;
}

export interface ConfigStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

const state: ConfigState = {
  config: null,
  status: {
    error: null,
    isLoading: false,
  },
};

const actions = {
  async [GET_CONFIG](
    { commit }: ActionContext<ConfigState, RootState>,
    payload: {
      name: string;
    },
  ) {
    commit(GET_CONFIG);

    try {
      const config = await configService.getConfig(payload);
      commit(GET_CONFIG_SUCCESS, config);
    } catch (error) {
      commit(GET_CONFIG_ERROR, error);
    }
  },
  async [CLEAR_CONFIG]({ commit }: ActionContext<ConfigState, RootState>) {
    commit(CLEAR_CONFIG);
  },
};

const mutations = {
  [GET_CONFIG](state) {
    state.status = {
      ...state.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFIG_SUCCESS](state, config) {
    state.config = {
      ...config,
    };
    state.status = {
      ...state.status,
      isLoading: false,
      error: null,
    };
  },
  [GET_CONFIG_ERROR](state, error) {
    state.status = {
      ...state.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_CONFIG](state) {
    state.config = null;
  },
};

const getters = {
  config: (state): Config => state?.config || null,
  isLoading: (state): boolean => state?.status?.isLoading || false,
  error: (state): ErrorMessage => state?.status?.error || null,
};

export const ConfigModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
