import stripeOptions from './stripe-options';

export class StripeWrapper {
  get stripe(): stripe.Stripe {
    return Stripe(stripeOptions.apiKey, {
      locale: stripeOptions.locale,
    });
  }
}

export default new StripeWrapper();
