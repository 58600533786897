import Nested from '@/components/nested/Nested.vue';

export const publicRoutes = (alreadyLoggedGuard) => [
  {
    path: '/login',
    name: 'Login',
    beforeEnter: alreadyLoggedGuard,
    component: () => import(/* webpackChunkName: "public" */ '@/views/auth/login/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "public" */ '@/views/auth/register/Register.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordParent',
    component: Nested,
    redirect: { name: 'ResetPassword', replace: true, append: false },
    children: [
      {
        path: '/',
        name: 'ResetPassword',
        component: () =>
          import(/* webpackChunkName: "public" */ '@/views/auth/reset-password/ResetPassword.vue'),
      },
      {
        path: 'update',
        name: 'UpdatePassword',
        component: () =>
          import(
            /* webpackChunkName: "public" */ '@/views/auth/update-password/UpdatePassword.vue'
          ),
      },
    ],
  },
  {
    path: '/accept-invitation',
    name: 'AcceptInvitation',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/auth/accept-invitation/AcceptInvitation.vue'
      ),
  },
  {
    path: '/404',
    name: 'Not Found',
    props: {
      code: '404',
    },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Exception.vue'),
  },
  {
    path: '/500',
    name: 'Server Error',
    props: {
      code: '500',
    },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Exception.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];
