<template>
  <v-sheet id="chat-users" class="ma-0">
    <div class="title">
      <div class="title__left">
        <div class="user__picture">
          <user-avatar v-if="user" :avatar="user.avatar" :thin-border="true" />
        </div>

        <div class="title__text">{{ $t('menu.chat') }}</div>
      </div>

      <div class="title__right">
        <v-btn icon small color="primaryFont" @click.native="$emit('close-chat-users')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="search">
      <AppSearch :dark="true" @change="onSearchChange" />
    </div>

    <v-lazy>
      <div class="users">
        <div
          v-for="chatUser in chatsUsers"
          :key="`chat-user-${chatUser.id}-${hasUnread(chatUser)}`"
          class="user"
          @click="$emit('open-chat', { userIds: chatUser.userIds, id: chatUser.id })"
        >
          <div class="user__picture">
            <v-icon v-if="isGroupChat(chatUser)" class="picture__icon">mdi-account-group</v-icon>

            <v-badge v-else color="red" overlap :value="hasUnread(chatUser)">
              <template v-slot:badge>
                <v-icon class="user__badge" color="white" dark>mdi-exclamation-thick</v-icon>
              </template>

              <user-avatar :avatar="chatUser.avatar" :thinBorder="true" />
            </v-badge>
          </div>

          <div class="user__name" v-bind:class="{ unread: hasUnread(chatUser) }">
            <span v-if="isGroupChat(chatUser)">
              {{ chatUser.name }}
            </span>

            <span v-else>
              {{
                $tc('chat.name', chatUser.displayLength, {
                  count: chatUser.displayLength,
                  name: chatUser.name,
                })
              }}
            </span>
          </div>
        </div>
      </div>
    </v-lazy>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_CHATS_MODULE } from '@/stores/umanize-app/actions/chats/chats.actions';

import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

export default {
  name: 'ChatUsers',
  props: {
    user: {
      type: Object,
      required: true,
    },
    chatsUsers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
  }),
  components: {
    UserAvatar,
    AppSearch,
  },
  computed: {
    ...mapGetters(APP_CHATS_MODULE, ['chatHasUnreadMessages']),
    hasUnread() {
      return (chat) => this.chatHasUnreadMessages(chat);
    },
    isGroupChat() {
      return (chatUser) => chatUser?.eventId;
    },
  },
  methods: {
    onSearchChange(search) {
      this.$emit('update-search', search);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

#chat-users {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: 100%;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  height: 15%;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }
}

.search {
  margin: 0.5rem 0 1rem;
}

.users {
  overflow: auto;
}

.user {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  cursor: pointer;

  &__badge {
    font-size: 0.7rem;
  }

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0.5rem;
  }

  &__name {
    font-size: $small-font-size;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    hyphens: auto;
    max-width: 15rem;

    &.unread {
      font-weight: $semi-bold;
    }
  }
}

.picture {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;

  &__icon {
    margin: 0 8px;
  }
}

@include breakpoint(medium) {
  #chat-users {
    height: calc(100% - 2px);
  }
}
</style>
