<template>
  <div class="conference-room">
    <div class="conference-room__content">
      <div class="content__title">{{ conferenceRoom.name }}</div>

      <v-btn
        class="content__action"
        :color="accentColor"
        :style="btnStyle"
        @click.native="selectRoom"
        small
        depressed
      >
        {{ $t('lobby.selectRoom') }}
      </v-btn>
    </div>

    <div class="conference-room__picture">
      <img class="picture" :src="conferenceRoom.coverImage" :alt="conferenceRoom.name" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConferenceItem',
  props: {
    conferenceRoom: {
      type: Object,
      required: true,
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    btnStyle: {
      type: String,
      required: false,
    },
  },
  methods: {
    selectRoom() {
      this.$emit('select-room');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.conference-room {
  display: flex;
  align-items: center;
  padding: 1rem 0 0.7rem;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 25px;
  }

  &__picture {
    flex: 1;
  }
}

.content {
  &__title {
    color: var(--v-primaryBackground-base);
    font-weight: $bold;
    font-size: $large-font-size;
  }

  &__action {
    width: 90px;
    border-radius: 5px;
  }
}

.picture {
  width: 100%;
}
</style>
