import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { STREAM_MODULE_NAME } from '@/stores/agnostic/actions/stream/agnostic-stream.actions';

export const STREAM_MODULE = `${UMANIZE_ADMIN_MODULE}/${STREAM_MODULE_NAME}`;

export const CREATE_STREAM = 'CREATE_STREAM';
export const CREATE_STREAM_SUCCESS = 'CREATE_STREAM_SUCCESS';
export const CREATE_STREAM_ERROR = 'CREATE_STREAM_ERROR';

export const START_STREAM = 'START_STREAM';
export const START_STREAM_SUCCESS = 'START_STREAM_SUCCESS';
export const START_STREAM_ERROR = 'START_STREAM_ERROR';

export const STOP_STREAM = 'STOP_STREAM';
export const STOP_STREAM_SUCCESS = 'STOP_STREAM_SUCCESS';
export const STOP_STREAM_ERROR = 'STOP_STREAM_ERROR';

export const DELETE_STREAM = 'DELETE_STREAM';
export const DELETE_STREAM_SUCCESS = 'DELETE_STREAM_SUCCESS';
export const DELETE_STREAM_ERROR = 'DELETE_STREAM_ERROR';
