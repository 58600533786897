/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';

import {
  TokenUser,
  SessionUser,
  LoginForm,
  HttpMethod,
  ResetForm,
  UpdateForm,
  User,
} from '@/models';
import DataUtil from '@/helpers/data/data.helper';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class AuthService {
  public async login(loginForm: LoginForm): Promise<User> {
    const tokenUser: TokenUser = await HttpService.perform<TokenUser>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.AUTH_ENDPOINT),
      loginForm,
    );

    if (tokenUser.token) {
      localStorage.setItem(HttpHelper.JWT_TOKEN, tokenUser.token);
    }

    return tokenUser.user;
  }

  public isSessionAlive(): boolean {
    const token: string = this.getToken();

    return token && !DataUtil.isTokenExpired(token);
  }

  public getUserFromToken(): SessionUser {
    const token: string = this.getToken();

    return this.buildUserFromToken(token);
  }

  public disconnect(): void {
    localStorage.removeItem(HttpHelper.JWT_TOKEN);
  }

  public async resetPassword(resetForm: ResetForm): Promise<void> {
    await HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.RESET_ENDPOINT),
      resetForm,
    );
  }

  public async updatePassword(passwordForm: UpdateForm, token: string): Promise<void> {
    await HttpService.perform<void>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.RESET_ENDPOINT, [token]),
      passwordForm,
    );
  }

  public async validatePasswordToken(token: string): Promise<void> {
    await HttpService.perform<void>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.RESET_ENDPOINT, [token]),
    );
  }

  private buildUserFromToken(token: string): SessionUser {
    const decoded: { [key: string]: string } = jwt_decode(token);

    return {
      id: decoded['user.id'],
      email: decoded['user.email'],
    };
  }

  private getToken(): string {
    return localStorage.getItem(HttpHelper.JWT_TOKEN);
  }
}

export default new AuthService();
