import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';
import { TICKET_MODULE } from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';

export const APP_TICKET_MODULE = `${UMANIZE_APP_MODULE}/${TICKET_MODULE}`;

export const SET_TICKET_NEWSLETTER = 'SET_TICKET_NEWSLETTER';
export const SET_TICKET_RECEIVE_INFO = 'SET_TICKET_RECEIVE_INFO';

export const PAYMENT_INTENT = 'PAYMENT_INTENT';
export const PAYMENT_INTENT_SUCCESS = 'PAYMENT_INTENT_SUCCESS';
export const PAYMENT_INTENT_ERROR = 'PAYMENT_INTENT_ERROR';

export const PAY = 'PAY';
export const PAY_SUCCESS = 'PAY_SUCCESS';
export const PAY_ERROR = 'PAY_ERROR';
