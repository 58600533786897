import axios from 'axios';
import HttpHelper from '@/helpers/http/http.helper';
import { HttpMethod } from '@/models';
import { ErrorMessage } from '@/models/error-message/error-message.model';

class HttpService {
  public async perform<TResponse>(
    method: HttpMethod,
    url: string,
    body?: unknown,
    withToken = true,
    withFormData = false,
  ): Promise<TResponse> {
    const config = {
      method,
      headers: HttpService.getHeaders(withToken, withFormData),
    };

    try {
      let args;
      switch (method) {
        case HttpMethod.get:
        case HttpMethod.delete:
          args = [url, config];
          break;
        case HttpMethod.post:
        case HttpMethod.patch:
        case HttpMethod.put:
          args = [url, HttpService.buildBody(body, withFormData), config];
          break;
        default:
          args = null;
          break;
      }

      const response = await axios[method as string](...args);

      if (response.status === 204 || (!body && response.status === 201)) {
        return {} as TResponse;
      }

      return response.data;
    } catch (e) {
      const error: ErrorMessage = {
        status: e?.response?.status,
        message: e?.response?.data?.message,
      };

      throw error;
    }
  }

  private static getHeaders(withToken: boolean, withFormData: boolean): HeadersInit {
    const headers = {
      Accept: 'application/json',
    };

    if (!withFormData) {
      headers['Content-Type'] = 'application/json';
    }

    if (withToken) {
      const token: string = localStorage.getItem(HttpHelper.JWT_TOKEN);

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return headers;
  }

  private static buildBody(body: unknown, withFormData: boolean): BodyInit {
    if (withFormData) {
      return body as BodyInit;
    }

    return body ? JSON.stringify(body) : null;
  }
}

export default new HttpService();
