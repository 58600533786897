import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';
import { DISCUSSION_GROUP_MODULE } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';

export const APP_DISCUSSION_GROUP_MODULE = `${UMANIZE_APP_MODULE}/${DISCUSSION_GROUP_MODULE}`;

export const CONNECT_TO_DISCUSSION_GROUP_SOCKET = 'CONNECT_TO_DISCUSSION_GROUP_SOCKET';
export const DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET = 'DISCONNECT_FROM_DISCUSSION_GROUP_SOCKET';

export const DISCUSSION_GROUP_CHAT_LOAD_MESSAGES = 'DISCUSSION_GROUP_CHAT_LOAD_MESSAGES';
export const DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_SUCCESS =
  'DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_SUCCESS';
export const DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_ERROR =
  'DISCUSSION_GROUP_CHAT_LOAD_MESSAGES_ERROR';

export const DISCUSSION_GROUP_SEND_CHATS_MESSAGE = 'DISCUSSION_GROUP_SEND_CHATS_MESSAGE';
export const DISCUSSION_GROUP_SEND_CHATS_MESSAGE_SUCCESS =
  'DISCUSSION_GROUP_SEND_CHATS_MESSAGE_SUCCESS';
export const DISCUSSION_GROUP_SEND_CHATS_MESSAGE_ERROR =
  'DISCUSSION_GROUP_SEND_CHATS_MESSAGE_ERROR';

export const DISCUSSION_GROUP_CHAT_NEW_MESSAGE_SUCCESS =
  'DISCUSSION_GROUP_CHAT_NEW_MESSAGE_SUCCESS';

export const DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE_SUCCESS =
  'DISCUSSION_GROUP_CHAT_UPDATED_MESSAGE_SUCCESS';
