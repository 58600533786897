<template>
  <div :class="[{ active: isActiveUser }, 'message']">
    <template v-if="hasValidUrl(message.body)">
      <div class="message__text" v-html="messageBody(message.body, messageUrl(message.body))" />

      <link-prevue v-if="hasValidUrl(message.body)" :url="messageUrl(message.body)">
        <template slot-scope="props">
          <div :class="{ preview__wrapper: hasInfo(props) }">
            <img v-if="!!props.img" class="preview__img" :src="props.img" :alt="props.title" />

            <div class="preview__info">
              <h4 v-if="!!props.title" class="info__title">{{ props.title }}</h4>
              <p v-if="!!props.description" class="info__text">{{ props.description }}</p>
            </div>
          </div>
        </template>
      </link-prevue>
    </template>

    <div v-else class="message__text" v-html="message.body" />
  </div>
</template>

<script>
import LinkPrevue from 'link-prevue';

export default {
  name: 'MessageBody',
  components: {
    LinkPrevue,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    isActiveUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlRegex: /(ftp|http|https)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    };
  },
  computed: {
    hasValidUrl() {
      return (url) => this.urlRegex.test(url);
    },
    messageUrl() {
      return (message) => message.match(this.urlRegex)[0] || null;
    },
    hasInfo() {
      return (meta) => !!meta.img && !!meta.title && !!meta.description;
    },
    messageBody() {
      return (message, url) => message.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.message {
  max-width: 280px;
}

.preview {
  &__wrapper {
    background-color: white;
    margin: 10px 10px 0 0;
    border-radius: 10px;
    text-align: center;
  }

  &__img {
    width: 100%;
    margin-bottom: 10px;
    aspect-ratio: 16/9;
    border-radius: 10px;
  }

  &__info {
    padding: 10px;
  }
}

.active {
  .message {
    &__text {
      text-align: right;
    }
  }

  .preview {
    &__wrapper {
      margin: 10px 0 0 10px;
    }
  }
}

.info {
  &__text {
    @include ellipsis();
    font-size: $x-small-font-size;
  }
}
</style>
