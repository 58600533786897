import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const TRAINING_MODULE_NAME = 'TrainingNoteModule';
export const TRAINING_NOTE_ADMIN_MODULE_NAME = 'TrainingNoteAdminModule';
export const TRAINING_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_NOTE_ADMIN_MODULE_NAME}`;
export const TRAINING_NOTE_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_MODULE_NAME}`;

export const GET_TRAINING_NOTES = 'GET_TRAINING_NOTES';
export const GET_ALL_TRAINING_NOTES = 'GET_ALL_TRAINING_NOTES';
export const GET_ALL_TRAINING_NOTES_SUCCESS = 'GET_ALL_TRAINING_NOTES_SUCCESS';
export const GET_ALL_TRAINING_NOTES_ERROR = 'GET_ALL_TRAINING_NOTES_ERROR';

export const GET_TRAINING_NOTE = 'GET_TRAINING_NOTE';

export const GET_TRAINING_NOTE_SUCCESS = 'GET_TRAINING_NOTE_SUCCESS';
export const GET_TRAINING_NOTE_ERROR = 'GET_TRAINING_NOTE_ERROR';

export const CLEAR_ALL_TRAINING_NOTE = 'CLEAR_ALL_TRAINING_NOTE';
export const CLEAR_TRAINING_NOTE = 'CLEAR_TRAINING_NOTE';
export const SAVE_TRAINING_NOTE = 'SAVE_TRAINING_NOTE';

export const ADD_TRAINING_NOTE = 'ADD_TRAINING_NOTE';
export const ADD_TRAINING_NOTE_SUCCESS = 'ADD_TRAINING_NOTE_SUCCESS';
export const ADD_TRAINING_NOTE_ERROR = 'ADD_TRAINING_NOTE_ERROR';

export const UPDATE_TRAINING_NOTE = 'UPDATE_TRAINING_NOTE';
export const UPDATE_TRAINING_NOTE_SUCCESS = 'UPDATE_TRAINING_NOTE_SUCCESS';
export const UPDATE_TRAINING_NOTE_ERROR = 'UPDATE_TRAINING_NOTE_ERROR';

export const UPDATE_LIST_TRAINING_NOTE = 'UPDATE_TRAINING_NOTE';
export const UPDATE_LIST_TRAINING_NOTE_SUCCESS = 'UPDATE_TRAINING_NOTE_SUCCESS';
export const UPDATE_LIST_TRAINING_NOTE_ERROR = 'UPDATE_TRAINING_NOTE_ERROR';

export const DELETE_TRAINING_NOTE = 'DELETE_TRAINING_NOTE';
export const DELETE_TRAINING_NOTE_SUCCESS = 'DELETE_TRAINING_NOTE_SUCCESS';
export const DELETE_TRAINING_NOTE_ERROR = 'DELETE_TRAINING_NOTE_ERROR';
