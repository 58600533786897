import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';

import DataUtil from '@/helpers/data/data.helper';
import { ChatDisplay } from '@/models/chat/chat-display.model';

import chatService from '@/services/chat/chat.service';
import { getChatSocket } from '@/socket/chat-namespace';

import {
  CHATS_LOADMESSAGES_SUCCESS,
  CHATS_NEW_MESSAGE,
  CLEAR_CHATS,
  CLOSE_CHAT,
  CREATE_CHAT,
  CREATE_CHAT_ERROR,
  CREATE_CHAT_SUCCESS,
  DISPLAY_CHATS,
  GET_CHATS,
  GET_CHATS_ERROR,
  GET_CHATS_MESSAGES,
  GET_CHATS_SUCCESS,
  GET_EVENT_CHATS,
  GET_EVENT_CHATS_SUCCESS,
  HIDE_CHATS,
  MARK_AS_READ,
  OPEN_CHAT,
  SEND_CHATS_MESSAGE,
  UPDATE_MAX_OPENED_CHATS,
  UPDATE_SEARCH,
  UPDATE_CHAT,
  UPDATE_CHAT_SUCCESS,
  UPDATE_CHAT_ERROR,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import { Chat, LoadMessagesSuccess, NewMessage, PublicProfile, User } from '@/models';
import {
  socketChatMessagesToChatMessage,
  socketChatMessageToChatMessage,
} from '@/stores/umanize-app/modules/chats/socket-chat-message.mapper';
import { mergeChats, sortChats } from '@/stores/umanize-app/modules/chats/chats.helper';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export interface ChatsState {
  displayed: boolean;
  search: string;
  chats: Chat[];
  eventChats: Chat[];
  openedChats: string[];
  maxOpenedChats: number;
  status: {
    error: string;
    isSending: boolean;
    isLoaded: boolean;
  };
}

const state: ChatsState = {
  displayed: false,
  search: '',
  chats: [],
  eventChats: [],
  openedChats: [],
  maxOpenedChats: 4,
  status: {
    error: '',
    isSending: false,
    isLoaded: false,
  },
};

const actions = {
  async [UPDATE_SEARCH]({ commit }: ActionContext<ChatsState, RootState>, searchText) {
    commit(UPDATE_SEARCH, searchText);
  },
  async [CLEAR_CHATS]({ commit }: ActionContext<ChatsState, RootState>) {
    commit(CLEAR_CHATS);
  },
  async [UPDATE_MAX_OPENED_CHATS](
    { commit }: ActionContext<ChatsState, RootState>,
    maxOpenedChats,
  ) {
    commit(UPDATE_MAX_OPENED_CHATS, maxOpenedChats);
  },
  async [DISPLAY_CHATS]({ commit }: ActionContext<ChatsState, RootState>) {
    commit(DISPLAY_CHATS);
  },
  async [HIDE_CHATS]({ commit }: ActionContext<ChatsState, RootState>) {
    commit(HIDE_CHATS);
  },
  async [GET_CHATS]({ commit }: ActionContext<ChatsState, RootState>) {
    commit(GET_CHATS);

    try {
      const chats = await chatService.getChats();
      commit(GET_CHATS_SUCCESS, chats);
    } catch (error) {
      commit(GET_CHATS_ERROR, error);
    }
  },
  async [GET_EVENT_CHATS]({ commit }: ActionContext<ChatsState, RootState>, eventId: string) {
    commit(GET_CHATS);

    try {
      const chats = await chatService.getEventChats(eventId);
      commit(GET_EVENT_CHATS_SUCCESS, chats);
    } catch (error) {
      commit(GET_CHATS_ERROR, error);
    }
  },
  async [OPEN_CHAT](
    { commit, dispatch, state, rootGetters }: ActionContext<ChatsState, RootState>,
    { id, userIds },
  ) {
    const user = rootGetters[`${APP_USER_MODULE}/loggedInUser`];
    const uniqueSortedUserIds = userIds ? [...new Set([...userIds, user.id])].sort() : [];

    const chat: Chat = id
      ? state.chats.find((chat: Chat) => chat.id === id)
      : state.chats.find((chat: Chat) => {
          const sortedUserIds = [...new Set(chat.userIds)].sort();

          return JSON.stringify(uniqueSortedUserIds) === JSON.stringify(sortedUserIds);
        });

    if (chat) {
      commit(OPEN_CHAT, chat);

      if (!state.displayed) {
        dispatch(DISPLAY_CHATS);
      }
    } else {
      dispatch(CREATE_CHAT, { userIds });
    }
  },
  async [CLOSE_CHAT]({ commit }: ActionContext<ChatsState, RootState>, chatId: string) {
    commit(CLOSE_CHAT, chatId);
  },
  async [GET_CHATS_MESSAGES]({ commit }: ActionContext<ChatsState, RootState>, id) {
    getChatSocket().emit('load-messages', id);
    commit(GET_CHATS_MESSAGES);
  },
  async [SEND_CHATS_MESSAGE](
    _: ActionContext<ChatsState, RootState>,
    payload: {
      id: string;
      message: string;
    },
  ) {
    getChatSocket().emit('create-message', payload.id, payload.message);
  },
  async [CREATE_CHAT](
    { commit, dispatch }: ActionContext<ChatsState, RootState>,
    payload: {
      userIds: string[];
      adminIds?: string[];
      name?: string;
      eventId?: string;
    },
  ) {
    commit(CREATE_CHAT);

    try {
      const chat = await chatService.createChat(payload);
      commit(CREATE_CHAT_SUCCESS, chat);

      if (payload.eventId) {
        await dispatch(GET_EVENT_CHATS, payload.eventId);
      } else {
        await dispatch(GET_CHATS);
        dispatch(OPEN_CHAT, { userIds: chat.userIds });
        dispatch(DISPLAY_CHATS);
      }
    } catch (error) {
      commit(CREATE_CHAT_ERROR, error);
    }
  },
  async [CHATS_LOADMESSAGES_SUCCESS](
    { commit }: ActionContext<ChatsState, RootState>,
    payload: {
      message: string;
      chatId: string;
    },
  ) {
    commit(CHATS_LOADMESSAGES_SUCCESS, payload);
  },
  async [CHATS_NEW_MESSAGE](
    { commit, rootGetters }: ActionContext<ChatsState, RootState>,
    payload: NewMessage & { user: User },
  ) {
    const user = rootGetters[`${APP_USER_MODULE}/loggedInUser`];
    commit(CHATS_NEW_MESSAGE, { ...payload, user });
  },
  async [MARK_AS_READ]({ commit }: ActionContext<ChatsState, RootState>, chat: Chat) {
    getChatSocket().emit('chat-read', chat.id);
    commit(MARK_AS_READ, chat);
  },
  async [UPDATE_CHAT](
    { commit, dispatch }: ActionContext<ChatsState, RootState>,
    payload: {
      chatId: string;
      chat: {
        userIds: string[];
        adminIds: string[];
        name: string;
        eventId: string;
      };
    },
  ) {
    commit(UPDATE_CHAT);

    try {
      const chat = await chatService.updateChat(payload);
      commit(UPDATE_CHAT_SUCCESS, chat);

      await dispatch(GET_EVENT_CHATS, payload.chat.eventId);
    } catch (error) {
      commit(UPDATE_CHAT_ERROR, error);
    }
  },
};

const mutations = {
  [UPDATE_SEARCH](state: ChatsState, searchText: string) {
    state.search = searchText;
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [UPDATE_MAX_OPENED_CHATS](state: ChatsState, maxOpenedChats: number) {
    state.maxOpenedChats = maxOpenedChats > 0 ? maxOpenedChats : 1;
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [DISPLAY_CHATS](state: ChatsState) {
    state.displayed = true;
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [HIDE_CHATS](state: ChatsState) {
    state.displayed = false;
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_CHATS](state: ChatsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_CHATS_SUCCESS](state: ChatsState, chats: Chat[]) {
    state.chats = [...sortChats(mergeChats(state.chats, chats))];

    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },
  [GET_CHATS_ERROR](state: ChatsState, error: Error) {
    state.status = {
      ...state.status,
      isSending: false,
      error: error.message,
    };
  },
  [GET_EVENT_CHATS_SUCCESS](state: ChatsState, chats: Chat[]) {
    state.eventChats = [...sortChats(mergeChats(state.chats, chats))];

    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },

  [CREATE_CHAT](state: ChatsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [CREATE_CHAT_SUCCESS](state: ChatsState, chat: Chat) {
    state.chats = [...state.chats, chat];
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [CREATE_CHAT_ERROR](state: ChatsState, error: Error) {
    state.status = {
      ...state.status,
      isSending: false,
      error: error.message,
    };
  },
  [OPEN_CHAT](state: ChatsState, chat: Chat) {
    state.openedChats = state.openedChats.filter((id: string) => id !== chat.id);
    state.openedChats.unshift(chat.id);

    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [CLOSE_CHAT](state: ChatsState, chatId: string) {
    state.openedChats = state.openedChats.filter((id: string) => id !== chatId);
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [GET_CHATS_MESSAGES](state: ChatsState) {
    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [CLEAR_CHATS](state: ChatsState) {
    state.chats = [];
  },
  [CHATS_LOADMESSAGES_SUCCESS](state: ChatsState, { messages, chatId }: LoadMessagesSuccess) {
    const currentChat = state.chats.find((it) => it.id === chatId);
    if (!currentChat) {
      return;
    }

    state.chats =
      state.chats?.map((chat) => {
        if (chat.id !== chatId) {
          return chat;
        }

        const allMessages = socketChatMessagesToChatMessage(messages, chat);

        return {
          ...chat,
          messages: [...allMessages],
        };
      }) || [];
  },
  [CHATS_NEW_MESSAGE](state: ChatsState, payload: NewMessage & { user: User }) {
    const currentChat = state.chats.find((it) => it.id === payload.chatId);
    if (!currentChat) {
      return;
    }

    state.chats =
      state.chats?.map((chat) => {
        if (chat.id !== payload.chatId) {
          return chat;
        }

        const currentMessages = chat.messages || [];

        return {
          ...chat,
          messages: [...currentMessages, socketChatMessageToChatMessage(payload, chat)],
          lastMessage: {
            ...payload,
          },
          unread: payload.userId !== payload.user.id,
        };
      }) || [];
  },
  [MARK_AS_READ](state: ChatsState, chat: Chat) {
    state.chats = state.chats.map((it) => {
      if (chat?.id !== it.id) {
        return it;
      }

      return {
        ...it,
        unread: false,
      };
    });
  },
  [UPDATE_CHAT](state: ChatsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [UPDATE_CHAT_SUCCESS](state: ChatsState, chat: Chat) {
    state.chats = state.chats?.map((c) => {
      if (c.id !== chat?.id) {
        return c;
      }

      return {
        ...c,
        ...chat,
      };
    });

    state.status = {
      ...state.status,
      isSending: false,
      error: null,
    };
  },
  [UPDATE_CHAT_ERROR](state: ChatsState, error: Error) {
    state.status = {
      ...state.status,
      isSending: false,
      error: error.message,
    };
  },
};

const getters = {
  displayed: (state) => state.displayed || false,
  search: (state) => state.search || null,
  chatsAll: (state) => state.chats || [],
  eventChatsAll: (state) => state.eventChats || [],
  chats: (_, { chatsAll, filterChatsWithoutMe }) => filterChatsWithoutMe(chatsAll),
  groupChats: (_, { eventChatsAll }) => (eventId) =>
    DataUtil.sortByStringAsc(
      eventChatsAll?.filter((chat) => chat?.eventId === eventId),
      'name',
    ),
  chatsDisplay: (_, { chats, search, mapChatsToDisplay }) =>
    sortChats(mapChatsToDisplay(chats, search)),
  openedChatsIds: (state) => state.openedChats || [],
  openedChats: (_, { chatsDisplay, openedChatsIds }) =>
    openedChatsIds
      .map((chatId: string) => chatsDisplay.find((chat) => chat.id === chatId))
      .filter((data) => !!data),
  maxOpenedChats: (state) => state.maxOpenedChats || 4,
  allMessages: (_, { chats }) =>
    chats
      ?.map((chat) => chat.messages)
      .flat(1)
      .filter((v) => !!v) || [],
  hasGlobalUnread: (_, { chatsAll }) => chatsAll?.some((it) => it.unread) || false,
  isSending: (state) => (state.status && state.status.isSending) || false,
  isLoaded: (state) => (state.status && state.status.isLoaded) || false,
  error: (state) => (state.status && state.status.error) || null,

  filterChatsWithoutMe: (rootGetters) => (chats: Chat[]) =>
    chats?.map((chat: Chat) => ({
      ...chat,
      userIds: chat.userIds?.filter(
        (u: string) => u !== rootGetters[`${APP_USER_MODULE}/loggedInUser`]?.id,
      ),
      users: chat.users?.filter(
        (u: PublicProfile) => u.id !== rootGetters[`${APP_USER_MODULE}/loggedInUser`]?.id,
      ),
    })) || [],
  mapChatsToDisplay: (_, rootState, __, rootGetters) => (chats: Chat[], search: string) =>
    chats
      ?.map((chat: Chat) => {
        const { users } = chat;
        const length: number = users?.length;
        const displayLength: number = (length || 1) - 1;
        const userId = rootGetters[`${APP_USER_MODULE}/loggedInUser`]?.id || '';

        const firstUser: PublicProfile = !!length && users[0].id !== userId ? users[0] : users[1];

        const avatar: string = firstUser?.avatar || '';
        const name: string =
          chat?.name || (firstUser ? `${firstUser.firstName} ${firstUser.lastName}` : '');

        return {
          ...chat,
          length,
          displayLength,
          avatar,
          name,
        };
      })
      .filter((chat: ChatDisplay) =>
        search ? chat.name.toLowerCase().includes(search.toLowerCase()) : !!chat,
      ) || [],
  chatHasUnreadMessages: ({ chats }: ChatsState) => (chat: Chat) =>
    !!chats?.find((it) => it.id === chat.id)?.unread || false,
};

export const ChatsModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
