import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const EXHIBITOR_VISITOR_NAMESPACE = `/exhibitor-visitor`;

export const connectToExhibitorVisitorNamespace = ({
  eventId,
  exhibitorId,
}: {
  eventId: string;
  exhibitorId: string;
}): Socket => {
  const namespace = EXHIBITOR_VISITOR_NAMESPACE;

  return connectToNamespace(namespace, { exhibitorId, eventId });
};

export const disconnectFromExhibitorVisitorNamespace = (exhibitorSocket: Socket) => {
  exhibitorSocket.disconnect();
};
