import { ActionContext } from 'vuex';

import { MessageType, Ticket } from '@/models';
import TicketService from '@/services/ticket/ticket.service';
import { RootState } from '@/stores/store.model';
import i18n from '@/i18n';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import {
  AgnosticTicketModule,
  TicketsState,
} from '@/stores/agnostic/modules/ticket/agnostic-ticket.module';
import {
  GET_TICKETS,
  GET_TICKETS_ERROR,
  GET_TICKETS_SUCCESS,
  SELECT_TICKET,
} from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';
import {
  CLEAR_SELECTED_TICKET,
  CREATE_TICKET,
  CREATE_TICKET_ERROR,
  CREATE_TICKET_SUCCESS,
  GET_ADMIN_TICKETS,
  GET_ADMIN_TICKETS_ERROR,
  GET_ADMIN_TICKETS_SUCCESS,
  SAVE_TICKET,
  UPDATE_TICKET,
  UPDATE_TICKET_ERROR,
  UPDATE_TICKET_SUCCESS,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';

const state: TicketsState = {
  tickets: [],
  selectedTicket: undefined,
  newsletter: false,
  receiveInfo: false,
  payment: undefined,
  status: {
    error: null,
    isSending: false,
    isLoaded: false,
  },
};

const actions = {
  [GET_TICKETS]: AgnosticTicketModule.actions.getTickets,
  [SELECT_TICKET]: AgnosticTicketModule.actions.selectTicket,
  [CLEAR_SELECTED_TICKET]({ commit }: ActionContext<TicketsState, RootState>): void {
    commit(CLEAR_SELECTED_TICKET);
  },
  async [SAVE_TICKET]({ dispatch }: ActionContext<TicketsState, RootState>, ticket: Ticket) {
    const dispatchedAction = ticket?.id ? UPDATE_TICKET : CREATE_TICKET;
    await dispatch(dispatchedAction, ticket);
  },
  async [UPDATE_TICKET](
    { commit, dispatch }: ActionContext<TicketsState, RootState>,
    ticket: Ticket,
  ) {
    commit(UPDATE_TICKET);

    try {
      await TicketService.updateTicket(ticket);
      commit(UPDATE_TICKET_SUCCESS);
      dispatch(GET_ADMIN_TICKETS, ticket.eventId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: 'Updated with success',
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      const error = i18n.t(`ticketing.errors.${err.status}`);
      commit(UPDATE_TICKET_ERROR, error);
    }
  },
  async [CREATE_TICKET](
    { commit, dispatch }: ActionContext<TicketsState, RootState>,
    ticket: Ticket,
  ) {
    commit(CREATE_TICKET);

    try {
      await TicketService.createTicket(ticket);
      commit(CREATE_TICKET_SUCCESS);
      await dispatch(GET_ADMIN_TICKETS, ticket.eventId);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: 'Created with success',
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (err) {
      const error = i18n.t(`ticketing.errors.${err.status}`);
      commit(CREATE_TICKET_ERROR, error);
    }
  },
  async [GET_ADMIN_TICKETS](
    { commit }: ActionContext<TicketsState, RootState>,
    eventId: string,
  ): Promise<Ticket[]> {
    commit(GET_ADMIN_TICKETS);

    try {
      const tickets = await TicketService.getEventTickets({ eventId, noRestriction: true });
      commit(GET_ADMIN_TICKETS_SUCCESS, tickets);

      return tickets;
    } catch (err) {
      const error = i18n.t(`ticketing.errors.${err.status}`);
      commit(GET_ADMIN_TICKETS_ERROR, error);
      throw err;
    }
  },
};

const mutations = {
  [CLEAR_SELECTED_TICKET](state: TicketsState) {
    state.selectedTicket = null;
  },
  [GET_TICKETS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_TICKETS_SUCCESS](state: TicketsState, tickets) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
    state.tickets = [...tickets];
  },
  [GET_TICKETS_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
    state.tickets = [];
    state.selectedTicket = undefined;
  },
  [GET_ADMIN_TICKETS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_ADMIN_TICKETS_SUCCESS](state: TicketsState, tickets) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
    state.tickets = [...tickets];
  },
  [GET_ADMIN_TICKETS_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
    state.tickets = [];
    state.selectedTicket = undefined;
  },
  [UPDATE_TICKET](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [SAVE_TICKET](state: TicketsState) {
    state.status = {
      ...state.status,
      error: null,
    };
  },
  [UPDATE_TICKET_SUCCESS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },
  [UPDATE_TICKET_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
  [CREATE_TICKET](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [CREATE_TICKET_SUCCESS](state: TicketsState) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },
  [CREATE_TICKET_ERROR](state: TicketsState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
  [SELECT_TICKET](state: TicketsState, ticket: Ticket) {
    state.selectedTicket = {
      ...ticket,
    };
  },
};

const getters = {
  tickets: (state: TicketsState) => state.tickets,
  ticketName: (state: TicketsState) => (ticketId: string) =>
    state.tickets.find((ticket) => ticket.id === ticketId)?.name || null,
  selectedTicket: (state: TicketsState) => state.selectedTicket,
  isSending: (state: TicketsState) => (state.status && state.status.isSending) || false,
  isLoaded: (state: TicketsState) => (state.status && state.status.isLoaded) || false,
  error: (state: TicketsState) => (state.status && state.status.error) || '',
  errorMessage: (state: TicketsState) => state.status?.error?.message || '',
  errorType: (state: TicketsState) => state.status?.error?.type || '',
};

export const AdminTicketModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
