import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { MatchingQuestion, MatchingResult } from '@/models';
import matchingService from '@/services/matching/matching.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import {
  AgnosticMatchingModule,
  MatchingQuestionsState,
  MatchingQuestionsStatus,
} from '@/stores/agnostic/modules/matching/agnostic-matching.module';
import {
  GET_MATCHING_QUESTIONS,
  GET_MATCHING_QUESTIONS_ERROR,
  GET_MATCHING_QUESTIONS_SUCCESS,
} from '@/stores/agnostic/actions/matching/matching.actions';
import {
  ANSWER_MATCHING_QUESTION,
  ANSWER_MATCHING_QUESTION_ERROR,
  ANSWER_MATCHING_QUESTION_SUCCESS,
  FILTER_MATCHING_RESULTS,
  GET_MATCHING_RESULTS,
  GET_MATCHING_RESULTS_ERROR,
  GET_MATCHING_RESULTS_SUCCESS,
  REMOVE_MATCHING_RESULTS,
  REMOVE_MATCHING_RESULTS_SUCCESS,
} from '@/stores/umanize-app/actions/matching/app-matching.actions';

export interface MatchingResultsStatus {
  error: ErrorMessage;
  areLoading: boolean;
  areLoaded: boolean;
}

interface MatchingResultFilter {
  searchText?: string;
  ticketType?: TicketType;
}

export interface MatchingResultsState {
  filter: MatchingResultFilter;
  results: MatchingResult[];
  status: MatchingResultsStatus;
}

export interface MatchingState {
  matchingQuestions: MatchingQuestionsState;
  matchingResults: MatchingResultsState;
}

const matchingQuestions: MatchingQuestionsState = {
  questions: [],
  status: {
    error: null,
    isAnswering: false,
    areLoading: true,
    areLoaded: false,
    isSaving: false,
  },
};

const matchingResults: MatchingResultsState = {
  filter: {
    searchText: '',
    ticketType: TicketType.all,
  },
  results: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: false,
  },
};

const state: MatchingState = {
  matchingQuestions,
  matchingResults,
};

const actions = {
  [GET_MATCHING_QUESTIONS]: AgnosticMatchingModule.actions.getMatchingQuestions,
  async [ANSWER_MATCHING_QUESTION]({ commit }: ActionContext<MatchingState, RootState>, payload) {
    commit(ANSWER_MATCHING_QUESTION);

    try {
      const { eventId, questionId, answerIds } = payload;
      const result = await matchingService.answerQuestion({ eventId, questionId, answerIds });
      commit(ANSWER_MATCHING_QUESTION_SUCCESS, { questionId, answerIds });
      return result;
    } catch (error) {
      commit(ANSWER_MATCHING_QUESTION_ERROR, error);
      throw error;
    }
  },
  async [GET_MATCHING_RESULTS]({ commit }: ActionContext<MatchingState, RootState>, eventId) {
    commit(GET_MATCHING_RESULTS);

    try {
      const results = await matchingService.getResults(eventId);
      commit(GET_MATCHING_RESULTS_SUCCESS, results);
    } catch (error) {
      commit(GET_MATCHING_RESULTS_ERROR, error);
    }
  },
  async [REMOVE_MATCHING_RESULTS]({ commit }: ActionContext<MatchingState, RootState>, eventId) {
    commit(REMOVE_MATCHING_RESULTS);

    await matchingService.removeAnswers(eventId);
    commit(REMOVE_MATCHING_RESULTS_SUCCESS);
  },
  [FILTER_MATCHING_RESULTS](
    { commit }: ActionContext<MatchingState, RootState>,
    filter: MatchingResultFilter,
  ) {
    commit(FILTER_MATCHING_RESULTS, filter);
  },
};

const mutations = {
  [GET_MATCHING_QUESTIONS](state: MatchingState) {
    state.matchingQuestions.status = {
      ...state.matchingQuestions.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [GET_MATCHING_QUESTIONS_SUCCESS](state: MatchingState, questions: MatchingQuestion[]) {
    state.matchingQuestions = {
      status: {
        ...state.matchingQuestions.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      questions: [...questions],
    };
  },
  [GET_MATCHING_QUESTIONS_ERROR](state: MatchingState, error: ErrorMessage) {
    state.matchingQuestions.status = {
      ...state.matchingQuestions.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [ANSWER_MATCHING_QUESTION](state: MatchingState) {
    state.matchingQuestions.status = {
      ...state.matchingQuestions.status,
      isAnswering: true,
      error: null,
    };
  },
  [ANSWER_MATCHING_QUESTION_SUCCESS](
    state: MatchingState,
    { questionId, answerIds }: { questionId: string; answerIds: string[] },
  ) {
    state.matchingQuestions = {
      questions: state.matchingQuestions.questions.map((q) =>
        q.id === questionId ? { ...q, currentAnswerIds: answerIds } : q,
      ),
      status: {
        ...state.matchingQuestions.status,
        isAnswering: false,
        error: null,
      },
    };
  },
  [ANSWER_MATCHING_QUESTION_ERROR](state: MatchingState, error: ErrorMessage) {
    state.matchingQuestions.status = {
      ...state.matchingQuestions.status,
      isAnswering: false,
      error,
    };
  },
  [GET_MATCHING_RESULTS](state: MatchingState) {
    state.matchingResults.status = {
      ...state.matchingResults.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [GET_MATCHING_RESULTS_SUCCESS](state: MatchingState, results: MatchingResult[]) {
    state.matchingResults = {
      filter: state.matchingResults.filter,
      status: {
        ...state.matchingResults.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      results: [...results],
    };
  },
  [GET_MATCHING_RESULTS_ERROR](state: MatchingState, error: ErrorMessage) {
    state.matchingResults.status = {
      ...state.matchingResults.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [FILTER_MATCHING_RESULTS](state: MatchingState, filter: MatchingResultFilter) {
    state.matchingResults.filter = filter;
  },
  [REMOVE_MATCHING_RESULTS]() {
    // We have nothing to do :-)
  },
  [REMOVE_MATCHING_RESULTS_SUCCESS](state: MatchingState) {
    state.matchingResults = {
      filter: {},
      status: {
        areLoading: false,
        areLoaded: false,
        error: null,
      },
      results: [],
    };
  },
};

const getters = {
  questionsState: (state: MatchingState): MatchingQuestionsState => state.matchingQuestions,
  matchingQuestions: (
    _,
    { questionsState }: { questionsState: MatchingQuestionsState },
  ): MatchingQuestion[] => questionsState?.questions || [],
  questionById: (_, { matchingQuestions }: { matchingQuestions: MatchingQuestion[] }) => (
    id,
  ): MatchingQuestion => matchingQuestions.find((element) => element.id === id),
  questionsStatus: (
    _,
    { questionsState }: { questionsState: MatchingQuestionsState },
  ): MatchingQuestionsStatus => questionsState?.status || null,
  questionsAreLoading: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): boolean => questionsStatus?.areLoading || false,
  questionsAreLoaded: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): boolean => questionsStatus?.areLoaded || false,
  questionsError: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): ErrorMessage => questionsStatus?.error || null,

  resultsState: (state: MatchingState): MatchingResultsState => state.matchingResults,
  resultsFilter: (
    _,
    { resultsState }: { resultsState: MatchingResultsState },
  ): MatchingResultFilter => resultsState.filter,
  resultsFilterTicketType: (
    _,
    {
      resultsFilter,
    }: {
      resultsFilter?: MatchingResultFilter;
    },
  ): TicketType | null => resultsFilter?.ticketType || TicketType.all,
  results: (state: MatchingState): MatchingResult[] => state.matchingResults.results,
  hasResults: (state: MatchingState): boolean => (state.matchingResults.results || []).length > 0,
  filteredResults: (
    _,
    {
      resultsFilter,
      matchingResults,
    }: {
      resultsFilter?: MatchingResultFilter;
      matchingResults: MatchingResult[];
    },
  ): MatchingResult[] =>
    (matchingResults || [])
      .filter(
        (r: MatchingResult) =>
          !resultsFilter?.ticketType ||
          resultsFilter?.ticketType === TicketType.all ||
          resultsFilter?.ticketType === r.ticket?.type ||
          (resultsFilter?.ticketType === TicketType.visitor && r.ticket?.type === undefined),
      )
      .filter((user: MatchingResult) =>
        (user.firstName.toLowerCase() + user.lastName.toLowerCase()).includes(
          resultsFilter.searchText?.toLowerCase(),
        ),
      ),
  hasFilteredResults: (
    _,
    {
      filteredResults,
    }: {
      filteredResults: MatchingResult[];
    },
  ): boolean => filteredResults.length > 0,
  matchingResults: (
    _,
    { resultsState }: { resultsState: MatchingResultsState },
  ): MatchingResult[] => resultsState?.results || [],
  resultsStatus: (
    _,
    { resultsState }: { resultsState: MatchingResultsState },
  ): MatchingResultsStatus => resultsState?.status || null,
  resultsAreLoading: (_, { resultsStatus }: { resultsStatus: MatchingResultsStatus }): boolean =>
    resultsStatus?.areLoading || false,
  resultsAreLoaded: (_, { resultsStatus }: { resultsStatus: MatchingResultsStatus }): boolean =>
    resultsStatus?.areLoaded || false,
  resultsError: (_, { resultsStatus }: { resultsStatus: MatchingResultsStatus }): ErrorMessage =>
    resultsStatus?.error || null,
};

export const AppMatchingModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
