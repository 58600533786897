import {
  GeneralAssembly,
  HttpMethod,
  Question,
  Answer,
  AnswerForm,
  QuestionForm,
  GeneralAssemblyRequest,
} from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class GeneralAssemblyService {
  public async getAll(eventId: string): Promise<GeneralAssembly[]> {
    return HttpService.perform<GeneralAssembly[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
      ]),
    );
  }

  public async getById(eventId: string, assemblyId: string): Promise<GeneralAssembly> {
    return HttpService.perform<GeneralAssembly>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
      ]),
    );
  }

  public async create(eventId: string, assembly: GeneralAssemblyRequest): Promise<GeneralAssembly> {
    return HttpService.perform<GeneralAssembly>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
      ]),
      assembly,
    );
  }

  public async update(
    eventId: string,
    assemblyId: string,
    assembly: GeneralAssembly,
  ): Promise<GeneralAssembly> {
    return HttpService.perform<GeneralAssembly>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
      ]),
      assembly,
    );
  }

  public async delete(eventId: string, assemblyId: string): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
      ]),
    );
  }

  public async getQuestions(eventId: string, assemblyId: string): Promise<Question[]> {
    return HttpService.perform<Question[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
      ]),
    );
  }

  public async getQuestionById(
    eventId: string,
    assemblyId: string,
    questionId: string,
  ): Promise<Question> {
    return HttpService.perform<Question>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
        questionId,
      ]),
    );
  }

  public async answerQuestion(
    eventId: string,
    assemblyId: string,
    questionId: string,
    answerForm: AnswerForm,
  ): Promise<Answer> {
    return HttpService.perform<Answer>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
        questionId,
        HttpHelper.ANSWERS,
      ]),
      answerForm,
    );
  }

  public getAnswers(eventId: string, assemblyId: string, questionId: string): Promise<Answer> {
    return HttpService.perform<Answer>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
        questionId,
        HttpHelper.ANSWERS,
      ]),
    );
  }

  public createQuestion(
    eventId: string,
    assemblyId: string,
    questionForm: QuestionForm,
  ): Promise<Question[]> {
    return HttpService.perform<Question[]>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
      ]),
      questionForm,
    );
  }

  public closeQuestion(eventId: string, assemblyId: string, questionId: string): Promise<Question> {
    return HttpService.perform<Question>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS,
        questionId,
        'close',
      ]),
    );
  }

  public getQuestionsAndResults(eventId: string, assemblyId: string): Promise<Question[]> {
    return HttpService.perform<Question[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.GENERAL_ASSEMBLIES,
        assemblyId,
        HttpHelper.QUESTIONS_RESULTS,
      ]),
    );
  }
}

export default new GeneralAssemblyService();
