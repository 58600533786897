import { DateTime, Interval } from 'luxon';
import DateUtil from '@/helpers/date/date.helper';
import { AppEvent } from '@/models';
import { Slot } from '@/stores/umanize-app/modules/availability/slot';

export const getAllConfigurableDays = (now: DateTime) => (
  event: AppEvent,
): Record<string, Slot[]> => {
  const startDate = DateTime.fromISO(event.startDate).toISODate();
  const endDate = DateTime.fromISO(event.endDate).toISODate();

  // Create all dates between start and end
  const days: Record<string, []> = {};
  let currentDate = DateTime.max(
    now.startOf('day'),
    DateTime.fromFormat(startDate, DateUtil.ISO_DATE_FORMAT).startOf('day'),
  ).toUTC();
  const end = DateTime.fromFormat(endDate, DateUtil.ISO_DATE_FORMAT).endOf('day').toUTC();
  while (Interval.fromDateTimes(currentDate, end).isBefore(end)) {
    days[currentDate.toISODate()] = [];
    currentDate = currentDate.plus({ days: 1 });
  }

  return days;
};
