import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const APPOINTMENT_MODULE_NAME = 'AppointmentModule';
export const APPOINTMENT_MODULE = `${UMANIZE_APP_MODULE}/${APPOINTMENT_MODULE_NAME}`;

export const GET_APPOINTMENTS_SLOT = 'GET_APPOINTMENTS_SLOT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const SAVE_APPOINTMENT = 'SAVE_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const CHECK_USERS_HAVE_APPOINTMENT = 'CHECK_USERS_HAVE_APPOINTMENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_SAVING = 'SET_SAVING';
