import axios from 'axios';

import store from '@/stores';
import router from '@/router';
import i18n from '@/i18n';
import HttpHelper from '@/helpers/http/http.helper';
import { MessageType } from '@/models';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';

export default function setup() {
  axios.interceptors.response.use(null, async (err) => {
    if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
      await store.dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('globals.errors.401'), type: MessageType.error },
        { root: true },
      );

      localStorage.removeItem(HttpHelper.JWT_TOKEN);

      if (!err.config.url.includes('/users/authenticate')) {
        await router.push({ name: 'Login' });
      }
    }

    if (err.response.status === 403 || err.response.data.message === '403 Forbidden') {
      await store.dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('globals.errors.403'), type: MessageType.error },
        { root: true },
      );
      await router.push({ name: 'Dashboard' });
    }

    if (err.response.status === 500 || err.response.data.message === '500 Internal Server Error') {
      router.push('/500');
    }

    // temporary fix - remove when backend manages user_roles for all users
    if (err.response.status === 404 && err.config.url.includes('permissions')) {
      return Promise.resolve();
    }

    return Promise.reject(err);
  });
}
