import { AdminEventModule } from '@/stores/umanize-admin/modules/event/admin-event.module';
import { PartnerModule } from '@/stores/umanize-admin/modules/partner/partner.module';
import { RolesModule } from '@/stores/umanize-admin/modules/roles/roles.module';
import { AdminMatchingModule } from '@/stores/umanize-admin/modules/matching/admin-matching.module';
import { AdminConferenceModule } from '@/stores/umanize-admin/modules/conference/admin-conference.module';
import { AdminGeneralAssemblyModule } from '@/stores/umanize-admin/modules/general-assembly/admin-general-assembly.module';
import { AdminDiscussionGroupModule } from '@/stores/umanize-admin/modules/discussion-group/admin-discussion-group.module';
import { AdminUserModule } from '@/stores/umanize-admin/modules/user/admin-user.module';
import { AdminExhibitorModule } from '@/stores/umanize-admin/modules/exhibitor/admin-exhibitor.module';
import { AdminContentModule } from '@/stores/umanize-admin/modules/content/admin-content.module';
import { TagModule } from '@/stores/umanize-admin/modules/tag/admin-tag.module';
import { AdminStreamModule } from '@/stores/umanize-admin/modules/stream/admin-stream.module';
import { AdminTrainingModule } from '@/stores/umanize-admin/modules/training/admin-training.module';
import { AdminTrainingSectionModule } from '@/stores/umanize-admin/modules/training-section/admin-training-section.module';
import { AdminTrainingLessonModule } from '@/stores/umanize-admin/modules/training-lesson/admin-training-lesson.module';
import { TrainingNoteModule } from '@/stores/umanize-admin/modules/training-note/training-note.module';
import { AdminTicketModule } from './modules/ticket/admin-ticket.module';
import { AdminCustomUiModule } from './modules/custom-ui/admin-custom-ui.module';

export const UmanizeAdminModule = {
  namespaced: true,
  modules: {
    ConferenceModule: AdminConferenceModule,
    ContentModule: AdminContentModule,
    EventModule: AdminEventModule,
    GeneralAssemblyModule: AdminGeneralAssemblyModule,
    MatchingModule: AdminMatchingModule,
    DiscussionGroupModule: AdminDiscussionGroupModule,
    TicketModule: AdminTicketModule,
    UserModule: AdminUserModule,
    ExhibitorModule: AdminExhibitorModule,
    PartnerModule,
    RolesModule,
    CustomUiModule: AdminCustomUiModule,
    TagModule,
    StreamModule: AdminStreamModule,
    AdminTrainingModule,
    AdminTrainingSectionModule,
    AdminTrainingLessonModule,
    TrainingNoteModule,
  },
};
