<template>
  <app-search-header-item
    :items="filteredExhibitorsBySearch"
    item="exhibitors"
    :eventHasItem="eventHasExhibitor"
    :maximumDisplayedElements="maximumDisplayedElements"
  >
    <v-list-item
      v-for="(exhibitor, index) in filteredExhibitorsBySearch.slice(0, maximumDisplayedElements)"
      :key="`kisoque-${index}`"
      :to="`/events/${event.id}/exhibitors/${exhibitor.id}`"
      @click="onCloseMenu()"
    >
      <v-list-item-content>
        <v-list-item-title
          class="d-flex flex-sm-row flex-column flex-wrap justify-sm-space-between"
        >
          {{ exhibitor.name }}

          <div class="d-flex flex-wrap">
            <v-chip
              class="ms-sm-2"
              small
              v-for="item in exhibitorTags(exhibitor)"
              :key="`exhibitor-item-${exhibitor.name}-${item}`"
            >
              {{ item }}
            </v-chip>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </app-search-header-item>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';

import AppSearchHeaderItem from './AppSearchHeaderItem.vue';

export default {
  name: 'SearchExhibitor',
  components: {
    AppSearchHeaderItem,
  },
  props: {
    event: {
      type: Object,
    },
    maximumDisplayedElements: {
      type: Number,
    },
    includeSearchNoSensitive: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors']),
    eventHasExhibitor() {
      return !!this.exhibitors.length;
    },
    filteredExhibitorsBySearch() {
      return this.exhibitors.filter(
        ({
          address,
          documents,
          email,
          facebook,
          instagram,
          kioskOptions,
          kioskTabs,
          linkedin,
          name,
          phoneNumber,
          twitter,
          users,
          website,
          youtube,
        }) => {
          const tags = kioskOptions?.tags ? kioskOptions?.tags.split(',') : '';
          const docs = documents ? documents?.map((doc) => doc.name) : [];
          const usersFullNames = users
            ? users?.map((user) => `${user?.firstName}${user?.lastName}`)
            : [];
          const tabs = kioskTabs ?? [];
          const kioskTabsSections = tabs?.flatMap((tab) =>
            tab.sections?.flatMap(({ title, summary, body, tags, resources }) => [
              title,
              summary,
              body,
              ...tags,
              ...resources.map((res) => res.name),
            ]),
          );

          return [
            name,
            email,
            website,
            phoneNumber,
            address,
            twitter,
            youtube,
            facebook,
            instagram,
            linkedin,
            ...tags,
            ...docs,
            ...usersFullNames,
            ...tabs?.map((tab) => tab.name),
            ...kioskTabsSections,
          ].some(this.includeSearchNoSensitive);
        },
      );
    },
  },
  methods: {
    exhibitorTags(exhibitor) {
      const tags = exhibitor?.kioskOptions?.tags ?? [];
      return tags.length === 0 ? [] : exhibitor?.kioskOptions?.tags?.split(',');
    },
    onCloseMenu() {
      this.$emit('close');
    },
  },
};
</script>
