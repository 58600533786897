import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { MatchingAnswer, MatchingQuestion, MessageType } from '@/models';
import matchingService from '@/services/matching/matching.service';
import i18n from '@/i18n';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  AgnosticMatchingModule,
  MatchingQuestionsState,
  MatchingQuestionsStatus,
  MatchingQuestionState,
  MatchingQuestionStatus,
} from '@/stores/agnostic/modules/matching/agnostic-matching.module';
import {
  GET_MATCHING_QUESTION,
  GET_MATCHING_QUESTION_ERROR,
  GET_MATCHING_QUESTION_SUCCESS,
  GET_MATCHING_QUESTIONS,
  GET_MATCHING_QUESTIONS_ERROR,
  GET_MATCHING_QUESTIONS_SUCCESS,
} from '@/stores/agnostic/actions/matching/matching.actions';
import {
  CLEAR_MATCHING_QUESTION,
  CREATE_MATCHING_ANSWER,
  CREATE_MATCHING_ANSWER_ERROR,
  CREATE_MATCHING_ANSWER_SUCCESS,
  CREATE_MATCHING_QUESTION,
  CREATE_MATCHING_QUESTION_ERROR,
  CREATE_MATCHING_QUESTION_SUCCESS,
  DELETE_MATCHING_ANSWER,
  DELETE_MATCHING_ANSWER_ERROR,
  DELETE_MATCHING_ANSWER_SUCCESS,
  DELETE_MATCHING_QUESTION,
  DELETE_MATCHING_QUESTION_ERROR,
  DELETE_MATCHING_QUESTION_SUCCESS,
  SAVE_MATCHING_ANSWER,
  SAVE_MATCHING_QUESTION,
  UPDATE_MATCHING_ANSWER,
  UPDATE_MATCHING_ANSWER_ERROR,
  UPDATE_MATCHING_ANSWER_SUCCESS,
  UPDATE_MATCHING_QUESTION,
  UPDATE_MATCHING_QUESTION_ERROR,
  UPDATE_MATCHING_QUESTION_SUCCESS,
} from '@/stores/umanize-admin/actions/matching/admin-matching.actions';

const matchingQuestionsState: MatchingQuestionsState = {
  questions: [],
  status: {
    error: null,
    isAnswering: false,
    areLoading: true,
    areLoaded: false,
    isSaving: false,
  },
};

const matchingQuestionState: MatchingQuestionState = {
  question: null,
  status: {
    isLoading: false,
    isSaving: false,
    error: null,
  },
};

export interface AdminMatchingQuestionsState {
  questions: MatchingQuestionsState;
  question: MatchingQuestionState;
}

const state: AdminMatchingQuestionsState = {
  questions: matchingQuestionsState,
  question: matchingQuestionState,
};

const actions = {
  [GET_MATCHING_QUESTIONS]: AgnosticMatchingModule.actions.getMatchingQuestions,
  [GET_MATCHING_QUESTION]: AgnosticMatchingModule.actions.getMatchingQuestion,
  async [SAVE_MATCHING_QUESTION](
    { dispatch }: ActionContext<MatchingQuestionsState, RootState>,
    questionForm: MatchingQuestion,
  ) {
    const dispatchEvent = questionForm.id ? UPDATE_MATCHING_QUESTION : CREATE_MATCHING_QUESTION;
    await dispatch(dispatchEvent, questionForm);
  },
  async [UPDATE_MATCHING_QUESTION](
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    questionForm: MatchingQuestion,
  ) {
    commit(UPDATE_MATCHING_QUESTION);

    try {
      await matchingService.updateQuestion(questionForm);
      commit(UPDATE_MATCHING_QUESTION_SUCCESS);
    } catch (error) {
      commit(UPDATE_MATCHING_QUESTION_ERROR, error);
    }
  },
  async [CREATE_MATCHING_QUESTION](
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    questionForm: MatchingQuestion,
  ) {
    commit(CREATE_MATCHING_QUESTION);

    try {
      const question = await matchingService.createQuestion(questionForm);
      commit(CREATE_MATCHING_QUESTION_SUCCESS, question);
    } catch (error) {
      commit(CREATE_MATCHING_QUESTION_ERROR, error);
    }
  },
  async [DELETE_MATCHING_QUESTION](
    { commit, dispatch }: ActionContext<MatchingQuestionsState, RootState>,
    { eventId, questionId }: { eventId: string; questionId: string },
  ) {
    commit(DELETE_MATCHING_QUESTION);

    try {
      await matchingService.deleteQuestion(eventId, questionId);
      commit(DELETE_MATCHING_QUESTION_SUCCESS);
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`admin.matching.question.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
      commit(DELETE_MATCHING_QUESTION_ERROR, error);
    }
  },
  async [SAVE_MATCHING_ANSWER](
    { dispatch }: ActionContext<MatchingQuestionsState, RootState>,
    answer: MatchingAnswer,
  ) {
    const dispatchAction = answer.id ? UPDATE_MATCHING_ANSWER : CREATE_MATCHING_ANSWER;
    await dispatch(dispatchAction, answer);
  },
  async [UPDATE_MATCHING_ANSWER](
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    answer: MatchingAnswer,
  ) {
    commit(UPDATE_MATCHING_ANSWER);

    try {
      await matchingService.updateQuestionAnswer(answer);
      commit(UPDATE_MATCHING_ANSWER_SUCCESS);
    } catch (error) {
      commit(UPDATE_MATCHING_ANSWER_ERROR, error);
    }
  },
  async [DELETE_MATCHING_ANSWER](
    { commit, dispatch }: ActionContext<MatchingQuestionsState, RootState>,
    answer: MatchingAnswer,
  ) {
    commit(DELETE_MATCHING_ANSWER);

    try {
      await matchingService.deleteQuestionAnswer(answer);
      commit(DELETE_MATCHING_ANSWER_SUCCESS);
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`admin.matching.answer.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
      commit(DELETE_MATCHING_ANSWER_ERROR, error);
    }
  },
  async [CREATE_MATCHING_ANSWER](
    { commit }: ActionContext<MatchingQuestionsState, RootState>,
    answer: MatchingAnswer,
  ) {
    commit(CREATE_MATCHING_ANSWER);

    try {
      await matchingService.createQuestionAnswer(answer);
      commit(CREATE_MATCHING_ANSWER_SUCCESS);
    } catch (error) {
      commit(CREATE_MATCHING_ANSWER_ERROR, error);
    }
  },
  async [CLEAR_MATCHING_QUESTION]({
    commit,
  }: ActionContext<AdminMatchingQuestionsState, RootState>) {
    commit(CLEAR_MATCHING_QUESTION);
  },
};

const mutations = {
  [GET_MATCHING_QUESTIONS](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [GET_MATCHING_QUESTIONS_SUCCESS](
    state: AdminMatchingQuestionsState,
    questions: MatchingQuestion[],
  ) {
    state.questions = {
      ...state.questions,
      status: {
        ...state.questions.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      questions: [...questions],
    };
  },
  [GET_MATCHING_QUESTIONS_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [GET_MATCHING_QUESTION](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.questions.status,
      isLoading: true,
    };
  },
  [GET_MATCHING_QUESTION_SUCCESS](state: AdminMatchingQuestionsState, question: MatchingQuestion) {
    state.question = {
      question,
      status: {
        ...state.question.status,
        isLoading: false,
      },
    };
  },
  [GET_MATCHING_QUESTION_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.question.status = {
      ...state.questions.status,
      isLoading: false,
      error,
    };
  },
  [CREATE_MATCHING_QUESTION](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.question.status,
      isSaving: true,
    };
  },
  [CREATE_MATCHING_QUESTION_SUCCESS](state: AdminMatchingQuestionsState, question) {
    state.question = {
      ...state.question,
      question,
      status: {
        ...state.question.status,
        isSaving: false,
      },
    };
  },
  [CREATE_MATCHING_QUESTION_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.question.status = {
      ...state.question.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_MATCHING_QUESTION](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.question.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_MATCHING_QUESTION_SUCCESS](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.question.status,
      isSaving: false,
    };
  },
  [UPDATE_MATCHING_QUESTION_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.question.status = {
      ...state.question.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_MATCHING_QUESTION](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.question.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_MATCHING_QUESTION_SUCCESS](state: AdminMatchingQuestionsState) {
    state.question.status = {
      ...state.question.status,
      isSaving: false,
    };
  },
  [DELETE_MATCHING_QUESTION_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.question.status = {
      ...state.question.status,
      isSaving: false,
      error,
    };
  },
  [CREATE_MATCHING_ANSWER](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: true,
      error: null,
    };
  },
  [CREATE_MATCHING_ANSWER_SUCCESS](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
    };
  },
  [CREATE_MATCHING_ANSWER_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_MATCHING_ANSWER](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_MATCHING_ANSWER_SUCCESS](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
    };
  },
  [UPDATE_MATCHING_ANSWER_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_MATCHING_ANSWER](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_MATCHING_ANSWER_SUCCESS](state: AdminMatchingQuestionsState) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
    };
  },
  [DELETE_MATCHING_ANSWER_ERROR](state: AdminMatchingQuestionsState, error: ErrorMessage) {
    state.questions.status = {
      ...state.questions.status,
      isSaving: false,
      error,
    };
  },
  [CLEAR_MATCHING_QUESTION](state: AdminMatchingQuestionsState) {
    state.question.question = null;
  },
};

const getters = {
  questionsState: (state: AdminMatchingQuestionsState): MatchingQuestionsState => state.questions,
  matchingQuestions: (
    _,
    { questionsState }: { questionsState: MatchingQuestionsState },
  ): MatchingQuestion[] => questionsState?.questions || [],
  questionsStatus: (
    _,
    { questionsState }: { questionsState: MatchingQuestionsState },
  ): MatchingQuestionsStatus => questionsState?.status || null,
  questionsAreLoading: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): boolean => questionsStatus?.areLoading || false,
  questionsAreLoaded: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): boolean => questionsStatus?.areLoaded || false,
  questionsError: (
    _,
    { questionsStatus }: { questionsStatus: MatchingQuestionsStatus },
  ): ErrorMessage => questionsStatus?.error || null,

  questionState: (state: AdminMatchingQuestionsState): MatchingQuestionState => state.question,
  matchingQuestion: (
    _,
    { questionState }: { questionState: MatchingQuestionState },
  ): MatchingQuestion => questionState?.question || null,
  questionStatus: (
    _,
    { questionState }: { questionState: MatchingQuestionState },
  ): MatchingQuestionStatus => questionState?.status || null,
  questionIsLoading: (_, { questionStatus }: { questionStatus: MatchingQuestionStatus }): boolean =>
    questionStatus?.isLoading || false,
  questionError: (
    _,
    { questionStatus }: { questionStatus: MatchingQuestionStatus },
  ): ErrorMessage => questionStatus?.error || null,
};

export const AdminMatchingModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
