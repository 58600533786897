import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';

import { Exhibitor, MessageType } from '@/models';
import exhibitorService from '@/services/exhibitor/exhibitor.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import {
  AgnosticExhibitorModule,
  CurrentExhibitorState,
  CurrentExhibitorStatus,
  ExhibitorsState,
  ExhibitorsStatus,
} from '@/stores/agnostic/modules/exhibitor/agnostic-exhibitor.module';
import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
  GET_EXHIBITOR_ERROR,
  GET_EXHIBITOR_SUCCESS,
  GET_EXHIBITORS,
  GET_EXHIBITORS_ERROR,
  GET_EXHIBITORS_SUCCESS,
  DELETE_EXHIBITOR,
  DELETE_EXHIBITOR_SUCCESS,
  DELETE_EXHIBITOR_ERROR,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  ADD_EXHIBITOR,
  ADD_EXHIBITOR_ERROR,
  ADD_EXHIBITOR_SUCCESS,
  CLEAR_EXHIBITOR_VIDEO,
  LINK_USER,
  LINK_USER_ERROR,
  LINK_USER_SUCCESS,
  SAVE_EXHIBITOR,
  UNLINK_USER,
  UNLINK_USER_ERROR,
  UNLINK_USER_SUCCESS,
  UPDATE_EXHIBITOR,
  UPDATE_EXHIBITOR_ERROR,
  UPDATE_EXHIBITOR_SUCCESS,
  UPLOAD_EXHIBITOR_SCREENSHOT,
  UPLOAD_EXHIBITOR_SCREENSHOT_ERROR,
  UPLOAD_EXHIBITOR_SCREENSHOT_SUCCESS,
  UPLOAD_EXHIBITOR_VIDEO,
  UPLOAD_EXHIBITOR_VIDEO_ERROR,
  UPLOAD_EXHIBITOR_VIDEO_SUCCESS,
} from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import KioskUtil from '@/helpers/kiosk/kiosk.helper';
import { ExhibitorScreenShotType } from '@/models/exhibitor/ExhibitorScreenShotType';
import i18n from '@/i18n';

export interface AdminExhibitorState {
  exhibitor: CurrentExhibitorState;
  exhibitors: ExhibitorsState;
  videoUpload: VideoUploadState;
}

export interface VideoFile {
  title: string;
  file: File;
}

export interface VideoUploadState {
  video: VideoFile;
  status: {
    error: ErrorMessage;
    isSending: boolean;
    isUploaded: boolean;
  };
}

const videoUploadState: VideoUploadState = {
  video: null,
  status: {
    error: null,
    isSending: false,
    isUploaded: false,
  },
};

const exhibitorState: CurrentExhibitorState = {
  exhibitor: null,
  status: {
    error: null,
    isSaving: false,
    isLoading: true,
    isSavingScreenshot: false,
  },
};

const exhibitorsState: ExhibitorsState = {
  exhibitors: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const state: AdminExhibitorState = {
  exhibitor: exhibitorState,
  exhibitors: exhibitorsState,
  videoUpload: videoUploadState,
};

const actions = {
  [GET_EXHIBITOR]: AgnosticExhibitorModule.actions.getExhibitor,
  [GET_EXHIBITORS]: AgnosticExhibitorModule.actions.getExhibitors,
  [CLEAR_EXHIBITOR]: AgnosticExhibitorModule.actions.clearExhibitor,
  async [SAVE_EXHIBITOR](
    { commit, dispatch }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitor: Exhibitor;
      isSavingScreenshot?: boolean;
    },
  ) {
    commit(SAVE_EXHIBITOR);
    if (payload?.exhibitor?.id) {
      await dispatch(UPDATE_EXHIBITOR, payload);
    } else {
      await dispatch(ADD_EXHIBITOR, payload);
    }
  },
  async [ADD_EXHIBITOR](
    { commit, dispatch }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitor: Exhibitor;
    },
  ) {
    commit(ADD_EXHIBITOR);

    try {
      await exhibitorService.create(payload);
      commit(ADD_EXHIBITOR_SUCCESS);
    } catch (error) {
      commit(ADD_EXHIBITOR_ERROR, error);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: error,
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    }
  },
  async [UPDATE_EXHIBITOR](
    { commit }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitor: Exhibitor;
      isSavingScreenshot?: boolean;
    },
  ) {
    commit(UPDATE_EXHIBITOR, payload.isSavingScreenshot);

    try {
      await exhibitorService.update(payload);
      commit(UPDATE_EXHIBITOR_SUCCESS);
    } catch (error) {
      commit(UPDATE_EXHIBITOR_ERROR, error);
    }
  },
  async [DELETE_EXHIBITOR](
    { commit, dispatch }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitorId: string;
    },
  ) {
    commit(DELETE_EXHIBITOR);

    try {
      await exhibitorService.delete(payload?.eventId, payload?.exhibitorId);

      commit(DELETE_EXHIBITOR_SUCCESS, payload?.exhibitorId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('exhibitors.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`exhibitors.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_EXHIBITOR_ERROR, error);
    }
  },
  async [LINK_USER](
    { commit, dispatch }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitorId: string;
      userId: string;
    },
  ) {
    commit(LINK_USER);

    try {
      await exhibitorService.link(payload);
      commit(LINK_USER_SUCCESS);
    } catch (error) {
      commit(LINK_USER_ERROR, error);
      if (error.status === 409) {
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t('admin.exhibitors.errors.409'),
            type: MessageType.error,
          },
          { root: true },
        );
      }
    }
  },
  async [UNLINK_USER](
    { commit }: ActionContext<AdminExhibitorState, RootState>,
    payload: {
      eventId: string;
      exhibitorId: string;
      userId: string;
    },
  ) {
    commit(UNLINK_USER);

    try {
      await exhibitorService.unLink(payload);
      commit(UNLINK_USER_SUCCESS);
    } catch (error) {
      commit(UNLINK_USER_ERROR, error);
    }
  },
  async [UPLOAD_EXHIBITOR_VIDEO](
    { commit }: ActionContext<VideoUploadState, RootState>,
    {
      eventId,
      exhibitorId,
      video,
    }: {
      eventId: string;
      exhibitorId: string;
      video: File;
    },
  ) {
    commit(UPLOAD_EXHIBITOR_VIDEO);

    try {
      const uploadedFile = await exhibitorService.upload(eventId, exhibitorId, video);

      commit(UPLOAD_EXHIBITOR_VIDEO_SUCCESS, uploadedFile);
    } catch (error) {
      commit(UPLOAD_EXHIBITOR_VIDEO_ERROR, error);
    }
  },
  async [CLEAR_EXHIBITOR_VIDEO]({ commit }: ActionContext<VideoUploadState, RootState>) {
    commit(CLEAR_EXHIBITOR_VIDEO);
  },
  async [UPLOAD_EXHIBITOR_SCREENSHOT](
    { commit }: ActionContext<VideoUploadState, RootState>,
    {
      eventId,
      exhibitorId,
      screenshot,
      pictureType,
    }: {
      eventId: string;
      exhibitorId: string;
      screenshot: Blob;
      pictureType: ExhibitorScreenShotType;
    },
  ) {
    commit(UPLOAD_EXHIBITOR_SCREENSHOT);

    try {
      await exhibitorService.uploadScreenShot(eventId, exhibitorId, screenshot, pictureType);

      commit(UPLOAD_EXHIBITOR_SCREENSHOT_SUCCESS);
    } catch (error) {
      commit(UPLOAD_EXHIBITOR_SCREENSHOT_ERROR, error);
    }
  },
  async [CLEAR_EXHIBITOR_VIDEO]({ commit }: ActionContext<VideoUploadState, RootState>) {
    commit(CLEAR_EXHIBITOR_VIDEO);
  },
};

const mutations = {
  [DELETE_EXHIBITOR](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_EXHIBITOR_SUCCESS](state: AdminExhibitorState, exhibitorId: string) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error: null,
    };
    state.exhibitors.exhibitors = state.exhibitors.exhibitors.filter(
      (exhibitor) => exhibitor.id !== exhibitorId,
    );
  },
  [DELETE_EXHIBITOR_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error,
    };
  },
  [GET_EXHIBITOR](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_EXHIBITOR_SUCCESS](state: AdminExhibitorState, exhibitor: Exhibitor) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isLoading: false,
        error: null,
      },
      exhibitor: {
        ...exhibitor,
      },
    };
  },
  [GET_EXHIBITOR_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isLoading: false,
        error,
      },
    };
  },
  [GET_EXHIBITORS](state: AdminExhibitorState) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_EXHIBITORS_SUCCESS](state: AdminExhibitorState, exhibitors: Exhibitor[]) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: false,
        error: null,
      },
      exhibitors: [...exhibitors],
    };
  },
  [GET_EXHIBITORS_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: false,
        error,
      },
    };
  },
  [CLEAR_EXHIBITOR](state: AdminExhibitorState) {
    state.exhibitor = {
      ...state.exhibitor,
      exhibitor: null,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        isLoading: false,
        error: null,
      },
    };
  },
  [SAVE_EXHIBITOR](state: AdminExhibitorState) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: true,
        error: null,
      },
    };
  },
  [ADD_EXHIBITOR](state: AdminExhibitorState) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: true,
        error: null,
      },
    };
  },
  [ADD_EXHIBITOR_SUCCESS](state: AdminExhibitorState) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        error: null,
      },
    };
  },
  [ADD_EXHIBITOR_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        error,
      },
    };
  },
  [UPDATE_EXHIBITOR](state: AdminExhibitorState, isSavingScreenshot) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: true,
        isSavingScreenshot: !!isSavingScreenshot,
        error: null,
      },
    };
  },
  [UPDATE_EXHIBITOR_SUCCESS](state: AdminExhibitorState) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        error: null,
      },
    };
  },
  [UPDATE_EXHIBITOR_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        isSavingScreenshot: false,
        error,
      },
    };
  },
  [LINK_USER](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: true,
      error: null,
    };
  },
  [LINK_USER_SUCCESS](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error: null,
    };
  },
  [LINK_USER_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error,
    };
  },
  [UNLINK_USER](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: true,
      error: null,
    };
  },
  [UNLINK_USER_SUCCESS](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error: null,
    };
  },
  [UNLINK_USER_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error,
    };
  },
  [UPLOAD_EXHIBITOR_VIDEO](state: AdminExhibitorState) {
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: true,
      isUploaded: false,
      error: null,
    };
  },
  [UPLOAD_EXHIBITOR_VIDEO_SUCCESS](state: AdminExhibitorState, video: VideoFile) {
    state.videoUpload.video = {
      ...video,
    };
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: false,
      isUploaded: true,
      error: null,
    };
  },
  [UPLOAD_EXHIBITOR_VIDEO_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: false,
      isUploaded: false,
      error,
    };
  },
  [UPLOAD_EXHIBITOR_SCREENSHOT](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: true,
      isSavingScreenshot: true,
      error: null,
    };
  },
  [UPLOAD_EXHIBITOR_SCREENSHOT_SUCCESS](state: AdminExhibitorState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      isSavingScreenshot: false,
      error: null,
    };
  },
  [UPLOAD_EXHIBITOR_SCREENSHOT_ERROR](state: AdminExhibitorState, error: ErrorMessage) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      isSavingScreenshot: false,
      error,
    };
  },
  [CLEAR_EXHIBITOR_VIDEO](state) {
    state.video = null;
  },
};

const getters = {
  exhibitorState: (state: AdminExhibitorState): CurrentExhibitorState => state.exhibitor,
  exhibitor: (_, { exhibitorState }: { exhibitorState: CurrentExhibitorState }): Exhibitor =>
    exhibitorState?.exhibitor,

  exhibitorStatus: (
    _,
    { exhibitorState }: { exhibitorState: CurrentExhibitorState },
  ): CurrentExhibitorStatus => exhibitorState?.status,
  exhibitorIsLoading: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): boolean => exhibitorStatus?.isLoading,
  exhibitorIsSaving: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): boolean => exhibitorStatus?.isSaving,
  exhibitorIsSavingScreenshot: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): boolean => exhibitorStatus?.isSavingScreenshot || false,
  exhibitorError: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): ErrorMessage => exhibitorStatus?.error,

  exhibitorsState: (state: AdminExhibitorState): ExhibitorsState => state.exhibitors,
  exhibitors: (_, { exhibitorsState }: { exhibitorsState: ExhibitorsState }): Exhibitor[] =>
    exhibitorsState?.exhibitors,
  exhibitorsWithCompletion: (_, { exhibitors }: { exhibitors: Exhibitor[] }): Exhibitor[] =>
    exhibitors.map((exhibitor) => ({
      ...exhibitor,
      completion: `${KioskUtil.getProgress(exhibitor)}%`,
    })),
  exhibitorsStatus: (
    _,
    { exhibitorsState }: { exhibitorsState: ExhibitorsState },
  ): ExhibitorsStatus => exhibitorsState?.status,
  exhibitorsAreLoading: (
    _,
    { exhibitorsStatus }: { exhibitorsStatus: ExhibitorsStatus },
  ): boolean => exhibitorsStatus?.areLoading,
  exhibitorsError: (
    _,
    { exhibitorsStatus }: { exhibitorsStatus: ExhibitorsStatus },
  ): ErrorMessage => exhibitorsStatus?.error,
  video: (state: AdminExhibitorState): VideoFile => state.videoUpload.video || null,
  videoIsSending: (state: AdminExhibitorState): boolean =>
    (state.videoUpload.status && state.videoUpload.status.isSending) || false,
  videoIsUploaded: (state: AdminExhibitorState): boolean =>
    (state.videoUpload.status && state.videoUpload.status.isUploaded) || false,
  videoError: (state: AdminExhibitorState): ErrorMessage =>
    (state.videoUpload.status && state.videoUpload.status.error) || null,
};

export const AdminExhibitorModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
