import './registerServiceWorker';
import VueTelInputVuetify from 'vue-tel-input-vuetify';

import VeeValidate from 'vee-validate';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import VueChatScroll from 'vue-chat-scroll';

import Vue from 'vue';
import VueEditor from 'vue2-editor';
import VueYouTubeEmbed from 'vue-youtube-embed';
import App from './App.vue';
import router from './router';
import store from './stores';
import axiosInterceptorSetup from './interceptors/http.interceptor';

import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './filters';
import './unity';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const { worker } = require('./mocks/browser');

  worker.start();
}

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GTAG,
      appName: `Umanize - ${process.env.NODE_ENV}`,
      pageTrackerScreenviewEnabled: true,
      enabled: process.env.NODE_ENV === 'production',
      params: {
        send_page_view: false,
      },
    },
    pageTrackerTemplate(to) {
      return {
        page_path: to.path,
      };
    },
  },
  router,
);

Vue.use(VueYouTubeEmbed);

Vue.use(VueChatScroll);

Vue.use(VueEditor);

Vue.use(VeeValidate);

axiosInterceptorSetup();

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
