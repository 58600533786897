import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

const NAMESPACE = '/stat';

export const PLAY_CONFERENCE = 'play-conference';
export const STOP_CONFERENCE = 'stop-conference';
export const LEAVE_CONFERENCE = 'leave-conference';

export const ENTER_EXHIBITOR = 'enter-exhibitor';
export const EXIT_EXHIBITOR = 'exit-exhibitor';
export const CLICK_EXHIBITOR = 'click-exhibitor';

export const EXHIBITOR_CHAT = 'chat';
export const EXHIBITOR_VIDEOCHAT = 'video-chat';
export const EXHIBITOR_WEBSITE = 'website';
export const EXHIBITOR_APPOINTMENT = 'appointment';
export const EXHIBITOR_FACEBOOK = 'facebook';
export const EXHIBITOR_INSTAGRAM = 'instagram';
export const EXHIBITOR_TWITTER = 'twitter';
export const EXHIBITOR_LINKEDIN = 'linkedin';
export const EXHIBITOR_YOUTUBE = 'youtube';
export const EXHIBITOR_ADDRESS = 'address';
export const EXHIBITOR_PHONE = 'phone';
export const EXHIBITOR_EMAIL = 'email';

export const ENTER_EVENT = 'enter-event';
export const EXIT_EVENT = 'exit-event';

let statSocket;

export const getStatSocket = (): Socket => statSocket;

export const setStatSocket = (socket) => {
  statSocket = socket;
};

export const connectToStatNamespace = (): Socket => connectToNamespace(NAMESPACE);

export const disconnectFromStatNamespace = () => {
  statSocket.disconnect();
  statSocket = null;
};
