import { UserEventAvailabilitySlot } from '@/models/user-availability/user-event-availability-slot';
import HttpService from '@/services/http/http.service';
import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import { NewUserEventAvailabilitySlot } from '@/models/user-availability/new-user-event-availability-slot';
import { UserEventAppointmentSlot } from '@/models/user-availability/user-event-appointment-slot';

type GetUserAvailabilityParameters = {
  eventId: string;
};
export const getUserAvailability = async ({
  eventId,
}: GetUserAvailabilityParameters): Promise<UserEventAvailabilitySlot[]> =>
  HttpService.perform<UserEventAvailabilitySlot[]>(
    HttpMethod.get,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.SLOTS_ENDPOINT]),
  );

type CreateUserAvailabilityParameters = {
  eventId: string;
  payload: NewUserEventAvailabilitySlot;
};
export const createUserAvailability = async ({
  eventId,
  payload,
}: CreateUserAvailabilityParameters): Promise<UserEventAvailabilitySlot> =>
  HttpService.perform<UserEventAvailabilitySlot>(
    HttpMethod.post,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.SLOTS_ENDPOINT]),
    payload,
  );

type DeleteUserAvailabilityParameters = {
  eventId: string;
  slotId: string;
};
export const deleteUserAvailability = async ({
  eventId,
  slotId,
}: DeleteUserAvailabilityParameters): Promise<void> =>
  HttpService.perform<void>(
    HttpMethod.delete,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.SLOTS_ENDPOINT,
      slotId,
    ]),
  );

type UpdateUserAvailabilitySlotDurationParameters = {
  eventId: string;
  slotDurationInMin: number;
};
export const updateUserAvailabilitySlotDuration = async ({
  eventId,
  slotDurationInMin,
}: UpdateUserAvailabilitySlotDurationParameters): Promise<UserEventAvailabilitySlot[]> =>
  HttpService.perform<UserEventAvailabilitySlot[]>(
    HttpMethod.put,
    HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
      eventId,
      HttpHelper.SLOTS_ENDPOINT,
      HttpHelper.DURATION_ENDPOINT,
    ]),
    { slotDurationInMin },
  );

type GetAvailableAppointmentSlotsParameters = {
  userId: string;
  eventId: string;
};
export const getAvailableAppointmentSlots = async ({
  userId,
  eventId,
}: GetAvailableAppointmentSlotsParameters): Promise<UserEventAppointmentSlot[]> =>
  HttpService.perform<UserEventAppointmentSlot[]>(
    HttpMethod.get,
    HttpHelper.buildApiRoute(HttpHelper.USER_ENDPOINT, [
      userId,
      HttpHelper.SLOTS_ENDPOINT,
      HttpHelper.EVENTS_ENDPOINT,
      eventId,
    ]),
  );
