import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { EVENT_MODULE } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export const ADMIN_EVENT_MODULE = `${UMANIZE_ADMIN_MODULE}/${EVENT_MODULE}`;

export const SAVE_EVENT = 'SAVE_EVENT';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';
