import { DateTime } from 'luxon';
import { Chat } from '@/models';

/**
 * Sorts chats by creation date of last message
 * @param chats a list of chats
 */
export const sortChats = (chats: Chat[]): Chat[] =>
  chats.sort((x, y) => {
    const xLastMessageCreated = x.lastMessage
      ? DateTime.fromISO(x.lastMessage.created).toMillis()
      : 0;
    const yLastMessageCreated = y.lastMessage
      ? DateTime.fromISO(y.lastMessage.created).toMillis()
      : 0;

    if (xLastMessageCreated === yLastMessageCreated) {
      return 0;
    }

    if (xLastMessageCreated === 0) {
      return 1;
    }

    if (yLastMessageCreated === 0) {
      return -1;
    }

    return yLastMessageCreated - xLastMessageCreated;
  });

/**
 * Merge two lists of chats. Does not override the current chats messages
 * @param current
 * @param newChats
 */
export const mergeChats = (current: Chat[], newChats: Chat[]) =>
  newChats.map((it) => current.find((existing) => existing.id === it.id) || it);
