import { Chat, ChatMessage, SocketChatMessage } from '@/models';
import DataUtil from '@/helpers/data/data.helper';

export const socketChatMessagesToChatMessage: (
  messages: SocketChatMessage[],
  chat: Chat,
) => ChatMessage[] = (messages, chat) =>
  DataUtil.sortByStringAsc(
    messages.map((message) => socketChatMessageToChatMessage(message, chat)),
    'dateCreated',
  );

export const socketChatMessageToChatMessage: (
  message: SocketChatMessage,
  chat: Chat,
) => ChatMessage = (message, chat) => {
  const user = chat.users?.find((it) => it.id === message.userId);
  return {
    id: message.id,
    body: message.msg,
    dateCreated: message.created,
    dateUpdated: message.lastUpdated,
    attributes: {
      id: message.userId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      avatar: user?.avatar,
    },
    from: message.userId,
  };
};
