import { ActionContext } from 'vuex';
import { Payment } from '@/models/ticketing/payment.model';

import { Ticket } from '@/models';
import TicketService from '@/services/ticket/ticket.service';
import { RootState } from '@/stores/store.model';
import i18n from '@/i18n';
import {
  GET_TICKETS,
  GET_TICKETS_ERROR,
  GET_TICKETS_SUCCESS,
  SELECT_TICKET,
} from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';

export enum TicketErrorType {
  STRIPE = 'stripe',
  UNEXPECTED = 'unexpected',
}

export interface TicketError {
  message: string;
  type: TicketErrorType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface TicketsState {
  tickets: Ticket[];
  selectedTicket?: Ticket;
  newsletter: boolean;
  receiveInfo: boolean;
  payment?: Payment;
  status: {
    error?: TicketError;
    isSending: boolean;
    isLoaded: boolean;
  };
}

const actions = {
  async getTickets(
    { commit, dispatch }: ActionContext<TicketsState, RootState>,
    eventId: string,
  ): Promise<Ticket[]> {
    commit(GET_TICKETS);

    try {
      const tickets = await TicketService.getEventTickets({ eventId, noRestriction: false });
      commit(GET_TICKETS_SUCCESS, tickets);
      if (tickets && tickets.length === 1) {
        await dispatch(SELECT_TICKET, tickets[0]);
      }

      return tickets;
    } catch (err) {
      const error = i18n.t(`ticketing.errors.${err.status}`);
      commit(GET_TICKETS_ERROR, error);
      throw err;
    }
  },
  async selectTicket(
    { commit }: ActionContext<TicketsState, RootState>,
    ticket: Ticket,
  ): Promise<Ticket> {
    const gst = ticket.price * 0.05;
    const qst = ticket.price * 0.09975;
    const total = ticket.price + gst + qst;

    commit(SELECT_TICKET, {
      ...ticket,
      gst,
      qst,
      total,
    });

    return ticket;
  },
};

export const AgnosticTicketModule = {
  actions,
};
