<template>
  <v-navigation-drawer
    v-if="displayMenu"
    class="main-menu"
    v-model="isMenuOpen"
    app
    temporary
    left
    bottom
    @input="onMenuChange()"
    @transitionend="onMenuChange()"
  >
    <v-sheet
      @click="$emit('toggle-display-menu')"
      class="main-menu__pull-me"
      :class="{ 'main-menu-container__pull-me--opened': isOpen }"
      outlined
      data-test-id="pull-me-btn"
    >
      <v-icon v-if="!isOpen" color="primaryBackground">mdi-home</v-icon>

      <v-icon
        v-else
        color="primaryBackground"
        @click="$emit('close-menu')"
        data-test-id="close-btn"
      >
        mdi-close
      </v-icon>
    </v-sheet>

    <template v-if="actionsShown">
      <div data-test-id="nav-actions" data-scroll-id="nav" class="main-menu__drawer">
        <menu-buttons
          class="drawer__header"
          :menu="menu"
          :isLoggedIn="isLoggedIn"
          colorText="white"
          btnColor="transparent"
        />

        <v-divider v-if="!!menu.length" class="mt-4" color="grey" />

        <div class="drawer__contents" v-on:wheel="onScroll" v-on:scroll="onScroll">
          <div id="contents__actions" class="contents__actions">
            <custom-buttons />

            <v-divider v-if="eventHasExternalLinks" class="mt-4" color="grey" />

            <custom-external-links-buttons class="mt-4" />
          </div>

          <div
            id="contents__video"
            class="contents__video"
            style="background: transparent; padding-bottom: 0"
            v-if="eventHasPromotionalVideo"
          >
            <v-overlay
              v-if="showScrollMore() && !isScrollToBottom"
              absolute
              class="overlay px-4"
              color="#272727"
            >
              <div>
                <v-icon>mdi-chevron-double-down</v-icon>
                {{ $t('menu.scrollForMore') }}
              </div>
            </v-overlay>
            <div style="padding-bottom: 15px; background: #272727">
              <h4 v-if="event.promotionalVideoTitle" class="video__title">
                {{ event.promotionalVideoTitle }}
              </h4>

              <vimeo-video
                v-if="event.promotionalVideo"
                :videoUrl="event.promotionalVideo"
                disableSound
                data-test-id="video"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="main-menu__content" v-else>
      <v-btn
        v-if="displayBack"
        class="content__back"
        @click="toggleNav('conferenceRooms')"
        depressed
      >
        <v-icon class="back__icon"> mdi-arrow-left-bold </v-icon>
        {{ $t('globals.back') }}
      </v-btn>

      <v-icon color="primaryBackground" class="content__close" @click="toggleNav()">
        mdi-close
      </v-icon>

      <div
        data-test-id="nav-conference-rooms"
        data-scroll-id="nav"
        v-if="isType('conferenceRooms') && eventHasConferenceRoom"
      >
        <div class="content__header">
          <h2 class="header__title">{{ conferenceBtnText }}</h2>

          <AppSearch
            v-if="isOpen"
            class="header__search"
            :search="search.confRoom"
            :dark="true"
            @change="onSearchChange($event, 'confRoom')"
          />
        </div>

        <conference-room-item
          class="conference-room"
          v-for="room in filteredElementsBySearch('confRoom', conferenceRooms)"
          :key="room.id"
          :conference-room="room"
          :accent-color="accentColor"
          :btn-style="btnTextColor"
          @select-room="selectRoom(room)"
        />

        <div
          class="content__none"
          v-if="filteredElementsBySearch('confRoom', conferenceRooms).length === 0"
          data-test-id="conference-rooms-none"
        >
          {{ $t('lobby.conferenceRooms.noResult') }}
        </div>
      </div>

      <div
        data-test-id="nav-conferences"
        data-scroll-id="nav"
        v-if="isType('conferences') || (isType('conferenceRooms') && !eventHasConferenceRoom)"
      >
        <div class="content__header">
          <h2 class="header__title">{{ conferenceBtnText }} - {{ selectedConferenceRoom.name }}</h2>

          <AppSearch
            v-if="isOpen"
            class="header__search"
            :search="search.conf"
            :dark="true"
            @change="onSearchChange($event, 'conf')"
          />
        </div>

        <conference-item
          class="conference"
          v-for="conference in filteredElementsBySearch('conf', displayedConferences)"
          :key="conference.id"
          :conference="conference"
          :agenda="agenda"
          :is-admin="userIsAdmin"
          :now="now"
          :event="event"
          :canGoToSpeaker="canGoToSpeaker"
          :accent-color="accentColor"
          :btn-style="btnTextColor"
          @select-conference="goTo($event, 'Conference', 'conferenceId')"
          @add-to-agenda="addConferenceToAgenda"
          @remove-from-agenda="removeConferenceFromAgenda"
          @select-conference-speaker="goTo($event, 'Survey', 'conferenceId')"
        />

        <div
          v-if="displayedConferences.length === 0"
          class="content__none"
          data-test-id="conferences-none"
        >
          {{ $t('lobby.conferences.none') }}
        </div>

        <div
          v-if="
            displayedConferences.length > 0 &&
            filteredElementsBySearch('conf', displayedConferences).length === 0
          "
          class="content__none"
          data-test-id="conferences-none"
        >
          {{ $t('lobby.conferences.noResult') }}
        </div>
      </div>

      <div data-test-id="nav-assemblies" data-scroll-id="nav" v-if="isType('assemblies')">
        <div class="content__header">
          <h2 class="header__title">{{ generalAssembliesBtnText }}</h2>

          <AppSearch
            v-if="isOpen"
            class="header__search"
            :search="search.assembly"
            :dark="true"
            @change="onSearchChange($event, 'assembly')"
          />
        </div>

        <div
          v-for="generalAssembly in filteredElementsBySearch(
            'assembly',
            accessibleGeneralAssemblies,
          )"
          :key="generalAssembly.id"
        >
          <assembly-item
            v-if="generalAssembly.userAccess.canAccess"
            class="assembly"
            :generalAssembly="generalAssembly"
            :now="now"
            :event="event"
            :is-admin="userIsAdmin"
            :accent-color="accentColor"
            :btn-style="btnTextColor"
            @select-general-assembly="goTo($event, 'GeneralAssembly', 'assemblyId')"
          />
        </div>

        <div
          v-if="accessibleGeneralAssemblies.length === 0"
          class="content__none"
          data-test-id="assemblies-none"
        >
          {{ $t('lobby.generalAssemblies.none') }}
        </div>

        <div
          v-if="
            accessibleGeneralAssemblies.length > 0 &&
            filteredElementsBySearch('assembly', accessibleGeneralAssemblies).length === 0
          "
          class="content__none"
          data-test-id="assemblies-none"
        >
          {{ $t('lobby.generalAssemblies.noResult') }}
        </div>
      </div>

      <div
        class="nav-exhibitors pb-2"
        data-test-id="nav-exhibitors"
        data-scroll-id="nav"
        v-if="isType('exhibitors')"
      >
        <div class="content__header">
          <h2 class="header__title">{{ event2dBtnText }}</h2>

          <AppSearch
            v-if="isOpen"
            class="header__search"
            :search="search.exhibitor"
            :dark="true"
            @change="onSearchChange($event, 'exhibitor')"
          />
        </div>

        <div
          class="nav-exhibitors__result"
          v-for="exhibitor in filteredExhibitorsBySearch('exhibitor', exhibitors)"
          :key="exhibitor.id"
        >
          <exhibitor-item
            class="exhibitor"
            :exhibitor="exhibitor"
            :accent-color="accentColor"
            :btn-style="btnTextColor"
            @select-exhibitor="goTo($event, 'Exhibitor', 'exhibitorId')"
            @search="onSearchChange"
          />
        </div>

        <div v-if="exhibitors.length === 0" class="content__none" data-test-id="exhibitors-none">
          {{ $t('lobby.exhibitors.none') }}
        </div>

        <div
          v-if="
            exhibitors.length > 0 &&
            filteredExhibitorsBySearch('exhibitor', exhibitors).length === 0
          "
          class="content__none"
          data-test-id="exhibitors-none"
        >
          {{ $t('lobby.exhibitors.noResult') }}
        </div>
      </div>

      <div
        data-test-id="nav-discussion-groups"
        data-scroll-id="nav"
        v-if="isType('discussionGroups')"
      >
        <div class="content__header">
          <h2 class="header__title">
            {{ discussionGroupsBtnText }}
          </h2>

          <AppSearch
            class="header__search"
            :search="search.discussionGroup"
            :dark="true"
            @change="onSearchChange($event, 'discussionGroup')"
          />
        </div>

        <discussion-group-item
          class="discussion-group"
          v-for="discussionGroup in filteredElementsBySearch(
            'discussionGroup',
            upComingDiscussionGroups,
          )"
          :key="discussionGroup.id"
          :discussion-group="discussionGroup"
          :agenda="agenda"
          :now="now"
          :event="event"
          :is-admin="userIsAdmin"
          :accent-color="accentColor"
          :btn-style="btnTextColor"
          @select-discussion-group="goTo($event, 'DiscussionGroup', 'discussionGroupId')"
          @add-to-agenda="addDiscussionGroupToAgenda"
          @remove-from-agenda="removeDiscussionGroupFromAgenda"
        />

        <div
          v-if="upComingDiscussionGroups.length === 0"
          class="content__none"
          data-test-id="discussion-groups-none"
        >
          {{ $t('lobby.discussionGroups.none') }}
        </div>

        <div
          v-if="
            upComingDiscussionGroups.length > 0 &&
            filteredElementsBySearch('discussionGroup', upComingDiscussionGroups).length === 0
          "
          class="content__none"
          data-test-id="discussion-groups-none"
        >
          {{ $t('lobby.discussionGroups.noResult') }}
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';

import ConferenceItem from '@/components/conference-item/ConferenceItem.vue';
import ConferenceRoomItem from '@/components/conference-room-item/ConferenceRoomItem.vue';
import AssemblyItem from '@/components/assembly-item/AssemblyItem.vue';
import DiscussionGroupItem from '@/components/discussion-group-item/DiscussionGroupItem.vue';
import ExhibitorItem from '@/components/exhibitor-item/ExhibitorItem.vue';
import VimeoVideo from '@/components/video/Video.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

import { isSpeaker } from '@/models/ticketing/user-ticket';
import MenuUtil from '@/helpers/menu/menu.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  AGENDA_MODULE,
  GET_AGENDA,
  ADD_CONFERENCE_TO_AGENDA,
  REMOVE_CONFERENCE_FROM_AGENDA,
  ADD_DISCUSSION_GROUP_TO_AGENDA,
  REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
} from '@/stores/umanize-app/actions/agenda/agenda.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_CONFERENCE_MODULE,
  SELECT_CONFERENCE_ROOM,
} from '@/stores/umanize-app/actions/conference/app-conference.actions';
import { APP_ASSEMBLY_MODULE } from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import { APP_DISCUSSION_GROUP_MODULE } from '@/stores/umanize-app/actions/discussion-group/discussion-group.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

import {
  GET_CONFERENCE_ROOMS,
  GET_CONFERENCES_FOR_EVENT,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { GET_ALL_ASSEMBLIES } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import { GET_DISCUSSION_GROUPS } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import { GET_EXHIBITORS } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import { GET_CUSTOM_UI } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';
import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';
import {
  GET_ALL_TRAININGS_ADMIN,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import {
  APP_MENU_MODULE,
  SET_SELECTED_CONFERENCE_ROOM,
  TOGGLE_NAV,
  CLEAR_ACTIONS_SHOWN,
  CLEAR_SEARCH,
  SET_SEARCH,
} from '@/stores/umanize-app/actions/menu/menu.actions';

import CustomButtons from './custom-buttons/CustomButtons.vue';
import MenuButtons from './menu-buttons/MenuButtons.vue';
import CustomExternalLinksButtons from './custom-buttons/CustomExternalLinksButtons.vue';

export default {
  name: 'MainMenu',
  components: {
    ConferenceItem,
    ConferenceRoomItem,
    AssemblyItem,
    DiscussionGroupItem,
    ExhibitorItem,
    VimeoVideo,
    AppSearch,
    CustomButtons,
    MenuButtons,
    CustomExternalLinksButtons,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    now: new Date().toISOString(),
    isScrollToBottom: false,

    isMenuOpen: false,
    menu: [],
  }),
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingStateAreLoading', 'allTrainingData']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    ...mapGetters(APP_CONFERENCE_MODULE, ['nextConferences', 'conferenceRooms']),
    ...mapGetters(APP_DISCUSSION_GROUP_MODULE, ['upComingDiscussionGroups']),
    ...mapGetters(APP_ASSEMBLY_MODULE, ['accessibleGeneralAssemblies']),
    ...mapGetters(AGENDA_MODULE, ['agenda']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser', 'loggedInUserRoles']),
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(SHARED_AUTH_MODULE, ['isLoggedIn']),
    ...mapGetters(APP_MENU_MODULE, [
      'selectedConferenceRoom',
      'actionsShown',
      'displayedItemsType',
      'search',
    ]),
    isType() {
      return (type) => this.displayedItemsType === type;
    },
    eventHasPromotionalVideo() {
      return this.event?.promotionalVideo;
    },
    eventHasExternalLinks() {
      return !!this.event?.externalLinks?.length;
    },
    userIsAdmin() {
      return this.loggedInUser.isAdmin;
    },
    displayMenu() {
      return (
        this.userIsAdmin ||
        this.event?.ticket?.userProfileCompleted ||
        !this.event?.ticket?.ticketUserProfile?.profileItems
      );
    },
    displayBack() {
      return this.selectedConferenceRoom && this.conferenceRooms.length > 1;
    },
    filteredExhibitorsBySearch() {
      return (type, items) =>
        this.search[type]
          ? items.filter(
              (i) =>
                i.name.toLowerCase().includes(this.search[type].toLowerCase()) ||
                i.kioskOptions?.tags?.toLowerCase().includes(this.search[type].toLowerCase()),
            )
          : items;
    },
    filteredElementsBySearch() {
      return (type, items) =>
        this.search[type]
          ? items.filter((i) => i.name.toLowerCase().includes(this.search[type].toLowerCase()))
          : items;
    },
    displayedConferences() {
      if (this.selectedConferenceRoom) {
        return this.nextConferences.filter(
          (conference) => conference.roomId === this.selectedConferenceRoom.id,
        );
      }

      return this.nextConferences;
    },
    eventHasConferenceRoom() {
      return this.conferenceRooms?.length > 0;
    },
    canAccessShowRoom() {
      return !isMobile && (this?.event?.ticket?.options?.features?.showroom || this.userIsAdmin);
    },
    canGoToSpeaker() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAdmin(this.loggedInUserRoles, this.event?.id)
      );
    },
    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    btnTextColor() {
      return `color: ${this.customUi?.btnTextColor || '#FFFFFF'}`;
    },
    event2dBtnText() {
      return (
        this.customUi?.event2dBtnText || this.$tc('lobby.exhibitors.title', this.exhibitors.length)
      );
    },
    conferenceBtnText() {
      return (
        this.customUi?.conferenceBtnText ||
        this.$tc('lobby.conferences.title', this.displayedConferences.length)
      );
    },
    generalAssembliesBtnText() {
      return this.customUi?.generalAssembliesBtnText || this.$t('lobby.generalAssemblies.title');
    },
    discussionGroupsBtnText() {
      return (
        this.customUi?.discussionGroupsBtnText ||
        this.$tc('lobby.discussionGroups.title', this.upComingDiscussionGroups.length)
      );
    },
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_ALL_TRAININGS_ADMIN]),
    ...mapActions(APP_CONFERENCE_MODULE, [
      GET_CONFERENCES_FOR_EVENT,
      GET_CONFERENCE_ROOMS,
      SELECT_CONFERENCE_ROOM,
    ]),
    ...mapActions(AGENDA_MODULE, [
      GET_AGENDA,
      ADD_CONFERENCE_TO_AGENDA,
      REMOVE_CONFERENCE_FROM_AGENDA,
      ADD_DISCUSSION_GROUP_TO_AGENDA,
      REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
    ]),
    ...mapActions(APP_DISCUSSION_GROUP_MODULE, [GET_DISCUSSION_GROUPS]),
    ...mapActions(APP_ASSEMBLY_MODULE, [GET_ALL_ASSEMBLIES]),
    ...mapActions(APP_EXHIBITOR_MODULE, [GET_EXHIBITORS]),
    ...mapActions(APP_CUSTOM_UI_MODULE, [GET_CUSTOM_UI]),
    ...mapActions(APP_MENU_MODULE, [
      SET_SELECTED_CONFERENCE_ROOM,
      TOGGLE_NAV,
      CLEAR_ACTIONS_SHOWN,
      SET_SEARCH,
      CLEAR_SEARCH,
    ]),
    onMenuChange() {
      this.$emit('change', this.isMenuOpen);
    },
    onSearchChange(search, type) {
      this[SET_SEARCH]({ search, type });
    },
    toggleNav(type) {
      this[TOGGLE_NAV]({ doc: document, type });
      this[CLEAR_SEARCH]();
    },
    selectRoom(room) {
      this[SET_SELECTED_CONFERENCE_ROOM](room);
      this.toggleNav('conferences');
    },
    goTo(id, name, typeId) {
      this.$router.push({
        name,
        params: {
          eventId: this.$route.params.eventId,
          [typeId]: id,
        },
      });
    },
    addConferenceToAgenda(conference) {
      this[ADD_CONFERENCE_TO_AGENDA](conference);
    },
    removeConferenceFromAgenda(conference) {
      this[REMOVE_CONFERENCE_FROM_AGENDA](conference);
    },
    addDiscussionGroupToAgenda(discussionGroup) {
      this[ADD_DISCUSSION_GROUP_TO_AGENDA](discussionGroup);
    },
    removeDiscussionGroupFromAgenda(discussionGroup) {
      this[REMOVE_DISCUSSION_GROUP_FROM_AGENDA](discussionGroup);
    },
    async initialize() {
      this.isMenuOpen = this.isOpen;
      const { eventId } = this.$route.params;

      await Promise.all([
        this[GET_CONFERENCES_FOR_EVENT](eventId),
        this[GET_CONFERENCE_ROOMS](eventId),
        this[GET_DISCUSSION_GROUPS](eventId),
        this[GET_AGENDA](eventId),
        this[GET_EXHIBITORS]({ eventId }),
        this[GET_CUSTOM_UI]({ eventId }),
        this[GET_ALL_TRAININGS_ADMIN]({ eventId: this.$route.params.eventId }),
      ]);

      if (this.event?.options.generalAssembly) {
        this[GET_ALL_ASSEMBLIES](eventId);
      }

      if (this.conferenceRooms.length === 1) {
        this[SELECT_CONFERENCE_ROOM](this.conferenceRooms[0]);
      }
    },

    showScrollMore() {
      const listData = document.getElementsByClassName('button__wrapper') ?? [];
      if (listData?.length) {
        const totalHeight =
          document.getElementsByClassName('contents__actions')[0]?.clientHeight ?? 0;
        const wrapperVideo =
          document.getElementsByClassName('contents__video')[0]?.clientHeight ?? 0;
        const wrapperHeight = document.getElementsByClassName('main-menu')[0]?.clientHeight ?? 0;
        const drawerHeader =
          document.getElementsByClassName('drawer__header')[0]?.clientHeight ?? 0;
        return (
          wrapperHeight -
            drawerHeader -
            totalHeight -
            wrapperVideo +
            (window.innerHeight * 20) / 100 +
            35 <
          0
        );
      }
      return false;
    },
    onScroll() {
      if (this.showScrollMore()) {
        const position = document
          .getElementsByClassName('contents__actions')[0]
          .getBoundingClientRect();
        if (position.bottom <= window.innerHeight - 13) {
          this.isScrollToBottom = true;
        } else {
          this.isScrollToBottom = false;
        }
      }
    },
  },
  async mounted() {
    this.initialize();
    this.menu = await MenuUtil.getMenu(this.$route);
    const videoHeight = document.getElementById('contents__video')?.clientHeight;
    if (document.getElementById('contents__actions')?.style) {
      document.getElementById('contents__actions').style.paddingBottom = `${videoHeight}px`;
    }
  },
  created() {
    this.timer = window.setInterval(() => {
      this.now = new Date().toISOString();
    }, 1000);
  },
  watch: {
    isOpen(value) {
      this.isMenuOpen = value;
    },
    async $route(to) {
      this.menu = await MenuUtil.getMenu(to);
      this.CLEAR_ACTIONS_SHOWN();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

$header-height: rem(57px);
$header-height-mobile: rem(46px);

.v-navigation-drawer__content {
  overflow-x: visible !important;
}

.main-menu {
  background: #272727 !important;
  width: auto !important;
  overflow: visible;
  color: var(--v-primaryBackground-base);
  height: calc(100% - #{$header-height-mobile});

  &__event-logo {
    width: 100%;
  }

  &__drawer {
    width: auto !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &__drawer::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__drawer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__pull-me {
    @include pull-me();
  }

  & :has(> .nav-exhibitors) {
    width: 95vw;
  }
}

.nav-exhibitors__result {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  grid-row-gap: 2px;
}

.drawer {
  &__header {
    padding: 10px 0;
  }

  &__contents {
    height: 79%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

::v-deep .button {
  width: 100%;
  text-align: center;
  word-break: break-word;

  &__wrapper {
    margin: 1rem 0;
    flex: 1;

    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.overlay {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  height: 35px;
  width: calc(100% - 40px);
  margin-left: 20px;
}

.contents {
  &__video {
    display: none;
    width: 100%;
    padding: 35px 20px;
    background-color: #272727;
  }
}

.content {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 20px 20px;
    padding-right: 20px;
    padding-bottom: 0 !important;
  }

  &__none {
    display: flex;
    justify-content: center;

    padding: 1rem;
  }

  &__close {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }

  &__back {
    height: 40px;
    margin-left: 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: $bold;
  }
}

.back {
  &__icon {
    margin-right: 10px;
  }
}

.header {
  &__title {
    font-size: $xx-large-font-size;
    margin-bottom: 15px;
  }

  &__search {
    max-width: 250px;
  }

  &__item a {
    color: var(--v-primaryBackground-base) !important;
  }
}

.video {
  &__title {
    text-align: left;
  }
}

.conference-room,
.conference,
.exhibitor,
.assembly,
.discussion-group {
  &:hover {
    background-color: var(--v-quaternaryBackground-base);
  }
}

@include breakpoint(small) {
  .main-menu {
    margin-top: rem(44px);
    width: auto !important;
    height: calc(100% - #{$header-height}) !important;
  }

  .drawer {
    &__contents {
      padding: 0 20px 15px 20px;
    }
  }

  .contents {
    &__actions {
      padding-bottom: calc(20vh + 35px);
    }

    &__video {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@include breakpoint(medium) {
  .main-menu {
    margin-top: 0;
    width: auto !important;
    height: calc(100% - #{$header-height}) !important;
    top: auto !important;

    &__content {
      width: 35vw;
      padding: 35px 0 0;
    }
  }

  .drawer {
    &__contents {
      width: 18vw;
      padding: 0 20px 15px 20px;
    }
  }

  .video {
    &__title {
      padding-bottom: 0.5rem;
    }
  }
}
</style>
