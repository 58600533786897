import { DateTime } from 'luxon';
import { PublicProfile } from '@/models/user/public-profile.model';

export class SurveyAnswer {
  public id?: string;

  public eventId: string;

  public conferenceId: string;

  public surveyId: string; // questionId

  public answer: string;

  public created: DateTime;

  public user: PublicProfile;

  constructor({ answer, surveyId, eventId, conferenceId, created, user }) {
    this.answer = answer;
    this.surveyId = surveyId;
    this.eventId = eventId;
    this.conferenceId = conferenceId;
    this.created = created;
    this.user = user;
  }
}
