import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const TRAINING_LESSON_MODULE_NAME = 'TrainingModule';
export const TRAINING_LESSON_ADMIN_MODULE_NAME = 'AdminTrainingLessonModule';
export const TRAINING_LESSON_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_LESSON_MODULE_NAME}`;
export const TRAINING_LESSON_ADMIN_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_LESSON_ADMIN_MODULE_NAME}`;

export const GET_TRAININGS_LESSON = 'GET_TRAININGS_LESSON';
export const GET_FULL_TRAININGS_LESSON = 'GET_FULL_TRAININGS_LESSON';
export const GET_ALL_TRAININGS_LESSON_ADMIN = 'GET_ALL_TRAININGS_LESSON_ADMIN';
export const GET_ALL_TRAININGS_LESSON_SUCCESS = 'GET_ALL_TRAININGS_LESSON_SUCCESS';
export const GET_ALL_TRAININGS_LESSON_ERROR = 'GET_ALL_TRAININGS_LESSON_ERROR';

export const GET_TRAINING_LESSON = 'GET_TRAINING_LESSON';
export const GET_TRAINING_LESSON_SUCCESS = 'GET_TRAINING_LESSON_SUCCESS';
export const GET_TRAINING_LESSON_ERROR = 'GET_TRAINING_LESSON_ERROR';

export const CLEAR_ALL_TRAINING_LESSON = 'CLEAR_ALL_TRAINING_LESSON';
export const CLEAR_TRAINING_LESSON = 'CLEAR_TRAINING_LESSON';
export const SAVE_TRAINING_LESSON = 'SAVE_TRAINING_LESSON';

export const ADD_TRAINING_LESSON = 'ADD_TRAINING_LESSON';
export const ADD_TRAINING_LESSON_SUCCESS = 'ADD_TRAINING_SUCCESS_LESSON';
export const ADD_TRAINING_LESSON_ERROR = 'ADD_TRAINING_ERROR_LESSON';

export const UPDATE_TRAINING_LESSON = 'UPDATE_TRAINING_LESSON';
export const UPDATE_TRAINING_LESSON_SUCCESS = 'UPDATE_TRAINING_LESSON_SUCCESS';
export const UPDATE_TRAINING_LESSON_ERROR = 'UPDATE_TRAINING_LESSON_ERROR';

export const DELETE_TRAINING_LESSON = 'DELETE_TRAINING_LESSON';
export const DELETE_TRAINING_LESSON_SUCCESS = 'DELETE_TRAINING_LESSON_SUCCESS';
export const DELETE_TRAINING_LESSON_ERROR = 'DELETE_TRAINING_LESSON_ERROR';

export const PUBLISH_TRAINING_LESSON = 'PUBLISH_TRAINING_LESSON';

export const CURRENT_UPDATE_ITEM = 'CURRENT_UPDATE_ITEM';

export const GENERATE_USER_CERTIFICATE = 'GENERATE_USER_CERTIFICATE';
export const GENERATE_USER_CERTIFICATE_SUCCESS = 'GENERATE_USER_CERTIFICATE_SUCCESS';
export const GENERATE_USER_CERTIFICATE_ERROR = 'GENERATE_USER_CERTIFICATE_ERROR';
