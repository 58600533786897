import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { UserNote } from '@/models/training/admin/training-note.model';

class TrainingNoteService {
  public async getAll(eventId: string, trainingId: string): Promise<UserNote[]> {
    return HttpService.perform<UserNote[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_NOTE,
      ]),
    );
  }

  public async get(eventId: string, trainingId: string, userNoteId): Promise<UserNote> {
    return HttpService.perform<UserNote>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_NOTE,
        userNoteId,
      ]),
    );
  }

  public async create(eventId: string, trainingId: string, note: UserNote): Promise<UserNote> {
    return HttpService.perform<UserNote>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_NOTE,
      ]),
      note,
    );
  }

  public async update(eventId: string, trainingId: string, note: UserNote): Promise<UserNote> {
    return HttpService.perform<UserNote>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_NOTE,
        note.id,
      ]),
      note,
    );
  }

  public async delete(eventId: string, trainingId: string, note: UserNote): Promise<UserNote> {
    return HttpService.perform<UserNote>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_NOTE,
        note.id,
      ]),
    );
  }
}

export default new TrainingNoteService();
