import { AppEventModule } from '@/stores/umanize-app/modules/event/app-event.module';
import { AppointmentModule } from '@/stores/umanize-app/modules/appointment/appointment.module';
import { AppMatchingModule } from '@/stores/umanize-app/modules/matching/app-matching.module';
import { AppConferenceModule } from '@/stores/umanize-app/modules/conference/app-conference.module';
import { ActivityModule } from '@/stores/umanize-app/modules/activity/activity.module';
import { AvailabilityModule } from '@/stores/umanize-app/modules/availability/availability.module';
import { AgendaModule } from '@/stores/umanize-app/modules/agenda/agenda.module';
import { PublicProfilesModule } from '@/stores/umanize-app/modules/public-profile/public-profiles.module';
import { PaymentsModule } from '@/stores/umanize-app/modules/payments/payments.module';
import { RelationsModule } from '@/stores/umanize-app/modules/relations/relations.module';
import { AppSurveyModule } from '@/stores/umanize-app/modules/survey/app.survey.module';
import { AppTrainingModule } from '@/stores/umanize-app/modules/training/app.training.module';
import { AppGeneralAssemblyModule } from '@/stores/umanize-app/modules/general-assembly/app-general-assembly.module';
import { AppDiscussionGroupModule } from '@/stores/umanize-app/modules/discussion-group/app-discussion-group.module';
import { PasswordModule } from '@/stores/umanize-app/modules/password/password.module';
import { ChatsModule } from '@/stores/umanize-app/modules/chats/chats.module';
import { AppTicketModule } from '@/stores/umanize-app/modules/ticket/app-ticket.module';
import { AppUserModule } from '@/stores/umanize-app/modules/user/app-user.module';
import { AppExhibitorModule } from '@/stores/umanize-app/modules/exhibitor/app-exhibitor.module';
import { AppContentModule } from '@/stores/umanize-app/modules/content/app-content.module';
import { ExhibitorVisitorModule } from '@/stores/umanize-app/modules/exhibitor-visitor/app-exhibitor-visitor.module';
import { AppStreamModule } from '@/stores/umanize-app/modules/stream/app-stream.module';
import { NotificationModule } from './modules/notification/app-notification.module';
import { AppCustomUiModule } from './modules/custom-ui/app-custom-ui.module';
import { MenuModule } from './modules/menu/menu.module';

export const UmanizeAppModule = {
  namespaced: true,
  modules: {
    ActivityModule,
    AgendaModule,
    AppointmentModule,
    AvailabilityModule,
    ChatsModule,
    ConferenceModule: AppConferenceModule,
    ContentModule: AppContentModule,
    DiscussionGroupModule: AppDiscussionGroupModule,
    EventModule: AppEventModule,
    ExhibitorModule: AppExhibitorModule,
    ExhibitorVisitorModule,
    GeneralAssemblyModule: AppGeneralAssemblyModule,
    MatchingModule: AppMatchingModule,
    NotificationModule,
    PasswordModule,
    PaymentsModule,
    PublicProfilesModule,
    RelationsModule,
    MenuModule,
    SurveyModule: AppSurveyModule,
    TrainingModule: AppTrainingModule,
    TicketModule: AppTicketModule,
    UserModule: AppUserModule,
    CustomUiModule: AppCustomUiModule,
    StreamModule: AppStreamModule,
  },
};
