import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { Stream, StreamCreateForm } from '@/models/stream/stream.model';

class StreamService {
  public async getAll(eventId: string): Promise<Stream[]> {
    return HttpService.perform<Stream[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.STREAM]),
    );
  }

  public async get(eventId: string, streamId: string): Promise<Stream> {
    return HttpService.perform<Stream>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.STREAM, streamId]),
    );
  }

  public async create(stream: StreamCreateForm): Promise<Stream> {
    return HttpService.perform<Stream>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [stream.eventId, HttpHelper.STREAM]),
      stream,
    );
  }

  public async start(stream: Stream): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        stream.eventId,
        HttpHelper.STREAM,
        stream.id,
        'start',
      ]),
    );
  }

  public async stop(stream: Stream): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        stream.eventId,
        HttpHelper.STREAM,
        stream.id,
        'stop',
      ]),
    );
  }

  public async delete(stream: Stream): Promise<Stream> {
    return HttpService.perform<Stream>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        stream.eventId,
        HttpHelper.STREAM,
        stream.id,
      ]),
    );
  }
}

export default new StreamService();
