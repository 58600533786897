import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

let notificationSocket;

export const NOTIFICATION_NAMESPACE = `/notification`;

export const SEND_NOTIFICATION = 'send-notification';

export const connectToNotificationNamespace = (): Socket => {
  const namespace = NOTIFICATION_NAMESPACE;

  return connectToNamespace(namespace);
};

export const disconnectFromNotificationNamespace = (notification: Socket) => {
  notification.disconnect();
};

export const getNotificationSocket = (): Socket => notificationSocket;

export const setNotificationSocket = (socket) => {
  notificationSocket = socket;
};

export const disconnectFromStatNamespace = () => {
  notificationSocket.disconnect();
  notificationSocket = null;
};
