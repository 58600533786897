import { ActionContext } from 'vuex';
import { PublicProfile } from '@/models';
import { RootState } from '@/stores/store.model';
import {
  GET_PUBLIC_PROFILE_BY_ID,
  GET_PUBLIC_PROFILE_BY_ID_SUCCESS,
  GET_PUBLIC_PROFILE_BY_ID_ERROR,
  SEARCH_USER_PROFILES,
  SEARCH_USER_PROFILES_SUCCESS,
  SEARCH_USER_PROFILES_ERROR,
} from '@/stores/umanize-app/actions/public-profile/public-profile.actions';
import publicProfileService from '@/services/public-profile/public-profile.service';

export interface PublicProfilesState {
  publicProfiles?: PublicProfile[];
  status: {
    error: string;
    isLoading: boolean;
  };
}

const state: PublicProfilesState = {
  publicProfiles: [],
  status: {
    error: '',
    isLoading: true,
  },
};

const actions = {
  async [GET_PUBLIC_PROFILE_BY_ID](
    { commit }: ActionContext<PublicProfilesState, RootState>,
    userId: string,
  ) {
    commit(GET_PUBLIC_PROFILE_BY_ID);

    try {
      const publicProfile = await publicProfileService.getPublicProfileByUserId(userId);
      commit(GET_PUBLIC_PROFILE_BY_ID_SUCCESS, publicProfile);
    } catch (error) {
      commit(GET_PUBLIC_PROFILE_BY_ID_ERROR, error);
    }
  },
  async [SEARCH_USER_PROFILES](
    { commit }: ActionContext<PublicProfilesState, RootState>,
    payload: {
      searchText: string;
      eventId: string;
    },
  ) {
    commit(SEARCH_USER_PROFILES);

    try {
      const publicProfiles = await publicProfileService.searchUserProfiles(
        payload.searchText,
        payload.eventId,
      );
      commit(SEARCH_USER_PROFILES_SUCCESS, publicProfiles);
    } catch (error) {
      commit(SEARCH_USER_PROFILES_ERROR, error);
    }
  },
};

const mutations = {
  [GET_PUBLIC_PROFILE_BY_ID](state) {
    state.status = {
      ...state.status,
      publicProfileIsLoading: true,
      error: null,
    };
  },
  [GET_PUBLIC_PROFILE_BY_ID_SUCCESS](state, publicProfile) {
    state.status = {
      ...state.status,
      publicProfileIsLoading: false,
      error: null,
    };
    state.publicProfiles = [...state.publicProfiles, publicProfile];
  },
  [GET_PUBLIC_PROFILE_BY_ID_ERROR](state, error) {
    state.status = {
      ...state.status,
      publicProfileIsLoading: false,
      error,
    };
  },
  [SEARCH_USER_PROFILES](state) {
    state.status = {
      ...state.status,
      publicProfilesAreLoading: true,
      error: null,
    };
  },
  [SEARCH_USER_PROFILES_SUCCESS](state, publicProfiles) {
    state.status = {
      ...state.status,
      publicProfilesAreLoading: false,
      error: null,
    };
    state.publicProfiles = publicProfiles;
  },
  [SEARCH_USER_PROFILES_ERROR](state, error) {
    state.status = {
      ...state.status,
      publicProfilesAreLoading: false,
      error,
    };
  },
};

const getters = {
  publicProfiles: (state) => state.publicProfiles || [],
  publicProfileByUserId: (state, { publicProfiles }) => (id: string) =>
    publicProfiles.find((profile) => profile.id === id),

  status: (state) => state.status || {},
  publicProfileIsLoading: (state, { status }) => status.publicProfileIsLoading || false,
  publicProfilesAreLoading: (state, { status }) => status.publicProfilesAreLoading || false,
  error: (state, { status }) => status.error || '',
};

export const PublicProfilesModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
