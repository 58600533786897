import { ImageDescription } from '../image-description/image.description.model';

export class AppEventOptions {
  public recommendedActivities = false;

  public smartMatching = false;

  public participantList = false;

  public generalAssembly = false;

  public contentLibrary = false;

  public contentLibraryOptions: {
    // when contentLibrary true
    backgroundImageUrl: string;
  } = {
    backgroundImageUrl: null,
  };

  public discussionGroups = false;

  public discussionGroupsOptions: {
    // when discussionGroups true
    backgroundImageUrl: string;
    leftPanelLogos: string[]; // not in form
  } = {
    backgroundImageUrl: null,
    leftPanelLogos: [],
  };

  public exposition = false;

  public appointments = false;

  public conferences = false;

  public showroomOptions: {
    // when exposition true
    vimeoVideoURL: string;
    unityLoaderURL: string;
    unityContainerURL: string;
    loadingBackground: string;
  } = {
    vimeoVideoURL: null,
    unityLoaderURL: null,
    unityContainerURL: null,
    loadingBackground: null,
  };

  public eventKioskOptions: {
    submitKioskDeadline: string;
  };

  public eventLobbyOptions?: { imageOptions: ImageDescription[] } = { imageOptions: [] };
}
