import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const ASSEMBLY_LOAD_QUESTIONS = 'assembly-load-questions-success';
export const ASSEMBLY_GET_QUESTION = 'assembly-get-question-success';
export const ASSEMBLY_QUESTION_CLOSED = 'assembly-question-closed';
export const ASSEMBLY_QUESTION_CREATED = 'assembly-question-created';
export const ASSEMBLY_QUESTION_STARTED = 'assembly-question-started';
export const ASSEMBLY_ANSWER_QUESTION = 'assembly-answer-question-success';
export const ASSEMBLY_LOAD_MESSAGES = 'assembly-load-chat-messages-success';
export const ASSEMBLY_CHAT_NEW_MESSAGE = 'assembly-new-chat-message';
export const ASSEMBLY_CHAT_UPDATED_MESSAGE = 'assembly-chat-updated-message';

export const getAssemblyNamespace = (assemblyId: string) => `/assembly-${assemblyId}`;

export const connectToAssemblyNamespace = (assemblyId: string): Socket => {
  const namespace = getAssemblyNamespace(assemblyId);

  return connectToNamespace(namespace);
};

export const disconnectFromAssemblyNamespace = (assemblySocket: Socket) =>
  assemblySocket.disconnect();
