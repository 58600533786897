export const CONTENT_MODULE = 'ContentModule';

export const GET_CONTENT_CATEGORY = 'GET_CONTENT_CATEGORY';
export const GET_CONTENT_CATEGORY_SUCCESS = 'GET_CONTENT_CATEGORY_SUCCESS';
export const GET_CONTENT_CATEGORY_ERROR = 'GET_CONTENT_CATEGORY_ERROR';

export const GET_CONTENT_ITEMS = 'GET_CONTENT_ITEMS';
export const GET_CONTENT_ITEMS_SUCCESS = 'GET_CONTENT_ITEMS_SUCCESS';
export const GET_CONTENT_ITEMS_ERROR = 'GET_CONTENT_ITEMS_ERROR';

export const GET_CONTENT_CATEGORIES = 'GET_CONTENT_CATEGORIES';
export const GET_CONTENT_CATEGORIES_SUCCESS = 'GET_CONTENT_CATEGORIES_SUCCESS';
export const GET_CONTENT_CATEGORIES_ERROR = 'GET_CONTENT_CATEGORIES_ERROR';

export const GET_CONTENT_ITEM = 'GET_CONTENT_ITEM';
export const GET_CONTENT_ITEM_SUCCESS = 'GET_CONTENT_ITEM_SUCCESS';
export const GET_CONTENT_ITEM_ERROR = 'GET_CONTENT_ITEM_ERROR';
