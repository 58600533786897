import { ActionContext } from 'vuex';
import {
  CLEAR_LAYOUT_EVENT_ID,
  SET_LAYOUT_EVENT_ID,
} from '@/stores/shared/actions/layout/layout.actions';
import { RootState } from '@/stores/store.model';

export interface LayoutState {
  eventId: string;
}

const state: LayoutState = {
  eventId: null,
};

const actions = {
  async [SET_LAYOUT_EVENT_ID]({ commit }: ActionContext<LayoutState, RootState>, eventId: string) {
    commit(SET_LAYOUT_EVENT_ID, eventId);
  },
  async [CLEAR_LAYOUT_EVENT_ID]({ commit }: ActionContext<LayoutState, RootState>) {
    commit(CLEAR_LAYOUT_EVENT_ID);
  },
};

const mutations = {
  [SET_LAYOUT_EVENT_ID](state, eventId) {
    state.eventId = eventId;
  },
  [CLEAR_LAYOUT_EVENT_ID](state) {
    state.eventId = null;
  },
};

const getters = {
  layoutEventId: (state) => state.eventId,
};

export const LayoutModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
