export const EVENT_MODULE = 'EventModule';

export const FETCH_EVENT_USERS = 'FETCH_EVENT_USERS';
export const FETCH_EVENT_USERS_SUCCESS = 'FETCH_EVENT_USERS_SUCCESS';
export const FETCH_EVENT_USERS_ERROR = 'FETCH_EVENT_USERS_ERROR';

export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_ERROR = 'FETCH_ALL_EVENTS_ERROR';

export const FETCH_EVENT_BY_ID = 'FETCH_EVENT_BY_ID';
export const FETCH_EVENT_BY_ID_SILENT = 'FETCH_EVENT_BY_ID_SILENT';
export const FETCH_EVENT_BY_ID_SUCCESS = 'FETCH_EVENT_BY_ID_SUCCESS';
export const FETCH_EVENT_BY_ID_ERROR = 'FETCH_EVENT_BY_ID_ERROR';

export const FETCH_MY_EVENTS = 'FETCH_MY_EVENTS';
export const FETCH_MY_EVENTS_SUCCESS = 'FETCH_MY_EVENTS_SUCCESS';
export const FETCH_MY_EVENTS_ERROR = 'FETCH_MY_EVENTS_ERROR';
