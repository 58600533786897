import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=106f4c7d&scoped=true&"
import script from "./MainMenu.vue?vue&type=script&lang=js&"
export * from "./MainMenu.vue?vue&type=script&lang=js&"
import style0 from "./MainMenu.vue?vue&type=style&index=0&id=106f4c7d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106f4c7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VDivider,VIcon,VNavigationDrawer,VOverlay,VSheet})
