import { ActionContext } from 'vuex';
import { User, MessageType, Ticket, UploadUserFile, UserRole, UserTicketProfile } from '@/models';
import { RootState } from '@/stores/store.model';
import userService from '@/services/user/user.service';
import i18n from '@/i18n';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import {
  AgnosticUserModule,
  CurrentProfileStatus,
  CurrentUserRolesState,
  CurrentUserRolesStatus,
  CurrentUserState,
  CurrentUserStatus,
} from '@/stores/agnostic/modules/user/agnostic-user.module';
import {
  UPDATE_AVATAR,
  UPDATE_AVATAR_ERROR,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_USER,
  UPDATE_TICKET_PROFILE_ERROR,
  UPDATE_TICKET_PROFILE_SUCCESS,
  UPDATE_TICKET_PROFILE,
  GET_TICKET_PROFILE,
  GET_TICKET_PROFILE_SUCCESS,
  GET_TICKET_PROFILE_ERROR,
  UPDATE_USER_ERROR,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_SUCCESS,
} from '@/stores/agnostic/actions/user/agnostic-user.actions';
import {
  ADD_USER,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  CLEAR_USER,
  GET_PERMISSIONS,
  GET_PERMISSIONS_ERROR,
  GET_PERMISSIONS_SUCCESS,
  SAVE_USER,
  UPLOAD_CSV_USERS,
  UPLOAD_CSV_USERS_ERROR,
  UPLOAD_CSV_USERS_SUCCESS,
  CLEAR_TICKET_PROFILE,
} from '@/stores/umanize-admin/actions/user/admin-user.actions';
import { UserTicketModule } from './user-ticket/user-ticket.module';

export interface UsersStatus {
  isLoading: boolean;
  isLoaded: boolean;
  isUploading: boolean;
  error: ErrorMessage;
}

export interface UsersState {
  users: User[];
  status: UsersStatus;
}

export interface CurrentProfileState {
  profile: UserTicketProfile;
  status: CurrentProfileStatus;
}

export interface AdminUserState {
  currentProfile: CurrentProfileState;
  currentUser: CurrentUserState;
  currentUserRoles: CurrentUserRolesState;
  users: UsersState;
}

const currentProfile: CurrentProfileState = {
  profile: null,
  status: {
    isLoading: true,
    isLoaded: false,
    error: null,
  },
};

const currentUser: CurrentUserState = {
  user: null,
  status: {
    isLoading: true,
    isLoaded: false,
    isSaving: false,
    error: null,
  },
};

const currentUserRoles: CurrentUserRolesState = {
  roles: null,
  status: {
    areLoaded: false,
    areLoading: false,
    areSaving: false,
    error: null,
  },
};

const users: UsersState = {
  users: [],
  status: {
    isLoading: true,
    isLoaded: false,
    isUploading: false,
    error: null,
  },
};

export const state: AdminUserState = {
  currentUser,
  currentProfile,
  currentUserRoles,
  users,
};

const actions = {
  [UPDATE_AVATAR]: AgnosticUserModule.actions.updateAvatar,
  [UPDATE_TICKET_PROFILE]: AgnosticUserModule.actions.updateTicketProfile,
  [GET_TICKET_PROFILE]: AgnosticUserModule.actions.getProfileTicket,
  [UPDATE_USER_PASSWORD]: AgnosticUserModule.actions.updateUserPassword,
  [UPDATE_USER]: AgnosticUserModule.actions.updateUser,
  async [SAVE_USER](
    { dispatch }: ActionContext<AdminUserState, RootState>,
    { user, ticket }: { user: User; ticket?: Ticket },
  ) {
    const dispatchAction = user?.id ? UPDATE_USER : ADD_USER;
    dispatch(dispatchAction, { user, ticket });
  },
  async [ADD_USER](
    { commit, dispatch }: ActionContext<AdminUserState, RootState>,
    { user }: { user: User; ticket?: Ticket },
  ) {
    commit(ADD_USER);

    try {
      const addedUser = await userService.createUser(user);
      commit(ADD_USER_SUCCESS, addedUser);
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`auth.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(ADD_USER_ERROR, error);
    }
  },
  async [UPLOAD_CSV_USERS](
    { commit, dispatch }: ActionContext<UsersState, RootState>,
    uploadUserFile: UploadUserFile,
  ) {
    commit(UPLOAD_CSV_USERS);

    try {
      await userService.uploadCsvUsers(uploadUserFile);

      commit(UPLOAD_CSV_USERS_SUCCESS);
      await dispatch(
        `${ADMIN_EVENT_MODULE}/${FETCH_EVENT_USERS}`,
        { id: uploadUserFile.eventId },
        { root: true },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`admin.users.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPLOAD_CSV_USERS_ERROR, error);
    }
  },
  async [GET_PERMISSIONS]({ commit, state }: ActionContext<AdminUserState, RootState>, user) {
    const isLoggedInUser = user.id !== state.currentUser.user.id;

    commit(GET_PERMISSIONS);
    try {
      const roles = await userService.getPermissions(user, isLoggedInUser);

      commit(GET_PERMISSIONS_SUCCESS, roles);
    } catch (error) {
      commit(GET_PERMISSIONS_ERROR, error);
    }
  },
  async [CLEAR_USER]({ commit }: ActionContext<AdminUserState, RootState>) {
    commit(CLEAR_USER);
  },
  async [CLEAR_TICKET_PROFILE]({ commit }: ActionContext<AdminUserState, RootState>) {
    commit(CLEAR_TICKET_PROFILE);
  },
};

const mutations = {
  [UPDATE_AVATAR](state) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_AVATAR_SUCCESS](state, payload: { avatar: string; isCurrentUser: boolean }) {
    state.currentUser = {
      ...state.currentUser,
      user: {
        ...state.currentUser.user,
        avatar: payload.avatar,
      },
      status: {
        ...state.currentUser.status,
        isSaving: false,
        error: null,
      },
    };
  },
  [UPDATE_AVATAR_ERROR](state, error) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_USER](state) {
    state.currentUser = {
      status: {
        ...state.currentUser.status,
        isSaving: true,
        error: null,
      },
      user: null,
    };
  },
  [UPDATE_USER_SUCCESS](state, user) {
    state.currentUser.user = {
      ...user,
    };
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_USER_ERROR](state, error) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error,
    };
  },
  [GET_TICKET_PROFILE](state) {
    state.currentProfile.profile = null;
    state.currentProfile.status = {
      ...state.currentUser.status,
      error: null,
      isLoading: true,
    };
  },
  [GET_TICKET_PROFILE_SUCCESS](state, profile) {
    state.currentProfile = {
      status: {
        ...state.currentUser.status,
        error: null,
        isLoading: false,
        isLoaded: true,
      },
      profile,
    };
  },
  [GET_TICKET_PROFILE_ERROR](state, error) {
    state.currentProfile.status = {
      ...state.currentUser.status,
      error,
      isLoading: false,
      isLoaded: false,
    };
  },

  [UPDATE_TICKET_PROFILE](state) {
    state.currentProfile.status = {
      ...state.currentUser.status,
      error: null,
      isLoading: false,
    };
  },
  [UPDATE_TICKET_PROFILE_SUCCESS](state, profile) {
    state.currentProfile = {
      status: {
        ...state.currentUser.status,
        error: null,
        isLoading: false,
        isLoaded: true,
      },
      profile,
    };
  },
  [UPDATE_TICKET_PROFILE_ERROR](state, error) {
    state.currentProfile.status = {
      ...state.currentUser.status,
      error,
      isLoading: false,
      isLoaded: false,
    };
  },
  [CLEAR_TICKET_PROFILE](state) {
    state.currentProfile.profile = null;
    state.currentProfile.status = {
      ...state.currentUser.status,
      error: null,
      isLoading: false,
      isLoaded: false,
    };
  },
  [ADD_USER](state) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_USER_SUCCESS](state, user) {
    state.currentUser.user = {
      ...user,
    };
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_USER_ERROR](state, error) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_USER_PASSWORD](state) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_USER_PASSWORD_SUCCESS](state) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_USER_PASSWORD_ERROR](state, error) {
    state.currentUser.status = {
      ...state.currentUser.status,
      isSaving: false,
      error,
    };
  },
  [UPLOAD_CSV_USERS](state) {
    state.users.status = {
      ...state.users.status,
      isUploading: true,
      error: null,
    };
  },
  [UPLOAD_CSV_USERS_SUCCESS](state) {
    state.users.status = {
      ...state.users.status,
      isUploading: false,
      error: null,
    };
  },
  [UPLOAD_CSV_USERS_ERROR](state, error) {
    state.users.status = {
      ...state.users.status,
      isUploading: false,
      error,
    };
  },
  [GET_PERMISSIONS](state) {
    state.currentUserRoles.status = {
      ...state.currentUserRoles.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_PERMISSIONS_SUCCESS](state, roles) {
    state.currentUserRoles = {
      ...state.currentUserRoles,
      roles: PermissionsUtil.flattenPermissionsInUserRoles(roles),
      status: {
        ...state.currentUserRoles.status,
        areLoading: false,
        areLoaded: true,
      },
    };
  },
  [GET_PERMISSIONS_ERROR](state: AdminUserState, error: ErrorMessage) {
    state.currentUserRoles = {
      ...state.currentUserRoles,
      roles: null,
      status: {
        ...state.currentUserRoles.status,
        areLoading: false,
        areLoaded: false,
        error,
      },
    };
  },
  [CLEAR_USER](state: AdminUserState) {
    state.currentUser.user = null;
  },
};

const getters = {
  currentUser: (state: AdminUserState): CurrentUserState => state.currentUser,
  user: (_, { currentUser }: { currentUser: CurrentUserState }): User => currentUser?.user || null,
  currentUserStatus: (_, { currentUser }: { currentUser: CurrentUserState }): CurrentUserStatus =>
    currentUser?.status || null,
  isSaving: (_, { currentUserStatus }: { currentUserStatus: CurrentUserStatus }): boolean =>
    currentUserStatus?.isSaving || false,
  isLoading: (_, { currentUserStatus }: { currentUserStatus: CurrentUserStatus }): boolean =>
    currentUserStatus?.isLoading || false,
  isLoaded: (_, { currentUserStatus }: { currentUserStatus: CurrentUserStatus }): boolean =>
    currentUserStatus?.isLoaded || false,
  error: (_, { currentUserStatus }: { currentUserStatus: CurrentUserStatus }): ErrorMessage =>
    currentUserStatus?.error || null,

  currentUserRoles: (state: AdminUserState): CurrentUserRolesState => state.currentUserRoles,
  roles: (_, { currentUserRoles }: { currentUserRoles: CurrentUserRolesState }): UserRole[] =>
    currentUserRoles?.roles || [],
  currentUserRolesStatus: (
    _,
    {
      currentUserRoles,
    }: {
      currentUserRoles: CurrentUserRolesState;
    },
  ): CurrentUserRolesStatus => currentUserRoles?.status || null,
  currentUserRolesAreSaving: (
    _,
    { currentUserRolesStatus }: { currentUserRolesStatus: CurrentUserRolesStatus },
  ): boolean => currentUserRolesStatus?.areSaving || false,
  currentUserRolesAreLoading: (
    _,
    { currentUserRolesStatus }: { currentUserRolesStatus: CurrentUserRolesStatus },
  ): boolean => currentUserRolesStatus?.areLoading || false,
  currentUserRolesAreLoaded: (
    _,
    { currentUserRolesStatus }: { currentUserRolesStatus: CurrentUserRolesStatus },
  ): boolean => currentUserRolesStatus?.areLoaded || false,
  currentUserRolesError: (
    _,
    { currentUserRolesStatus }: { currentUserRolesStatus: CurrentUserRolesStatus },
  ): ErrorMessage => currentUserRolesStatus?.error || null,

  usersState: (state: AdminUserState): UsersState => state.users,
  users: (_, { usersState }: { usersState: UsersState }): User[] => usersState?.users || [],
  usersStatus: (_, { usersState }: { usersState: UsersState }): UsersStatus =>
    usersState?.status || null,
  usersIsUploading: (_, { usersStatus }: { usersStatus: UsersStatus }): boolean =>
    usersStatus?.isUploading || false,
  usersIsLoading: (_, { usersStatus }: { usersStatus: UsersStatus }): boolean =>
    usersStatus?.isLoading || false,
  usersIsLoaded: (_, { usersStatus }: { usersStatus: UsersStatus }): boolean =>
    usersStatus?.isLoaded || false,
  usersError: (_, { usersStatus }: { usersStatus: UsersStatus }): ErrorMessage =>
    usersStatus?.error || null,
  currentProfileState: (state: AdminUserState): CurrentProfileState => state.currentProfile,
  profile: (
    _,
    { currentProfileState }: { currentProfileState: CurrentProfileState },
  ): UserTicketProfile => currentProfileState.profile,
  profileError: (
    _,
    { currentProfileState }: { currentProfileState: CurrentProfileState },
  ): ErrorMessage => currentProfileState?.status.error || null,
  profileIsLoading: (
    _,
    { currentProfileState }: { currentProfileState: CurrentProfileState },
  ): boolean => currentProfileState?.status.isLoading || false,
  profileIsLoaded: (
    _,
    { currentProfileState }: { currentProfileState: CurrentProfileState },
  ): boolean => currentProfileState?.status.isLoaded || false,
};

export const AdminUserModule = {
  namespaced: true,
  actions,
  getters,
  modules: {
    UserTicketModule,
  },
  mutations,
  state,
};
