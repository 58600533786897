import { ActionContext } from 'vuex';
import { DateTime } from 'luxon';
import i18n from '@/i18n';
import { Conference, DiscussionGroup, ExtendedScheduleItem, ScheduleItem } from '@/models';
import { RootState } from '@/stores/store.model';
import AgendaService from '@/services/agenda/agenda.service';
import { sortedAgenda } from '@/stores/umanize-app/modules/agenda/agenda.helper';
import { UserEventAppointment } from '@/models/users-appointments/user-event-appointment';
import {
  APPOINTMENT_MODULE,
  DELETE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import {
  ADD_CONFERENCE_TO_AGENDA,
  ADD_DISCUSSION_GROUP_TO_AGENDA,
  ADD_TO_AGENDA,
  ADD_TO_AGENDA_ERROR,
  ADD_TO_AGENDA_SUCCESS,
  GET_AGENDA,
  GET_AGENDA_ERROR,
  GET_AGENDA_SUCCESS,
  REMOVE_APPOINTMENT_FROM_AGENDA,
  REMOVE_CONFERENCE_FROM_AGENDA,
  REMOVE_DISCUSSION_GROUP_FROM_AGENDA,
  REMOVE_FROM_AGENDA,
  REMOVE_FROM_AGENDA_ERROR,
  REMOVE_FROM_AGENDA_SUCCESS,
} from '../../actions/agenda/agenda.actions';

export interface AgendaState {
  agenda: ExtendedScheduleItem[];
  status: {
    error: string;
    isSending: boolean;
    isLoaded: boolean;
  };
}

const state: AgendaState = {
  agenda: [],
  status: {
    error: '',
    isSending: false,
    isLoaded: false,
  },
};

const actions = {
  async [GET_AGENDA]({ commit }: ActionContext<AgendaState, RootState>, eventId: string) {
    commit(GET_AGENDA);

    try {
      const items = await AgendaService.getAgenda(eventId);
      commit(GET_AGENDA_SUCCESS, items);
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(GET_AGENDA_ERROR, error);
    }
  },
  async [ADD_CONFERENCE_TO_AGENDA](
    { commit }: ActionContext<AgendaState, RootState>,
    conference: Conference,
  ) {
    commit(ADD_TO_AGENDA);

    try {
      const scheduledItem = await AgendaService.addConferenceToAgenda(conference);
      commit(ADD_TO_AGENDA_SUCCESS, scheduledItem);
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(ADD_TO_AGENDA_ERROR, error);
    }
  },
  async [ADD_DISCUSSION_GROUP_TO_AGENDA](
    { commit }: ActionContext<AgendaState, RootState>,
    discussionGroup: DiscussionGroup,
  ) {
    commit(ADD_TO_AGENDA);

    try {
      const scheduledItem = await AgendaService.addDiscussionGroupToAgenda(discussionGroup);
      commit(ADD_TO_AGENDA_SUCCESS, scheduledItem);
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(ADD_TO_AGENDA_ERROR, error);
    }
  },
  async [REMOVE_CONFERENCE_FROM_AGENDA](
    { commit, state }: ActionContext<AgendaState, RootState>,
    conference: Conference,
  ) {
    commit(REMOVE_FROM_AGENDA);

    try {
      const scheduledItem = state.agenda.find(
        (item: ScheduleItem) => item.conferenceId === conference.id,
      );
      if (scheduledItem) {
        await AgendaService.removeFromAgenda(scheduledItem);
        commit(REMOVE_FROM_AGENDA_SUCCESS, scheduledItem);
      }
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(REMOVE_FROM_AGENDA_ERROR, error);
    }
  },
  async [REMOVE_DISCUSSION_GROUP_FROM_AGENDA](
    { commit, state }: ActionContext<AgendaState, RootState>,
    discussionGroup: DiscussionGroup,
  ) {
    commit(REMOVE_FROM_AGENDA);

    try {
      const scheduledItem = state.agenda.find(
        (item: ScheduleItem) => item.discussionGroupId === discussionGroup.id,
      );
      if (scheduledItem) {
        await AgendaService.removeFromAgenda(scheduledItem);
        commit(REMOVE_FROM_AGENDA_SUCCESS, scheduledItem);
      }
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(REMOVE_FROM_AGENDA_ERROR, error);
    }
  },
  async [REMOVE_APPOINTMENT_FROM_AGENDA](
    { commit, state, dispatch }: ActionContext<AgendaState, RootState>,
    appointment: UserEventAppointment,
  ) {
    commit(REMOVE_FROM_AGENDA);

    try {
      const scheduledItem = state.agenda.find(
        (item: ScheduleItem) => item.userEventAppointmentId === appointment.id,
      );
      if (scheduledItem) {
        await dispatch(
          `${APPOINTMENT_MODULE}/${DELETE_APPOINTMENT}`,
          { eventId: appointment.eventId, appointmentId: appointment.id },
          { root: true },
        );
        commit(REMOVE_FROM_AGENDA_SUCCESS, scheduledItem);
      }
    } catch (err) {
      const error = i18n.t(`agenda.errors.${err.status}`);
      commit(REMOVE_FROM_AGENDA_ERROR, error);
    }
  },
};

const mutations = {
  [GET_AGENDA](state: AgendaState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [GET_AGENDA_SUCCESS](state: AgendaState, items: ExtendedScheduleItem[]) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
    state.agenda = items;
  },
  [GET_AGENDA_ERROR](state: AgendaState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
  [ADD_TO_AGENDA](state: AgendaState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [ADD_TO_AGENDA_SUCCESS](state: AgendaState, item: ExtendedScheduleItem) {
    state.agenda = [...state.agenda, item];
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
  },
  [ADD_TO_AGENDA_ERROR](state: AgendaState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
  [REMOVE_FROM_AGENDA](state: AgendaState) {
    state.status = {
      ...state.status,
      isSending: true,
      error: null,
    };
  },
  [REMOVE_FROM_AGENDA_SUCCESS](state: AgendaState, item: ExtendedScheduleItem) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: true,
      error: null,
    };
    state.agenda = [...state.agenda.filter((ag) => ag.id !== item.id)];
  },
  [REMOVE_FROM_AGENDA_ERROR](state: AgendaState, error) {
    state.status = {
      ...state.status,
      isSending: false,
      isLoaded: false,
      error,
    };
  },
};

const selectors = {
  agenda: (state: AgendaState): ExtendedScheduleItem[] => state.agenda || [],
  sortedAgenda: (state: AgendaState, { agenda }): ExtendedScheduleItem[] =>
    sortedAgenda(DateTime.now())(agenda),
  isConferenceOnAgenda: (state: AgendaState) => (conference: Conference): boolean =>
    !!state.agenda.find((item: ExtendedScheduleItem) => item.conferenceId === conference.id),
  isDiscussionGroupOnAgenda: (state: AgendaState) => (discussionGroup: DiscussionGroup): boolean =>
    !!state.agenda.find(
      (item: ExtendedScheduleItem) => item.discussionGroupId === discussionGroup.id,
    ),
  isSending: (state: AgendaState) => (state.status && state.status.isSending) || false,
  isLoaded: (state: AgendaState) => (state.status && state.status.isLoaded) || false,
  error: (state: AgendaState) => (state.status && state.status.error) || '',
};

export const AgendaModule = {
  namespaced: true,
  actions,
  getters: selectors,
  mutations,
  state,
};
