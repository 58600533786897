/* eslint-disable */
import { HttpMethod } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';
import { AdminTrainingSectionModel } from '@/models/training/admin/section/admin-training-section.model';

class TrainingSectionService {
  public async getAll(eventId: string, trainingId: string): Promise<AdminTrainingSectionModel[]> {
    return HttpService.perform<AdminTrainingSectionModel[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
      ]),
    );
  }

  public async get(
    eventId: string,
    trainingId: string,
    trainingSectionId,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<AdminTrainingSectionModel>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
      ]),
    );
  }

  public async create(
    eventId: string,
    section: AdminTrainingSectionModel,
    trainingId: string,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<any>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
      ]),
      section,
    );
  }

  public async update(
    eventId: string,
    section: AdminTrainingSectionModel,
    trainingId: string,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<any>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        section.id,
      ]),
      section,
    );
  }

  public async delete(
    eventId: string,
    trainingId: string,
    section: AdminTrainingSectionModel,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<AdminTrainingSectionModel>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        section.id,
      ]),
    );
  }

  public async publish(
    eventId: string,
    trainingId: string,
    trainingSectionId: string,
    published: Date,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<AdminTrainingSectionModel>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
        HttpHelper.TRAINING_SECTIONS_PUBLISH,
      ]),
      { published },
    );
  }

  public async unpublish(
    eventId: string,
    trainingId: string,
    trainingSectionId: string,
  ): Promise<AdminTrainingSectionModel> {
    return HttpService.perform<AdminTrainingSectionModel>(
      HttpMethod.delete,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.TRAINING,
        trainingId,
        HttpHelper.TRAINING_SECTIONS,
        trainingSectionId,
        HttpHelper.TRAINING_SECTIONS_PUBLISH,
      ]),
    );
  }
}

export default new TrainingSectionService();
