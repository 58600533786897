export const EXHIBITOR_MODULE = 'ExhibitorModule';

export const GET_EXHIBITOR = 'GET_EXHIBITOR';
export const GET_EXHIBITOR_SUCCESS = 'GET_EXHIBITOR_SUCCESS';
export const GET_EXHIBITOR_ERROR = 'GET_EXHIBITOR_ERROR';

export const GET_EXHIBITORS = 'GET_EXHIBITORS';
export const GET_EXHIBITORS_SUCCESS = 'GET_EXHIBITORS_SUCCESS';
export const GET_EXHIBITORS_ERROR = 'GET_EXHIBITORS_ERROR';

export const DELETE_EXHIBITOR = 'DELETE_EXHIBITOR';
export const DELETE_EXHIBITOR_SUCCESS = 'DELETE_EXHIBITOR_SUCCESS';
export const DELETE_EXHIBITOR_ERROR = 'DELETE_EXHIBITOR_ERROR';

export const CLEAR_EXHIBITOR = 'CLEAR_EXHIBITOR';
