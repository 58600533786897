import { ActionContext } from 'vuex';
import { RootState } from '@/stores/store.model';
import { SurveyAnswer, SurveyQuestion, SurveyQuestionType } from '@/models';
import SurveyService from '@/services/survey/survey.service';
import {
  ADD_SURVEY_QUESTION,
  ADD_SURVEY_QUESTION_ERROR,
  ADD_SURVEY_QUESTION_SUCCESS,
  ANSWER_SURVEY_QUESTION,
  ANSWER_SURVEY_QUESTION_ERROR,
  ANSWER_SURVEY_QUESTION_SUCCESS,
  CLEAR_SURVEY_ANSWERS,
  CLEAR_SURVEY_QUESTION,
  CLOSE_SURVEY_QUESTION,
  CLOSE_SURVEY_QUESTION_ERROR,
  CLOSE_SURVEY_QUESTION_SUCCESS,
  CONFERENCE_CLOSE_SURVEY,
  CONFERENCE_NEW_SURVEY,
  DELETE_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION_ERROR,
  DELETE_SURVEY_QUESTION_SUCCESS,
  GET_SURVEY_ANSWERS,
  GET_SURVEY_ANSWERS_ERROR,
  GET_SURVEY_ANSWERS_SUCCESS,
  GET_SURVEY_QUESTION,
  GET_SURVEY_QUESTION_ERROR,
  GET_SURVEY_QUESTION_SUCCESS,
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_ERROR,
  GET_SURVEY_QUESTIONS_SUCCESS,
  GET_SURVEY_USER_ANSWERS,
  GET_SURVEY_USER_ANSWERS_ERROR,
  GET_SURVEY_USER_ANSWERS_SUCCESS,
  SET_SURVEY_QUESTION,
  START_SURVEY_QUESTION,
  START_SURVEY_QUESTION_ERROR,
  START_SURVEY_QUESTION_SUCCESS,
  SURVEY_ANSWER_QUESTION,
  SURVEY_MODULE,
} from '@/stores/umanize-app/actions/survey/survey.actions';
import DataUtil from '@/helpers/data/data.helper';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { SurveyResult } from '@/models/survey/survey-results.model';

interface QuestionsStatus {
  error: ErrorMessage;
  areLoading: boolean;
}

export interface QuestionsState {
  questions: SurveyQuestion[];
  status: QuestionsStatus;
}

interface QuestionStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
  isUpdating: boolean;
}

export interface QuestionState {
  question: SurveyQuestion;
  status: QuestionStatus;
}

interface AnswersStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface AnswersState {
  answers: SurveyAnswer[];
  answersUser: SurveyAnswer[];
  status: AnswersStatus;
}

interface AnswerStatus {
  error: ErrorMessage;
  isSaving: boolean;
  isLoaded: boolean;
}

export interface AnswerState {
  status: AnswerStatus;
}

export interface SurveyState {
  questions: QuestionsState;
  currentQuestion: QuestionState;
  answers: AnswersState;
  answersUser: AnswersState;
  currentAnswer: AnswerState;
}

const questionsState: QuestionsState = {
  questions: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const currentQuestionState: QuestionState = {
  question: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
    isUpdating: false,
  },
};

const answersState: AnswersState = {
  answers: [],
  answersUser: [],
  status: {
    error: null,
    isLoading: true,
    isLoaded: false,
  },
};

const currentAnswerState: AnswerState = {
  status: {
    isLoaded: false,
    error: null,
    isSaving: false,
  },
};

const state: SurveyState = {
  questions: questionsState,
  currentQuestion: currentQuestionState,
  answers: answersState,
  currentAnswer: currentAnswerState,
  answersUser: answersState,
};

const actions = {
  [CONFERENCE_NEW_SURVEY](
    { commit, dispatch }: ActionContext<SurveyState, RootState>,
    questions: SurveyQuestion[],
  ) {
    commit(GET_SURVEY_QUESTIONS_SUCCESS, questions);

    const current: SurveyQuestion = questions?.find(
      (question: SurveyQuestion) => !!question.startedAt && !question.closedAt,
    );

    if (current) {
      dispatch(SET_SURVEY_QUESTION, current);
    }
  },
  [CONFERENCE_CLOSE_SURVEY](
    { commit, dispatch }: ActionContext<SurveyState, RootState>,
    history: SurveyQuestion[],
  ) {
    commit(GET_SURVEY_QUESTIONS_SUCCESS, history);

    const current: SurveyQuestion = history?.find(
      (question: SurveyQuestion) => !!question.startedAt && !question.closedAt,
    );
    dispatch(SET_SURVEY_QUESTION, current);
  },
  async [GET_SURVEY_QUESTIONS](
    { commit, dispatch }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      withAnswers = true,
    }: { eventId: string; conferenceId: string; withAnswers?: boolean },
  ) {
    commit(GET_SURVEY_QUESTIONS);

    try {
      const questions: SurveyQuestion[] = await SurveyService.getQuestions(
        eventId,
        conferenceId,
        withAnswers,
      );
      commit(GET_SURVEY_QUESTIONS_SUCCESS, questions);

      const current: SurveyQuestion = questions?.find(
        (question: SurveyQuestion) => !!question.startedAt && !question.closedAt,
      );

      await dispatch(SET_SURVEY_QUESTION, current);
    } catch (error) {
      commit(GET_SURVEY_QUESTIONS_ERROR, error);
    }
  },
  async [GET_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(GET_SURVEY_QUESTION);

    try {
      const question: SurveyQuestion = await SurveyService.getQuestionById(
        eventId,
        conferenceId,
        questionId,
      );
      commit(GET_SURVEY_QUESTION_SUCCESS, question);
    } catch (error) {
      commit(GET_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [DELETE_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(DELETE_SURVEY_QUESTION);

    try {
      await SurveyService.deleteQuestion(eventId, conferenceId, questionId);
      commit(DELETE_SURVEY_QUESTION_SUCCESS);
    } catch (error) {
      commit(DELETE_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [ADD_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      question,
    }: { eventId: string; conferenceId: string; question: SurveyQuestion },
  ) {
    commit(ADD_SURVEY_QUESTION);

    try {
      await SurveyService.createQuestion(eventId, conferenceId, question);

      commit(ADD_SURVEY_QUESTION_SUCCESS);
    } catch (error) {
      commit(ADD_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [CLOSE_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(CLOSE_SURVEY_QUESTION);

    try {
      await SurveyService.closeQuestion(eventId, conferenceId, questionId);

      commit(CLOSE_SURVEY_QUESTION_SUCCESS);
    } catch (error) {
      commit(CLOSE_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [START_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(START_SURVEY_QUESTION);

    try {
      await SurveyService.startQuestion(eventId, conferenceId, questionId);

      commit(START_SURVEY_QUESTION_SUCCESS);
    } catch (error) {
      commit(START_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [SURVEY_ANSWER_QUESTION](
    { dispatch, rootGetters }: ActionContext<SurveyState, RootState>,
    { conferenceId, answer: surveyAnswer }: { conferenceId: string; answer: SurveyAnswer },
  ) {
    const currentSurveyQuestion: SurveyQuestion = rootGetters[`${SURVEY_MODULE}/question`];

    if (conferenceId !== currentSurveyQuestion?.conferenceId) {
      return;
    }

    const currentResults = currentSurveyQuestion.results || [];
    const answer =
      currentSurveyQuestion.type === SurveyQuestionType.text
        ? SurveyQuestionType.text
        : surveyAnswer.answer;

    let results;
    const existingAnswer = currentResults.find((it) => it.answer === answer);
    if (existingAnswer) {
      results = currentResults.map((result: SurveyResult) =>
        result.answer === answer
          ? {
              ...result,
              count: result.count + 1,
            }
          : result,
      );
    } else {
      results = [
        ...currentResults,
        {
          answer,
          count: 1,
        },
      ];
    }

    dispatch(SET_SURVEY_QUESTION, {
      ...currentSurveyQuestion,
      results,
    });
  },
  async [SET_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    question: SurveyQuestion,
  ) {
    commit(SET_SURVEY_QUESTION, question);
  },
  async [CLEAR_SURVEY_QUESTION]({ commit }: ActionContext<SurveyState, RootState>) {
    commit(CLEAR_SURVEY_QUESTION);
  },
  async [ANSWER_SURVEY_QUESTION](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
      answer,
    }: {
      eventId: string;
      conferenceId: string;
      questionId: string;
      answer: string;
    },
  ) {
    commit(ANSWER_SURVEY_QUESTION);

    try {
      const newAnswer: SurveyAnswer = await SurveyService.answerQuestion(
        eventId,
        conferenceId,
        questionId,
        answer,
      );

      commit(ANSWER_SURVEY_QUESTION_SUCCESS, newAnswer);
    } catch (error) {
      commit(ANSWER_SURVEY_QUESTION_ERROR, error);
    }
  },
  async [GET_SURVEY_ANSWERS](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(GET_SURVEY_ANSWERS);

    try {
      const answers: SurveyAnswer[] = await SurveyService.getAnswers(
        eventId,
        conferenceId,
        questionId,
      );
      commit(GET_SURVEY_ANSWERS_SUCCESS, answers);
    } catch (error) {
      commit(GET_SURVEY_ANSWERS_ERROR, error);
    }
  },
  async [GET_SURVEY_USER_ANSWERS](
    { commit }: ActionContext<SurveyState, RootState>,
    {
      eventId,
      conferenceId,
      questionId,
    }: { eventId: string; conferenceId: string; questionId: string },
  ) {
    commit(GET_SURVEY_USER_ANSWERS);

    try {
      const answers: SurveyAnswer[] = await SurveyService.getUserAnswers(
        eventId,
        conferenceId,
        questionId,
      );
      commit(GET_SURVEY_USER_ANSWERS_SUCCESS, answers);
    } catch (error) {
      commit(GET_SURVEY_USER_ANSWERS_ERROR, error);
    }
  },
  async [CLEAR_SURVEY_ANSWERS]({ commit }: ActionContext<SurveyState, RootState>) {
    commit(CLEAR_SURVEY_ANSWERS);
  },
};

const mutations = {
  [GET_SURVEY_QUESTIONS](state: SurveyState) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_SURVEY_QUESTIONS_SUCCESS](state: SurveyState, questions: SurveyQuestion[]) {
    state.questions = {
      ...state.questions,
      status: {
        ...state.questions.status,
        areLoading: false,
        error: null,
      },
      questions: [...questions],
    };
  },
  [GET_SURVEY_QUESTIONS_ERROR](state: SurveyState, error: ErrorMessage) {
    state.questions.status = {
      ...state.questions.status,
      areLoading: false,
      error,
    };
  },

  [GET_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_SURVEY_QUESTION_SUCCESS](state: SurveyState, question: SurveyQuestion) {
    state.currentQuestion = {
      ...state.currentQuestion,
      status: {
        ...state.currentQuestion.status,
        isLoading: false,
        error: null,
      },
      question,
    };
  },
  [GET_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isLoading: false,
      error,
    };
  },

  [DELETE_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_SURVEY_QUESTION_SUCCESS](state: SurveyState) {
    state.currentQuestion = {
      ...state.currentQuestion,
      status: {
        ...state.currentQuestion.status,
        isSaving: false,
        error: null,
      },
    };
  },
  [DELETE_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isSaving: false,
      error,
    };
  },

  [ADD_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_SURVEY_QUESTION_SUCCESS](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isSaving: false,
    };
  },
  [ADD_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isSaving: false,
      error,
    };
  },

  [CLOSE_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isUpdating: true,
      error: null,
    };
  },
  [CLOSE_SURVEY_QUESTION_SUCCESS](state: SurveyState) {
    state.currentQuestion = {
      ...state.currentQuestion,
      status: {
        ...state.currentQuestion.status,
        isUpdating: false,
        error: null,
      },
      question: null,
    };
  },
  [CLOSE_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isUpdating: false,
      error,
    };
  },

  [START_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isUpdating: true,
      error: null,
    };
  },
  [START_SURVEY_QUESTION_SUCCESS](state: SurveyState) {
    state.currentQuestion = {
      ...state.currentQuestion,
      status: {
        ...state.currentQuestion.status,
        isUpdating: false,
        error: null,
      },
    };
  },
  [START_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentQuestion.status = {
      ...state.currentQuestion.status,
      isUpdating: false,
      error,
    };
  },

  [SET_SURVEY_QUESTION](state: SurveyState, question: SurveyQuestion) {
    state.currentQuestion = {
      ...state.currentQuestion,
      question,
    };
  },
  [CLEAR_SURVEY_QUESTION](state: SurveyState) {
    state.currentQuestion = {
      ...state.currentQuestion,
      question: null,
    };
  },

  [GET_SURVEY_ANSWERS](state: SurveyState) {
    state.answers.status = {
      ...state.answers.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_SURVEY_ANSWERS_SUCCESS](state: SurveyState, answers: SurveyAnswer[]) {
    state.answers = {
      ...state.answers,
      status: {
        ...state.answers.status,
        isLoading: false,
        error: null,
      },
      answers: [...answers],
    };
  },
  [GET_SURVEY_ANSWERS_ERROR](state: SurveyState, error: ErrorMessage) {
    state.answers.status = {
      ...state.answers.status,
      isLoading: false,
      error,
    };
  },

  [GET_SURVEY_USER_ANSWERS](state: SurveyState) {
    state.answers.status = {
      ...state.answers.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_SURVEY_USER_ANSWERS_SUCCESS](state: SurveyState, answers: SurveyAnswer[]) {
    state.answers = {
      ...state.answers,
      status: {
        ...state.answers.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      answersUser: [...answers],
    };
  },
  [GET_SURVEY_USER_ANSWERS_ERROR](state: SurveyState, error: ErrorMessage) {
    state.answers.status = {
      ...state.answers.status,
      isLoaded: true,
      isLoading: false,
      error,
    };
  },

  [ANSWER_SURVEY_QUESTION](state: SurveyState) {
    state.currentAnswer.status = {
      ...state.currentAnswer.status,
      isSaving: true,
      isLoaded: false,
      error: null,
    };
  },
  [ANSWER_SURVEY_QUESTION_SUCCESS](state: SurveyState) {
    state.currentAnswer.status = {
      ...state.currentAnswer.status,
      isSaving: false,
      isLoaded: true,
      error: null,
    };
  },
  [ANSWER_SURVEY_QUESTION_ERROR](state: SurveyState, error: ErrorMessage) {
    state.currentAnswer.status = {
      ...state.currentAnswer.status,
      isSaving: false,
      isLoaded: true,
      error,
    };
  },

  [CLEAR_SURVEY_ANSWERS](state: SurveyState) {
    state.answers = {
      ...state.answers,
      answers: [],
      answersUser: [],
    };
  },
};

const getters = {
  questionState: (state: SurveyState): QuestionState => state.currentQuestion,
  question: (_, { questionState }: { questionState: QuestionState }): SurveyQuestion =>
    questionState?.question,
  questionTotalAnswers: (_, { question }: { question: SurveyQuestion }): number =>
    question?.results?.reduce(
      (total: number, currentValue: { answer: string; count: number }) =>
        total + currentValue.count,
      0,
    ) || 0,

  questionStatus: (_, { questionState }: { questionState: QuestionState }): QuestionStatus =>
    questionState?.status,
  questionIsLoading: (_, { questionStatus }: { questionStatus: QuestionStatus }): boolean =>
    questionStatus?.isLoading,
  questionIsSaving: (_, { questionStatus }: { questionStatus: QuestionStatus }): boolean =>
    questionStatus?.isSaving,
  questionIsUpdating: (_, { questionStatus }: { questionStatus: QuestionStatus }): boolean =>
    questionStatus?.isUpdating,
  questionError: (_, { questionStatus }: { questionStatus: QuestionStatus }): ErrorMessage =>
    questionStatus?.error,

  questionsState: (state: SurveyState): QuestionsState => state.questions,
  history: (_, { questionsState }: { questionsState: QuestionsState }): SurveyQuestion[] =>
    questionsState?.questions.filter((question: SurveyQuestion) => !!question.closedAt) || [],
  historySorted: (_, { history }: { history: SurveyQuestion[] }): SurveyQuestion[] =>
    DataUtil.sortByStringDesc(history, 'created'),

  waitingQuestions: (_, { questionsState }: { questionsState: QuestionsState }): SurveyQuestion[] =>
    questionsState?.questions.filter(
      (question: SurveyQuestion) => !question.startedAt && !question.closedAt,
    ) || [],
  waitingQuestionsSorted: (
    _,
    { waitingQuestions }: { waitingQuestions: SurveyQuestion[] },
  ): SurveyQuestion[] => DataUtil.sortByStringDesc(waitingQuestions, 'created'),
  ongoingQuestion: (_, { questionsState }: { questionsState: QuestionsState }): SurveyQuestion =>
    questionsState?.questions.filter(
      (question: SurveyQuestion) => question.startedAt && !question.closedAt,
    )[0] || null,
  questionsStatus: (_, { questionsState }: { questionsState: QuestionsState }): QuestionsStatus =>
    questionsState?.status,
  questionsAreLoading: (_, { questionsStatus }: { questionsStatus: QuestionsStatus }): boolean =>
    questionsStatus?.areLoading,
  questionsError: (_, { questionsStatus }: { questionsStatus: QuestionsStatus }): ErrorMessage =>
    questionsStatus?.error,

  answersState: (state: SurveyState): AnswersState => state.answers,
  answers: (_, { answersState }: { answersState: AnswersState }): SurveyAnswer[] =>
    answersState?.answers,
  answersSorted: (_, { answers }: { answers: SurveyAnswer[] }): SurveyAnswer[] =>
    DataUtil.sortByStringDesc(answers, 'created'),

  answersStatus: (_, { answersState }: { answersState: AnswersState }): AnswersStatus =>
    answersState?.status,
  answersIsLoading: (_, { answersStatus }: { answersStatus: AnswersStatus }): boolean =>
    answersStatus?.isLoading,
  answersError: (_, { answersStatus }: { answersStatus: AnswersStatus }): ErrorMessage =>
    answersStatus?.error,

  answersUser: (_, { answersState }: { answersState: AnswersState }): SurveyAnswer[] =>
    answersState?.answersUser,

  answerState: (state: SurveyState): AnswerState => state.currentAnswer,
  answerStatus: (_, { answerState }: { answerState: AnswerState }): AnswerStatus =>
    answerState?.status,
  answerIsSaving: (_, { answerStatus }: { answerStatus: AnswerStatus }): boolean =>
    answerStatus?.isSaving,
  answerSuccess: (_, { answerStatus }: { answerStatus: AnswerStatus }): boolean =>
    answerStatus?.isLoaded,
  answerError: (_, { answerStatus }: { answerStatus: AnswerStatus }): ErrorMessage =>
    answerStatus?.error,
};

export const AppSurveyModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
