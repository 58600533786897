export enum AnswerType {
  APPROVE = 'approve',
  REJECT = 'reject',
  REFRAIN = 'refrain',
}
export interface Answer {
  id: string;
  assemblyId: string;
  questionId: string;
  userId: string;
  answer: AnswerType;
  created: string;
}

export interface Result {
  answer: AnswerType;
  count: number;
}

export interface AnswerResults {
  questionId: string;
  results: Result[];
}

export interface Question {
  id: string;
  assemblyId: string;
  askedBy: string;
  question: string;
  created: string;
  startedAt: string;
  closedAt: string;
  userAnswer?: Answer;
  results?: AnswerResults;
}
