import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';

import { Exhibitor, MessageType, PublicProfile } from '@/models';
import exhibitorService from '@/services/exhibitor/exhibitor.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import {
  AgnosticExhibitorModule,
  CurrentExhibitorState,
  CurrentExhibitorStatus,
  ExhibitorsState,
  ExhibitorsStatus,
} from '@/stores/agnostic/modules/exhibitor/agnostic-exhibitor.module';
import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
  GET_EXHIBITOR_ERROR,
  GET_EXHIBITOR_SUCCESS,
  GET_EXHIBITORS,
  GET_EXHIBITORS_ERROR,
  GET_EXHIBITORS_SUCCESS,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  CONNECT_TO_EXHIBITOR_USER_SOCKET,
  DISCONNECT_FROM_EXHIBITOR_USER_SOCKET,
  EXHIBITOR_PUNCH_IN,
  EXHIBITOR_PUNCH_OUT,
  EXHIBITOR_USER_UPDATE_STATUS,
  EXHIBITOR_USER_UPDATE_STATUS_ERROR,
  EXHIBITOR_USER_UPDATE_STATUS_SUCCESS,
  GET_SELF_EXHIBITOR_USER,
  GET_SELF_EXHIBITOR_USER_ERROR,
  GET_SELF_EXHIBITOR_USER_SUCCESS,
  CONNECT_TO_EXHIBITOR_VISITOR_SOCKET,
  DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET,
  VISIT_EXHIBITOR,
  LEAVE_EXHIBITOR,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import {
  connectToExhibitorUserNamespace,
  disconnectFromExhibitorUserNamespace,
  EXHIBITOR_LEFT,
  EXHIBITOR_USER_STATUS_UPDATED,
  EXHIBITOR_VISITED,
} from '@/socket/exhibitor-user-namespace';
import {
  connectToExhibitorVisitorNamespace,
  disconnectFromExhibitorVisitorNamespace,
} from '@/socket/exhibitor-visitor-namespace';
import { ExhibitorRep } from '@/models/exhibitor/exhibitor.rep.model';
import { APP_EXHIBITOR_VISITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor-visitor/app-exhibitor-visitor.actions';
import { ExhibitorVisitor } from '@/models/exhibitor-visitor/exhibitor-visitor.model';

export interface AppExhibitorsState {
  exhibitor: CurrentExhibitorState;
  exhibitors: ExhibitorsState;
  exhibitorUserState: ExhibitorUserState;
}

export interface ExhibitorUserState {
  user: ExhibitorRep;
  status: CurrentExhibitorStatus;
}

const exhibitorUserState: ExhibitorUserState = {
  user: null,
  status: {
    isSaving: false,
    isSavingScreenshot: false,
    isLoading: true,
    error: null,
  },
};

const exhibitorState: CurrentExhibitorState = {
  exhibitor: null,
  status: {
    error: null,
    isSaving: false,
    isLoading: true,
    isSavingScreenshot: false,
  },
};

const exhibitorsState: ExhibitorsState = {
  exhibitors: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const state: AppExhibitorsState = {
  exhibitor: exhibitorState,
  exhibitors: exhibitorsState,
  exhibitorUserState,
};

const ONLINE = 'online';
const OFFLINE = 'offline';

let exhibitorSocket;

const actions = {
  [GET_EXHIBITOR]: AgnosticExhibitorModule.actions.getExhibitor,
  [GET_EXHIBITORS]: AgnosticExhibitorModule.actions.getExhibitors,
  async [GET_SELF_EXHIBITOR_USER](
    { commit, dispatch }: ActionContext<AppExhibitorsState, RootState>,
    payload: {
      eventId: string;
    },
  ) {
    commit(GET_SELF_EXHIBITOR_USER);

    try {
      const user = await exhibitorService.getSelfExhibitorUser(payload);
      commit(GET_SELF_EXHIBITOR_USER_SUCCESS, user);
    } catch (error) {
      commit(GET_SELF_EXHIBITOR_USER_ERROR, error);
      if (error.status !== 404) {
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: error,
            type: MessageType.error,
          },
          {
            root: true,
          },
        );
      }
    }
  },
  [CLEAR_EXHIBITOR]: AgnosticExhibitorModule.actions.clearExhibitor,
  [CONNECT_TO_EXHIBITOR_USER_SOCKET](
    { dispatch }: ActionContext<AppExhibitorsState, RootState>,
    { eventId, exhibitorId, userId }: { eventId: string; exhibitorId: string; userId: string },
  ) {
    exhibitorSocket = connectToExhibitorUserNamespace(eventId, exhibitorId, userId);

    exhibitorSocket.on('connect', async () => {
      await dispatch(EXHIBITOR_USER_UPDATE_STATUS, {
        eventId,
        exhibitorId,
        userId,
        status: ONLINE,
      });
    });

    exhibitorSocket.on(EXHIBITOR_VISITED, async (exhibitorVisitor: ExhibitorVisitor) => {
      await dispatch(`${APP_EXHIBITOR_VISITOR_MODULE}/${VISIT_EXHIBITOR}`, exhibitorVisitor, {
        root: true,
      });
    });

    exhibitorSocket.on(EXHIBITOR_LEFT, async (userId: string) => {
      await dispatch(
        `${APP_EXHIBITOR_VISITOR_MODULE}/${LEAVE_EXHIBITOR}`,
        { userId },
        { root: true },
      );
    });
  },
  async [DISCONNECT_FROM_EXHIBITOR_USER_SOCKET](
    { dispatch }: ActionContext<AppExhibitorsState, RootState>,
    { eventId, exhibitorId, userId }: { eventId: string; exhibitorId: string; userId: string },
  ) {
    await dispatch(EXHIBITOR_USER_UPDATE_STATUS, { eventId, exhibitorId, userId, status: OFFLINE });

    disconnectFromExhibitorUserNamespace(exhibitorSocket);
  },
  [CONNECT_TO_EXHIBITOR_VISITOR_SOCKET](
    { commit }: ActionContext<AppExhibitorsState, RootState>,
    { eventId, exhibitorId }: { eventId: string; exhibitorId: string },
  ) {
    exhibitorSocket = connectToExhibitorVisitorNamespace({ eventId, exhibitorId });

    exhibitorSocket.on(EXHIBITOR_USER_STATUS_UPDATED, async (userId, status) => {
      commit(EXHIBITOR_USER_UPDATE_STATUS_SUCCESS, { userId, status });
    });
  },
  async [DISCONNECT_FROM_EXHIBITOR_VISITOR_SOCKET]() {
    disconnectFromExhibitorVisitorNamespace(exhibitorSocket);
  },
  async [EXHIBITOR_USER_UPDATE_STATUS](
    { commit }: ActionContext<AppExhibitorsState, RootState>,
    payload: { eventId: string; exhibitorId: string; userId: string; status: string },
  ) {
    commit(EXHIBITOR_USER_UPDATE_STATUS);

    try {
      const { status } = await exhibitorService.updateStatus({ ...payload });
      commit(EXHIBITOR_USER_UPDATE_STATUS_SUCCESS, { status });
    } catch (error) {
      commit(EXHIBITOR_USER_UPDATE_STATUS_ERROR, error);
    }
  },
  async [EXHIBITOR_PUNCH_IN](
    { dispatch }: ActionContext<AppExhibitorsState, RootState>,
    payload: { eventId: string; exhibitorId: string; userId: string },
  ) {
    await dispatch(EXHIBITOR_USER_UPDATE_STATUS, { ...payload, status: ONLINE });
  },
  async [EXHIBITOR_PUNCH_OUT](
    { dispatch }: ActionContext<AppExhibitorsState, RootState>,
    payload: { eventId: string; exhibitorId: string },
  ) {
    await dispatch(EXHIBITOR_USER_UPDATE_STATUS, { ...payload, status: OFFLINE });
  },
};

const mutations = {
  [GET_EXHIBITOR](state: AppExhibitorsState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_EXHIBITOR_SUCCESS](state: AppExhibitorsState, exhibitor: Exhibitor) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isLoading: false,
        error: null,
      },
      exhibitor: {
        ...exhibitor,
      },
    };
  },
  [GET_EXHIBITOR_ERROR](state: AppExhibitorsState, error: ErrorMessage) {
    state.exhibitor = {
      ...state.exhibitor,
      status: {
        ...state.exhibitor.status,
        isLoading: false,
        error,
      },
    };
  },
  [GET_EXHIBITORS](state: AppExhibitorsState) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: true,
        error: null,
      },
    };
  },
  [GET_EXHIBITORS_SUCCESS](state: AppExhibitorsState, exhibitors: Exhibitor[]) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: false,
        error: null,
      },
      exhibitors: [...exhibitors],
    };
  },
  [GET_EXHIBITORS_ERROR](state: AppExhibitorsState, error: ErrorMessage) {
    state.exhibitors = {
      ...state.exhibitors,
      status: {
        ...state.exhibitors.status,
        areLoading: false,
        error,
      },
    };
  },
  [GET_SELF_EXHIBITOR_USER](state: AppExhibitorsState) {
    state.exhibitorUserState = {
      ...state.exhibitorUserState,
      user: null,
      status: {
        ...state.exhibitorUserState.status,
        isLoading: true,
        error: null,
      },
    };
  },
  [GET_SELF_EXHIBITOR_USER_SUCCESS](state: AppExhibitorsState, rep: ExhibitorRep) {
    state.exhibitorUserState = {
      ...state.exhibitorUserState,
      user: rep,
      status: {
        ...state.exhibitorUserState.status,
        isLoading: false,
        error: null,
      },
    };
  },
  [GET_SELF_EXHIBITOR_USER_ERROR](state: AppExhibitorsState, error: ErrorMessage) {
    state.exhibitorUserState = {
      ...state.exhibitorUserState,
      user: null,
      status: {
        ...state.exhibitorUserState.status,
        isLoading: false,
        error,
      },
    };
  },
  [CLEAR_EXHIBITOR](state: AppExhibitorsState) {
    state.exhibitor = {
      ...state.exhibitor,
      exhibitor: null,
      status: {
        ...state.exhibitor.status,
        isSaving: false,
        isLoading: false,
        error: null,
      },
    };
  },
  [EXHIBITOR_USER_UPDATE_STATUS](state: AppExhibitorsState) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: true,
    };
  },
  [EXHIBITOR_USER_UPDATE_STATUS_SUCCESS](
    state: AppExhibitorsState,
    payload: { userId?: string; status: string },
  ) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
    };

    // If the payload contains the userId, then we update the user in the exhibitor reps list (for exhibitor visitors)
    // Else we just update the current exhibitor status (for exhibitor users)
    if (payload.userId && state.exhibitor?.exhibitor?.users) {
      const repIndex = state.exhibitor.exhibitor.users.findIndex(
        (element) => element.id === payload.userId,
      );
      const reps = [...state.exhibitor.exhibitor.users];
      reps[repIndex] = { ...reps[repIndex], status: payload.status };

      state.exhibitor.exhibitor.users = [...reps];
    } else {
      state.exhibitorUserState.user = {
        ...state.exhibitorUserState.user,
        status: payload.status,
      };
    }
  },
  [EXHIBITOR_USER_UPDATE_STATUS_ERROR](state: AppExhibitorsState, error: ErrorMessage) {
    state.exhibitor.status = {
      ...state.exhibitor.status,
      isSaving: false,
      error,
    };
  },
};
const getters = {
  exhibitorState: (state: AppExhibitorsState): CurrentExhibitorState => state.exhibitor,
  exhibitor: (_, { exhibitorState }: { exhibitorState: CurrentExhibitorState }): Exhibitor =>
    exhibitorState?.exhibitor,

  // eslint-disable-next-line max-len
  exhibitorUsers: (
    _,
    { exhibitorState }: { exhibitorState: CurrentExhibitorState },
  ): PublicProfile[] => exhibitorState?.exhibitor?.users || [],
  exhibitorStatus: (
    _,
    { exhibitorState }: { exhibitorState: CurrentExhibitorState },
  ): CurrentExhibitorStatus => exhibitorState?.status,
  exhibitorIsLoading: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): boolean => exhibitorStatus?.isLoading,
  exhibitorIsSaving: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): boolean => exhibitorStatus?.isSaving,
  exhibitorError: (
    _,
    { exhibitorStatus }: { exhibitorStatus: CurrentExhibitorStatus },
  ): ErrorMessage => exhibitorStatus?.error,

  exhibitorsState: (state: AppExhibitorsState): ExhibitorsState => state.exhibitors,
  exhibitors: (_, { exhibitorsState }: { exhibitorsState: ExhibitorsState }): Exhibitor[] =>
    exhibitorsState?.exhibitors,
  exhibitorsStatus: (
    _,
    { exhibitorsState }: { exhibitorsState: ExhibitorsState },
  ): ExhibitorsStatus => exhibitorsState?.status,
  exhibitorsAreLoading: (
    _,
    { exhibitorsStatus }: { exhibitorsStatus: ExhibitorsStatus },
  ): boolean => exhibitorsStatus?.areLoading,
  exhibitorsError: (
    _,
    { exhibitorsStatus }: { exhibitorsStatus: ExhibitorsStatus },
  ): ErrorMessage => exhibitorsStatus?.error,
  exhibitorSelf: (state: AppExhibitorsState): ExhibitorRep => state.exhibitorUserState.user,
};

export const AppExhibitorModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
