import { HttpMethod, ActivityResult, ActivityQuestion } from '@/models';
import QuestionnaireUtils from '@/helpers/questionnaire/questionnaire.helper';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

export interface AnswerQuestionRequest {
  eventId: string;
  questionId: string;
  answerIds: string[];
}

class ActivityService {
  private key = 'ra';

  public async getQuestions(eventId: string): Promise<ActivityQuestion[]> {
    const questions: ActivityQuestion[] = await HttpService.perform<ActivityQuestion[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.ACTIVITY,
        HttpHelper.QUESTIONS,
      ]),
    );

    return QuestionnaireUtils.mapQuestionStoredAnswers({
      eventId,
      questions,
      key: this.key,
    }) as ActivityQuestion[];
  }

  public async answerQuestion({
    eventId,
    questionId,
    answerIds,
  }: AnswerQuestionRequest): Promise<void> {
    const result = HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.ACTIVITY,
        HttpHelper.QUESTIONS,
        questionId,
        HttpHelper.ANSWERS,
        answerIds[0],
      ]),
    );

    QuestionnaireUtils.storeAnswers({
      eventId,
      questionId,
      answerIds,
      key: this.key,
    });

    return result;
  }

  public async getResults(eventId: string): Promise<ActivityResult> {
    return HttpService.perform<ActivityResult>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [
        eventId,
        HttpHelper.ACTIVITY,
        HttpHelper.RESULTS,
      ]),
    );
  }
}

export default new ActivityService();
