<template>
  <div class="custom-buttons">
    <template v-if="eventHasExternalLinks">
      <div
        class="button__wrapper"
        v-for="(link, index) in event.externalLinks"
        :key="`${link.name}${index}`"
      >
        <v-btn
          block
          x-large
          class="custom-buttons__btn button"
          :color="accentColor"
          :style="btnTextColor"
          @click="openLink(link.url)"
          data-test-id="external-link"
        >
          {{ link.name }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

export default {
  name: 'CustomExternalLinksButtons',
  components: {},
  methods: {
    openLink(url, newTab = true) {
      window.open(url, newTab ? '_blank' : '_self');
    },
  },
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'eventIsLoaded']),
    accentColor() {
      return this.customUi?.btnColor || 'primary';
    },
    btnTextColor() {
      return `color: ${this.customUi?.btnTextColor || '#FFFFFF'}`;
    },
    eventHasExternalLinks() {
      return !!this.event?.externalLinks?.length;
    },
  },
};
</script>
