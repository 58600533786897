<template>
  <div class="discussion-group">
    <div class="discussion-group__content">
      <div class="content__header">
        <div class="header__title">{{ discussionGroup.name }}</div>

        <div class="header__description">
          <read-more
            :less="$t('globals.readLess')"
            :more="$t('globals.readMore')"
            :text="discussionGroup.description"
            v-if="discussionGroup.description"
          />
        </div>

        <div class="header__date">
          {{ $d(Date.parse(discussionGroup.startTime), 'long') }}
        </div>
      </div>

      <div class="content__actions">
        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          v-if="!isInAgenda() && !canAccess()"
          @click.native="$emit('add-to-agenda', discussionGroup)"
        >
          {{ $t('lobby.add') }}
        </v-btn>

        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          v-if="isInAgenda() && !canAccess()"
          @click.native="$emit('remove-from-agenda', discussionGroup)"
        >
          {{ $t('lobby.remove') }}
        </v-btn>

        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          @click="$emit('select-discussion-group', discussionGroup.id)"
          v-if="canAccess()"
        >
          {{ $t('lobby.join') }}
        </v-btn>
      </div>
    </div>

    <div class="discussion-group__picture">
      <img
        class="picture"
        :src="discussionGroup.coverImages && discussionGroup.coverImages[0]"
        :alt="discussionGroup.name"
      />
    </div>
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';
import ReadMore from '@/components/read-more/ReadMore.vue';

export default {
  name: 'DiscussionGroupItem',
  props: {
    discussionGroup: {
      type: Object,
      required: true,
    },
    agenda: {
      type: Array,
      required: true,
    },
    now: {
      type: String,
      default: new Date().toISOString(),
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    btnStyle: {
      type: String,
      required: false,
    },
  },
  components: {
    ReadMore,
  },
  methods: {
    canAccess() {
      return DateUtil.canAccessDiscussionGroup(this.discussionGroup, this.isAdmin, this.now);
    },
    isInAgenda() {
      return !!this.agenda.find((item) => item.discussionGroupId === this.discussionGroup.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.discussion-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 30px;

  &__content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 25px;
  }

  &__picture {
    flex: 1;
    width: 45%;
  }
}

.actions {
  &__action {
    width: 90px;
    border-radius: 5px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.header {
  &__title {
    color: var(--v-primaryBackground-base);
    font-weight: $bold;
    font-size: $default-font-size;
  }

  &__description {
    font-size: $small-font-size;
    margin-bottom: 30px;
  }

  &__date {
    font-size: $small-font-size;
    font-weight: $bold;
    margin-bottom: 5px;
  }
}

.picture {
  width: 100%;
}
</style>
