/**
 * Navigate to the discussion group with id "discussionGroupId".
 *
 * @param $router the vue router
 * @param discussionGroupId the id of the discussion group
 * @param eventId the id of the event
 */
import VueRouter, { RawLocation } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/stores/store.model';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  FETCH_ALL_EVENTS,
  FETCH_MY_EVENTS,
} from '@/stores/agnostic/actions/event/agnostic-event.actions';

export const navigateToDiscussionGroup = ($router: VueRouter) => ({
  discussionGroupId,
  eventId,
}: {
  discussionGroupId: string;
  eventId: string;
}) => {
  $router.push({
    name: 'DiscussionGroup',
    params: {
      discussionGroupId,
      eventId,
    },
  });
};

export const navigateToDashBoard = async ($router: VueRouter, $store: Store<RootState>) => {
  const promises = [
    $store.dispatch(`${APP_EVENT_MODULE}/${FETCH_ALL_EVENTS}`),
    $store.dispatch(`${APP_EVENT_MODULE}/${FETCH_MY_EVENTS}`),
  ];
  await Promise.all(promises);
  const myOnlyEvent = $store.getters[`${APP_EVENT_MODULE}/myOnlyEvent`];

  if (myOnlyEvent) {
    if ($router.currentRoute.name !== 'Lobby') {
      await $router.push({
        name: 'Lobby',
        params: {
          eventId: myOnlyEvent.id,
        },
      });
    }
  } else {
    await $router.push({
      name: 'Dashboard',
    });
  }
};

export const userBookingLocation = ({
  eventId,
  userId,
}: {
  eventId: string;
  userId: string;
}): RawLocation => ({
  name: 'Booking',
  params: { eventId, userId },
});

export const toUserBooking = ($router: VueRouter) => ({
  eventId,
  userId,
}: {
  eventId: string;
  userId: string;
}) => {
  $router.push(userBookingLocation({ eventId, userId }));
};

export const toEventPublicProfile = ($router: VueRouter) => ({
  eventId,
  userId,
}: {
  eventId: string;
  userId: string;
}) => {
  $router.push({
    name: 'EventPublicProfile',
    params: { eventId, userId },
  });
};

export const toMeetingConfiguration = ($router: VueRouter) => ({ eventId }: { eventId }) => {
  $router.push({
    name: 'MeetingConfiguration',
    params: { eventId },
  });
};

export const toDashboard = () => ({
  name: 'Dashboard',
});

export const toProfile = (eventId: string) => {
  if (eventId) {
    return {
      name: 'TicketProfile',
      params: { eventId },
    };
  }
  return {
    name: 'Profile',
    params: {},
  };
};

export const toLobby = (eventId) => ({
  name: 'Lobby',
  params: { eventId },
});

export const toAdminDashboard = () => ({
  name: 'EventListAdmin',
});

export const toExhibitors = (eventId) => ({
  name: 'Exhibitors',
  params: {
    eventId,
  },
});

export const toEditContentLibrary = ($router: VueRouter) => ({
  eventId,
  contentLibraryId,
}: {
  eventId: string;
  contentLibraryId: string;
}) => {
  $router.push({
    name: 'EditContentLibraryAdmin',
    params: {
      eventId,
      contentLibraryId,
    },
  });
};

export const toAddContentLibrary = ($router: VueRouter) => ({ eventId }: { eventId: string }) => {
  $router.push({
    name: 'AddContentLibraryAdmin',
    params: {
      eventId,
    },
  });
};
