import { USER_MODULE } from '@/stores/agnostic/actions/user/agnostic-user.actions';
import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const ADMIN_USER_MODULE = `${UMANIZE_ADMIN_MODULE}/${USER_MODULE}`;

export const SAVE_USER = 'SAVE_USER';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPLOAD_CSV_USERS = 'UPLOAD_CSV_USERS';
export const UPLOAD_CSV_USERS_SUCCESS = 'UPLOAD_CSV_USERS_SUCCESS';
export const UPLOAD_CSV_USERS_ERROR = 'UPLOAD_CSV_USERS_ERROR';

export const TOGGLE_TICKET_STATUS = 'TOGGLE_TICKET_STATUS';
export const UPDATE_USER_TICKETID = 'UPDATE_USER_TICKETID';

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';

export const CLEAR_USER = 'CLEAR_USER';

export const CLEAR_TICKET_PROFILE = 'CLEAR_TICKET_PROFILE';
