import { HttpMethod, Tag } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class TagService {
  public async getTags(eventId: string): Promise<Tag[]> {
    return HttpService.perform<Tag[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.TAGS_ENDPOINT]),
    );
  }

  public async createTag(eventId: string, tag: Tag): Promise<void> {
    return HttpService.perform<void>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.TAGS_ENDPOINT]),
      tag,
    );
  }
}

export default new TagService();
