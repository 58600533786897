import { Partner } from '@/models/event/partner.model';
import { Schedulable } from '../schedule/Schedulable.model';

export class DiscussionGroup implements Schedulable {
  id: string;

  eventId: string;

  name: string;

  description: string;

  backgroundImage: string;

  chatRoom: string;

  wherebyUrl: string;

  wherebyHostUrl: string;

  hasWherebyChat: boolean;

  chatId: string;

  startTime: string;

  endTime: string;

  coverImages: string[];

  partners: DiscussionGroupPartner[];
}

type DiscussionGroupPartner = Partner & { discussionGroupId: string };
