import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const options = {
  breakpoint: {
    mobileBreakpoint: 736,
    thresholds: {
      xs: 480,
      sm: 736,
      md: 980,
      lg: 1280,
      xl: 1620,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00B5A8',
        primaryBackground: '#FFFFFF',
        secondaryBackground: '#EEEEEE',
        tertiaryBackground: '#ECECEC',
        quaternaryBackground: '#393939',
        adminBackground: '#EFEEEF',
        primaryFont: '#2D2D2D',
        secondaryFont: '#A4A4A4',
        info: '#00B5A8',
        gray: '#C6C6C6',
        darkGray: '#3D3D3D',
        darkerGray: '#1E1E1E',
      },
      dark: {
        primaryBackground: '#212323',
        secondarybackground: '#EEEEEE',
        tertiaryBackground: '#ECECEC',
        quaternaryBackground: '#393939',
        adminBackground: '#EFEEEF',
        primaryFont: '#FFFFFF',
        info: '#00B5A8',
        gray: '#C6C6C6',
        darkGray: '#3D3D3D',
        darkerGray: '#1E1E1E',
      },
    },
  },
};

export default new Vuetify(options);
