import { ActionContext } from 'vuex';

import { AppEvent, EventUserOrPublicProfile, MessageType } from '@/models';
import EventService from '@/services/event/event.service';
import { RootState } from '@/stores/store.model';
import {
  CREATE_EVENT,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_SUCCESS,
  SAVE_EVENT,
  UPDATE_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
} from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import {
  AgnosticEventModule,
  CurrentEventState,
  CurrentEventStatus,
  EventsState,
  EventsStatus,
  EventUsersState,
  EventUsersStatus,
  MyEventsState,
  MyEventsStatus,
} from '@/stores/agnostic/modules/event/agnostic-event.module';
import {
  FETCH_ALL_EVENTS,
  FETCH_ALL_EVENTS_ERROR,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_EVENT_BY_ID,
  FETCH_EVENT_BY_ID_ERROR,
  FETCH_EVENT_BY_ID_SILENT,
  FETCH_EVENT_BY_ID_SUCCESS,
  FETCH_EVENT_USERS,
  FETCH_EVENT_USERS_ERROR,
  FETCH_EVENT_USERS_SUCCESS,
  FETCH_MY_EVENTS,
  FETCH_MY_EVENTS_ERROR,
  FETCH_MY_EVENTS_SUCCESS,
} from '@/stores/agnostic/actions/event/agnostic-event.actions';
import { AppEventState } from '@/stores/umanize-app/modules/event/app-event.module';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export interface AdminEventState {
  event: CurrentEventState;
  events: EventsState;
  users: EventUsersState;
  myEvents: MyEventsState;
}

const currentEventState: CurrentEventState = {
  event: null,
  status: {
    error: null,
    isSaving: false,
    isLoading: false,
    isLoaded: false,
  },
};

const eventUsersState: EventUsersState = {
  filter: {
    searchText: '',
    ticketType: TicketType.all,
  },
  users: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

const eventsState: EventsState = {
  events: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

const myEventsState: MyEventsState = {
  myEvents: [],
  status: {
    error: null,
    areLoading: true,
    areLoaded: true,
  },
};

export const state: AdminEventState = {
  event: currentEventState,
  events: eventsState,
  users: eventUsersState,
  myEvents: myEventsState,
};

const actions = {
  [FETCH_ALL_EVENTS]: AgnosticEventModule.actions.fetchAllEvents,
  [FETCH_MY_EVENTS]: AgnosticEventModule.actions.fetchMyEvents,
  [FETCH_EVENT_USERS]: AgnosticEventModule.actions.fetchEventsUsers,
  [FETCH_EVENT_BY_ID_SILENT]: AgnosticEventModule.actions.fetchEventByIdSilent,
  async [SAVE_EVENT]({ dispatch }: ActionContext<AdminEventState, RootState>, event: AppEvent) {
    const dispatchedAction = event.id ? UPDATE_EVENT : CREATE_EVENT;

    await dispatch(dispatchedAction, event);
  },
  async [CREATE_EVENT](
    { commit, dispatch }: ActionContext<AdminEventState, RootState>,
    event: AppEvent,
  ) {
    commit(CREATE_EVENT);

    try {
      const createdEvent = await EventService.createEvent(event);
      commit(CREATE_EVENT_SUCCESS, createdEvent);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('event.add.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      commit(CREATE_EVENT_ERROR, error);
    }
  },
  async [UPDATE_EVENT](
    { commit, dispatch }: ActionContext<AdminEventState, RootState>,
    event: AppEvent,
  ) {
    commit(UPDATE_EVENT);

    try {
      const updatedEvent = await EventService.updateEvent(event);
      commit(UPDATE_EVENT_SUCCESS, updatedEvent);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('event.edit.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      commit(UPDATE_EVENT_ERROR, error);
    }
  },
};

const mutations = {
  [FETCH_ALL_EVENTS](state) {
    state.events.status = {
      ...state.events.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_ALL_EVENTS_SUCCESS](state, events) {
    state.events = {
      status: {
        ...state.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      events: [...events],
    };
  },
  [FETCH_ALL_EVENTS_ERROR](state, error) {
    state.events.status = {
      ...state.events.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [FETCH_MY_EVENTS](state) {
    state.myEvents.status = {
      ...state.myEvents.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_MY_EVENTS_SUCCESS](state, events) {
    state.myEvents = {
      status: {
        ...state.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      myEvents: [...events],
    };
  },
  [FETCH_MY_EVENTS_ERROR](state, error) {
    state.myEvents.status = {
      ...state.myEvents.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [FETCH_EVENT_BY_ID](state) {
    state.event.status = {
      ...state.event.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [FETCH_EVENT_BY_ID_SUCCESS](state, event) {
    state.event = {
      status: {
        ...state.event.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      event: {
        ...event,
      },
    };
  },
  [FETCH_EVENT_BY_ID_ERROR](state, error) {
    state.event.status = {
      ...state.event.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
  [FETCH_EVENT_USERS](state) {
    state.users.status = {
      ...state.status,
      areLoading: true,
      areLoaded: false,
      error: null,
    };
  },
  [FETCH_EVENT_USERS_SUCCESS](state, users) {
    state.users = {
      ...state.users,
      status: {
        ...state.users.status,
        areLoading: false,
        areLoaded: true,
        error: null,
      },
      users: [...users],
    };
  },
  [FETCH_EVENT_USERS_ERROR](state, error) {
    state.users.status = {
      ...state.users.status,
      areLoading: false,
      areLoaded: false,
      error,
    };
  },
  [UPDATE_EVENT](state) {
    state.event.status = {
      ...state.event.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_EVENT_SUCCESS](state, event) {
    state.event = {
      status: {
        ...state.event.status,
        isSaving: false,
        error: null,
      },
      event: {
        ...event,
      },
    };
  },
  [UPDATE_EVENT_ERROR](state, error) {
    state.event.status = {
      ...state.event.status,
      isSaving: false,
      error,
    };
  },
  [CREATE_EVENT](state) {
    state.event.status = {
      ...state.event.status,
      isSaving: true,
      error: null,
    };
  },
  [CREATE_EVENT_SUCCESS](state, event) {
    state.event = {
      status: {
        ...state.event.status,
        isSaving: false,
        error: null,
      },
      event: {
        ...event,
      },
    };
  },
  [CREATE_EVENT_ERROR](state, error) {
    state.event.status = {
      ...state.event.status,
      isSaving: false,
      error,
    };
  },
};

const getters = {
  eventState: (state: AppEventState): CurrentEventState => state.event,
  event: (_, { eventState }: { eventState: CurrentEventState }): AppEvent =>
    eventState?.event || null,
  eventStatus: (_, { eventState }: { eventState: CurrentEventState }): CurrentEventStatus =>
    eventState?.status || null,
  eventIsLoading: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): boolean =>
    eventStatus?.isLoading || false,
  eventIsSaving: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): boolean =>
    eventStatus?.isSaving || false,
  eventIsLoaded: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): boolean =>
    eventStatus?.isLoaded || false,
  eventError: (_, { eventStatus }: { eventStatus: CurrentEventStatus }): ErrorMessage =>
    eventStatus?.error || null,

  eventUsersState: (state: AdminEventState): EventUsersState => state.users,
  eventUsers: (
    _,
    { eventUsersState }: { eventUsersState: EventUsersState },
  ): EventUserOrPublicProfile[] => eventUsersState?.users || [],
  eventUsersStatus: (
    _,
    { eventUsersState }: { eventUsersState: EventUsersState },
  ): EventUsersStatus => eventUsersState?.status || null,
  usersAreLoading: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): boolean =>
    eventUsersStatus?.areLoading || false,
  usersAreLoaded: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): boolean =>
    eventUsersStatus?.areLoaded || false,
  usersError: (_, { eventUsersStatus }: { eventUsersStatus: EventUsersStatus }): ErrorMessage =>
    eventUsersStatus?.error || null,

  myEventsState: (state: AdminEventState): MyEventsState => state.myEvents,
  events: (_, { myEventsState }: { myEventsState: MyEventsState }) =>
    myEventsState?.myEvents?.filter((event) => event.public) || [],
  myAdminEvents: (_, { myEventsState }: { myEventsState: MyEventsState }, __, rootGetters) =>
    (myEventsState?.myEvents || []).filter((event) =>
      PermissionsUtil.isAdmin(rootGetters[`${APP_USER_MODULE}/loggedInUserRoles`], event.id),
    ),
  myEventsStatus: (_, { myEventsState }: { myEventsState: MyEventsState }): EventsStatus =>
    myEventsState?.status || null,
  myEventsAreLoading: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): boolean =>
    myEventsStatus?.areLoading || false,
  myEventsAreLoaded: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): boolean =>
    myEventsStatus?.areLoaded || false,
  myEventsError: (_, { myEventsStatus }: { myEventsStatus: MyEventsStatus }): ErrorMessage =>
    myEventsStatus?.error || null,
};

export const AdminEventModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
