import { DateTime } from 'luxon';
import { ActionContext } from 'vuex';
import cuid from 'cuid';
import { AppEvent, MessageType } from '@/models';
import {
  ADD_SLOT,
  LOAD_USER_AVAILABILITY,
  REMOVE_SLOT,
  SAVE,
  SET_END_SLOT,
  SET_LOADING,
  SET_SLOT_DURATION,
  SET_START_SLOT,
} from '@/stores/umanize-app/actions/availability/availability.actions';
import { RootState } from '@/stores/store.model';
import {
  createUserAvailability,
  deleteUserAvailability,
  getUserAvailability,
  updateUserAvailabilitySlotDuration,
} from '@/services/user-availability/user-availability.service';
import DateUtil from '@/helpers/date/date.helper';
import { ActionContextWithGetter } from '@/core-types';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';
import { getAllConfigurableDays } from '@/stores/umanize-app/modules/availability/availability.helper';
import { Slot } from '@/stores/umanize-app/modules/availability/slot';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

type SaveMutation = {
  oldId: string;
  newId: string;
  day: string;
}[];
type SetSlotMutation = {
  value: string;
  id: string;
  day: string;
};
type RemoveSlotMutation = {
  slot: Slot;
  day: string;
};
type SlotDuration = 15 | 30;
type Days = Record<string, Slot[]>;
export type AvailabilityState = {
  slotDuration: SlotDuration;
  days: Days;
  loading: boolean;
};
const state: AvailabilityState = {
  slotDuration: 30,
  days: undefined,
  loading: false,
};

type Getters = {
  getDays: Days;
  getSlotDuration: SlotDuration;
  isLoading: boolean;
  getDaysKeys: string[];
  getSlotsForDay: (day: string) => Slot[];
  hasUnsavedSlot: boolean;
  hasSavedSlot: boolean;
};

const getters = {
  getDays: (state: AvailabilityState): Days => state.days || {},
  getSlotDuration: (state: AvailabilityState): SlotDuration => state.slotDuration,
  isLoading: (state: AvailabilityState): boolean => state.loading,
  getDaysKeys: (state: AvailabilityState, { getDays }: Partial<Getters>): string[] =>
    Object.keys(getDays),
  getSlotsForDay: (state: AvailabilityState, { getDays }: Partial<Getters>) => (
    day: string,
  ): Slot[] => getDays[day] || [],
  hasUnsavedSlot: (
    state: AvailabilityState,
    { getDaysKeys, getSlotsForDay }: Partial<Getters>,
  ): boolean => getDaysKeys.some((day) => getSlotsForDay(day).some((slot) => !slot.saved)),
  hasSavedSlot: (
    state: AvailabilityState,
    { getDaysKeys, getSlotsForDay }: Partial<Getters>,
  ): boolean => getDaysKeys.some((day) => getSlotsForDay(day).some((slot) => slot.saved)),
};

const actions = {
  async [LOAD_USER_AVAILABILITY]({
    commit,
    state,
    rootGetters,
    dispatch,
  }: ActionContext<AvailabilityState, RootState>) {
    try {
      const appEvent: AppEvent = rootGetters[`${APP_EVENT_MODULE}/event`];
      const userAvailabilities = await getUserAvailability({ eventId: appEvent.id });

      const days = getAllConfigurableDays(DateTime.now())(appEvent);

      const slotDuration =
        userAvailabilities.length > 0
          ? userAvailabilities[0].slotDurationInMin
          : state.slotDuration;
      userAvailabilities.forEach((it) => {
        const availabilityStartDate = DateTime.fromISO(it.starting).toISODate();

        if (!days[availabilityStartDate]) {
          return;
        }

        const slot: Slot = {
          id: it.id,
          start: DateTime.fromISO(it.starting).toFormat('HH:mm'),
          end: DateTime.fromISO(it.ending).toFormat('HH:mm'),
          saved: true,
        };
        days[availabilityStartDate].push(slot);
      });

      commit(LOAD_USER_AVAILABILITY, {
        slotDuration,
        days,
      });
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    }
  },
  async [SET_SLOT_DURATION](
    {
      commit,
      getters,
      rootGetters,
      dispatch,
    }: ActionContextWithGetter<AvailabilityState, RootState, Getters>,
    slotDuration: SlotDuration,
  ) {
    if (getters.hasSavedSlot) {
      try {
        const appEvent: AppEvent = rootGetters[`${APP_EVENT_MODULE}/event`];
        commit(SET_LOADING, true);
        await updateUserAvailabilitySlotDuration({
          eventId: appEvent.id,
          slotDurationInMin: slotDuration,
        });
      } catch (e) {
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t(`meetings.errors.${e.status}`),
            type: MessageType.error,
          },
          {
            root: true,
          },
        );
      } finally {
        commit(SET_LOADING, false);
      }
    }
    commit(SET_SLOT_DURATION, slotDuration);
  },
  async [ADD_SLOT]({ commit }: ActionContext<AvailabilityState, RootState>, day: string) {
    commit(ADD_SLOT, day);
  },
  async [REMOVE_SLOT](
    { commit, rootGetters, dispatch }: ActionContext<AvailabilityState, RootState>,
    payload: RemoveSlotMutation,
  ) {
    const { slot } = payload;

    if (slot.saved) {
      try {
        commit(SET_LOADING, true);
        const appEvent: AppEvent = rootGetters[`${APP_EVENT_MODULE}/event`];
        await deleteUserAvailability({
          eventId: appEvent.id,
          slotId: slot.id,
        });
      } catch (e) {
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t(`meetings.errors.${e.status}`),
            type: MessageType.error,
          },
          {
            root: true,
          },
        );
      } finally {
        commit(SET_LOADING, false);
      }
    }

    commit(REMOVE_SLOT, payload);
  },
  async [SET_START_SLOT](
    { commit }: ActionContext<AvailabilityState, RootState>,
    payload: SetSlotMutation,
  ) {
    commit(SET_START_SLOT, payload);
  },
  async [SET_END_SLOT](
    { commit }: ActionContext<AvailabilityState, RootState>,
    payload: SetSlotMutation,
  ) {
    commit(SET_END_SLOT, payload);
  },
  async [SAVE]({
    commit,
    getters,
    rootGetters,
    dispatch,
  }: ActionContext<AvailabilityState, RootState>) {
    try {
      commit(SET_LOADING, true);

      const appEvent: AppEvent = rootGetters[`${APP_EVENT_MODULE}/event`];
      const slotDuration: number = getters.getSlotDuration;
      const days: string[] = getters.getDaysKeys;

      const savedSlots: SaveMutation = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const day of days) {
        const dateTimeFromDay = DateTime.fromFormat(day, DateUtil.ISO_DATE_FORMAT);
        const slots: Slot[] = getters.getSlotsForDay(day);

        // eslint-disable-next-line no-restricted-syntax
        for await (const slot of slots.filter((it) => !it.saved)) {
          const response = await createUserAvailability({
            eventId: appEvent.id,
            payload: {
              starting: dateTimeFromDay
                .plus(DateUtil.timeFormat24hToObject(slot.start))
                .toUTC()
                .toISO({ suppressMilliseconds: true }),
              ending: dateTimeFromDay
                .plus(DateUtil.timeFormat24hToObject(slot.end))
                .toUTC()
                .toISO({ suppressMilliseconds: true }),
              slotDurationInMin: slotDuration,
            },
          });

          savedSlots.push({
            oldId: slot.id,
            newId: response.id,
            day,
          });
        }
      }

      commit(SAVE, savedSlots);
    } catch (e) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`meetings.errors.${e.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );
    } finally {
      commit(SET_LOADING, false);
    }
  },
};
const mutations = {
  [LOAD_USER_AVAILABILITY](
    state: AvailabilityState,
    { slotDuration, days }: { slotDuration: SlotDuration; days: Days },
  ) {
    state.days = { ...days };
    state.slotDuration = slotDuration;
  },
  [SET_SLOT_DURATION](state: AvailabilityState, slotDuration: SlotDuration) {
    state.slotDuration = slotDuration;
  },
  [ADD_SLOT](state: AvailabilityState, day: string) {
    if (!state.days[day]) {
      return;
    }

    state.days = {
      ...state.days,
      [day]: [
        ...state.days[day],
        {
          id: cuid(),
          saved: false,
        },
      ],
    };
  },
  [REMOVE_SLOT](state: AvailabilityState, { slot, day }: RemoveSlotMutation) {
    if (!state.days[day]) {
      return;
    }

    const newSlots = state.days[day].filter((it) => it.id !== slot.id);

    state.days = {
      ...state.days,
      [day]: [...newSlots],
    };
  },
  [SET_START_SLOT](state: AvailabilityState, { value, id, day }: SetSlotMutation) {
    if (!state.days[day]) {
      return;
    }

    state.days = {
      ...state.days,
      [day]: state.days[day].map((it) => {
        if (it.id !== id) {
          return it;
        }
        return {
          ...it,
          start: value,
        };
      }),
    };
  },
  [SET_END_SLOT](state: AvailabilityState, { value, id, day }: SetSlotMutation) {
    if (!state.days[day]) {
      return;
    }

    state.days = {
      ...state.days,
      [day]: state.days[day].map((it) => {
        if (it.id !== id) {
          return it;
        }
        return {
          ...it,
          end: value,
        };
      }),
    };
  },
  [SET_LOADING](state: AvailabilityState, saving: boolean) {
    state.loading = saving;
  },
  [SAVE](state: AvailabilityState, payload: SaveMutation) {
    payload.forEach(({ day, oldId, newId }) => {
      if (!state.days[day]) {
        return;
      }

      state.days = {
        ...state.days,
        [day]: state.days[day].map((it) => {
          if (it.id !== oldId) {
            return it;
          }
          return {
            ...it,
            id: newId,
            saved: true,
          };
        }),
      };
    });
  },
};

export const AvailabilityModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
