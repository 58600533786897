import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const TRAINING_SECTION_MODULE_NAME = 'TrainingModule';
export const TRAINING_SECTION_ADMIN_MODULE_NAME = 'AdminTrainingSectionModule';
export const TRAINING_SECTION_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_SECTION_MODULE_NAME}`;
export const TRAINING_SECTION_ADMIN_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_SECTION_ADMIN_MODULE_NAME}`;

export const GET_TRAININGS_SECTION = 'GET_TRAININGS_SECTION';
export const GET_ALL_TRAININGS_SECTION_ADMIN = 'GET_ALL_TRAININGS_SECTION_ADMIN';
export const GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON =
  'GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON';
export const GET_ALL_TRAININGS_SECTION_SUCCESS = 'GET_ALL_TRAININGS_SECTION_SUCCESS';
export const GET_ALL_TRAININGS_SECTION_ERROR = 'GET_ALL_TRAININGS_SECTION_ERROR';

export const GET_TRAINING_SECTION = 'GET_TRAINING_SECTION';
export const GET_TRAINING_SECTION_SUCCESS = 'GET_TRAINING_SECTION_SUCCESS';
export const GET_TRAINING_SECTION_ERROR = 'GET_TRAINING_SECTION_ERROR';

export const CLEAR_ALL_TRAINING_SECTION = 'CLEAR_ALL_TRAINING_SECTION';
export const CLEAR_TRAINING_SECTION = 'CLEAR_TRAINING_SECTION';
export const SAVE_TRAINING_SECTION = 'SAVE_TRAINING_SECTION';

export const ADD_TRAINING_SECTION = 'ADD_TRAINING_SECTION';
export const ADD_TRAINING_SECTION_SUCCESS = 'ADD_TRAINING_SUCCESS_SECTION';
export const ADD_TRAINING_SECTION_ERROR = 'ADD_TRAINING_ERROR_SECTION';

export const UPDATE_TRAINING_SECTION = 'UPDATE_TRAINING_SECTION';
export const UPDATE_TRAINING_SECTION_SUCCESS = 'UPDATE_TRAINING_SECTION_SUCCESS';
export const UPDATE_TRAINING_SECTION_ERROR = 'UPDATE_TRAINING_SECTION_ERROR';

export const DELETE_TRAINING_SECTION = 'DELETE_TRAINING_SECTION';
export const DELETE_TRAINING_SECTION_SUCCESS = 'DELETE_TRAINING_SECTION_SUCCESS';
export const DELETE_TRAINING_SECTION_ERROR = 'DELETE_TRAINING_SECTION_ERROR';

export const PUBLISH_TRAINING_SECTION = 'PUBLISH_TRAINING_SECTION';
export const UNPUBLISH_TRAINING_SECTION = 'UNPUBLISH_TRAINING_SECTION';

export const COMPLETE_LESSION_TRAINING_SECTION = 'GET_ALL_TRAININGS_SECTION_SUCCESS';
