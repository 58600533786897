import { Document } from '@/models/document/document.model';
import { ExhibitorRep } from './exhibitor.rep.model';
import { ExhibitorTab } from './exhibitor.tab.model';

export class Exhibitor {
  id: string;

  eventId: string;

  name: string;

  users: ExhibitorRep[];

  website: string;

  email: string;

  phoneNumber: string;

  address: string;

  twitter: string;

  youtube: string;

  facebook: string;

  instagram: string;

  linkedin: string;

  documents?: Document[];

  completion: string;

  kioskOptions?: {
    deskPanelPicture?: string;
    furnitureColor?: string;
    hasMainVideo: boolean;
    image3dUrl?: string;
    isSubmitted: boolean;
    kioskType: number;
    logo?: string;
    mainVideo?: string;
    playableMainVideoUrl?: string;
    sidePanelPicture?: string;
    surfaceColor?: string;
    tags?: string;
    topPanelPicture?: string;
    videoThumbnail?: string;
    zoneFirstColor?: string;
    zoneSecondColor?: string;
    zoneThirdColor?: string;
  };

  status: string;

  priority: number;

  kioskTabs: ExhibitorTab[];

  constructor() {
    this.kioskOptions = {
      isSubmitted: false,
      kioskType: -1,
      hasMainVideo: false,
    };

    this.kioskTabs = [new ExhibitorTab(), new ExhibitorTab()];
  }
}
