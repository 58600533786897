import { TicketGAOptions } from '@/models/ticketing/ticket-general-assembly-options';
import { UserTicketPreferences } from '@/models/ticketing/user-ticket-preferences';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import { TicketFeatures } from '@/models/ticketing/ticket-features';

export type UserTicket = {
  id: string;
  eventId: string;
  userId: string;
  ticketId: string;
  type: string;
  paymentId: string;
  active: boolean;
  purchaseDate: string;
  options: {
    disableFunctionalities: string[];
    generalAssemblies: TicketGAOptions[];
    features: TicketFeatures;
    conferences: string[];
    discussionGroups: string[];
  };
  userProfileCompleted: boolean;
} & UserTicketPreferences;

export type UpdatableUserTicket = {
  type?: string;
  active?: boolean;
  ticketId?: string;
};

export const isSpeaker = (ticket: UserTicket): boolean => ticket?.type === TicketType.speaker;

export const isExhibitor = (ticket: UserTicket): boolean => ticket?.type === TicketType.exhibitor;
