import { DateTime, Duration, Interval } from 'luxon';
import ct from 'countries-and-timezones';

import { isSpeaker } from '@/models/ticketing/user-ticket';
import type { AppEvent, Conference, DiscussionGroup } from '@/models';

export default abstract class DateUtil {
  public static ISO_DATE_FORMAT = 'yyyy-LL-dd';

  public static TIME_FORMAT_24H_WITH_SEMI = 'H:mm';

  public static TIME_FORMAT_24H_WITHOUT_SEMI = 'Hmm';

  public static DATETIME_W_FORMAT = 'yyyy-MM-dd hh:mm';

  public static isValidTimeFormat24h(value: string) {
    return (
      !!value &&
      (DateTime.fromFormat(value, DateUtil.TIME_FORMAT_24H_WITH_SEMI).isValid ||
        DateTime.fromFormat(value, DateUtil.TIME_FORMAT_24H_WITHOUT_SEMI).isValid)
    );
  }

  public static dateTimeFromTimeFormat24h(value: string) {
    let time;
    if (DateTime.fromFormat(value, DateUtil.TIME_FORMAT_24H_WITH_SEMI).isValid) {
      time = DateTime.fromFormat(value, DateUtil.TIME_FORMAT_24H_WITH_SEMI);
    } else {
      time = DateTime.fromFormat(value, DateUtil.TIME_FORMAT_24H_WITHOUT_SEMI);
    }

    return time;
  }

  public static timeFormat24hToObject(value: string) {
    if (!DateUtil.isValidTimeFormat24h(value)) {
      return {};
    }

    const time = DateUtil.dateTimeFromTimeFormat24h(value);
    return Duration.fromISOTime(time.toFormat('HH:mm')).toObject();
  }

  public static isNowOrBefore(isoDate: string, now?: string) {
    const internalNow: DateTime = now ? DateTime.fromISO(now) : DateTime.local();
    const comparedDate = DateTime.fromISO(isoDate);

    return internalNow >= comparedDate;
  }

  public static isNowOrAfter(isoDate: string, now?: string) {
    const internalNow: DateTime = now ? DateTime.fromISO(now) : DateTime.local();
    const comparedDate = DateTime.fromISO(isoDate);

    return internalNow <= comparedDate;
  }

  public static isAfter(isoDate: string, now?: string) {
    const internalNow: DateTime = now ? DateTime.fromISO(now) : DateTime.local();
    const comparedDate = DateTime.fromISO(isoDate);

    return internalNow < comparedDate;
  }

  public static isBefore(isoDate: string, now?: string) {
    const internalNow: DateTime = now ? DateTime.fromISO(now) : DateTime.local();
    const comparedDate = DateTime.fromISO(isoDate);

    return internalNow > comparedDate;
  }

  public static canAccessConference(
    conference: Conference,
    now?: string,
    event?: AppEvent,
  ): boolean {
    const startTime = DateTime.fromISO(conference.startTime).minus({ minutes: 15 }).toISO();

    return (
      isSpeaker(event?.ticket) ||
      (DateUtil.isNowOrBefore(startTime, now) && DateUtil.isNowOrAfter(conference.endTime, now))
    );
  }

  public static canAccessEvent(event: AppEvent, isAdminOnEvent, now?: string): boolean {
    return isAdminOnEvent || DateUtil.isNowOrAfter(event.endDate, now);
  }

  public static canAccessDiscussionGroup(
    discussionGroup: DiscussionGroup,
    isAdmin: boolean,
    now?: string,
  ): boolean {
    const startTime = DateTime.fromISO(discussionGroup.startTime).minus({ minutes: 15 }).toISO();

    return (
      isAdmin ||
      (DateUtil.isNowOrBefore(startTime, now) &&
        DateUtil.isNowOrAfter(discussionGroup.endTime, now))
    );
  }

  public static getTimeZoneList(): string[] {
    return Object.keys(ct.getAllTimezones()).sort();
  }

  public static getTimestamp(datetime: DateTime, locale: string): string {
    const daysAgo = Interval.fromDateTimes(datetime, DateTime.now()).length('days');

    if (daysAgo < 1) {
      return datetime.setLocale(locale).toFormat('t');
    }

    if (daysAgo < 6 && daysAgo >= 1) {
      return datetime.setLocale(locale).toFormat('ccc t');
    }

    return datetime.setLocale(locale).toFormat('DD');
  }

  public static formatLocalTimeWithSeconds(datetime: DateTime, locale: string): string {
    return datetime.setLocale(locale).toFormat('tt');
  }

  public static hasNextConference(endTime: string, startTime: string): boolean {
    const endDateTime = DateTime.fromISO(endTime);
    const startDateTime = DateTime.fromISO(startTime);

    return endDateTime <= startDateTime && endDateTime >= startDateTime.minus({ minutes: 60 });
  }

  public static getFormattedDate = (date: string, locale: string): string => {
    const dateTime = DateTime.fromISO(date, { locale });

    return dateTime.toFormat('dd LLLL yyyy');
  };
}
