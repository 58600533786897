import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { isMobile } from 'mobile-device-detect';

import store from '@/stores';
import i18n from '@/i18n';
import { toAdminDashboard, toDashboard, toExhibitors, toLobby, toProfile } from '@/navigation';

import { pageview } from 'vue-gtag';

import DateUtil from '@/helpers/date/date.helper';
import LayoutUtil from '@/helpers/layout/layout.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { MessageType } from '@/models';
import { isSpeaker } from '@/models/ticketing/user-ticket';

import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import {
  AUTH_MODULE_SESSION_VALIDITY,
  LOGIN,
  SHARED_AUTH_MODULE,
} from '@/stores/shared/actions/auth/auth.actions';

import {
  APP_ASSEMBLY_MODULE,
  DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET,
  DISCONNECT_FROM_ASSEMBLY_SOCKET,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import {
  APP_EXHIBITOR_MODULE,
  GET_SELF_EXHIBITOR_USER,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_CONTENT_MODULE } from '@/stores/umanize-app/actions/content/app-content.actions';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { GET_PERMISSIONS } from '@/stores/umanize-admin/actions/user/admin-user.actions';

import { FETCH_EVENT_BY_ID_SILENT } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import { GET_CONTENT_CATEGORIES } from '@/stores/agnostic/actions/content/agnostic-content.actions';

import {
  ENTER_EVENT,
  EXIT_EVENT,
  EXIT_EXHIBITOR,
  getStatSocket,
  LEAVE_CONFERENCE,
} from '@/socket/stat-namespace';

import { buildAuthenticatedRoutes } from './authenticated.routes';
import { buildPrivateEventViews, buildPublicEventRoutes } from './event.routes';
import { publicRoutes } from './public.routes';

Vue.use(VueRouter);

function getEventModule(to) {
  return LayoutUtil.isAdminRoute(to) ? ADMIN_EVENT_MODULE : APP_EVENT_MODULE;
}

async function fetchEvent(to, _, next = null) {
  const module = getEventModule(to);

  await store.dispatch(`${module}/${FETCH_EVENT_BY_ID_SILENT}`, to.params.eventId);
  if (next) {
    next();
  }
}

async function alreadyLoggedGuard(to, __, next) {
  if (to.query?.email && to.query?.password) {
    await store.dispatch(
      `${SHARED_AUTH_MODULE}/${LOGIN}`,
      { email: to.query.email, password: to.query.password },
      { root: true },
    );
  }

  const valid = await store.dispatch(AUTH_MODULE_SESSION_VALIDITY, null, { root: true });

  if (valid) next(toDashboard());
  next();
}

async function eventGuard(to, from, next) {
  await fetchEvent(to, from);
  const module = getEventModule(to);
  const event = store.getters[`${module}/event`];
  const user = store.getters[`${APP_USER_MODULE}/loggedInUser`];

  if (
    !user.isAdmin &&
    !event?.ticket?.userProfileCompleted &&
    event?.ticket?.ticketUserProfile?.profileItems
  ) {
    next(toProfile(event.id));
  }

  if (!DateUtil.canAccessEvent(event, user)) {
    store.dispatch(`${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`, {
      text: i18n.t('globals.closed'),
      type: MessageType.warn,
    });

    if (from.name !== 'Dashboard') {
      next(toDashboard());
    }
  } else {
    next();
  }
}

async function exhibitorGuard(to, from, next) {
  const event = store.getters[`${APP_EVENT_MODULE}/event`];
  await store.dispatch(`${APP_EXHIBITOR_MODULE}/${GET_SELF_EXHIBITOR_USER}`, { eventId: event.id });
  const exhibitorSelf = store.getters[`${APP_EXHIBITOR_MODULE}/exhibitorSelf`];

  if (!exhibitorSelf) {
    await store.dispatch(`${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`, {
      text: i18n.t('globals.errors.403'),
      type: MessageType.error,
    });

    if (from.name !== 'Lobby') {
      next(toLobby(event.id));
    }
  } else {
    next();
  }
}

async function adminGuard(to, from, next) {
  const userRoles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];
  const { eventId } = to.params;

  if (!PermissionsUtil.isAdmin(userRoles, eventId)) {
    next(toDashboard());
  } else {
    next();
  }
}

async function showroomGuard(to, from, next) {
  await eventGuard(to, from, next);
  const event = store.getters[`${APP_EVENT_MODULE}/event`];
  const userRoles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];
  const canAccessShowRoom =
    event?.ticket?.options?.features?.showroom || PermissionsUtil.isAdmin(userRoles, event.id);

  if (isMobile) {
    next(toExhibitors(event.id));
  } else if (!canAccessShowRoom) {
    next(toLobby(event.id));
  } else {
    next();
  }
}

async function contentLibraryGuard(to, from, next) {
  await eventGuard(to, from, next);
  const event = store.getters[`${APP_EVENT_MODULE}/event`];

  await store.dispatch(`${APP_CONTENT_MODULE}/${GET_CONTENT_CATEGORIES}`, event.id);
  const contentCategories = store.getters[`${APP_CONTENT_MODULE}/contentCategories`];

  const userRoles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];

  const canAccessContentLibraries =
    event?.ticket?.options?.features?.contentLibrary ||
    PermissionsUtil.isAdmin(userRoles, event.id);

  if (contentCategories.length === 1 && canAccessContentLibraries) {
    next({
      name: 'ContentCategory',
      params: { eventId: event.id, categoryId: contentCategories[0].id },
    });
  } else if (!canAccessContentLibraries) {
    next(toLobby(event.id));
  } else {
    next();
  }
}

async function speakerGuard(to, from, next) {
  const userRoles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];
  const event = store.getters[`${APP_EVENT_MODULE}/event`];

  if (isSpeaker(event?.ticket) || PermissionsUtil.isAdmin(userRoles, event.id)) {
    next();
  } else {
    next(toLobby(event.id));
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: toDashboard(),
  },
  ...publicRoutes(alreadyLoggedGuard),
  ...buildPublicEventRoutes(fetchEvent, adminGuard),
  ...buildPrivateEventViews(
    eventGuard,
    exhibitorGuard,
    showroomGuard,
    contentLibraryGuard,
    speakerGuard,
  ),
  ...buildAuthenticatedRoutes(adminGuard),
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name && to.params.savePosition && to.params.position) {
      return to.params.position;
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  LayoutUtil.scrollToTop();
  pageview({
    page_title: to.name,
    page_path: to.path,
  });
});

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.title) {
    window.document.title = `Umanize - ${i18n.t(to.meta.title).toString()}`;
  }

  if (to.meta.requiresAuth) {
    try {
      const user = await store.dispatch(AUTH_MODULE_SESSION_VALIDITY);
      await store.dispatch(`${APP_USER_MODULE}/${GET_PERMISSIONS}`, user);

      if (!user) {
        next({
          name: 'Login',
          query: {
            redirect: to.path,
          },
        });

        return;
      }

      if (to.meta.requiredCompleteProfile) {
        const event = store.getters[`${module}/event`];
        const { eventId } = to.params;

        if (event) {
          next(toProfile(eventId));
        }
      }

      if (to.meta.requiredPermissions) {
        const { eventId } = to.params;
        const roles = store.getters[`${APP_USER_MODULE}/loggedInUserRoles`];

        if (
          !roles.length ||
          !PermissionsUtil.isAuthorized(to.meta.requiredPermissions, roles, eventId)
        ) {
          store.dispatch(`${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`, {
            text: i18n.t('globals.errors.403'),
            type: MessageType.error,
          });
          if (to.path.includes('admin') && from.path.includes('admin')) {
            next(toAdminDashboard());
          } else {
            next(toDashboard());
          }
          return;
        }
      }

      if (from.name === 'GeneralAssembly') {
        await store.dispatch(
          `${APP_ASSEMBLY_MODULE}/${DISCONNECT_FROM_ASSEMBLY_SOCKET}`,
          from.params.assemblyId,
        );

        window.location.assign(to.path);
        return;
      }

      if (from.name === 'GeneralAssemblyAdmin') {
        await store.dispatch(
          `${APP_ASSEMBLY_MODULE}/${DISCONNECT_FROM_ASSEMBLY_ADMIN_SOCKET}`,
          from.params.assemblyId,
        );

        window.location.assign(to.path);
        return;
      }

      if (from.name === 'ShowRoom' || from.name === 'GeneralAssembly') {
        window.location.assign(to.path);
        return;
      }

      manageEventStats(from.params.eventId, to.params.eventId);
      manageConferenceStats(from.params.conferenceId, to.params.conferenceId, from.params.eventId);
      manageExhibitorStats(from.params.exhibitorId, to.params.exhibitorId, from.params.eventId);

      next();
    } catch (error) {
      next({
        name: 'Login',
        query: {
          redirect: to.path,
        },
      });
    }
  } else {
    next();
  }
});

function manageEventStats(fromEventId, toEventId) {
  const statSocket = getStatSocket();
  if (statSocket && !fromEventId && toEventId) {
    getStatSocket().emit(ENTER_EVENT, toEventId);
  }

  if (statSocket && fromEventId && !toEventId) {
    getStatSocket().emit(EXIT_EVENT, fromEventId);
  }
}

function manageConferenceStats(fromConferenceId, toConferenceId, eventId) {
  const statSocket = getStatSocket();

  if (statSocket && fromConferenceId && fromConferenceId !== toConferenceId) {
    const timestamp = new Date();
    getStatSocket().emit(LEAVE_CONFERENCE, eventId, fromConferenceId, timestamp);
  }
}

function manageExhibitorStats(fromExhibitorId, toExhibitorId, eventId) {
  const statSocket = getStatSocket();

  if (statSocket && fromExhibitorId && fromExhibitorId !== toExhibitorId) {
    getStatSocket().emit(EXIT_EXHIBITOR, eventId, fromExhibitorId);
  }
}

export default router;
