import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import conferenceService from '@/services/conference/conference.service';
import { ConferenceRoom, Conference, MessageType } from '@/models';
import i18n from '@/i18n';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import DateUtil from '@/helpers/date/date.helper';
import {
  GET_CONFERENCE,
  GET_CONFERENCE_ERROR,
  GET_CONFERENCE_SUCCESS,
  GET_CONFERENCE_ROOM,
  GET_CONFERENCE_ROOM_ERROR,
  GET_CONFERENCE_ROOM_SUCCESS,
  GET_CONFERENCE_ROOMS,
  GET_CONFERENCE_ROOMS_ERROR,
  GET_CONFERENCE_ROOMS_SUCCESS,
  GET_CONFERENCES_FOR_EVENT,
  GET_CONFERENCES_FOR_EVENT_ERROR,
  GET_CONFERENCES_FOR_EVENT_SUCCESS,
  CLEAR_CONFERENCE_ROOM,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import {
  AgnosticConferenceModule,
  ConferenceRoomsState,
  ConferenceRoomsStatus,
  ConferenceRoomState,
  ConferenceRoomStatus,
  ConferencesState,
  ConferencesStatus,
  CurrentConferenceState,
  CurrentConferenceStatus,
} from '@/stores/agnostic/modules/conference/agnostic-conference.module';
import {
  ADD_CONFERENCE,
  ADD_CONFERENCE_ERROR,
  ADD_CONFERENCE_ROOM,
  ADD_CONFERENCE_ROOM_ERROR,
  ADD_CONFERENCE_ROOM_SUCCESS,
  ADD_CONFERENCE_SUCCESS,
  CLEAR_CONFERENCE,
  DELETE_CONFERENCE,
  DELETE_CONFERENCE_ERROR,
  DELETE_CONFERENCE_ROOM,
  DELETE_CONFERENCE_ROOM_ERROR,
  DELETE_CONFERENCE_ROOM_SUCCESS,
  DELETE_CONFERENCE_SUCCESS,
  SAVE_CONFERENCE,
  SAVE_CONFERENCE_ROOM,
  UPDATE_CONFERENCE,
  UPDATE_CONFERENCE_ERROR,
  UPDATE_CONFERENCE_ROOM,
  UPDATE_CONFERENCE_ROOM_ERROR,
  UPDATE_CONFERENCE_ROOM_SUCCESS,
  UPDATE_CONFERENCE_SUCCESS,
} from '@/stores/umanize-admin/actions/conference/admin-conference.actions';

export interface AdminConferenceState {
  currentConference: CurrentConferenceState;
  conferences: ConferencesState;
  conferenceRooms: ConferenceRoomsState;
  currentConferenceRoom: ConferenceRoomState;
}

const currentConference: CurrentConferenceState = {
  conference: null,
  status: {
    error: null,
    isLoading: true,
    isOver: false,
  },
};

const conferences: ConferencesState = {
  conferences: [],
  status: {
    error: null,
    isLoading: true,
  },
};

const conferenceRooms: ConferenceRoomsState = {
  selectedConferenceRoom: null,
  conferenceRooms: [],
  status: {
    error: null,
    isLoading: true,
  },
};

const currentConferenceRoom: ConferenceRoomState = {
  conferenceRoom: null,
  status: {
    isLoading: true,
    error: null,
  },
};

const state: AdminConferenceState = {
  currentConference,
  conferences,
  conferenceRooms,
  currentConferenceRoom,
};

const actions = {
  [GET_CONFERENCES_FOR_EVENT]: AgnosticConferenceModule.actions.getConferencesForEvent,
  [GET_CONFERENCE_ROOMS]: AgnosticConferenceModule.actions.getConferenceRooms,
  [GET_CONFERENCE]: AgnosticConferenceModule.actions.getConference,
  [GET_CONFERENCE_ROOM]: AgnosticConferenceModule.actions.getConferenceRoom,
  [CLEAR_CONFERENCE_ROOM]: AgnosticConferenceModule.actions.clearConferenceRoom,
  [CLEAR_CONFERENCE]({ commit }: ActionContext<AdminConferenceState, RootState>) {
    commit(CLEAR_CONFERENCE);
  },
  async [SAVE_CONFERENCE](
    { dispatch }: ActionContext<AdminConferenceState, RootState>,
    conference: Conference,
  ) {
    const dispatchedAction = conference?.id ? UPDATE_CONFERENCE : ADD_CONFERENCE;
    await dispatch(dispatchedAction, conference);
  },
  async [UPDATE_CONFERENCE](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    payload: Conference,
  ) {
    commit(UPDATE_CONFERENCE);

    try {
      const conference = await conferenceService.updateConference(payload);

      if (conference && conference.id) {
        commit(UPDATE_CONFERENCE_SUCCESS);
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t('conference.update.success'),
            type: MessageType.info,
          },
          {
            root: true,
          },
        );
      }
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conference.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_CONFERENCE_ERROR, error);
    }
  },
  async [ADD_CONFERENCE](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    payload: Conference,
  ) {
    commit(ADD_CONFERENCE);

    try {
      const conference = await conferenceService.addConference(payload);

      if (conference && conference.id) {
        commit(ADD_CONFERENCE_SUCCESS);
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t('conference.add.success'),
            type: MessageType.info,
          },
          {
            root: true,
          },
        );
      }
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conference.add.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(ADD_CONFERENCE_ERROR, error);
    }
  },
  async [SAVE_CONFERENCE_ROOM](
    { dispatch }: ActionContext<AdminConferenceState, RootState>,
    conference: ConferenceRoom,
  ) {
    const action = conference?.id ? UPDATE_CONFERENCE_ROOM : ADD_CONFERENCE_ROOM;
    await dispatch(action, conference);
  },
  async [UPDATE_CONFERENCE_ROOM](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    payload: ConferenceRoom,
  ) {
    commit(UPDATE_CONFERENCE_ROOM);

    try {
      const conferenceRoom = await conferenceService.updateConferenceRoom(payload);

      if (conferenceRoom && conferenceRoom.id) {
        commit(UPDATE_CONFERENCE_ROOM_SUCCESS);
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t('conferenceRoom.update.success'),
            type: MessageType.info,
          },
          {
            root: true,
          },
        );
      }
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conferenceRoom.update.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_CONFERENCE_ROOM_ERROR, error);
    }
  },
  async [DELETE_CONFERENCE_ROOM](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    payload: {
      eventId: string;
      conferenceRoomId: string;
    },
  ) {
    commit(DELETE_CONFERENCE_ROOM);

    try {
      await conferenceService.deleteConferenceRoom(payload?.eventId, payload?.conferenceRoomId);

      commit(DELETE_CONFERENCE_ROOM_SUCCESS, payload?.conferenceRoomId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('conferenceRoom.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conferenceRoom.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_CONFERENCE_ROOM_ERROR, error);
    }
  },
  async [ADD_CONFERENCE_ROOM](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    payload: ConferenceRoom,
  ) {
    commit(ADD_CONFERENCE_ROOM);

    try {
      const conferenceRoom = await conferenceService.addConferenceRoom(payload);

      if (conferenceRoom && conferenceRoom.id) {
        commit(ADD_CONFERENCE_ROOM_SUCCESS);
        dispatch(
          `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
          {
            text: i18n.t('conferenceRoom.add.success'),
            type: MessageType.info,
          },
          {
            root: true,
          },
        );
      }
    } catch (error) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conferenceRoom.add.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(ADD_CONFERENCE_ROOM_ERROR, error);
    }
  },
  async [DELETE_CONFERENCE](
    { commit, dispatch }: ActionContext<AdminConferenceState, RootState>,
    conference: Conference,
  ) {
    commit(DELETE_CONFERENCE);

    try {
      await conferenceService.deleteConference(conference);

      commit(DELETE_CONFERENCE_SUCCESS);
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('conference.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      await dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`conference.delete.errors.${error.status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_CONFERENCE_ERROR, error);
    }
  },
};

const mutations = {
  [CLEAR_CONFERENCE](state: AdminConferenceState) {
    state.currentConference = {
      ...state.currentConference,
      conference: null,
    };
  },
  [GET_CONFERENCE](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_SUCCESS](state: AdminConferenceState, conference) {
    state.currentConference = {
      ...state.currentConference,
      status: {
        ...state.currentConference.status,
        isLoading: false,
        error: null,
      },
      conference: {
        ...conference,
      },
    };
  },
  [GET_CONFERENCE_ERROR](state: AdminConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      isLoading: false,
      error,
    };
  },
  [UPDATE_CONFERENCE](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [UPDATE_CONFERENCE_SUCCESS](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [UPDATE_CONFERENCE_ERROR](state: AdminConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error,
    };
  },
  [GET_CONFERENCE_ROOM](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_ROOM_SUCCESS](state: AdminConferenceState, conferenceRoom) {
    state.currentConferenceRoom = {
      ...state.currentConferenceRoom,
      status: {
        ...state.currentConferenceRoom.status,
        isLoading: false,
        error: null,
      },
      conferenceRoom: {
        ...conferenceRoom,
      },
    };
  },
  [GET_CONFERENCE_ROOM_ERROR](state: AdminConferenceState, error) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: false,
      error,
    };
  },
  [DELETE_CONFERENCE_ROOM](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: true,
      error: null,
    };
  },
  [DELETE_CONFERENCE_ROOM_SUCCESS](state: AdminConferenceState, conferenceRoomId: string) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: false,
      error: null,
    };
    state.conferenceRooms.conferenceRooms = state.conferenceRooms.conferenceRooms.filter(
      (conferenceRoom) => conferenceRoom.id !== conferenceRoomId,
    );
  },
  [DELETE_CONFERENCE_ROOM_ERROR](state: AdminConferenceState, error: ErrorMessage) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      isLoading: false,
      error,
    };
  },
  [SAVE_CONFERENCE](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [ADD_CONFERENCE](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [ADD_CONFERENCE_SUCCESS](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [ADD_CONFERENCE_ERROR](state: AdminConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error,
    };
  },
  [GET_CONFERENCES_FOR_EVENT](state: AdminConferenceState) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCES_FOR_EVENT_SUCCESS](state: AdminConferenceState, conferences: Conference[]) {
    state.conferences = {
      status: {
        ...state.conferences.status,
        isLoading: false,
        error: null,
      },
      conferences: [...conferences],
    };
  },
  [GET_CONFERENCES_FOR_EVENT_ERROR](state: AdminConferenceState, error: ErrorMessage) {
    state.conferences.status = {
      ...state.conferences.status,
      isLoading: false,
      error,
    };
  },
  [GET_CONFERENCE_ROOMS](state: AdminConferenceState) {
    state.conferenceRooms.status = {
      ...state.conferenceRooms.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_CONFERENCE_ROOMS_SUCCESS](state: AdminConferenceState, conferenceRooms: ConferenceRoom[]) {
    state.conferenceRooms = {
      ...state.conferenceRooms,
      status: {
        ...state.conferenceRooms.status,
        isLoading: false,
        error: null,
      },
      conferenceRooms: [...conferenceRooms],
    };
  },
  [GET_CONFERENCE_ROOMS_ERROR](state: AdminConferenceState, error: ErrorMessage) {
    state.conferenceRooms.status = {
      ...state.conferenceRooms.status,
      isLoading: false,
      error,
    };
  },
  [CLEAR_CONFERENCE_ROOM](state: AdminConferenceState) {
    state.currentConferenceRoom = {
      ...state.currentConferenceRoom,
      conferenceRoom: null,
    };
  },
  [ADD_CONFERENCE_ROOM](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error: null,
    };
  },
  [ADD_CONFERENCE_ROOM_SUCCESS](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error: null,
    };
  },
  [ADD_CONFERENCE_ROOM_ERROR](state: AdminConferenceState, error) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error,
    };
  },
  [UPDATE_CONFERENCE_ROOM](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error: null,
    };
  },
  [UPDATE_CONFERENCE_ROOM_SUCCESS](state: AdminConferenceState) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error: null,
    };
  },
  [UPDATE_CONFERENCE_ROOM_ERROR](state: AdminConferenceState, error) {
    state.currentConferenceRoom.status = {
      ...state.currentConferenceRoom.status,
      error,
    };
  },
  [DELETE_CONFERENCE](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [DELETE_CONFERENCE_SUCCESS](state: AdminConferenceState) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error: null,
    };
  },
  [DELETE_CONFERENCE_ERROR](state: AdminConferenceState, error) {
    state.currentConference.status = {
      ...state.currentConference.status,
      error,
    };
  },
};

const getters = {
  currentState: (state: AdminConferenceState): CurrentConferenceState => state.currentConference,
  conference: (_, { currentState }: { currentState: CurrentConferenceState }): Conference =>
    currentState?.conference || null,
  currentConferenceStatus: (
    _,
    { currentState }: { currentState: CurrentConferenceState },
  ): CurrentConferenceStatus => currentState?.status || null,
  conferenceIsLoading: (
    _,
    { currentConferenceStatus }: { currentConferenceStatus: CurrentConferenceStatus },
  ): boolean => currentConferenceStatus?.isLoading || false,
  conferenceError: (
    _,
    { currentConferenceStatus }: { currentConferenceStatus: CurrentConferenceStatus },
  ): ErrorMessage => currentConferenceStatus?.error || null,

  conferencesState: (state: AdminConferenceState): ConferencesState => state.conferences,
  conferences: (_, { conferencesState }: { conferencesState: ConferencesState }): Conference[] =>
    conferencesState?.conferences || [],

  conferenceIdNameMap: (_, { conferences }: { conferences: Conference[] }): Map<string, string> => {
    const map = new Map<string, string>();
    conferences.forEach((conference) => map.set(conference.id, conference.name));
    return map;
  },

  nextConferences: (_, { conferences }: { conferences: Conference[] }) =>
    conferences.filter((conference) => DateUtil.isNowOrAfter(conference.endTime)),
  conferencesStatus: (
    _,
    { conferencesState }: { conferencesState: ConferencesState },
  ): ConferencesStatus => conferencesState?.status || null,
  conferencesAreLoading: (
    _,
    { conferencesStatus }: { conferencesStatus: ConferencesStatus },
  ): boolean => conferencesStatus?.isLoading || false,
  conferencesError: (
    _,
    { conferencesStatus }: { conferencesStatus: ConferencesStatus },
  ): ErrorMessage => conferencesStatus?.error || null,

  conferenceRoomsState: (state: AdminConferenceState): ConferenceRoomsState =>
    state.conferenceRooms,
  selectedConferenceRoom: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoom => conferenceRoomsState?.selectedConferenceRoom || null,
  conferenceRooms: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoom[] => conferenceRoomsState?.conferenceRooms || [],
  conferenceRoomsStatus: (
    _,
    { conferenceRoomsState }: { conferenceRoomsState: ConferenceRoomsState },
  ): ConferenceRoomsStatus => conferenceRoomsState?.status,
  conferenceRoomsAreLoading: (
    _,
    { conferenceRoomsStatus }: { conferenceRoomsStatus: ConferenceRoomsStatus },
  ): boolean => conferenceRoomsStatus?.isLoading || false,
  conferenceRoomsError: (
    _,
    { conferenceRoomsStatus }: { conferenceRoomsStatus: ConferenceRoomsStatus },
  ): ErrorMessage => conferenceRoomsStatus?.error || null,

  conferenceRoomState: (state: AdminConferenceState): ConferenceRoomState =>
    state.currentConferenceRoom,
  conferenceRoom: (
    _,
    { conferenceRoomState }: { conferenceRoomState: ConferenceRoomState },
  ): ConferenceRoom => conferenceRoomState?.conferenceRoom || null,
  conferenceRoomStatus: (
    _,
    { currentState }: { currentState: CurrentConferenceState },
  ): CurrentConferenceStatus => currentState?.status || null,
  conferenceRoomIsLoading: (
    _,
    { conferenceRoomStatus }: { conferenceRoomStatus: ConferenceRoomStatus },
  ): boolean => conferenceRoomStatus?.isLoading || false,
  conferenceRoomError: (
    _,
    { conferenceRoomStatus }: { conferenceRoomStatus: ConferenceRoomStatus },
  ): ErrorMessage => conferenceRoomStatus?.error || null,
};

export const AdminConferenceModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
