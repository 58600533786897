<template>
  <app-search-header-item
    :items="filteredContentsLibraryBySearch"
    item="contentLibraries"
    :eventHasItem="eventHasContentLibrary"
    :maximumDisplayedElements="maximumDisplayedElements"
  >
    <v-list-item
      v-for="(contentCategory, indexLibrary) in filteredContentsLibraryBySearch.slice(
        0,
        maximumDisplayedElements,
      )"
      :key="indexLibrary"
    >
      <v-list-group sub-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ contentCategory.name }} </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(contentItem, index) in contentCategory.items"
          :key="`content-item-${index}`"
          :to="`/events/${event.id}/content-library/${contentCategory.id}`"
          @click="onCloseMenu()"
        >
          <v-list-item-content>
            <v-list-item-title :key="`content-item-title-${index}`">
              {{ contentCategory.name }} - {{ contentItem.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="font-italic" v-if="filteredContentsLibraryBySearch.length === 0">
          {{ $t('admin.users.headers.noResult') }}
        </v-list-item>

        <v-list-item
          class="font-italic"
          v-if="filteredContentsLibraryBySearch.length > maximumDisplayedElements"
        >
          {{ $t('admin.users.headers.moreResult') }}
        </v-list-item>
      </v-list-group>
    </v-list-item>
  </app-search-header-item>
</template>

<script>
import AppSearchHeaderItem from './AppSearchHeaderItem.vue';

export default {
  name: 'SearchContentLibraries',
  components: {
    AppSearchHeaderItem,
  },
  props: {
    maximumDisplayedElements: {
      type: Number,
    },
    event: {
      type: Object,
    },
    contentCategories: {
      type: Array,
    },
    includeSearchNoSensitive: {
      type: Function,
    },
  },
  computed: {
    eventHasContentLibrary() {
      return (
        this.event?.options.contentLibrary &&
        !this.event?.ticket?.options?.disableFunctionalities?.includes('contentLibraries')
      );
    },
    filteredContentsLibraryBySearch() {
      return this.contentCategories.filter(({ name, items }) => {
        const itemsContent = items ?? [];

        return [name, ...itemsContent.flatMap(({ name, description }) => [name, description])].some(
          this.includeSearchNoSensitive,
        );
      });
    },
  },
  methods: {
    onCloseMenu() {
      this.$emit('close');
    },
  },
};
</script>
