<template>
  <v-badge color="notification" class="badge" overlap :value="hasGlobalUnread">
    <v-icon v-if="!clickable" large color="primaryFont">mdi-chat</v-icon>
    <v-icon v-else color="primaryFont" size="34" @click="openChatUsers">mdi-chat</v-icon>
  </v-badge>
</template>

<script>
export default {
  name: 'NotificationBadge',
  props: {
    hasGlobalUnread: {
      type: Boolean,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: () => 'primaryFont',
    },
  },
  data() {
    return {
      hasUnread: false,
    };
  },
  watch: {
    hasGlobalUnread(params) {
      const ele = document.getElementById(this.idNotification);
      this.hasUnread = params;
      if (params) {
        ele?.classList?.add('active');
      } else {
        ele?.classList?.remove('active');
      }
      this.$forceUpdate();
    },
  },
  methods: {
    openChatUsers() {
      this.$emit('open-chat-users');
    },
  },
};
</script>

<style lang="scss" scoped>
//@import '../../styles/core/variables';
</style>
