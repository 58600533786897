import { UMANIZE_APP_MODULE } from '@/stores/umanize-app/umanize-app.module.config';

export const SURVEY_MODULE_NAME = 'SurveyModule';
export const SURVEY_MODULE = `${UMANIZE_APP_MODULE}/${SURVEY_MODULE_NAME}`;

export const CONFERENCE_NEW_SURVEY = 'CONFERENCE_NEW_SURVEY';
export const CONFERENCE_CLOSE_SURVEY = 'CONFERENCE_CLOSE_SURVEY';

export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_HISTORY';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_HISTORY_SUCCESS';
export const GET_SURVEY_QUESTIONS_ERROR = 'GET_SURVEY_HISTORY_ERROR';

export const GET_SURVEY_QUESTION = 'GET_SURVEY_QUESTION';
export const GET_SURVEY_QUESTION_SUCCESS = 'GET_SURVEY_QUESTION_SUCCESS';
export const GET_SURVEY_QUESTION_ERROR = 'GET_SURVEY_QUESTION_ERROR';

export const ADD_SURVEY_QUESTION = 'ADD_SURVEY_QUESTION';
export const ADD_SURVEY_QUESTION_SUCCESS = 'ADD_SURVEY_QUESTION_SUCCESS';
export const ADD_SURVEY_QUESTION_ERROR = 'ADD_SURVEY_QUESTION_ERROR';

export const DELETE_SURVEY_QUESTION = 'DELETE_SURVEY_QUESTION';
export const DELETE_SURVEY_QUESTION_SUCCESS = 'DELETE_SURVEY_QUESTION_SUCCESS';
export const DELETE_SURVEY_QUESTION_ERROR = 'DELETE_SURVEY_QUESTION_ERROR';

export const CLOSE_SURVEY_QUESTION = 'CLOSE_SURVEY_QUESTION';
export const CLOSE_SURVEY_QUESTION_SUCCESS = 'CLOSE_SURVEY_QUESTION_SUCCESS';
export const CLOSE_SURVEY_QUESTION_ERROR = 'CLOSE_SURVEY_QUESTION_ERROR';

export const START_SURVEY_QUESTION = 'START_SURVEY_QUESTION';
export const START_SURVEY_QUESTION_SUCCESS = 'START_SURVEY_QUESTION_SUCCESS';
export const START_SURVEY_QUESTION_ERROR = 'START_SURVEY_QUESTION_ERROR';

export const SURVEY_ANSWER_QUESTION = 'SURVEY_ANSWER_QUESTION';

export const ANSWER_SURVEY_QUESTION = 'ANSWER_SURVEY_QUESTION';
export const ANSWER_SURVEY_QUESTION_SUCCESS = 'ANSWER_SURVEY_QUESTION_SUCCESS';
export const ANSWER_SURVEY_QUESTION_ERROR = 'ANSWER_SURVEY_QUESTION_ERROR';

export const SET_SURVEY_QUESTION = 'SET_SURVEY_QUESTION';
export const CLEAR_SURVEY_QUESTION = 'CLEAR_SURVEY_QUESTION';

export const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS';
export const GET_SURVEY_ANSWERS_SUCCESS = 'GET_SURVEY_ANSWERS_SUCCESS';
export const GET_SURVEY_ANSWERS_ERROR = 'GET_SURVEY_ANSWERS_ERROR';

export const GET_SURVEY_USER_ANSWERS = 'GET_SURVEY_USER_ANSWERS';
export const GET_SURVEY_USER_ANSWERS_SUCCESS = 'GET_SURVEY_USER_ANSWERS_SUCCESS';
export const GET_SURVEY_USER_ANSWERS_ERROR = 'GET_SURVEY_USER_ANSWERS_ERROR';

export const CLEAR_SURVEY_ANSWERS = 'CLEAR_SURVEY_ANSWERS';
