import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';

export const TRAINING_MODULE_NAME = 'TrainingModule';
export const TRAINING_ADMIN_MODULE_NAME = 'AdminTrainingModule';
export const TRAINING_ADMIN_MODULE = `${UMANIZE_ADMIN_MODULE}/${TRAINING_ADMIN_MODULE_NAME}`;

export const GET_TRAININGS = 'GET_TRAININGS';
export const GET_ALL_TRAININGS_ADMIN = 'GET_TRAININGS_ADMIN';
export const GET_ALL_TRAININGS_ADMIN_PROGRESS = 'GET_ALL_TRAININGS_ADMIN_PROGRESS';
export const GET_ALL_TRAININGS_SUCCESS = 'GET_TRAININGS_SUCCESS';
export const GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR = 'GET_ALL_TRAININGS_ADMIN_PROGRESS_ERROR';
export const GET_ALL_TRAININGS_ERROR = 'GET_TRAININGS_ERROR';

export const GET_TRAINING = 'GET_TRAINING';

export const SET_TRAINING_TRAINER_LIST_ID = 'SET_TRAINING_TRAINER_LIST_ID';

export const GET_TRAINING_SUCCESS = 'GET_TRAINING_SUCCESS';
export const GET_TRAINING_ERROR = 'GET_TRAINING_ERROR';

export const CLEAR_ALL_TRAINING = 'CLEAR_TRAINING';
export const CLEAR_TRAINING = 'CLEAR_TRAINING';
export const SAVE_TRAINING = 'SAVE_TRAINING';

export const ADD_TRAINING = 'ADD_TRAINING';
export const ADD_TRAINING_SUCCESS = 'ADD_TRAINING_SUCCESS';
export const ADD_TRAINING_ERROR = 'ADD_TRAINING_ERROR';

export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const UPDATE_TRAINING_SUCCESS = 'UPDATE_TRAINING_SUCCESS';
export const UPDATE_TRAINING_ERROR = 'UPDATE_TRAINING_ERROR';

export const DELETE_TRAINING = 'DELETE_TRAINING';
export const DELETE_TRAINING_SUCCESS = 'DELETE_TRAINING_SUCCESS';
export const DELETE_TRAINING_ERROR = 'DELETE_TRAINING_ERROR';

export const GET_USERS_LINKED_TO_TRAINING = 'GET_USERS_LINKED_TO_TRAINING ';
export const GET_USERS_LINKED_TO_TRAINING_SUCCESS = 'GET_USERS_LINKED_TO_TRAINING_SUCCESS';
export const GET_USERS_LINKED_TO_TRAINING_ERROR = 'GET_USERS_LINKED_TO_TRAINING_ERROR';

export const LINK_USER_TO_TRAINING = 'LINK_USER_TO_TRAINING ';
export const LINK_USER_TO_TRAINING_SUCCESS = 'LINK_USER_TO_TRAINING_SUCCESS';
export const LINK_USER_TO_TRAINING_ERROR = 'LINK_USER_TO_TRAINING_ERROR';

export const SET_TRAINING_USER_CUSTOM_FIELDS = 'SET_TRAINING_USER_CUSTOM_FIELDS';
