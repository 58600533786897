<template>
  <div class="conference">
    <div class="conference__content">
      <div class="content__header">
        <div class="header__title">{{ conference.name }}</div>

        <div class="header__description">
          <read-more
            :less="$t('globals.readLess')"
            :more="$t('globals.readMore')"
            :text="conference.description"
            v-if="conference.description"
          />
        </div>

        <div class="header__date">{{ $d(Date.parse(conference.startTime), 'long') }}</div>
      </div>

      <div class="content__actions">
        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          v-if="!isInAgenda() && !isStarted()"
          @click.native="$emit('add-to-agenda', conference)"
        >
          {{ $t('lobby.add') }}
        </v-btn>

        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          v-if="isInAgenda() && !isStarted()"
          @click.native="$emit('remove-from-agenda', conference)"
        >
          {{ $t('lobby.remove') }}
        </v-btn>

        <v-btn
          class="actions__action"
          small
          depressed
          :color="accentColor"
          :style="btnStyle"
          v-if="isStarted()"
          @click.native="$emit('select-conference', conference.id)"
        >
          {{ $t('lobby.join') }}
        </v-btn>

        <v-btn
          class="actions__action"
          small
          depressed
          outlined
          color="primaryBackground"
          v-if="canGoToSpeaker"
          @click.native="$emit('select-conference-speaker', conference.id)"
        >
          {{ $t('lobby.backstage') }}
        </v-btn>
      </div>
    </div>

    <div class="conference__picture">
      <v-img
        class="picture"
        :src="conference.coverImages && conference.coverImages[0]"
        :alt="conference.name"
      />
    </div>
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';
import ReadMore from '@/components/read-more/ReadMore.vue';

export default {
  name: 'ConferenceItem',
  props: {
    conference: {
      type: Object,
      required: true,
    },
    agenda: {
      type: Array,
      required: true,
    },
    now: {
      type: String,
      default: new Date().toISOString(),
    },
    event: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    canGoToSpeaker: {
      type: Boolean,
      default: false,
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    btnStyle: {
      type: String,
      required: false,
    },
  },
  components: {
    ReadMore,
  },
  methods: {
    isStarted() {
      return DateUtil.canAccessConference(this.conference, this.now, this.event) || this.isAdmin;
    },
    isInAgenda() {
      return !!this.agenda.find((item) => item.conferenceId === this.conference.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.conference {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 30px;

  &__content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 25px;
  }

  &__picture {
    flex: 1;
    width: 45%;
  }
}

.content__actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 1fr;
  row-gap: 10px;
  column-gap: 10px;

  .actions {
    &__action {
      height: 100%;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      border-radius: 5px;
    }
  }
}

.header {
  &__title {
    color: var(--v-primaryBackground-base);
    font-weight: $bold;
    font-size: $default-font-size;
  }

  &__description {
    font-size: $small-font-size;
    margin-bottom: 30px;
  }

  &__date {
    font-size: $small-font-size;
    font-weight: $bold;
    margin-bottom: 5px;
  }
}

.picture {
  width: 100%;
}
</style>
