/* eslint-disable no-param-reassign,no-multi-assign */
import isEqual from 'lodash.isequal';
import { Exhibitor, KioskOptions } from '@/models';

const MORE_CHOICES_ARRAY = ['surfaceColor', 'furnitureColor'];
const COLORS_ARRAY = ['zoneFirstColor', 'zoneSecondColor', 'zoneThirdColor'];
const PANELS_ARRAY = ['deskPanelPicture', 'sidePanelPicture', 'topPanelPicture'];
const CONTACTS_ARRAY = ['email', 'phoneNumber', 'website', 'address'];
const SOCIALS_ARRAY = ['linkedin', 'twitter', 'facebook', 'instagram', 'youtube'];

export default class KioskUtil {
  public static isKioskTypeSet(exhibitor: Exhibitor) {
    return exhibitor?.kioskOptions?.kioskType >= 1;
  }

  public static areColorsSet(exhibitor: Exhibitor) {
    return exhibitor?.kioskOptions && this.allItemsAreSet(exhibitor.kioskOptions, COLORS_ARRAY);
  }

  public static arePanelsSet(exhibitor: Exhibitor) {
    return exhibitor?.kioskOptions && this.allItemsAreSet(exhibitor.kioskOptions, PANELS_ARRAY);
  }

  public static areMoreChoicesSet(exhibitor: Exhibitor) {
    return (
      exhibitor?.kioskOptions && this.allItemsAreSet(exhibitor.kioskOptions, MORE_CHOICES_ARRAY)
    );
  }

  public static isAtLeastOneContactSet(exhibitor: Exhibitor) {
    return exhibitor && this.someItemsSetWithValidForm(exhibitor, CONTACTS_ARRAY);
  }

  public static isAtLeastOneSocialSet(exhibitor: Exhibitor) {
    return exhibitor && this.someItemsSetWithValidForm(exhibitor, SOCIALS_ARRAY);
  }

  public static getProgress(exhibitor: Exhibitor) {
    return (
      ([
        KioskUtil.areColorsSet(exhibitor),
        KioskUtil.arePanelsSet(exhibitor),
        KioskUtil.areMoreChoicesSet(exhibitor),
        KioskUtil.isKioskTypeSet(exhibitor),
      ].filter((elem) => elem).length /
        4) *
      100
    ).toFixed(0);
  }

  public static canSubmit(exhibitor: Exhibitor) {
    return (
      KioskUtil.areColorsSet(exhibitor) &&
      KioskUtil.arePanelsSet(exhibitor) &&
      KioskUtil.areMoreChoicesSet(exhibitor) &&
      KioskUtil.isKioskTypeSet(exhibitor)
    );
  }

  public static getIcon(isValid, isEditing) {
    if (isValid) {
      return 'mdi-check';
    }

    if (isEditing) {
      return 'mdi-circle-outline';
    }

    return 'mdi-close';
  }

  public static sendToUnity(unityInstance, property, data) {
    unityInstance.SendMessage('KioskBuilder', property, data);
  }

  public static initUnityKiosk(unityInstance, exhibitorForm) {
    setTimeout(() => {
      const {
        kioskType,
        zoneFirstColor,
        zoneSecondColor,
        zoneThirdColor,
        logo,
        sidePanelPicture,
        topPanelPicture,
        deskPanelPicture,
        surfaceColor,
        furnitureColor,
        videoThumbnail,
      } = exhibitorForm.kioskOptions;

      if (kioskType >= 1) {
        unityInstance.SendMessage('KioskBuilder', 'ChangeKiosk', kioskType - 1);
      }
      unityInstance.SendMessage('KioskBuilder', 'WallColors1', zoneFirstColor);
      unityInstance.SendMessage('KioskBuilder', 'WallColors2', zoneSecondColor);
      unityInstance.SendMessage('KioskBuilder', 'WallColors3', zoneThirdColor);
      unityInstance.SendMessage('KioskBuilder', 'Logo', logo);
      unityInstance.SendMessage('KioskBuilder', 'SidePanelPicture', sidePanelPicture);
      unityInstance.SendMessage('KioskBuilder', 'TopPanelPicture', topPanelPicture);
      unityInstance.SendMessage('KioskBuilder', 'DeskPanelPicture', deskPanelPicture);
      unityInstance.SendMessage('KioskBuilder', 'SurfaceColor', surfaceColor);
      unityInstance.SendMessage('KioskBuilder', 'FurnitureColor', furnitureColor);
      unityInstance.SendMessage('KioskBuilder', 'VideoThumbnail', videoThumbnail || logo);
    });
  }

  public static hasChanged(exhibitor, form) {
    return !isEqual(exhibitor, form);
  }

  private static allItemsAreSet(form: KioskOptions, checkedArray: string[]) {
    return checkedArray.every((element) => !!form[element] && form[element] !== '');
  }

  private static someItemsSetWithValidForm(form: Exhibitor, checkedArray: string[]) {
    return checkedArray.some((element) => !!form[element] && form[element] !== '');
  }
}
