import { HttpMethod } from '@/models';

import httpHelper from '@/helpers/http/http.helper';
import httpService from '@/services/http/http.service';
import { UpdatableUserTicket, UserTicket } from '@/models/ticketing/user-ticket';

interface UpdateTicketRequest {
  userId: string;
  eventId: string;
  ticket: UpdatableUserTicket;
}

class UserTicketService {
  public async update({ userId, eventId, ticket }: UpdateTicketRequest): Promise<UserTicket> {
    return httpService.perform<UserTicket>(
      HttpMethod.patch,
      httpHelper.buildApiRoute(httpHelper.USER_ENDPOINT, [userId, 'tickets', 'events', eventId]),
      ticket,
    );
  }
}

export default new UserTicketService();
