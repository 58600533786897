interface TranslateItem {
  fr: string;
  en: string;
}

interface ValuesProfileItem {
  value: TranslateItem;
  id?: string;
}

export enum ProfileItemType {
  text = 'text',
  number = 'number',
  textarea = 'textarea',
  listbox = 'listbox',
  listboxMultiple = 'listbox_multiple',
  radio = 'radio',
  checkbox = 'checkbox',
  date = 'date',
  phone = 'phone',
  email = 'email',
}

export const ProfileItemTypeWithValues = [
  ProfileItemType.listbox,
  ProfileItemType.listboxMultiple,
  ProfileItemType.radio,
  ProfileItemType.checkbox,
];

export interface ProfileItem {
  id?: string;
  name: TranslateItem;
  type: ProfileItemType;
  values?: ValuesProfileItem[];
  order: boolean;
  required: boolean;
  deleted: boolean;
}
