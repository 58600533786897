import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { Partner } from '@/models/event/partner.model';
import {
  ADD_PARTNER,
  ADD_PARTNER_ERROR,
  ADD_PARTNER_SUCCESS,
  CLEAR_PARTNER,
  DELETE_PARTNER,
  DELETE_PARTNER_ERROR,
  DELETE_PARTNER_SUCCESS,
  GET_PARTNER,
  GET_PARTNER_ERROR,
  GET_PARTNER_SUCCESS,
  GET_PARTNERS,
  GET_PARTNERS_ERROR,
  GET_PARTNERS_SUCCESS,
  SAVE_PARTNER,
  UPDATE_PARTNER,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_SUCCESS,
} from '@/stores/umanize-admin/actions/partners/partners.actions';
import partnerService from '@/services/partner/partner.service';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import i18n from '@/i18n';
import { MessageType } from '@/models';

interface PartnerStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isSaving: boolean;
}

export interface CurrentPartnerState {
  partner: Partner;
  status: PartnerStatus;
}

interface PartnersStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface PartnersState {
  partners: Partner[];
  status: PartnersStatus;
}

export interface PartnerState {
  partner: CurrentPartnerState;
  partners: PartnersState;
}

export const partnerState: CurrentPartnerState = {
  partner: null,
  status: {
    error: null,
    isLoading: false,
    isSaving: false,
  },
};

export const partnersState: PartnersState = {
  partners: [],
  status: {
    error: null,
    isLoading: true,
  },
};

export const state: PartnerState = {
  partner: partnerState,
  partners: partnersState,
};

const actions = {
  async [GET_PARTNERS](
    { commit }: ActionContext<PartnerState, RootState>,
    payload: {
      eventId: string;
      conferenceId?: string;
      discussionGroupId?: string;
    },
  ) {
    commit(GET_PARTNERS);

    try {
      const partners: Partner[] = await partnerService.getPartners(payload);
      commit(GET_PARTNERS_SUCCESS, partners);
    } catch (error) {
      commit(GET_PARTNERS_ERROR, error);
    }
  },
  async [GET_PARTNER](
    { commit }: ActionContext<PartnerState, RootState>,
    payload: {
      eventId: string;
      partnerId: string;
      conferenceId?: string;
      discussionGroupId?: string;
    },
  ) {
    commit(GET_PARTNER);

    try {
      const partner: Partner = await partnerService.getPartner(payload);
      commit(GET_PARTNER_SUCCESS, partner);
    } catch (error) {
      commit(GET_PARTNER_ERROR, error);
    }
  },
  async [SAVE_PARTNER](
    { dispatch }: ActionContext<PartnerState, RootState>,
    payload: { partner: Partner; conferenceId?: string; discussionGroupId?: string },
  ) {
    const action = payload?.partner?.id ? UPDATE_PARTNER : ADD_PARTNER;
    await dispatch(action, payload);
  },
  async [CLEAR_PARTNER]({ commit }: ActionContext<PartnerState, RootState>) {
    commit(CLEAR_PARTNER);
  },
  async [ADD_PARTNER](
    { commit, dispatch }: ActionContext<PartnerState, RootState>,
    payload: { partner: Partner; conferenceId?: string; discussionGroupId?: string },
  ) {
    commit(ADD_PARTNER);

    try {
      await partnerService.create(payload);
      commit(ADD_PARTNER_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        { text: i18n.t('admin.partners.success'), type: MessageType.info },
        { root: true },
      );
    } catch (error) {
      commit(ADD_PARTNER_ERROR, error);
    }
  },
  async [UPDATE_PARTNER](
    { commit }: ActionContext<PartnerState, RootState>,
    payload: { partner: Partner; conferenceId?: string; discussionGroupId?: string },
  ) {
    commit(UPDATE_PARTNER);

    try {
      await partnerService.update(payload);

      commit(UPDATE_PARTNER_SUCCESS);
    } catch (error) {
      commit(UPDATE_PARTNER_ERROR, error);
    }
  },
  async [DELETE_PARTNER](
    { commit }: ActionContext<PartnerState, RootState>,
    payload: { partner: Partner; conferenceId?: string; discussionGroupId?: string },
  ) {
    commit(DELETE_PARTNER);

    try {
      await partnerService.delete(payload);
      commit(DELETE_PARTNER_SUCCESS);
    } catch (error) {
      commit(DELETE_PARTNER_ERROR, error);
    }
  },
};

const mutations = {
  [GET_PARTNERS](state: PartnerState) {
    state.partners.status = {
      ...state.partners.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_PARTNERS_SUCCESS](state: PartnerState, partners: Partner[]) {
    state.partners = {
      ...state.partners,
      status: {
        ...state.partners.status,
        isLoading: false,
        error: null,
      },
      partners: [...partners],
    };
  },
  [GET_PARTNERS_ERROR](state: PartnerState, error: ErrorMessage) {
    state.partners.status = {
      ...state.partners.status,
      isLoading: false,
      error,
    };
  },
  [GET_PARTNER](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isLoading: true,
      error: null,
    };
  },
  [GET_PARTNER_SUCCESS](state: PartnerState, partner: Partner) {
    state.partner = {
      ...state.partner,
      status: {
        ...state.partner.status,
        isLoading: false,
        error: null,
      },
      partner: {
        ...partner,
      },
    };
  },
  [GET_PARTNER_ERROR](state: PartnerState, error: ErrorMessage) {
    state.partner.status = {
      ...state.partner.status,
      isLoading: false,
      error,
    };
  },
  [ADD_PARTNER](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_PARTNER_SUCCESS](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_PARTNER_ERROR](state: PartnerState, error: ErrorMessage) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_PARTNER](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_PARTNER_SUCCESS](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_PARTNER_ERROR](state: PartnerState, error: ErrorMessage) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_PARTNER](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_PARTNER_SUCCESS](state: PartnerState) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error: null,
    };
  },
  [DELETE_PARTNER_ERROR](state: PartnerState, error: ErrorMessage) {
    state.partner.status = {
      ...state.partner.status,
      isSaving: false,
      error,
    };
  },
  [CLEAR_PARTNER](state: PartnerState) {
    state.partner = {
      ...state.partner,
      partner: null,
    };
  },
};

const getters = {
  partnerState: (state: PartnerState): CurrentPartnerState => state.partner,
  partner: (_, { partnerState }: { partnerState: CurrentPartnerState }): Partner =>
    partnerState?.partner || null,

  partnerStatus: (_, { partnerState }: { partnerState: CurrentPartnerState }): PartnerStatus =>
    partnerState?.status || null,
  partnerIsLoading: (_, { partnerStatus }: { partnerStatus: PartnerStatus }): boolean =>
    partnerStatus?.isLoading || false,
  partnerIsSaving: (_, { partnerStatus }: { partnerStatus: PartnerStatus }): boolean =>
    partnerStatus?.isSaving || false,
  partnerError: (_, { partnerStatus }: { partnerStatus: PartnerStatus }): ErrorMessage =>
    partnerStatus?.error || null,

  partnersState: (state: PartnerState): PartnersState => state.partners,
  partners: (_, { partnersState }: { partnersState: PartnersState }): Partner[] =>
    partnersState?.partners || [],

  partnersStatus: (_, { partnersState }: { partnersState: PartnersState }): PartnersStatus =>
    partnersState?.status || null,
  partnersAreLoading: (_, { partnersStatus }: { partnersStatus: PartnersStatus }): boolean =>
    partnersStatus?.isLoading || false,
  partnersError: (_, { partnersStatus }: { partnersStatus: PartnersStatus }): ErrorMessage =>
    partnersStatus?.error || null,
};

export const PartnerModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
