import { Permissions } from '@/models/role/permissions.model';

export class Role {
  id: string;

  name: string;

  permissions: Permissions;

  constructor() {
    this.permissions = {
      admin: {
        canView: false,
      },
      conferenceSurveys: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      conferences: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canUpdatePartial: false,
        canView: false,
      },
      contentLibraries: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canUpdatePartial: false,
        canView: false,
      },
      customUi: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      discussionGroups: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      events: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canUpdatePartial: false,
        canView: false,
      },
      exhibitors: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      generalAssemblies: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      notifications: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      partners: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      roles: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      smartMatchings: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      tickets: {
        canCreate: false,
        canDelete: false,
        canUpdate: false,
        canView: false,
      },
      users: {
        canDelete: false,
        canImport: false,
        canUpdatePassword: false,
        canUpdateProfile: false,
        canUpdateTickets: false,
        canView: false,
      },
    };
  }
}
