import { QuestionnaireQuestion } from '@/models/interface/questionnaire-question.interface';
import type { MatchingAnswer } from '@/models';

export class MatchingQuestion implements QuestionnaireQuestion {
  public id: string;

  public eventId: string;

  public answers: MatchingAnswer[];

  public currentAnswerIds?: string[];

  public priority: number;

  public questions: {
    visitor: string;
    exhibitor: string;
  };

  constructor(
    {
      currentAnswerIds,
      questions,
      eventId,
      id,
      priority,
    }: {
      questions: {
        visitor: string;
        exhibitor: string;
      };
      eventId: string;
      currentAnswerIds?: string[];
      id?: string;
      priority?: number;
    },
    answers: MatchingAnswer[] = [],
  ) {
    this.questions = { ...questions };
    this.eventId = eventId;
    this.currentAnswerIds = currentAnswerIds;
    this.id = id;
    this.priority = priority;
    this.answers = answers;
  }
}
