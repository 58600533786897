import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { ASSEMBLY_MODULE } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';

export const ADMIN_ASSEMBLY_MODULE = `${UMANIZE_ADMIN_MODULE}/${ASSEMBLY_MODULE}`;

export const SAVE_GENERAL_ASSEMBLY = 'SAVE_GENERAL_ASSEMBLY';
export const UPDATE_GENERAL_ASSEMBLY = 'UPDATE_GENERAL_ASSEMBLY';
export const SAVE_UPDATE_GENERAL_ASSEMBLY = 'SAVE_UPDATE_GENERAL_ASSEMBLY';
export const SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS = 'SAVE_UPDATE_GENERAL_ASSEMBLY_SUCCESS';
export const SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR = 'SAVE_UPDATE_GENERAL_ASSEMBLY_ERROR';
