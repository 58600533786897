export class UserTicketProfile {
  firstName: string;

  lastName: string;

  about: string;

  ticketUserProfileId: string;

  id: string;

  userId: string;

  profilId: string;

  profileItems: {
    value?: string;
    values?: string[];
    profileItemId: string;
  }[];

  completed: boolean;

  ticketId: string;
}
