import { UMANIZE_ADMIN_MODULE } from '@/stores/umanize-admin/umanize-admin.module.config';
import { EXHIBITOR_MODULE } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';

export const ADMIN_EXHIBITOR_MODULE = `${UMANIZE_ADMIN_MODULE}/${EXHIBITOR_MODULE}`;

export const ADD_EXHIBITOR = 'ADD_EXHIBITOR';
export const ADD_EXHIBITOR_SUCCESS = 'ADD_EXHIBITOR_SUCCESS';
export const ADD_EXHIBITOR_ERROR = 'ADD_EXHIBITOR_ERROR';

export const UPDATE_EXHIBITOR = 'UPDATE_EXHIBITOR';
export const UPDATE_EXHIBITOR_SUCCESS = 'UPDATE_EXHIBITOR_SUCCESS';
export const UPDATE_EXHIBITOR_ERROR = 'UPDATE_EXHIBITOR_ERROR';

export const SAVE_EXHIBITOR = 'SAVE_EXHIBITOR';

export const LINK_USER = 'LINK_USER';
export const LINK_USER_SUCCESS = 'LINK_USER_SUCCESS';
export const LINK_USER_ERROR = 'LINK_USER_ERROR';

export const UNLINK_USER = 'UNLINK_USER';
export const UNLINK_USER_SUCCESS = 'UNLINK_USER_SUCCESS';
export const UNLINK_USER_ERROR = 'UNLINK_USER_ERROR';

export const UPLOAD_EXHIBITOR_VIDEO = 'UPLOAD_EXHIBITOR_VIDEO';
export const UPLOAD_EXHIBITOR_VIDEO_SUCCESS = 'UPLOAD_EXHIBITOR_VIDEO_SUCCESS';
export const UPLOAD_EXHIBITOR_VIDEO_ERROR = 'UPLOAD_EXHIBITOR_VIDEO_ERROR';

export const UPLOAD_EXHIBITOR_SCREENSHOT = 'UPLOAD_EXHIBITOR_SCREENSHOT';
export const UPLOAD_EXHIBITOR_SCREENSHOT_SUCCESS = 'UPLOAD_EXHIBITOR_SCREENSHOT_SUCCESS';
export const UPLOAD_EXHIBITOR_SCREENSHOT_ERROR = 'UPLOAD_EXHIBITOR_SCREENSHOT_ERROR';

export const CLEAR_EXHIBITOR_VIDEO = 'CLEAR_VIDEO';
