/* eslint-disable operator-linebreak */
/* tslint:disable:no-bitwise */
import Dinero from 'dinero.js';
import { utils as currencyUtils } from 'iso4217';
import { getUserLocale } from 'get-user-locale';

export interface JwtContent {
  exp?: number;
  iat?: number;
  user?: {
    email?: string;
    id?: string;
  };
}

export default abstract class DataUtil {
  public static parseJwt(token: string): JwtContent {
    if (token === null) {
      return {};
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('invalid token');
    }

    const decoded = DataUtil.decodeBase64(parts[1]);

    if (!decoded) {
      throw new Error('invalid payload');
    }

    return JSON.parse(decoded);
  }

  public static getTokenExpirationDate(token: string): Date {
    const decoded = DataUtil.parseJwt(token);

    if (!Object.prototype.hasOwnProperty.call(decoded, 'exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public static isTokenExpired(token: string, offsetSeconds = 0): boolean {
    const date = this.getTokenExpirationDate(token);
    if (date == null) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  public static decodeBase64(urlBase64: string): string {
    let output = urlBase64.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw Error('Illegal base64url string!');
      }
    }

    return this.b64DecodeUnicode(output);
  }

  public static b64decode(b64String: string): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    const newB64String = String(b64String).replace(/=+$/, '');

    if (newB64String.length % 4 === 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }

    for (
      let bc = 0, bs = void 0, buffer = void 0, idx = 0;
      (buffer = newB64String.charAt(idx++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }

  public static b64DecodeUnicode(b64String: string): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(
          this.b64decode(b64String),
          (c): string => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`,
        )
        .join(''),
    );
  }

  public static sortByStringAsc(array, sortParam) {
    return [...array].sort((a, b) => {
      if (a[sortParam] < b[sortParam]) {
        return -1;
      }
      if (a[sortParam] > b[sortParam]) {
        return 1;
      }
      return 0;
    });
  }

  public static sortByStringDesc(array, sortParam) {
    return [...array].sort((a, b) => {
      if (a[sortParam] > b[sortParam]) {
        return -1;
      }
      if (a[sortParam] < b[sortParam]) {
        return 1;
      }
      return 0;
    });
  }

  public static formattedPrice(amount, currency, locale = getUserLocale()) {
    if (!currency) {
      return '';
    }

    const dineroPrice = Dinero({
      amount: parseInt((amount * 100).toFixed(0), 10),
      currency,
      precision: currencyUtils.getByCode(currency)?.Fraction || 0, // Not every currency has a precision of 2 (See JPY)
    }).setLocale(locale);

    return Intl.NumberFormat(locale, {
      currency: dineroPrice.getCurrency(),
      style: 'currency',
      minimumFractionDigits: 0, // So we don't show .00 if the amount has no decimals
    }).format(dineroPrice.toUnit());
  }

  public static removeItemFromObjectArray(array, key, value) {
    const duplicatedArray = [...array];
    const index = duplicatedArray.findIndex((item) => item[key] === value);
    if (index >= 0) {
      duplicatedArray.splice(index, 1);
    }

    return duplicatedArray;
  }

  public static b64toBlob(b64Data) {
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'image/png' });
  }

  public static async getJsonData(url) {
    const response = await fetch(url)
      .then((response) => response.json())
      .catch((error) => console.error(error));

    return response;
  }
}
