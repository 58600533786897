import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import conferenceService from '@/services/conference/conference.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import {
  CLEAR_CONFERENCE_ROOM,
  GET_CONFERENCE,
  GET_CONFERENCE_ERROR,
  GET_CONFERENCE_ROOM,
  GET_CONFERENCE_ROOM_ERROR,
  GET_CONFERENCE_ROOM_SUCCESS,
  GET_CONFERENCE_ROOMS,
  GET_CONFERENCE_ROOMS_ERROR,
  GET_CONFERENCE_ROOMS_SUCCESS,
  GET_CONFERENCE_SUCCESS,
  GET_CONFERENCES_FOR_EVENT,
  GET_CONFERENCES_FOR_EVENT_ERROR,
  GET_CONFERENCES_FOR_EVENT_SUCCESS,
  GET_CONFERENCES_FOR_ROOM,
  GET_CONFERENCES_FOR_ROOM_ERROR,
  GET_CONFERENCES_FOR_ROOM_SUCCESS,
  GO_TO_NEXT_CONFERENCE_IN_ROOM,
  GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS,
  GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { Conference, ConferenceRoom } from '@/models';

export interface CurrentConferenceStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isOver: boolean;
}

export interface CurrentConferenceState {
  conference: Conference;
  status: CurrentConferenceStatus;
}

export interface ConferencesStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface ConferencesState {
  conferences: Conference[];
  status: ConferencesStatus;
}

export interface ConferenceRoomsStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface ConferenceRoomsState {
  selectedConferenceRoom: ConferenceRoom;
  conferenceRooms: ConferenceRoom[];
  status: ConferenceRoomsStatus;
}

export interface ConferenceRoomStatus {
  error: ErrorMessage;
  isLoading: boolean;
}

export interface ConferenceRoomState {
  conferenceRoom: ConferenceRoom;
  status: ConferenceRoomStatus;
}

const actions = {
  async getConferencesForEvent(
    { commit }: ActionContext<ConferencesState, RootState>,
    id: string,
  ): Promise<Conference[]> {
    commit(GET_CONFERENCES_FOR_EVENT);

    try {
      const conferences = await conferenceService.getConferencesForEvent(id);
      commit(GET_CONFERENCES_FOR_EVENT_SUCCESS, conferences);
      return conferences;
    } catch (error) {
      commit(GET_CONFERENCES_FOR_EVENT_ERROR, error);
      throw error;
    }
  },
  async getConferenceRooms({ commit }: ActionContext<ConferenceRoomsState, RootState>, id: string) {
    commit(GET_CONFERENCE_ROOMS);

    try {
      const conferenceRooms = await conferenceService.getConferenceRooms(id);
      commit(GET_CONFERENCE_ROOMS_SUCCESS, conferenceRooms);
    } catch (error) {
      commit(GET_CONFERENCE_ROOMS_ERROR, error);
    }
  },
  async getConference(
    { commit }: ActionContext<CurrentConferenceState, RootState>,
    payload: {
      eventId: string;
      conferenceId: string;
    },
  ): Promise<Conference> {
    commit(GET_CONFERENCE);

    try {
      const conference = await conferenceService.getConferenceById(
        payload.eventId,
        payload.conferenceId,
      );
      commit(GET_CONFERENCE_SUCCESS, conference);
      return conference;
    } catch (error) {
      commit(GET_CONFERENCE_ERROR, error);
      throw error;
    }
  },
  async getConferenceRoom(
    { commit }: ActionContext<ConferenceRoomState, RootState>,
    payload: { eventId: string; conferenceRoomId: string },
  ): Promise<ConferenceRoom> {
    commit(GET_CONFERENCE_ROOM);

    try {
      const conferenceRoom = await conferenceService.getConferenceRoomById(
        payload.eventId,
        payload.conferenceRoomId,
      );
      commit(GET_CONFERENCE_ROOM_SUCCESS, conferenceRoom);
      return conferenceRoom;
    } catch (error) {
      commit(GET_CONFERENCE_ROOM_ERROR, error);
      throw error;
    }
  },
  async getConferencesForRoom(
    { commit }: ActionContext<ConferencesState, RootState>,
    payload: { eventId: string; roomId: string },
  ): Promise<Conference[]> {
    commit(GET_CONFERENCES_FOR_ROOM);

    try {
      const conferences = await conferenceService.getConferencesForRoom(
        payload.eventId,
        payload.roomId,
      );
      commit(GET_CONFERENCES_FOR_ROOM_SUCCESS, conferences);
      return conferences;
    } catch (error) {
      commit(GET_CONFERENCES_FOR_ROOM_ERROR, error);
      throw error;
    }
  },
  async clearConferenceRoom({ commit }: ActionContext<ConferenceRoomsState, RootState>) {
    commit(CLEAR_CONFERENCE_ROOM);
  },
  async goToNextConferenceInRoom(
    { commit }: ActionContext<CurrentConferenceState, RootState>,
    payload: { eventId: string; conferenceId: string },
  ) {
    commit(GO_TO_NEXT_CONFERENCE_IN_ROOM);

    try {
      await conferenceService.goToNextConferenceInRoom(payload.eventId, payload.conferenceId);
      commit(GO_TO_NEXT_CONFERENCE_IN_ROOM_SUCCESS);
    } catch (error) {
      commit(GO_TO_NEXT_CONFERENCE_IN_ROOM_ERROR, error);
      throw error;
    }
  },
};

export const AgnosticConferenceModule = {
  namespaced: true,
  actions,
};
