import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

export const CONFERENCE_ADD_ANSWER_SURVEY_EVENT = 'add-survey-answer';

export const CONFERENCE_SPEAKER_NAMESPACE = `/speaker-conference`;

export const connectToConferenceSpeakerNamespace = (conferenceId: string): Socket =>
  connectToNamespace(CONFERENCE_SPEAKER_NAMESPACE, { conferenceId });

export const disconnectFromConferenceSpeakerNamespace = (conferenceSocket: Socket) =>
  conferenceSocket.disconnect();
