<template>
  <div class="assembly">
    <div class="assembly__content">
      <div class="content__header">
        <div class="header__title">{{ generalAssembly.name }}</div>
        <div class="header__description">{{ generalAssembly.description }}</div>

        <div class="header__date">
          {{ $d(Date.parse(generalAssembly.startTime), 'long') }}
        </div>
      </div>

      <v-btn
        v-if="isStarted()"
        class="content__action"
        :color="accentColor"
        :style="btnStyle"
        small
        depressed
        @click="$emit('select-general-assembly', generalAssembly.id)"
      >
        {{ $t('general-assembly.participate') }}
      </v-btn>
    </div>

    <div class="assembly__picture">
      <img
        class="picture"
        :src="generalAssembly.coverImages && generalAssembly.coverImages[0]"
        :alt="generalAssembly.name"
      />
    </div>
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';

export default {
  name: 'AssemblyItem',
  props: {
    generalAssembly: {
      type: Object,
      required: true,
    },
    now: {
      type: String,
      default: new Date().toISOString(),
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    btnStyle: {
      type: String,
      required: false,
    },
  },
  methods: {
    isStarted() {
      return DateUtil.canAccessConference(this.generalAssembly, this.now) || this.isAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.assembly {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 1rem 30px;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 25px;
  }

  &__picture {
    flex: 1;
    width: 50%;
  }
}

.content {
  &__action {
    max-width: 200px;
    border-radius: 5px;
  }
}

.header {
  &__title {
    color: var(--v-primaryBackground-base);
    font-weight: $bold;
    font-size: $default-font-size;
  }

  &__description {
    font-size: $small-font-size;
    margin-bottom: 30px;
  }

  &__date {
    font-size: $small-font-size;
    font-weight: $bold;
    margin-bottom: 5px;
  }
}

.picture {
  width: 100%;
}
</style>
