import { HttpMethod, Chat, NewChatForm } from '@/models';
import HttpHelper from '@/helpers/http/http.helper';
import HttpService from '@/services/http/http.service';

class ChatService {
  public async getChats(): Promise<Chat[]> {
    return HttpService.perform<Chat[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.CHATS_ENDPOINT),
    );
  }

  public async getEventChats(eventId): Promise<Chat[]> {
    return HttpService.perform<Chat[]>(
      HttpMethod.get,
      HttpHelper.buildApiRoute(HttpHelper.EVENTS_ENDPOINT, [eventId, HttpHelper.CHATS_ENDPOINT]),
    );
  }

  public async createChat(payload): Promise<Chat> {
    const newChatForm: NewChatForm = {
      userIds: payload.userIds,
      adminIds: payload.adminIds || [],
      name: payload?.name || '',
      eventId: payload?.eventId || '',
    };

    return HttpService.perform<Chat>(
      HttpMethod.post,
      HttpHelper.buildApiRoute(HttpHelper.CHATS_ENDPOINT),
      newChatForm,
    );
  }

  public async updateChat({ chatId, chat }): Promise<Chat> {
    return HttpService.perform<Chat>(
      HttpMethod.put,
      HttpHelper.buildApiRoute(HttpHelper.CHATS_ENDPOINT, [chatId]),
      chat,
    );
  }
}

export default new ChatService();
