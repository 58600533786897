import { ActionContext } from 'vuex';

import { RootState } from '@/stores/store.model';
import { ActivityResult, ActivityQuestion } from '@/models';
import activityService from '@/services/activity/activity.service';
import {
  ANSWER_ACTIVITY_ERROR,
  ANSWER_ACTIVITY,
  ANSWER_ACTIVITY_SUCCESS,
  GET_ACTIVITY_RESULT,
  GET_ACTIVITY_RESULT_ERROR,
  GET_ACTIVITY_QUESTIONS,
  GET_ACTIVITY_QUESTIONS_ERROR,
  GET_ACTIVITY_QUESTIONS_SUCCESS,
  GET_ACTIVITY_RESULT_SUCCESS,
} from '@/stores/umanize-app/actions/activity/activity.actions';
import { ErrorMessage } from '@/models/error-message/error-message.model';

export interface ActivityQuestionsStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface ActivityQuestionsState {
  questions: ActivityQuestion[];
  status: ActivityQuestionsStatus;
}

const questionsState: ActivityQuestionsState = {
  questions: [],
  status: {
    error: null,
    isLoading: true,
    isLoaded: false,
  },
};

export interface ActivityAnswersStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface ActivityAnswersState {
  status: ActivityAnswersStatus;
}

const answersState: ActivityAnswersState = {
  status: {
    error: null,
    isLoading: true,
    isLoaded: false,
  },
};

export interface ActivityResultStatus {
  error: ErrorMessage;
  isLoading: boolean;
  isLoaded: boolean;
}

export interface ActivityResultState {
  result: ActivityResult;
  status: ActivityResultStatus;
}

const resultState: ActivityResultState = {
  result: null,
  status: {
    error: null,
    isLoading: true,
    isLoaded: false,
  },
};

export interface ActivityState {
  result: ActivityResultState;
  questions: ActivityQuestionsState;
  answers: ActivityAnswersState;
}

const state: ActivityState = {
  result: resultState,
  questions: questionsState,
  answers: answersState,
};

const actions = {
  async [GET_ACTIVITY_QUESTIONS]({ commit }: ActionContext<ActivityState, RootState>, eventId) {
    commit(GET_ACTIVITY_QUESTIONS);

    try {
      const questions: ActivityQuestion[] = await activityService.getQuestions(eventId);
      commit(GET_ACTIVITY_QUESTIONS_SUCCESS, questions);
    } catch (error) {
      commit(GET_ACTIVITY_QUESTIONS_ERROR, error);
    }
  },

  async [ANSWER_ACTIVITY](
    { commit }: ActionContext<ActivityState, RootState>,
    { eventId, questionId, answerId }: { eventId: string; questionId: string; answerId: string },
  ) {
    commit(ANSWER_ACTIVITY);

    try {
      await activityService.answerQuestion({ eventId, questionId, answerIds: [answerId] });
      commit(ANSWER_ACTIVITY_SUCCESS, { questionId, answerId });

      return { questionId, answerId };
    } catch (error) {
      commit(ANSWER_ACTIVITY_ERROR, error);
      throw error;
    }
  },
  async [GET_ACTIVITY_RESULT]({ commit }: ActionContext<ActivityResultState, RootState>, eventId) {
    commit(GET_ACTIVITY_RESULT);

    try {
      const results: ActivityResult = await activityService.getResults(eventId);
      commit(GET_ACTIVITY_RESULT_SUCCESS, results);
    } catch (error) {
      commit(GET_ACTIVITY_RESULT_ERROR, error);
    }
  },
};

const mutations = {
  [GET_ACTIVITY_QUESTIONS](state: ActivityState) {
    state.questions = {
      ...state.questions,
      status: {
        ...state.questions.status,
        isLoading: true,
        isLoaded: false,
        error: null,
      },
    };
  },
  [GET_ACTIVITY_QUESTIONS_SUCCESS](state: ActivityState, questions: ActivityQuestion[]) {
    state.questions = {
      ...state.questions,
      status: {
        ...state.questions.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      questions: [...questions],
    };
  },
  [GET_ACTIVITY_QUESTIONS_ERROR](state: ActivityState, error: ErrorMessage) {
    state.questions = {
      ...state.questions,
      status: {
        ...state.questions.status,
        isLoading: false,
        isLoaded: false,
        error,
      },
    };
  },
  [ANSWER_ACTIVITY](state: ActivityState) {
    state.answers = {
      ...state.answers,
      status: {
        ...state.answers.status,
        isLoading: true,
        isLoaded: false,
        error: null,
      },
    };
  },
  [ANSWER_ACTIVITY_SUCCESS](
    state: ActivityState,
    { questionId, answerId }: { questionId: string; answerId: string },
  ) {
    state.questions = {
      ...state.questions,
      questions: (state.questions.questions || []).map((q: ActivityQuestion) =>
        q.id === questionId ? { ...q, currentAnswerIds: [answerId] } : q,
      ),
    };
    state.answers = {
      ...state.answers,
      status: {
        ...state.answers.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
    };
  },
  [ANSWER_ACTIVITY_ERROR](state: ActivityState, error: ErrorMessage) {
    state.answers = {
      ...state.answers,
      status: {
        ...state.answers.status,
        isLoading: false,
        isLoaded: false,
        error,
      },
    };
  },
  [GET_ACTIVITY_RESULT](state: ActivityState) {
    state.result = {
      ...state.result,
      status: {
        ...state.result.status,
        isLoading: true,
        isLoaded: false,
        error: null,
      },
    };
  },
  [GET_ACTIVITY_RESULT_SUCCESS](state: ActivityState, result: ActivityResult) {
    state.result = {
      ...state.result,
      status: {
        ...state.result.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      result,
    };
  },
  [GET_ACTIVITY_RESULT_ERROR](state: ActivityState, error: ErrorMessage) {
    state.result = {
      ...state.result,
      status: {
        ...state.result.status,
        isLoading: false,
        isLoaded: false,
        error,
      },
    };
  },
};

const getters = {
  questionState: (state: ActivityState): ActivityQuestionsState => state.questions,
  questions: (
    _,
    { questionState }: { questionState: ActivityQuestionsState },
  ): ActivityQuestion[] => questionState?.questions,

  questionStatus: (
    _,
    { questionState }: { questionState: ActivityQuestionsState },
  ): ActivityQuestionsStatus => questionState?.status,
  questionIsLoading: (
    _,
    { questionStatus }: { questionStatus: ActivityQuestionsStatus },
  ): boolean => questionStatus?.isLoading,
  questionIsLoaded: (_, { questionStatus }: { questionStatus: ActivityQuestionsStatus }): boolean =>
    questionStatus?.isLoaded,
  questionError: (
    _,
    { questionStatus }: { questionStatus: ActivityQuestionsStatus },
  ): ErrorMessage => questionStatus?.error,

  answersState: (state: ActivityState): ActivityAnswersState => state.answers,
  answersStatus: (
    _,
    { answersState }: { answersState: ActivityAnswersState },
  ): ActivityAnswersStatus => answersState?.status,

  answersIsLoading: (_, { answersStatus }: { answersStatus: ActivityAnswersStatus }): boolean =>
    answersStatus?.isLoading,
  answersIsLoaded: (_, { answersStatus }: { answersStatus: ActivityAnswersStatus }): boolean =>
    answersStatus?.isLoaded,
  answersError: (_, { answersStatus }: { answersStatus: ActivityAnswersStatus }): ErrorMessage =>
    answersStatus?.error,

  resultState: (state: ActivityState): ActivityResultState => state.result,
  activityResult: (_, { resultState }: { resultState: ActivityResultState }): ActivityResult =>
    resultState?.result,

  resultStatus: (_, { resultState }: { resultState: ActivityResultState }): ActivityResultStatus =>
    resultState?.status,
  activityResultIsLoading: (_, { resultStatus }: { resultStatus: ActivityResultStatus }): boolean =>
    resultStatus?.isLoading,
  activityResultIsLoaded: (_, { resultStatus }: { resultStatus: ActivityResultStatus }): boolean =>
    resultStatus?.isLoaded,
  activityResultError: (
    _,
    { resultStatus }: { resultStatus: ActivityResultStatus },
  ): ErrorMessage => resultStatus?.error,
};

export const ActivityModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
