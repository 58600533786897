import { SHARED_MODULE } from '@/stores/shared/shared.module.config';

export const FILE_MODULE = 'FileUploadModule';
export const SHARED_FILE_MODULE = `${SHARED_MODULE}/${FILE_MODULE}`;

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const CLEAR_FILE = 'CLEAR_FILE';
