import { Socket } from 'socket.io-client';
import {
  ASSEMBLY_CHAT_NEW_MESSAGE,
  ASSEMBLY_CHAT_UPDATED_MESSAGE,
  ASSEMBLY_GET_QUESTION,
  ASSEMBLY_LOAD_MESSAGES,
  ASSEMBLY_LOAD_QUESTIONS,
  ASSEMBLY_QUESTION_CLOSED,
  ASSEMBLY_QUESTION_CREATED,
  ASSEMBLY_QUESTION_STARTED,
} from '@/socket/assembly-namespace';
import {
  ChatMessage,
  GetQuestionSuccess,
  LoadAssemblyMessagesSuccess,
  LoadAssemblyQuestionsSuccess,
  NewMessage,
  Question,
} from '@/models';
import {
  ADD_QUESTION_SUCCESS,
  ASSEMBLY_CHAT_LOAD_MESSAGES,
  ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS,
  ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS,
  ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS,
  CLOSE_QUESTION_SUCCESS,
  GET_QUESTION_SUCCESS,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  START_QUESTION_SUCCESS,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';

export const addSharedSocketEvents = (assemblySocket: Socket, context) => {
  assemblySocket.on(ASSEMBLY_LOAD_QUESTIONS, async (payload: LoadAssemblyQuestionsSuccess) => {
    context.commit(GET_QUESTIONS_SUCCESS, payload.questions);
    const { eventId, assemblyId } = payload;
    const ongoingQuestion = payload?.questions.find(
      (question: Question) => !!question.startedAt && !question.closedAt,
    );
    if (ongoingQuestion) {
      assemblySocket.emit('assembly-get-question', eventId, assemblyId, ongoingQuestion.id);
    }
  });

  assemblySocket.on('connect', () => {
    const { id, eventId } = context.getters.generalAssembly;

    context.dispatch(GET_QUESTIONS, { assemblyId: id, eventId });
    context.dispatch(ASSEMBLY_CHAT_LOAD_MESSAGES, { assemblyId: id, eventId });
  });

  assemblySocket.on(ASSEMBLY_GET_QUESTION, async (payload: GetQuestionSuccess) => {
    context.commit(GET_QUESTION_SUCCESS, payload.question);
  });

  assemblySocket.on(ASSEMBLY_QUESTION_CLOSED, async (question: Question) => {
    context.commit(CLOSE_QUESTION_SUCCESS, question);
  });

  assemblySocket.on(ASSEMBLY_QUESTION_CREATED, async (question: Question) => {
    context.commit(ADD_QUESTION_SUCCESS, question);
  });

  assemblySocket.on(ASSEMBLY_QUESTION_STARTED, async (question: Question) => {
    context.commit(GET_QUESTION_SUCCESS, question);
    context.commit(START_QUESTION_SUCCESS, question);
  });

  assemblySocket.on(ASSEMBLY_LOAD_MESSAGES, async (payload: LoadAssemblyMessagesSuccess) => {
    context.commit(ASSEMBLY_CHAT_LOAD_MESSAGES_SUCCESS, payload.messages);
  });

  assemblySocket.on(ASSEMBLY_CHAT_NEW_MESSAGE, async (payload: NewMessage) => {
    context.commit(ASSEMBLY_SEND_CHATS_MESSAGE_SUCCESS, payload);
  });

  assemblySocket.on(ASSEMBLY_CHAT_UPDATED_MESSAGE, async (message: ChatMessage) => {
    context.commit(ASSEMBLY_CHAT_UPDATED_MESSAGE_SUCCESS, message);
  });
};
