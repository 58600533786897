import { Socket } from 'socket.io-client';
import { connectToNamespace } from '@/socket/socket-v3';

const NAMESPACE = '/chat';

let chatSocket;

export const getChatSocket = (): Socket => chatSocket;

export const setChatSocket = (socket) => {
  chatSocket = socket;
};

export const connectToChatNamespace = (): Socket => connectToNamespace(NAMESPACE);

export const disconnectFromChatNamespace = () => {
  chatSocket.disconnect();
  chatSocket = null;
};
