import { ActionContext } from 'vuex';
import i18n from '@/i18n';
import { RootState } from '@/stores/store.model';
import { ContentCategory, ContentItem, MessageType } from '@/models';
import ContentLibraryService from '@/services/content-library/content-library.service';
import { ErrorMessage } from '@/models/error-message/error-message.model';
import { MESSAGE_MODULE, DISPLAY_MESSAGE } from '@/stores/shared/actions/message/message.actions';
import {
  AgnosticContentModule,
  CategoriesState,
  CategoriesStatus,
  CategoryState,
  CategoryStatus,
  ItemsState,
  ItemsStatus,
  ItemState,
  ItemStatus,
} from '@/stores/agnostic/modules/content/agnostic-content.module';
import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_CATEGORIES_ERROR,
  GET_CONTENT_CATEGORIES_SUCCESS,
  GET_CONTENT_CATEGORY,
  GET_CONTENT_CATEGORY_ERROR,
  GET_CONTENT_CATEGORY_SUCCESS,
  GET_CONTENT_ITEM,
  GET_CONTENT_ITEM_ERROR,
  GET_CONTENT_ITEM_SUCCESS,
  GET_CONTENT_ITEMS,
  GET_CONTENT_ITEMS_ERROR,
  GET_CONTENT_ITEMS_SUCCESS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';
import {
  ADD_CONTENT_CATEGORY,
  ADD_CONTENT_CATEGORY_ERROR,
  ADD_CONTENT_CATEGORY_SUCCESS,
  ADD_CONTENT_ITEM,
  ADD_CONTENT_ITEM_ERROR,
  ADD_CONTENT_ITEM_SUCCESS,
  CLEAR_CONTENT_CATEGORY,
  CLEAR_CONTENT_ITEM,
  SAVE_CONTENT_CATEGORY,
  SAVE_CONTENT_ITEM,
  UPDATE_CONTENT_CATEGORY,
  UPDATE_CONTENT_CATEGORY_ERROR,
  UPDATE_CONTENT_CATEGORY_SUCCESS,
  UPDATE_CONTENT_ITEM,
  UPDATE_CONTENT_ITEM_ERROR,
  UPDATE_CONTENT_ITEM_SUCCESS,
  DELETE_CONTENT_CATEGORY,
  DELETE_CONTENT_CATEGORY_ERROR,
  DELETE_CONTENT_CATEGORY_SUCCESS,
  DELETE_CONTENT_ITEM,
  DELETE_CONTENT_ITEM_ERROR,
  DELETE_CONTENT_ITEM_SUCCESS,
  UPLOAD_CONTENT_ITEM_VIDEO,
  UPLOAD_CONTENT_ITEM_VIDEO_ERROR,
  UPLOAD_CONTENT_ITEM_VIDEO_SUCCESS,
  DELETE_CONTENT_ITEM_VIDEO,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';
import trainingService from '@/services/training/training.service';

export interface AdminContentState {
  categories: CategoriesState;
  category: CategoryState;
  items: ItemsState;
  item: ItemState;
  videoUpload: VideoUploadState;
}

export interface VideoFile {
  title: string;
  file: File;
}

export interface VideoUploadState {
  video: VideoFile;
  status: {
    error: ErrorMessage;
    isSending: boolean;
    isUploaded: boolean;
  };
}

const videoUploadState: VideoUploadState = {
  video: null,
  status: {
    error: null,
    isSending: false,
    isUploaded: false,
  },
};
const categories: CategoriesState = {
  categories: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const category: CategoryState = {
  category: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const items: ItemsState = {
  items: [],
  status: {
    error: null,
    areLoading: true,
  },
};

const item: ItemState = {
  item: null,
  status: {
    error: null,
    isLoading: true,
    isSaving: false,
  },
};

const state: AdminContentState = {
  categories,
  category,
  items,
  item,
  videoUpload: videoUploadState,
};

const actions = {
  [GET_CONTENT_CATEGORIES]: AgnosticContentModule.actions.getContentCategories,
  [GET_CONTENT_CATEGORY]: AgnosticContentModule.actions.getContentCategory,
  [GET_CONTENT_ITEMS]: AgnosticContentModule.actions.getContentItems,
  [GET_CONTENT_ITEM]: AgnosticContentModule.actions.getContentItem,
  async [SAVE_CONTENT_CATEGORY](
    { dispatch }: ActionContext<CategoryState, RootState>,
    contentCategory: ContentCategory,
  ) {
    const dispatchedAction = contentCategory?.id ? UPDATE_CONTENT_CATEGORY : ADD_CONTENT_CATEGORY;
    await dispatch(dispatchedAction, contentCategory);
  },
  async [UPDATE_CONTENT_CATEGORY](
    { commit, dispatch }: ActionContext<CategoryState, RootState>,
    payload: ContentCategory,
  ) {
    commit(UPDATE_CONTENT_CATEGORY);
    try {
      await ContentLibraryService.updateContentLibrary(payload);

      commit(UPDATE_CONTENT_CATEGORY_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentLibraries.update.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentLibraries.update.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_CONTENT_CATEGORY_ERROR, error);
    }
  },
  async [ADD_CONTENT_CATEGORY](
    { commit, dispatch }: ActionContext<CategoryState, RootState>,
    payload: ContentCategory,
  ) {
    commit(ADD_CONTENT_CATEGORY);

    try {
      await ContentLibraryService.createContentLibrary(payload);

      commit(ADD_CONTENT_CATEGORY_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentLibraries.add.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentLibraries.add.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(ADD_CONTENT_CATEGORY_ERROR, error);
    }
  },
  [CLEAR_CONTENT_CATEGORY]({ commit }: ActionContext<CategoryState, RootState>) {
    commit(CLEAR_CONTENT_CATEGORY);
  },
  async [ADD_CONTENT_ITEM](
    { commit, dispatch }: ActionContext<ItemState, RootState>,
    payload: ContentItem,
  ) {
    commit(ADD_CONTENT_ITEM);

    try {
      await ContentLibraryService.createContentItem(payload);

      commit(ADD_CONTENT_ITEM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentItem.add.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentItem.add.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(ADD_CONTENT_ITEM_ERROR, error);
    }
  },
  async [UPDATE_CONTENT_ITEM](
    { commit, dispatch }: ActionContext<ItemState, RootState>,
    payload: ContentItem,
  ) {
    commit(UPDATE_CONTENT_ITEM);

    try {
      await ContentLibraryService.updateContentItem(payload);

      commit(UPDATE_CONTENT_ITEM_SUCCESS);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentItem.update.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentItem.update.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(UPDATE_CONTENT_ITEM_ERROR, error);
    }
  },
  [CLEAR_CONTENT_ITEM]({ commit }: ActionContext<ItemState, RootState>) {
    commit(CLEAR_CONTENT_ITEM);
  },
  async [SAVE_CONTENT_ITEM](
    { dispatch }: ActionContext<ItemState, RootState>,
    contentItem: ContentItem,
  ) {
    const dispatchedAction = contentItem?.id ? UPDATE_CONTENT_ITEM : ADD_CONTENT_ITEM;
    await dispatch(dispatchedAction, contentItem);
  },
  async [DELETE_CONTENT_CATEGORY](
    { commit, dispatch }: ActionContext<CategoryState, RootState>,
    payload: { eventId: string; categoryId: string },
  ) {
    commit(DELETE_CONTENT_CATEGORY);
    try {
      await ContentLibraryService.deleteContentLibrary(payload.eventId, payload.categoryId);

      commit(DELETE_CONTENT_CATEGORY_SUCCESS, payload.categoryId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentLibraries.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentLibraries.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_CONTENT_CATEGORY_ERROR, error);
    }
  },
  async [DELETE_CONTENT_ITEM](
    { commit, dispatch }: ActionContext<CategoryState, RootState>,
    payload: { eventId: string; categoryId: string; itemId: string },
  ) {
    commit(DELETE_CONTENT_ITEM);
    try {
      await ContentLibraryService.deleteContentItem(
        payload.eventId,
        payload.categoryId,
        payload.itemId,
      );

      commit(DELETE_CONTENT_ITEM_SUCCESS, payload.itemId);
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t('contentItem.delete.success'),
          type: MessageType.info,
        },
        {
          root: true,
        },
      );
    } catch (error: unknown | ErrorMessage) {
      dispatch(
        `${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`,
        {
          text: i18n.t(`contentItem.delete.errors.${(error as ErrorMessage).status}`),
          type: MessageType.error,
        },
        {
          root: true,
        },
      );

      commit(DELETE_CONTENT_ITEM_ERROR, error);
    }
  },
  async [UPLOAD_CONTENT_ITEM_VIDEO](
    { commit }: ActionContext<VideoUploadState, RootState>,
    {
      eventId,
      contentLibraryId,
      video,
      trainingId,
    }: {
      eventId: string;
      contentLibraryId: string;
      video: File;
      trainingId?: string;
    },
  ) {
    commit(UPLOAD_CONTENT_ITEM_VIDEO);

    try {
      const uploadedFile = contentLibraryId
        ? await ContentLibraryService.upload(eventId, contentLibraryId, video)
        : await trainingService.upload(eventId, trainingId, video);
      commit(UPLOAD_CONTENT_ITEM_VIDEO_SUCCESS, uploadedFile);
    } catch (error) {
      commit(UPLOAD_CONTENT_ITEM_VIDEO_ERROR, error);
    }
  },
  [DELETE_CONTENT_ITEM_VIDEO]({ commit }: ActionContext<CategoryState, RootState>) {
    commit(DELETE_CONTENT_ITEM_VIDEO);
  },
};

const mutations = {
  [GET_CONTENT_CATEGORIES](state) {
    state.categories.status = {
      ...state.categories.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_CONTENT_CATEGORIES_SUCCESS](state, categories) {
    state.categories = {
      status: {
        ...state.categories.status,
        areLoading: false,
        error: null,
      },
      categories: [...categories],
    };
  },
  [GET_CONTENT_CATEGORIES_ERROR](state, error) {
    state.categories.status = {
      ...state.categories.status,
      areLoading: false,
      error,
    };
  },
  [GET_CONTENT_CATEGORY](state) {
    state.category.status = {
      ...state.category.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_CONTENT_CATEGORY_SUCCESS](state, contentCategory: ContentCategory) {
    state.category = {
      status: {
        ...state.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      category: {
        ...contentCategory,
      },
    };
  },
  [GET_CONTENT_CATEGORY_ERROR](state, error) {
    state.category.status = {
      ...state.category.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
  [GET_CONTENT_ITEMS](state) {
    state.items.status = {
      ...state.items.status,
      areLoading: true,
      error: null,
    };
  },
  [GET_CONTENT_ITEMS_SUCCESS](state, contentItems) {
    state.items = {
      status: {
        ...state.items.status,
        areLoading: false,
        error: null,
      },
      items: [...contentItems],
    };
  },
  [GET_CONTENT_ITEMS_ERROR](state, error) {
    state.items.status = {
      ...state.items.status,
      areLoading: false,
      error,
    };
  },
  [GET_CONTENT_ITEM](state) {
    state.item.status = {
      ...state.item.status,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  },
  [GET_CONTENT_ITEM_SUCCESS](state, contentItem: ContentItem) {
    state.item = {
      status: {
        ...state.status,
        isLoading: false,
        isLoaded: true,
        error: null,
      },
      item: {
        ...contentItem,
      },
    };
  },
  [GET_CONTENT_ITEM_ERROR](state, error) {
    state.item.status = {
      ...state.item.status,
      isLoading: false,
      isLoaded: false,
      error,
    };
  },
  [UPDATE_CONTENT_CATEGORY](state: AdminContentState) {
    state.category.status = {
      ...state.category.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_CONTENT_CATEGORY_SUCCESS](state: AdminContentState) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_CONTENT_CATEGORY_ERROR](state: AdminContentState, error) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error,
    };
  },
  [ADD_CONTENT_CATEGORY](state: AdminContentState) {
    state.category.status = {
      ...state.category.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_CONTENT_CATEGORY_SUCCESS](state: AdminContentState) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_CONTENT_CATEGORY_ERROR](state: AdminContentState, error) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error,
    };
  },
  [CLEAR_CONTENT_CATEGORY](state) {
    state.category = {
      ...state.category,
      category: null,
    };
  },
  [DELETE_CONTENT_CATEGORY](state: AdminContentState) {
    state.category.status = {
      ...state.category.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_CONTENT_CATEGORY_SUCCESS](state: AdminContentState, categoryId: string) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error: null,
    };
    state.categories.categories = state.categories.categories.filter(
      (category) => category.id !== categoryId,
    );
  },
  [DELETE_CONTENT_CATEGORY_ERROR](state: AdminContentState, error: ErrorMessage) {
    state.category.status = {
      ...state.category.status,
      isSaving: false,
      error,
    };
  },
  [ADD_CONTENT_ITEM](state: AdminContentState) {
    state.item.status = {
      ...state.item.status,
      isSaving: true,
      error: null,
    };
  },
  [ADD_CONTENT_ITEM_SUCCESS](state: AdminContentState) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error: null,
    };
  },
  [ADD_CONTENT_ITEM_ERROR](state: AdminContentState, error) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error,
    };
  },
  [UPDATE_CONTENT_ITEM](state: AdminContentState) {
    state.item.status = {
      ...state.item.status,
      isSaving: true,
      error: null,
    };
  },
  [UPDATE_CONTENT_ITEM_SUCCESS](state: AdminContentState) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error: null,
    };
  },
  [UPDATE_CONTENT_ITEM_ERROR](state: AdminContentState, error) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error,
    };
  },
  [DELETE_CONTENT_ITEM](state: AdminContentState) {
    state.item.status = {
      ...state.item.status,
      isSaving: true,
      error: null,
    };
  },
  [DELETE_CONTENT_ITEM_SUCCESS](state: AdminContentState, itemId: string) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error: null,
    };
    state.items.items = state.items.items.filter((item) => item.id !== itemId);
  },
  [DELETE_CONTENT_ITEM_ERROR](state: AdminContentState, error: ErrorMessage) {
    state.item.status = {
      ...state.item.status,
      isSaving: false,
      error,
    };
  },
  [CLEAR_CONTENT_ITEM](state) {
    state.item = {
      ...state.item,
      item: null,
    };
  },
  [UPLOAD_CONTENT_ITEM_VIDEO](state: AdminContentState) {
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: true,
      isUploaded: false,
      error: null,
    };
  },
  [UPLOAD_CONTENT_ITEM_VIDEO_SUCCESS](state: AdminContentState, video: VideoFile) {
    state.videoUpload.video = {
      ...video,
    };
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: false,
      isUploaded: true,
      error: null,
    };
  },
  [UPLOAD_CONTENT_ITEM_VIDEO_ERROR](state: AdminContentState, error: ErrorMessage) {
    state.videoUpload.status = {
      ...state.videoUpload.status,
      isSending: false,
      isUploaded: false,
      error,
    };
  },
  [DELETE_CONTENT_ITEM_VIDEO](state: AdminContentState) {
    state.videoUpload = {
      ...state.videoUpload,
      video: null,
      status: {
        ...state.videoUpload.status,
        isUploaded: false,
      },
    };
  },
};

const getters = {
  categoriesState: (state: AdminContentState): CategoriesState => state.categories,
  contentCategories: (
    _,
    { categoriesState }: { categoriesState: CategoriesState },
  ): ContentCategory[] => categoriesState?.categories || [],
  categoriesStatus: (
    _,
    { categoriesState }: { categoriesState: CategoriesState },
  ): CategoriesStatus => categoriesState?.status || null,
  categoriesAreLoading: (
    _,
    { categoriesStatus }: { categoriesStatus: CategoriesStatus },
  ): boolean => categoriesStatus?.areLoading || false,
  categoriesError: (
    _,
    { categoriesStatus }: { categoriesStatus: CategoriesStatus },
  ): ErrorMessage => categoriesStatus?.error || null,

  categoryState: (state: AdminContentState): CategoryState => state.category,
  contentCategory: (_, { categoryState }: { categoryState: CategoryState }): ContentCategory =>
    categoryState?.category || null,
  categoryStatus: (_, { categoryState }: { categoryState: CategoryState }): CategoryStatus =>
    categoryState?.status || null,
  categoryIsLoading: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): boolean =>
    categoryStatus?.isLoading || false,
  categoryIsSaving: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): boolean =>
    categoryStatus?.isSaving || false,
  categoryError: (_, { categoryStatus }: { categoryStatus: CategoryStatus }): ErrorMessage =>
    categoryStatus?.error || null,

  itemsState: (state: AdminContentState): ItemsState => state.items,
  contentItems: (_, { itemsState }: { itemsState: ItemsState }): ContentItem[] =>
    itemsState?.items || [],
  itemsStatus: (_, { itemsState }: { itemsState: ItemsState }): ItemsStatus =>
    itemsState?.status || null,
  itemsAreLoading: (_, { itemsStatus }: { itemsStatus: ItemsStatus }): boolean =>
    itemsStatus?.areLoading || false,
  itemsError: (_, { itemsStatus }: { itemsStatus: ItemsStatus }): ErrorMessage =>
    itemsStatus?.error || null,

  itemState: (state: AdminContentState): ItemState => state.item,
  contentItem: (_, { itemState }: { itemState: ItemState }): ContentItem => itemState?.item || null,
  itemStatus: (_, { itemState }: { itemState: ItemState }): ItemStatus => itemState?.status || null,
  itemIsLoading: (_, { itemStatus }: { itemStatus: ItemStatus }): boolean =>
    itemStatus?.isLoading || false,
  itemIsSaving: (_, { itemStatus }: { itemStatus: ItemStatus }): boolean =>
    itemStatus?.isSaving || false,
  itemError: (_, { itemStatus }: { itemStatus: ItemStatus }): ErrorMessage =>
    itemStatus?.error || null,
  video: (state: AdminContentState): VideoFile => state.videoUpload.video || null,
  videoIsSending: (state: AdminContentState): boolean =>
    (state.videoUpload.status && state.videoUpload.status.isSending) || false,
  videoIsUploaded: (state: AdminContentState): boolean =>
    (state.videoUpload.status && state.videoUpload.status.isUploaded) || false,
  videoError: (state: AdminContentState): ErrorMessage =>
    (state.videoUpload.status && state.videoUpload.status.error) || null,
};

export const AdminContentModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
